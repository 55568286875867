import { Link } from "@mui/material";
import React from "react";

import styles from "./LinkCellRenderer.module.scss";

interface LinkCellRendererProps<T> {
  value: string;
  data: T;
  getLink?: (data: T) => string | undefined;
  target?: React.HTMLAttributeAnchorTarget;
  onClick?: (data: T) => void;
}

export const LinkCellRenderer = <T,>({
  value,
  data,
  getLink,
  target,
  onClick,
}: LinkCellRendererProps<T>) => {
  const handleClick = () => {
    if (onClick) {
      onClick(data);
    }
  };

  const handleGetLink = () => {
    if (getLink) {
      return getLink(data);
    }

    return undefined;
  };

  return (
    <Link
      href={handleGetLink()}
      onClick={handleClick}
      target={target}
      className={styles.link}
    >
      {value}
    </Link>
  );
};
