import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { FileDownloadOutlined } from "@mui/icons-material";
import { Search } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { GridReadyEvent } from "ag-grid-community";
import { IRowNode } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState } from "react";

import { ElectionPeriodTreeLevel, TreeNumber } from "../../../constants/enums";
import { PerformanceDetailsLabel } from "../../../constants/LabelAndTooltipConstants";
import { StringConstants } from "../../../constants/StringConstants";
import { useGridExtensions } from "../../../hooks/useGridExtensions";
import {
  IAsOfDate,
  IInvestmentBreakdownGrouped,
  IInvestmentBreakdownViewModel,
} from "../../../types/dataTypes";
import { isSomething } from "../../../types/typeGuards";
import { getFirstDayOfQuarter } from "../../../utils/formatters";
import { hideTooltips } from "../../DoubleTapTooltip/DoubleTapTooltip";
import { IconButtonWithTooltip } from "../../IconButtonWithTooltip/IconButtonWithTooltip";
import {
  DEFAULT_COLUMN_DEF,
  EXCEL_STYLES,
  getColumnDefs,
} from "./gridColumnConfigs";
import styles from "./PerformanceDetailsGrid.module.scss";

// TODO fix to only show "View By: " when selected
const VIEW_BY_OPTIONS: {
  [label: string]: {
    treeNumber: TreeNumber;
    dataKey: string;
    headerName: string;
  };
} = {
  "View By: Fund": {
    treeNumber: TreeNumber.BUSINESS_UNIT,
    dataKey: "businessUnit",
    headerName: PerformanceDetailsLabel.BUSINESS_UNIT,
  },
  "View By: Election Period": {
    treeNumber: TreeNumber.ELECTION_PERIOD,
    dataKey: "period",
    headerName: PerformanceDetailsLabel.ELECTION_PERIOD,
  },
};

interface IPerformanceDetailsGridProps {
  filter: string | undefined;
  onChangeFilter: (searchTerm: string) => void;
  investmentBreakdownGrouped: IInvestmentBreakdownGrouped;
  onDownload: (() => Promise<void>) | undefined;
  asOfDates: IAsOfDate;
}

export interface IPerformanceDetailsGridContext {
  filter: string | undefined;
}

export const PerformanceDetailsGrid = (props: IPerformanceDetailsGridProps) => {
  const {
    filter,
    onChangeFilter,
    investmentBreakdownGrouped,
    onDownload,
    asOfDates,
  } = props;

  const [isGridReady, setIsGridReady] = useState<boolean>(false);
  const [selectedViewBy] = useState<string>(Object.keys(VIEW_BY_OPTIONS)[0]);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const gridRef = useRef<AgGridReact>(null);

  const {
    setHeaderHeight,
    resizeColumns,
    onGridReady: OnGridReadyCommon,
  } = useGridExtensions("viewByColumn");

  useEffect(() => {
    const hasBusinessUnitData =
      investmentBreakdownGrouped[TreeNumber.BUSINESS_UNIT] &&
      investmentBreakdownGrouped[TreeNumber.BUSINESS_UNIT].length;
    const hasElectionPeriodData =
      investmentBreakdownGrouped[TreeNumber.ELECTION_PERIOD] &&
      investmentBreakdownGrouped[TreeNumber.ELECTION_PERIOD].length;
    const newData = investmentBreakdownGrouped[TreeNumber.BUSINESS_UNIT];
    if (hasBusinessUnitData && hasElectionPeriodData && isGridReady) {
      // Tree number 1(ELECTION_PERIOD) is only one with tree-level 0 total row
      const summaryRow = investmentBreakdownGrouped[
        TreeNumber.ELECTION_PERIOD
      ].filter((data) => data.treeLevel === ElectionPeriodTreeLevel.ENTITY)[0];

      const earliestDate = getFirstDayOfQuarter(asOfDates.earliestAsOfDate);
      const realizedDate = isSomething(summaryRow.realizedProceeds)
        ? summaryRow.realizedProceeds.value.asOfDate
        : undefined;
      const unrealizedDate = isSomething(summaryRow.unrealizedValue)
        ? summaryRow.unrealizedValue.value.asOfDate
        : undefined;
      if (gridRef.current !== null) {
        gridRef.current.api.updateGridOptions({
          rowData: newData.filter((data) => data.treeLevel !== 0),
          columnDefs: getColumnDefs(realizedDate, earliestDate, unrealizedDate),
          pinnedBottomRowData: [summaryRow],
        });
      }
    }
  }, [investmentBreakdownGrouped, isGridReady, asOfDates]);

  const onGridReady = (params: GridReadyEvent) => {
    setIsGridReady(true);
    OnGridReadyCommon(params);
  };

  // recursively search and expand rows if match found
  const recursiveSearchRow = (
    rowNode: IRowNode<IInvestmentBreakdownViewModel>,
    searchTerms: string[]
  ) => {
    if (rowNode.childrenAfterFilter) {
      // recursive call on each child row
      rowNode.childrenAfterFilter.forEach((childNode) => {
        recursiveSearchRow(childNode, searchTerms);
        if (
          childNode.expanded ||
          searchTerms.every((searchTerm) =>
            childNode.key
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase().trim())
          )
        ) {
          // expand parent row if child row key matches search terms OR child row is expanded
          rowNode.expanded = true;
        }
      });
    } else {
      // if no children, don't expand row
      rowNode.expanded = false;
    }
  };

  const searchForValue = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    if (isGridReady && gridRef.current !== null) {
      gridRef.current.api.updateGridOptions({
        quickFilterText: searchTerm,
      });

      if (searchTerm.trim() !== "") {
        gridRef.current.api.forEachNode(
          (rowNode: IRowNode<IInvestmentBreakdownViewModel>) => {
            const searchTerms = searchTerm.split(" ");

            /* If there's at least 1 child row that matches the search term, expand parent row
            otherwise keep parent row collapsed
          */
            recursiveSearchRow(rowNode, searchTerms);
          }
        );
      } else {
        gridRef.current.api.collapseAll();
      }
    }

    if (gridRef.current !== null) {
      gridRef.current.api.onGroupExpandedOrCollapsed();
    }
    onChangeFilter(searchTerm);
  };

  const onSearchValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchForValue(event.target.value);
  };

  addEventListener("resize", () => {
    const viewByOptions = VIEW_BY_OPTIONS[selectedViewBy];
    const newData = investmentBreakdownGrouped[viewByOptions.treeNumber];

    if (newData && isGridReady && gridRef.current !== null) {
      const summaryRow = investmentBreakdownGrouped[
        TreeNumber.ELECTION_PERIOD
      ].filter((data) => data.treeLevel === ElectionPeriodTreeLevel.ENTITY)[0];

      const earliestDate = getFirstDayOfQuarter(asOfDates.earliestAsOfDate);
      const realizedDate = isSomething(summaryRow.realizedProceeds)
        ? summaryRow.realizedProceeds.value.asOfDate
        : undefined;
      const unrealizedDate = isSomething(summaryRow.unrealizedValue)
        ? summaryRow.unrealizedValue.value.asOfDate
        : undefined;

      gridRef.current.api.updateGridOptions({
        columnDefs: getColumnDefs(realizedDate, earliestDate, unrealizedDate),
      });
      gridRef.current.api.refreshHeader();
    }
  });

  const context: IPerformanceDetailsGridContext = {
    filter,
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid container className={styles.header}>
        <TextField
          label={StringConstants.SEARCH_PERF_DETAILS_GRID}
          size="small"
          fullWidth
          className={styles.searchBar}
          onChange={onSearchValueChanged}
          value={searchTerm}
          InputProps={{
            endAdornment: (
              <InputAdornment className={styles.searchIcon} position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        {onDownload !== undefined && (
          <IconButtonWithTooltip
            onClickFunction={onDownload}
            buttonIcon={FileDownloadOutlined}
            hoverText={StringConstants.DOWNLOAD}
            disabled={!isGridReady}
            className={styles.downloadIcon}
          />
        )}
      </Grid>
      <Grid item xs={12} className={`ag-theme-alpine`} id={styles.dataGrid}>
        <AgGridReact<IInvestmentBreakdownViewModel>
          ref={gridRef}
          groupDisplayType="custom"
          defaultColDef={DEFAULT_COLUMN_DEF}
          excelStyles={EXCEL_STYLES}
          domLayout="autoHeight"
          treeData={true}
          getDataPath={(data) => data.hierarchy}
          onFirstDataRendered={setHeaderHeight}
          onColumnResized={setHeaderHeight}
          onGridReady={onGridReady}
          onRowDataUpdated={resizeColumns}
          onBodyScroll={hideTooltips}
          cacheQuickFilter={true}
          suppressAggFuncInHeader={true}
          suppressContextMenu={true}
          suppressCellFocus={true}
          context={context}
          suppressMovableColumns={true}
        />
      </Grid>
    </Grid>
  );
};
