import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { Grid } from "@mui/material";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef } from "react";

import { hideTooltips } from "../../../../../../../components/DoubleTapTooltip/DoubleTapTooltip";
import { DEFAULT_COLUMN_DEF } from "../../../../../../../components/Grid/gridColumnConfig";
import { ElectionsLabels } from "../../../../../../../constants/LabelAndTooltipConstants";
import { useGridExtensions } from "../../../../../../../hooks/useGridExtensions";
import {
  AllocationGridKeys,
  IStrategyForecastedDeploymentPercentage,
} from "../../../../../../../types/electionDataTypes";
import { toPercentWithOneDecimal } from "../../../../../../../utils/agGridUtils";
import styles from "./AllocationGrid.module.scss";

interface IAllocationGridProps {
  allocationStrategies: IStrategyForecastedDeploymentPercentage[];
}

const PINNED_TOTAL_ROW = [
  {
    strategyId: 0,
    strategyName: ElectionsLabels.TOTAL,
    percentage: 100,
  },
];

export const AllocationGrid: React.FC<IAllocationGridProps> = ({
  allocationStrategies,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const { setHeaderHeight, resizeColumns, onGridReady } = useGridExtensions();

  const allocationColumnDefs: ColDef<IStrategyForecastedDeploymentPercentage>[] =
    useMemo(
      () => [
        {
          field: AllocationGridKeys.STRATEGY_ID,
          hide: true,
        },
        {
          field: AllocationGridKeys.STRATEGY_NAME,
          headerName: ElectionsLabels.STRATEGY,
          sortable: false,
        },
        {
          field: AllocationGridKeys.PERCENTAGE,
          headerName: ElectionsLabels.ALLOCATION_PERCENTAGE,
          type: "rightAligned",
          valueFormatter: toPercentWithOneDecimal,
          sortable: false,
        },
      ],
      []
    );

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} className={`ag-theme-alpine`} id={styles.dataGrid}>
        <AgGridReact<IStrategyForecastedDeploymentPercentage>
          ref={gridRef}
          rowData={allocationStrategies}
          pinnedBottomRowData={PINNED_TOTAL_ROW}
          defaultColDef={DEFAULT_COLUMN_DEF}
          columnDefs={allocationColumnDefs}
          domLayout="autoHeight"
          onFirstDataRendered={setHeaderHeight}
          onColumnResized={setHeaderHeight}
          cacheQuickFilter={true}
          suppressAggFuncInHeader={true}
          suppressContextMenu={true}
          suppressCellFocus={true}
          onRowDataUpdated={resizeColumns}
          onGridSizeChanged={resizeColumns}
          onDisplayedColumnsChanged={resizeColumns}
          onBodyScroll={hideTooltips}
          suppressMenuHide={true}
          suppressDragLeaveHidesColumns={true}
          suppressMovableColumns={true}
          tabIndex={-1} // disable focus on grid
          onGridReady={onGridReady}
        />
      </Grid>
    </Grid>
  );
};
