import { Text, View } from "@react-pdf/renderer";
import {
  ElectionsLabels,
  IEligibilityStage,
  isSomething,
  mapYesOrNo,
} from "common";
import React from "react";

import { styles } from "../ElectionAgreementDocumentStyleSheet";
import { QuestionResponses } from "../QuestionResponses/QuestionResponses";

interface IEligibilityPageProps {
  eligibilityStage: IEligibilityStage;
}

export const EligibilityPage = (props: IEligibilityPageProps) => {
  const { eligibilityStage } = props;

  const isUSResident = eligibilityStage.residentialInformation.isUSResident;
  const isNYResident = isSomething(
    eligibilityStage.residentialInformation.isNYResident
  )
    ? eligibilityStage.residentialInformation.isNYResident.value
    : false;

  return (
    <>
      <View break>
        <Text style={styles.h2}>{ElectionsLabels.ELIGIBILITY}</Text>
        {isSomething(isUSResident) && (
          <Text style={styles.h3}>
            {ElectionsLabels.RESIDENTIAL_INFORMATION}
          </Text>
        )}
      </View>
      {isSomething(isUSResident) && (
        <QuestionResponses
          questionResponses={[
            {
              question: ElectionsLabels.COUNTRY_RESIDENT_TEXT,
              response: mapYesOrNo(isUSResident.value),
            },
            {
              question: ElectionsLabels.STATE_RESIDENT_TEXT,
              response: mapYesOrNo(isNYResident),
            },
          ]}
        />
      )}
      <View>
        <Text style={styles.h3}>
          {ElectionsLabels.ACCREDITED_INVESTOR_STATUS}
        </Text>
      </View>
      <QuestionResponses
        questionResponses={[
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.ACCREDITED_INVESTOR
                .INDIVIDUAL_NET_WORTH,
            response: mapYesOrNo(
              eligibilityStage.eligibleStatus.accreditedInvestorStatus
                .hasIndividualNetWorth
            ),
          },
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.ACCREDITED_INVESTOR
                .INDIVIDUAL_INCOME,
            response: mapYesOrNo(
              eligibilityStage.eligibleStatus.accreditedInvestorStatus
                .hasIndividualIncome
            ),
          },
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.ACCREDITED_INVESTOR
                .PROFESSIONAL,
            response: mapYesOrNo(
              eligibilityStage.eligibleStatus.accreditedInvestorStatus
                .isProfessional
            ),
          },
        ]}
      />
      <View>
        <Text style={styles.h3}>
          {ElectionsLabels.QUALIFIED_PURCHASER_STATUS}
        </Text>
      </View>
      <QuestionResponses
        questionResponses={[
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER
                .BLACKSTONE_FUND,
            response: mapYesOrNo(
              eligibilityStage.eligibleStatus.qualifiedPurchaserStatus
                .hasInvestmentsInBlackstoneFunds
            ),
          },
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER.COMPANY,
            response: mapYesOrNo(
              eligibilityStage.eligibleStatus.qualifiedPurchaserStatus.isCompany
            ),
          },
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER.TRUST,
            response: mapYesOrNo(
              eligibilityStage.eligibleStatus.qualifiedPurchaserStatus.isTrust
            ),
          },
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER
                .INDIVIDUAL_INVESTMENT,
            response: mapYesOrNo(
              eligibilityStage.eligibleStatus.qualifiedPurchaserStatus
                .isIndividualInvestment
            ),
          },
        ]}
      />
    </>
  );
};
