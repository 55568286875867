import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  ElectionsLabels,
  FormCardHeader,
  IEligibilityTooltips,
  IEligibleStatus,
  InfoTooltip,
  Markdown,
  nothing,
  setIsSBSElectionSaveEnabled,
  updateEligibilityStatus,
} from "common";
import React, { ChangeEvent } from "react";
import { Control, Controller, FieldPath, UseFormReturn } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { selectEligibilityStatuses } from "../../../../../../redux/selectors/eligibilitySelectors";
import { IBaseStore } from "../../../../../../redux/store";
import { FlattenedEligibilityStatus } from "../../Eligibility";
import styles from "./EligibleStatusForm.module.scss";

interface IEligibilityProps {
  tooltips: IEligibilityTooltips;
  form: UseFormReturn<FlattenedEligibilityStatus>;
  hasErrors: boolean;
}

export const EligibleStatusForm: React.FC<IEligibilityProps> = ({
  tooltips,
  form,
  hasErrors,
}) => {
  const dispatch = useDispatch();

  const eligibilityStatuses = useSelector((state: IBaseStore) =>
    selectEligibilityStatuses(state)
  );

  const accreditedInvestorStatus_hasIndividualNetWorth =
    "accreditedInvestorStatus_hasIndividualNetWorth";
  const accreditedInvestorStatus_hasIndividualIncome =
    "accreditedInvestorStatus_hasIndividualIncome";
  const accreditedInvestorStatus_isProfessional =
    "accreditedInvestorStatus_isProfessional";
  const qualifiedPurchaserStatus_hasInvestmentsInBlackstoneFunds =
    "qualifiedPurchaserStatus_hasInvestmentsInBlackstoneFunds";
  const qualifiedPurchaserStatus_isCompany =
    "qualifiedPurchaserStatus_isCompany";
  const qualifiedPurchaserStatus_isTrust = "qualifiedPurchaserStatus_isTrust";
  const qualifiedPurchaserStatus_isIndividualInvestment =
    "qualifiedPurchaserStatus_isIndividualInvestment";
  const isNotEligible = "isNotEligible";

  const validate = (
    _: boolean,
    value: FlattenedEligibilityStatus
  ): boolean | string => {
    return !Object.values(value).every((checkbox) => checkbox === false) || "";
  };

  const EligibilityCheckbox = ({
    label,
    name,
    control,
  }: {
    label: string;
    name: FieldPath<FlattenedEligibilityStatus>;
    control: Control<FlattenedEligibilityStatus>;
  }) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={{ validate: validate }}
        render={({ field }) => (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  className={styles.checkbox}
                  {...field}
                  checked={
                    name != isNotEligible && eligibilityStatuses.isNotEligible
                      ? false
                      : field.value
                  }
                  onChange={(e) => {
                    field.onChange(e);
                    handleChange(e);
                  }}
                />
              }
              label={label}
              sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
              disabled={
                name != isNotEligible && eligibilityStatuses.isNotEligible
              }
            />
          </FormGroup>
        )}
      />
    );
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selections = form.getValues();
    const eligibleStatus: IEligibleStatus = {
      accreditedInvestorStatus: {
        hasIndividualNetWorth:
          selections.accreditedInvestorStatus_hasIndividualNetWorth,
        hasIndividualIncome:
          selections.accreditedInvestorStatus_hasIndividualIncome,
        isProfessional: selections.accreditedInvestorStatus_isProfessional,
      },
      qualifiedPurchaserStatus: {
        hasInvestmentsInBlackstoneFunds:
          selections.qualifiedPurchaserStatus_hasInvestmentsInBlackstoneFunds,
        isCompany: selections.qualifiedPurchaserStatus_isCompany,
        isTrust: selections.qualifiedPurchaserStatus_isTrust,
        isIndividualInvestment:
          selections.qualifiedPurchaserStatus_isIndividualInvestment,
      },
      isNotEligible: selections.isNotEligible,
    };
    const notEligibleStatus: IEligibleStatus = {
      accreditedInvestorStatus: {
        hasIndividualNetWorth: false,
        hasIndividualIncome: false,
        isProfessional: false,
      },
      qualifiedPurchaserStatus: {
        hasInvestmentsInBlackstoneFunds: false,
        isCompany: false,
        isTrust: false,
        isIndividualInvestment: false,
      },
      isNotEligible: selections.isNotEligible,
    };
    if (e.target.name === isNotEligible && e.target.value === "false") {
      form.reset({
        accreditedInvestorStatus_hasIndividualIncome: false,
        accreditedInvestorStatus_hasIndividualNetWorth: false,
        accreditedInvestorStatus_isProfessional: false,
        qualifiedPurchaserStatus_hasInvestmentsInBlackstoneFunds: false,
        qualifiedPurchaserStatus_isCompany: false,
        qualifiedPurchaserStatus_isIndividualInvestment: false,
        qualifiedPurchaserStatus_isTrust: false,
        isNotEligible: selections.isNotEligible,
      });
      dispatch(updateEligibilityStatus(notEligibleStatus));
    } else {
      dispatch(updateEligibilityStatus(eligibleStatus));
    }
    dispatch(setIsSBSElectionSaveEnabled(true));
  };

  return (
    <Stack>
      <FormCardHeader
        title={ElectionsLabels.ELIGIBILITY_ATTESTATION}
        subTitle={ElectionsLabels.ELIGIBILITY_CHECK_ALL_BOXES}
        tooltip={nothing}
        required={true}
        hasError={hasErrors}
      />
      <Stack
        className={styles.firstSubHeaderGrouping}
        direction={"row"}
        alignItems={"center"}
      >
        <Typography className={styles.subHeader} variant="h3">
          {ElectionsLabels.ACCREDITED_INVESTOR_STATUS}
        </Typography>
        <InfoTooltip
          tooltipContent={
            <Markdown text={tooltips.accreditedInvestorStatusTooltip} />
          }
        />
      </Stack>
      <EligibilityCheckbox
        label={
          ElectionsLabels.ELIGIBILITY_CHECKBOX.ACCREDITED_INVESTOR
            .INDIVIDUAL_NET_WORTH
        }
        name={accreditedInvestorStatus_hasIndividualNetWorth}
        control={form.control}
      />
      <EligibilityCheckbox
        label={
          ElectionsLabels.ELIGIBILITY_CHECKBOX.ACCREDITED_INVESTOR
            .INDIVIDUAL_INCOME
        }
        name={accreditedInvestorStatus_hasIndividualIncome}
        control={form.control}
      />
      <EligibilityCheckbox
        label={
          ElectionsLabels.ELIGIBILITY_CHECKBOX.ACCREDITED_INVESTOR.PROFESSIONAL
        }
        name={accreditedInvestorStatus_isProfessional}
        control={form.control}
      />

      <Stack
        className={styles.subHeaderGrouping}
        direction={"row"}
        alignItems={"center"}
      >
        <Typography className={styles.subHeader} variant="h3">
          {ElectionsLabels.QUALIFIED_PURCHASER_STATUS}
        </Typography>
        <InfoTooltip
          tooltipContent={
            <Markdown text={tooltips.qualifiedPurchaserStatusTooltip} />
          }
        />
      </Stack>

      <EligibilityCheckbox
        label={
          ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER
            .BLACKSTONE_FUND
        }
        name={qualifiedPurchaserStatus_hasInvestmentsInBlackstoneFunds}
        control={form.control}
      />
      <EligibilityCheckbox
        label={ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER.COMPANY}
        name={qualifiedPurchaserStatus_isCompany}
        control={form.control}
      />
      <EligibilityCheckbox
        label={ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER.TRUST}
        name={qualifiedPurchaserStatus_isTrust}
        control={form.control}
      />
      <EligibilityCheckbox
        label={
          ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER
            .INDIVIDUAL_INVESTMENT
        }
        name={qualifiedPurchaserStatus_isIndividualInvestment}
        control={form.control}
      />

      <Stack
        className={styles.subHeaderGrouping}
        direction={"row"}
        alignItems={"center"}
      >
        <Typography className={styles.subHeader} variant="h3">
          {ElectionsLabels.NOT_ELIGIBLE}
        </Typography>
        <InfoTooltip
          tooltipContent={<Markdown text={tooltips.notEligibleTooltip} />}
        />
      </Stack>

      <EligibilityCheckbox
        label={ElectionsLabels.ELIGIBILITY_CHECKBOX.NOT_ELIGIBLE}
        name={isNotEligible}
        control={form.control}
      />
    </Stack>
  );
};
