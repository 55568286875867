import { createReducer } from "@reduxjs/toolkit";
import {
  convertToEntityDataStore,
  DataLoadStatus,
  defaultViewDataBuilder,
  defaultViewDataState as commonDefailtViewDataState,
  DownloadRequestStatus,
  errAdminEntitlements,
  errAllClientData,
  errElectionClientMappings,
  errEquityEmployees,
  errInternalInvestmentClients,
  IEmployee,
  IInteralInvestmentClient,
  isSomething,
  nothing,
  recvAdminEntitlements,
  recvAllClientData,
  recvCanViewElectionResultPage,
  recvElectionAdminEntitlements,
  recvElectionClientMappings,
  recvEquityEmployees,
  recvInternalInvestmentClients,
  reqAllClientData,
  reqAllEntitlements,
  reqElectionClientMappings,
  reqEquityEmployees,
  reqInternalInvestmentClients,
  reqSelectedClient,
  reqSelectedEmployee,
  resetSelectedClient,
  resetSelectedEmployee,
  setDownloadReqStatus,
  StockSymbol,
} from "common";

import {
  AdminUIClientsStore,
  AdminUIEmployeesStore,
  AdminUIEntitlementStore,
  AdminUIViewDataStore,
} from "../../types/storeTypes";

export const adminUIEntitlementReducer = createReducer<AdminUIEntitlementStore>(
  {
    hasInternalInvestmentAdminEntitlement: false,
    internalInvestmentAdminEntitlementLoadStatus: DataLoadStatus.NOT_REQUESTED,
    isElectionsAdmin: false,
    isElectionsAdminLoadStatus: DataLoadStatus.NOT_REQUESTED,
    canViewElectionResult: false,
    canViewElectionResultLoadStatus: DataLoadStatus.NOT_REQUESTED,
    hasEquityAdminEntitlement: false,
    equityAdminEntitlementLoadStatus: DataLoadStatus.NOT_REQUESTED,
    isBankAccountsAdmin: false,
    isBankAccountsAdminLoadStatus: DataLoadStatus.NOT_REQUESTED,
  },
  (builder) => {
    builder.addCase(reqAllEntitlements, (state) => {
      state.internalInvestmentAdminEntitlementLoadStatus =
        DataLoadStatus.LOADING;
      state.equityAdminEntitlementLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvElectionAdminEntitlements, (state, action) => {
      state.isElectionsAdmin = action.payload;
      state.isElectionsAdminLoadStatus = DataLoadStatus.SUCCESSFUL;
    });
    builder.addCase(recvCanViewElectionResultPage, (state, action) => {
      state.canViewElectionResult = action.payload;
      state.canViewElectionResultLoadStatus = DataLoadStatus.SUCCESSFUL;
    });
    builder.addCase(recvAdminEntitlements, (state, action) => {
      if (isSomething(action.payload)) {
        state.hasInternalInvestmentAdminEntitlement =
          action.payload.value.canViewAllInternalInvestmentData;
        state.internalInvestmentAdminEntitlementLoadStatus =
          DataLoadStatus.SUCCESSFUL;
        state.hasEquityAdminEntitlement =
          action.payload.value.canViewAllEquityData;
        state.equityAdminEntitlementLoadStatus = DataLoadStatus.SUCCESSFUL;
        state.isBankAccountsAdmin = action.payload.value.canViewAllBankAccounts;
        state.isBankAccountsAdminLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.hasInternalInvestmentAdminEntitlement = false;
        state.internalInvestmentAdminEntitlementLoadStatus =
          DataLoadStatus.EMPTY_RESPONSE;
        state.hasEquityAdminEntitlement = false;
        state.equityAdminEntitlementLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
        state.isBankAccountsAdmin = false;
        state.isBankAccountsAdminLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errAdminEntitlements, (state, action) => {
      state.internalInvestmentAdminEntitlementLoadStatus = action.payload;
      state.equityAdminEntitlementLoadStatus = action.payload;
      state.isBankAccountsAdminLoadStatus = action.payload;
    });
  }
);

export const adminUIClientsReducer = createReducer<AdminUIClientsStore>(
  {
    internalInvestmentClients: nothing,
    internalInvestmentClientsLoadStatus: DataLoadStatus.NOT_REQUESTED,
    electionsClientMappings: nothing,
    electionsClientMappingsLoadStatus: DataLoadStatus.NOT_REQUESTED,
  },
  (builder) => {
    builder.addCase(reqInternalInvestmentClients, (state) => {
      state.internalInvestmentClientsLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvInternalInvestmentClients, (state, action) => {
      if (action.payload) {
        state.internalInvestmentClients = action.payload;
        state.internalInvestmentClientsLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.internalInvestmentClients = nothing;
        state.internalInvestmentClientsLoadStatus =
          DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errInternalInvestmentClients, (state, action) => {
      state.internalInvestmentClientsLoadStatus = action.payload;
    });
    builder.addCase(reqElectionClientMappings, (state) => {
      state.electionsClientMappingsLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvElectionClientMappings, (state, action) => {
      if (action.payload) {
        state.electionsClientMappings = action.payload;
        state.electionsClientMappingsLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.electionsClientMappings = nothing;
        state.electionsClientMappingsLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errElectionClientMappings, (state, action) => {
      state.internalInvestmentClientsLoadStatus = action.payload;
    });
  }
);

export const adminUIEmployeesReducer = createReducer<AdminUIEmployeesStore>(
  {
    employees: nothing,
    employeesLoadStatus: DataLoadStatus.NOT_REQUESTED,
  },
  (builder) => {
    builder.addCase(reqEquityEmployees, (state) => {
      state.employeesLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvEquityEmployees, (state, action) => {
      if (action.payload) {
        state.employees = action.payload;
        state.employeesLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.employees = action.payload;
        state.employeesLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errEquityEmployees, (state, action) => {
      state.employeesLoadStatus = action.payload;
    });
  }
);

const DEFAULT_SELECTED_CLIENT: IInteralInvestmentClient = {
  id: "",
  mdmId: 0,
  name: "",
  shortName: "",
  investmentVehicles: [],
};

export const adminUISelectedClientReducer =
  createReducer<IInteralInvestmentClient>(
    DEFAULT_SELECTED_CLIENT,
    (builder) => {
      builder.addCase(reqSelectedClient, (state, action) => {
        if (action.payload) {
          state.id = action.payload.id;
          state.mdmId = action.payload.mdmId;
          state.name = action.payload.name;
          state.shortName = action.payload.shortName;
          state.investmentVehicles = action.payload.investmentVehicles;
        }
      });
      builder.addCase(resetSelectedClient, () => {
        return DEFAULT_SELECTED_CLIENT;
      });
    }
  );

const defaultViewDataState: AdminUIViewDataStore = {
  ...commonDefailtViewDataState,
  entitiesData: [],
  entitiesDataLoadStatus: DataLoadStatus.NOT_REQUESTED,
  downloadRequestStatus: DownloadRequestStatus.NOT_REQUESTED,
  selectedStockSymbol: StockSymbol.BX,
};

export const adminUIViewDataReducer = createReducer<AdminUIViewDataStore>(
  defaultViewDataState,
  (builder) => {
    builder.addCase(setDownloadReqStatus, (state, action) => {
      state.downloadRequestStatus = action.payload;
    });
    builder.addCase(reqAllClientData, (state) => {
      state.entitiesDataLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvAllClientData, (state, action) => {
      if (isSomething(action.payload)) {
        for (const iv of action.payload.value) {
          const entity = convertToEntityDataStore(iv);
          state.entitiesData = state.entitiesData.concat(entity);
        }
        state.entitiesDataLoadStatus = DataLoadStatus.SUCCESSFUL;
      }
    });
    builder.addCase(errAllClientData, (state, action) => {
      state.entitiesDataLoadStatus = action.payload;
    });

    defaultViewDataBuilder(builder);
  }
);

const DEFAULT_SELECTED_EMPLOYEE = {
  id: "",
  name: "",
};

export const adminUISelectedEmployeeReducer = createReducer<IEmployee>(
  DEFAULT_SELECTED_EMPLOYEE,
  (builder) => {
    builder.addCase(reqSelectedEmployee, (state, action) => {
      if (action.payload) {
        state.id = action.payload.id;
        state.name = action.payload.name;
      }
    });
    builder.addCase(resetSelectedEmployee, () => {
      return DEFAULT_SELECTED_EMPLOYEE;
    });
  }
);
