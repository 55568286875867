import {
  AgreementPdfRequest,
  BankAccountDocumentError,
  BankAccountDocumentInfo,
  BankAccountsResponse,
  ICountry,
  ICurrency,
  InvestmentVehicleBankAccount,
  IVBankAccounts,
} from "../types/bankAccountDataTypes";
import { IESignature } from "../types/dataTypes";
import { isSomething } from "../types/typeGuards";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { EnvironmentResolver } from "./environmentResolver";
import { foundData, handleBlobResponse, handleResponse } from "./utils";

interface ServiceErrorType {
  message: string;
  errorType: BankAccountDocumentError;
}

interface ServiceError {
  success: boolean;
  errors: ServiceErrorType[];
}

export type BankAccountsResponseDto = Json<BankAccountsResponse>;
export type ICountryResponseDto = Json<ICountry>;
export type ICurrencyResponseDto = Json<ICurrency>;
export type IServiceErrorDto = Json<ServiceError>;

export const getBankAccountsForClient = async (
  clientId: number
): Promise<Maybe<BankAccountsResponseDto>> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE +
    `/gp-bank-accounts?clientId=${clientId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return await handleResponse(response);
};

export const getBankAccountsForIV = async (
  investmentVehicleAxiomId: number,
  isAdmin: boolean
): Promise<Maybe<BankAccountsResponseDto>> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE +
    `/gp-bank-accounts/investment-vehicles/${investmentVehicleAxiomId}?isAdminMode=${isAdmin}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return await handleResponse(response);
};

export const getAgreementDocument = async (
  request: AgreementPdfRequest,
  isAdmin: boolean
): Promise<Maybe<{ fileName: string; blob: Blob }>> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE +
    `/gp-bank-accounts/pdf?isAdminMode=${isAdmin}`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.access_token}`,
    },
    body: JSON.stringify(request),
  });

  const blob = await handleBlobResponse(response);

  if (!blob) return undefined;

  const date = new Date(response.headers.get("Date") as string);
  const dateString = [
    date.getFullYear(),
    ("0" + (date.getMonth() + 1)).slice(-2),
    ("0" + date.getDate()).slice(-2),
  ].join("");

  return {
    fileName: `Bank Account Agreement_${dateString}.pdf`,
    blob: blob,
  };
};

export const getBankAccountCountries = async (): Promise<
  Maybe<ICountryResponseDto[]>
> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE + `/country`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return await handleResponse(response);
};

export const getBankAccountCurrencies = async (): Promise<
  Maybe<ICurrencyResponseDto[]>
> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE + `/currency`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return await handleResponse(response);
};

export const postSubmitBankAccounts = async (
  bankAccounts: IVBankAccounts[],
  eSignature: IESignature,
  isAdmin: boolean,
  documents: BankAccountDocumentInfo[]
) => {
  const investmentVehicleBankAccounts: InvestmentVehicleBankAccount[] = [];
  bankAccounts.forEach((bankAccount) => {
    investmentVehicleBankAccounts.push({
      investmentVehicleMdmId: bankAccount.assignedIV.mdmId,
      bankAccountAssignment: bankAccount.assignments,
      main: bankAccount.main,
      intermediary: isSomething(bankAccount.intermediary)
        ? {
            ...bankAccount.intermediary.value,
            country: bankAccount.main.country,
            currency: bankAccount.main.currency,
          }
        : undefined,
    });
  });
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE +
    `/gp-bank-accounts?isAdminMode=${isAdmin}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json-patch+json",
    },
    method: "POST",
    body: JSON.stringify({
      investmentVehicleBankAccounts,
      documents: documents,
      eSignature,
      effectiveDate: new Date().toISOString(),
    }),
  });

  return await handleResponse(response);
};

export const deleteAdminBankAccountsForIV = async (
  investmentVehicleAxiomId: number
): Promise<boolean> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE +
    `/gp-bank-accounts/investment-vehicles/${investmentVehicleAxiomId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
    method: "DELETE",
  });

  return await foundData(response.status);
};

export type BankAccountDocResponse = {
  id: string;
  error?: BankAccountDocumentError;
};

export const postBankAccountDocument = async (
  file: File
): Promise<BankAccountDocResponse> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE + `/documents`;
  const formData = new FormData();
  formData.append("file", file);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
    body: formData,
  });

  if (response.status === 200) {
    const id = await response.text();
    return {
      id: id,
    };
  } else {
    try {
      const responseJson: IServiceErrorDto = await response.json();
      const responseObject: ServiceError = {
        success: responseJson.success,
        errors: responseJson.errors.map((error: Json<ServiceErrorType>) => {
          return {
            message: error.message,
            errorType: error.errorType as BankAccountDocumentError,
          };
        }),
      };
      return {
        id: "",
        error: responseObject.errors[0].errorType,
      };
    } catch {
      return {
        id: "",
        error: BankAccountDocumentError.UNKNOWN,
      };
    }
  }
};
