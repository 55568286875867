import { Box } from "@mui/material";
import { AdminEquityLabels, resetSelectedEmployee } from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HasEntitlementContainer } from "../../common/components/HasEntitlementContainer/HasEntitlementContainer";
import { AdminUIStore } from "../../redux/store";
import styles from "./EquityLandingPage.module.scss";

export const EquityLandingPage = () => {
  const { selectedEmployee } = useSelector((store: AdminUIStore) => store);
  const dispatch = useDispatch();

  useEffect(() => {
    async function checkData() {
      if (selectedEmployee.id !== "") {
        dispatch(resetSelectedEmployee());
      }
    }
    checkData();
  }, [selectedEmployee, dispatch]);

  return (
    <div className={styles.page}>
      <HasEntitlementContainer
        entitlementLoadStatusName="equityAdminEntitlementLoadStatus"
        entitlementName="hasEquityAdminEntitlement"
      >
        <Box
          component="img"
          alt={"Landing Page background"}
          src="/assets/images/AdminLanding.png"
          className={styles.landingImage}
        />
        <h1 className={styles.landingText}>
          {AdminEquityLabels.PLEASE_SEARCH}
        </h1>
      </HasEntitlementContainer>
    </div>
  );
};
