import {
  BusinessUnitTreeLevel,
  InvestmentBreakdownKey,
  TreeDepth,
  TreeNumber,
} from "../constants/enums";
import {
  IInvestmentBreakdownDatum,
  IInvestmentBreakdownGrouped,
  IInvestmentBreakdownValueSet,
  IInvestmentBreakdownViewModel,
} from "../types/dataTypes";
import { EMPTY_INVESTMENT_BREAKDOWN_DATA } from "../types/defaultTypes";
import { isSomething } from "../types/typeGuards";

/*
 *Client>Business Unit>Fund>Tranche(TREE_NUMBER 2)
 *Client>Period>Fund(TREE_NUMBER 1)
 */
const getGroupingValue = (
  investmentBreakdown: IInvestmentBreakdownDatum,
  treeNameSegment: string
) => {
  switch (treeNameSegment) {
    case "PERIOD":
      return investmentBreakdown.period;
    case "FUND_EID":
      return investmentBreakdown.fundShortName;
    case "TRANCHE_ID":
      return investmentBreakdown.trancheName;
    case "BUSINESS_UNIT_ID":
      return investmentBreakdown.businessUnitName;
    default:
      return "";
  }
};

const getHierarchy = (
  investmentBreakdown: IInvestmentBreakdownDatum
): string[] => {
  const hierarchyOrder: string[] = investmentBreakdown.treeName.split(">");
  const hierarchy: string[] = [];

  // start from 1 to skip investor-level grouped row
  for (let i = 1; i <= Number(investmentBreakdown.treeLevel); i++) {
    hierarchy.push(getGroupingValue(investmentBreakdown, hierarchyOrder[i]));
  }

  return hierarchy;
};

/**
 * Convert a list of IInvestmentBreakdownDatum to IInvestmentBreakdownGrouped object
 * Also,filtered data in accordance with design specs and AG grid API requirements
 * @param investmentBreakdowns - The investment breakdown json
 * @returns A InvestmentBreakdownGroupedData object.
 */
export const convertToInvestmentBreakdownGrouped = (
  investmentBreakdowns: IInvestmentBreakdownDatum[]
): IInvestmentBreakdownGrouped => {
  const investmentBreakdownByTreeDeep = investmentBreakdowns.filter(
    (investmentBreakdown: IInvestmentBreakdownDatum) =>
      Number(investmentBreakdown.treeLevel) <=
      TreeDepth[investmentBreakdown.treeNumber]
  );

  const investmentBreakdownGrouped: IInvestmentBreakdownGrouped =
    investmentBreakdownByTreeDeep.reduce(
      (
        obj: IInvestmentBreakdownGrouped,
        curr: IInvestmentBreakdownDatum,
        currentIndex: number
      ) => {
        const treeNumber: TreeNumber = curr.treeNumber;
        const hierarchy = getHierarchy(curr);
        const lastLevel = hierarchy.length - 1;
        const displayName =
          curr.treeLevel === 0 ? "Total" : hierarchy[lastLevel];
        if (
          curr.treeNumber === TreeNumber.BUSINESS_UNIT &&
          curr.treeLevel === BusinessUnitTreeLevel.TRANCHE
        ) {
          // add unique index to tranche level data to ensure rows with same tranche name all display
          hierarchy[lastLevel] = `${hierarchy[lastLevel]} ${currentIndex}`;
        }
        const investmentBreakdownViewModel: IInvestmentBreakdownViewModel = {
          ...curr,
          displayName,
          hierarchy,
        };

        obj[treeNumber] = obj[treeNumber] || [];
        obj[treeNumber].push(investmentBreakdownViewModel);

        return obj;
      },
      structuredClone(EMPTY_INVESTMENT_BREAKDOWN_DATA)
    );

  return investmentBreakdownGrouped;
};

/**
 * Sorts a grouping of investment dreakdown data sets in hierarchical way
 * @param unsorted an unsorted goruping of investment dreakdown data sets
 */
export const sortInvestmentBreakdown = (
  unsorted: IInvestmentBreakdownViewModel[]
) => {
  return unsorted.sort((a, b) => {
    if (a.hierarchy.length > b.hierarchy.length) {
      return 1;
    } else if (a.hierarchy.length === b.hierarchy.length) {
      return a.hierarchy[a.hierarchy.length - 1].localeCompare(
        b.hierarchy[b.hierarchy.length - 1]
      );
    } else {
      return -1;
    }
  });
};

type InvestmentBreakdownValueSelectors = {
  [key in InvestmentBreakdownKey]: (
    datum: IInvestmentBreakdownValueSet
  ) => number;
};

export const InvestmentBreakDownValueSelectors: InvestmentBreakdownValueSelectors =
  {
    [InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED]: (datum) =>
      isSomething(datum.unrealizedValue)
        ? datum.unrealizedValue.value.remainingCapitalInvested
        : 0,
    [InvestmentBreakdownKey.UNREALIZED_GAIN_LOSS]: (datum) =>
      isSomething(datum.unrealizedValue)
        ? datum.unrealizedValue.value.gainLoss
        : 0,
    [InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST]: (datum) =>
      isSomething(datum.unrealizedValue)
        ? datum.unrealizedValue.value.carriedInterest
        : 0,
    [InvestmentBreakdownKey.TOTAL_UNREALIZED_VALUE]: (datum) =>
      isSomething(datum.unrealizedValue)
        ? datum.unrealizedValue.value.total
        : 0,
    [InvestmentBreakdownKey.RETURN_OF_CAPITAL]: (datum) =>
      isSomething(datum.realizedProceeds)
        ? datum.realizedProceeds.value.returnOfCapital
        : 0,
    [InvestmentBreakdownKey.REALIZED_GAIN_LOSS]: (datum) =>
      isSomething(datum.realizedProceeds)
        ? datum.realizedProceeds.value.gainLoss
        : 0,
    [InvestmentBreakdownKey.REALIZED_CARRIED_INTEREST]: (datum) =>
      isSomething(datum.realizedProceeds)
        ? datum.realizedProceeds.value.carriedInterest
        : 0,
    [InvestmentBreakdownKey.TOTAL_REALIZED_PROCEEDS]: (datum) =>
      isSomething(datum.realizedProceeds)
        ? datum.realizedProceeds.value.total
        : 0,
    [InvestmentBreakdownKey.CAPITAL_INVESTED]: (datum) =>
      isSomething(datum.investment)
        ? datum.investment.value.capitalInvested * -1
        : 0, //Capital Is An outFlow from the user perspective
    [InvestmentBreakdownKey.ABSOLUTE_CAPITAL_INVESTED]: (datum) =>
      isSomething(datum.investment)
        ? datum.investment.value.capitalInvested
        : 0,
    [InvestmentBreakdownKey.NET_CASH_FLOW]: (datum) =>
      isSomething(datum.investment) ? datum.investment.value.netCashFlow : 0,
  };
