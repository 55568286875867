import { Dashboard, EnvironmentResolver } from "common";
import React from "react";

const DashboardWrapper = () => {
  return (
    <Dashboard
      showUserWelcome={false}
      hasViewStockEntitlement={false}
      hasViewRestrictedEquity={
        EnvironmentResolver.ENV.RESTRICTED_EQUITY_ADMIN_FEATURE_FLAG
      }
      hasViewV2DashboardEquityCardEntitlement={false}
    />
  );
};

export default DashboardWrapper;
