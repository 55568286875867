import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { CashFlowChart } from "../../../components/CashFlowChart/CashFlowChart";
import { IDashboardCardContentProps } from "../../../components/DashboardCard/DashboardCard";
import { TitleWithPeriodToggle } from "../../../components/TitleWithPeriodToggle/TitleWithPeriodToggle";
import {
  InvestmentBreakdownKey,
  InvestmentValueDisplay,
  PeriodType,
} from "../../../constants/enums";
import {
  getDateRangeHeader,
  InvestmentHistoryLabel,
  SectionHeader,
} from "../../../constants/LabelAndTooltipConstants";
import colors from "../../../styles/_colors.scss";
import {
  IBalancePerformanceData,
  IHistoricalSummaryDatum,
} from "../../../types/dataTypes";
import { InvestmentHistoryValueItem } from "../../../types/investmentHistoryTypes";
import { isSomething } from "../../../types/typeGuards";
import { getFirstDayOfQuarter } from "../../../utils/formatters";
import {
  initializeChartDataAndToolbar,
  trimData,
} from "../../../utils/investmentHistoryUtils";
import styles from "./CashFlow.module.scss";
import { CashFlowLegend } from "./CashFlowLegend/CashFlowLegend";

const cashFlowDataTypes: InvestmentHistoryValueItem[] = [
  {
    key: InvestmentBreakdownKey.TOTAL_REALIZED_PROCEEDS,
    color: colors.avocado,
    isSelected: true,
    labelOverride: InvestmentHistoryLabel.REALIZED_PROCEEDS,
  },
  {
    key: InvestmentBreakdownKey.CAPITAL_INVESTED,
    color: colors.cobalt,
    isSelected: true,
  },
  {
    key: InvestmentBreakdownKey.NET_CASH_FLOW,
    color: colors.white,
    isSelected: false,
    isReadOnly: true,
    labelOverride: InvestmentHistoryLabel.NET_CASH_FLOW,
  },
];

export const CashFlow = (
  props: IDashboardCardContentProps<IBalancePerformanceData>
) => {
  const { historicalSummaryGrouped, asOfDate } = props.data;

  const [graphData, setGraphData] = useState<IHistoricalSummaryDatum[]>([]);
  const [periodType, setPeriodType] = useState<PeriodType>(
    PeriodType.QUARTERLY
  );
  const isCumulative = false;
  const valueDisplay = InvestmentValueDisplay.NONCUMULATIVE;

  const [graphInitialized, setGraphInitialized] = useState<boolean>(false);
  const [dataTypes, setDataTypes] = useState<InvestmentHistoryValueItem[]>([]);
  const [highlightedBar, setHighlightedBar] =
    useState<InvestmentBreakdownKey | null>(null);

  const firstQuarterDate = useMemo(() => {
    return asOfDate.earliestAsOfDate;
  }, [asOfDate]);

  useEffect(() => {
    if (isSomething(historicalSummaryGrouped)) {
      const trimmedData = trimData(
        historicalSummaryGrouped.value[periodType],
        cashFlowDataTypes.map((type) => type.key),
        valueDisplay
      );
      setGraphData(trimmedData);
      initializeChartDataAndToolbar(
        trimmedData,
        cashFlowDataTypes,
        setDataTypes,
        setGraphInitialized
      );
    }
  }, [historicalSummaryGrouped, valueDisplay, periodType]);

  return (
    <div className={styles.card}>
      <Grid item xs={12} className={styles.header}>
        <TitleWithPeriodToggle
          sectionHeader={SectionHeader.CASH_FLOW}
          sectionSubHeader={getDateRangeHeader(
            getFirstDayOfQuarter(asOfDate.earliestAsOfDate),
            asOfDate.latestAsOfDate
          )}
          tabSelected={"CashFlow"}
          historicalSummaryData={graphData}
          periodType={periodType}
          setPeriodType={setPeriodType}
          valueDisplay={valueDisplay}
          dataTypes={dataTypes}
          setDataTypes={setDataTypes}
        />
      </Grid>
      <Grid item xs={12} className={styles.chart}>
        <CashFlowChart
          historicalSummaryData={graphData}
          firstQuarterDate={firstQuarterDate}
          dataTypes={dataTypes}
          highlightedBar={highlightedBar}
          graphInitialized={graphInitialized}
          periodType={periodType}
        />
      </Grid>
      <Grid item xs={12} className={styles.legend}>
        <CashFlowLegend
          historicalSummaryData={graphData}
          isCumulative={isCumulative}
          dataTypes={dataTypes}
          highlightedBar={highlightedBar}
          setHighlightedBar={setHighlightedBar}
        />
      </Grid>
    </div>
  );
};
