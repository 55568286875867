import { Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { ElectionsDocumentDownloadButton } from "../../../../../components/ElectionsDocumentDownloadButton/ElectionsDocumentDownloadButton";
import { Markdown } from "../../../../../components/Markdown/Markdown";
import { ElectionsLabels } from "../../../../../constants/LabelAndTooltipConstants";
import {
  selectCanUseFinancing,
  selectFinancingOptionsDocumentId,
} from "../../../../../redux/selectors";
import { selectFinancingContent } from "../../../../../redux/selectors/electionReviewAndSignSelectors";
import { IBaseStore } from "../../../../../redux/store";
import { ElectionAccordionObject } from "../../../../../types/electionDataTypes";
import { isSomething } from "../../../../../types/typeGuards";
import { AccordionElectionCard } from "../../Shared/AccordionElectionCard/AccordionElectionCard";
import styles from "./FinancingOptionsCard.module.scss";

export const FinancingOptionsCard = () => {
  const cardContent = useSelector((state: IBaseStore) =>
    selectFinancingContent(state)
  );

  const canUseFinancing = useSelector((state: IBaseStore) =>
    selectCanUseFinancing(state)
  );

  const financingOptionsDocumentId = useSelector((state: IBaseStore) =>
    selectFinancingOptionsDocumentId(state)
  );

  const { activeElection } = useSelector(
    (state: IBaseStore) => state.selectedElection
  );

  const Header = () => {
    return (
      <Stack className={styles.header}>
        <Typography className={styles.title}>
          {ElectionsLabels.FINANCING_OPTIONS}
        </Typography>
        {isSomething(activeElection) &&
          financingOptionsDocumentId !== undefined && (
            <ElectionsDocumentDownloadButton
              label={ElectionsLabels.FINANCING_OPTIONS_DOWNLOAD_BUTTON_TEXT}
              electionRoundId={activeElection.value.electionRoundId}
              documentId={financingOptionsDocumentId}
            />
          )}
      </Stack>
    );
  };

  return canUseFinancing && isSomething(cardContent) ? (
    <AccordionElectionCard
      headerComponent={<Header />}
      cardContentComponent={<Markdown text={cardContent.value} />}
      accordionId={ElectionAccordionObject.ELECT_FINANCING_OPTIONS}
    />
  ) : (
    <></>
  );
};
