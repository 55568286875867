import {
  CommonElectionWorkflowPages,
  ElectionsLabels,
  ElectionWorkflowPagesType,
  ElectionWorkflowStageId,
} from "common";

import { OverviewStageWrapper } from "../../features/Elections/ElectionWorkflow/OverviewStage/OverviewStageWrapper";
import { ReviewAndSignStageWrapper } from "../../features/Elections/ElectionWorkflow/ReviewAndSignStage/ReviewAndSignStageWrapper";

export const ElectionWorkflowPages: ElectionWorkflowPagesType = {
  ...CommonElectionWorkflowPages,
  [ElectionWorkflowStageId.OVERVIEW]: {
    name: ElectionsLabels.OVERVIEW,
    hasError: false,
    validStepperNavigationStages: [],
    element: OverviewStageWrapper,
  },
  [ElectionWorkflowStageId.REVIEW_AND_SIGN]: {
    name: ElectionsLabels.REVIEW_AND_SIGN,
    hasError: false,
    validStepperNavigationStages: [
      ElectionWorkflowStageId.OVERVIEW,
      ElectionWorkflowStageId.ELIGIBILITY,
      ElectionWorkflowStageId.STRATEGIES,
      ElectionWorkflowStageId.ELECT,
      ElectionWorkflowStageId.BANK_ACCOUNT,
    ],
    nextButtonTextOverride: ElectionsLabels.AGREE_AND_SUBMIT,
    element: ReviewAndSignStageWrapper,
  },
};
