import { Button, Stack } from "@mui/material";
import { BankAccountConstants } from "common";
import React, { useCallback } from "react";

import { IAddAccounCellProps } from "../AccountAssignmentGrid";
import styles from "./AddAccountCell.module.scss";

export const AddAccountCell = (props: IAddAccounCellProps) => {
  const { openBankAccountDialog } = props;

  const handleClick = useCallback(() => {
    if (props.data?.purpose) {
      openBankAccountDialog(props.data?.purpose);
    }
  }, [props.data, openBankAccountDialog]);

  return (
    <Stack direction="row" className={styles.addAccountCell}>
      <Button
        disabled={props.data?.disabled}
        variant="text"
        onClick={handleClick}
      >
        {BankAccountConstants.ADD_ACCOUNT}
      </Button>
    </Stack>
  );
};
