import { Box, Card } from "@mui/material";
import React from "react";

import { InfoTooltip } from "../../../../../components/InfoTooltip/InfoTooltip";
import { MonospaceNumber } from "../../../../../components/MonospaceNumber/MonospaceNumber";
import { EquityLabel } from "../../../../../constants/LabelAndTooltipConstants";
import { getCurrencyFormattedValueWithZeroDefault } from "../../../../../utils/formatters";
import {
  getEquityValues,
  getTotalLabels,
} from "../../../../../utils/restrictedEquityCalloutUtils";
import styles from "./RestrictedEquityCallout.module.scss";

interface IRestrictedEquityCalloutProps {
  tooltipParagraphs?: string[];
  total: number;
  unvestedTotal: number;
  unvestedTooltipsParagraphs?: string[] | React.ReactNode;
  vestedRestrictedTotal: number;
  vestedRestrictedTooltipsParagraphs?: string[] | React.ReactNode;
  vestedExchangeableTotal: number;
  vestedExchangeableTooltipsParagraphs?: string[] | React.ReactNode;
  showTooltip?: boolean;
  unit: string;
  showVestedExchangeable: boolean;
  formatter?: (val: number | undefined) => string;
}

export interface IRestrictedEquityValueProps {
  label: string;
  value: number;
  tooltipsParagraphs?: string[] | React.ReactNode;
  showTooltip?: boolean;
  formatter?: (val: number | undefined) => string;
}

const RestrictedEquityValue = (props: IRestrictedEquityValueProps) => {
  const { label, value, tooltipsParagraphs, showTooltip, formatter } = props;
  return (
    <Box className={styles.valueContainer}>
      <Box className={styles.valueLabel}>
        <span>{label}</span>
        {showTooltip && (
          <InfoTooltip tooltipContent={tooltipsParagraphs ?? [""]} />
        )}
      </Box>
      <Box className={styles.innerValue}>
        <MonospaceNumber
          value={value}
          valueFormatter={formatter ?? getCurrencyFormattedValueWithZeroDefault}
        />
      </Box>
    </Box>
  );
};

export const RestrictedEquityCallout = (
  props: IRestrictedEquityCalloutProps
) => {
  const {
    total,
    unvestedTotal,
    unvestedTooltipsParagraphs,
    vestedRestrictedTotal,
    vestedRestrictedTooltipsParagraphs,
    vestedExchangeableTotal,
    vestedExchangeableTooltipsParagraphs,
    showTooltip,
    unit,
    showVestedExchangeable,
    formatter,
  } = props;

  const equityValues = getEquityValues(
    unvestedTotal,
    showVestedExchangeable,
    unvestedTooltipsParagraphs,
    vestedRestrictedTotal,
    vestedRestrictedTooltipsParagraphs,
    vestedExchangeableTotal,
    vestedExchangeableTooltipsParagraphs,
    showTooltip
  );

  const formatFunc = formatter ?? getCurrencyFormattedValueWithZeroDefault;

  return (
    <Card className={styles.container}>
      <Box className={styles.totalContainer}>
        <Box className={styles.totalValueLabel}>
          <span>{getTotalLabels(unit, EquityLabel.TOTAL)}</span>
        </Box>
        <Box className={styles.value}>
          <MonospaceNumber value={total} valueFormatter={formatFunc} />
        </Box>
      </Box>
      <Box className={styles.breakdown}>
        {equityValues.map((equityValue, index) => (
          <React.Fragment key={index}>
            <RestrictedEquityValue
              label={equityValue.label}
              value={equityValue.value}
              tooltipsParagraphs={equityValue.tooltipsParagraphs}
              showTooltip={equityValue.showTooltip}
              formatter={formatFunc}
            />
            {index < equityValues.length - 1 && (
              <Box className={styles.separator} />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Card>
  );
};
