import { CustomCellRendererProps } from "ag-grid-react";
import React from "react";

import { MonospaceNumber } from "../../MonospaceNumber/MonospaceNumber";

export const getMonospaceCellRenderer = (
  formatter: (value: number | undefined) => string
) => {
  const MonospaceCellRenderer = (
    cellRendererParams: CustomCellRendererProps
  ) => (
    <MonospaceNumber
      value={cellRendererParams.value}
      valueFormatter={formatter}
    />
  );
  return MonospaceCellRenderer;
};
