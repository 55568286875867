import {
  ElectionTabsDisplayOrder,
  PageWithSubTabs,
  PathFragment,
  StringConstants,
} from "common";
import React from "react";

import { ElectionAdminPages } from "../../constants/Pages/SubTabPages";

const ElectionEditorWrapper = () => {
  return (
    <PageWithSubTabs
      pageHeader={StringConstants.ELECTION_ADMIN}
      pages={ElectionAdminPages}
      tabDisplayOrder={ElectionTabsDisplayOrder}
      defaultTab={`${PathFragment.ELECTION_EDITOR}`}
    />
  );
};

export default ElectionEditorWrapper;
