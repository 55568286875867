import React from "react";
import { useSelector } from "react-redux";

import { ElectionsDocumentDownloadButton } from "../../../../../../components/ElectionsDocumentDownloadButton/ElectionsDocumentDownloadButton";
import { FormCardHeader } from "../../../../../../components/FormCardHeader/FormCardHeader";
import { ElectionsLabels } from "../../../../../../constants/LabelAndTooltipConstants";
import {
  selectStrategyOverviewDocumentId,
  selectStrategyOverviewTooltip,
} from "../../../../../../redux/selectors";
import { IBaseStore } from "../../../../../../redux/store";
import { isSomething } from "../../../../../../types/typeGuards";

interface IStrategyOverviewProps {
  isAccordion?: boolean;
}

export const StrategyOverviewHeader = (props: IStrategyOverviewProps) => {
  const { isAccordion = false } = props;

  const { activeElection } = useSelector(
    (state: IBaseStore) => state.selectedElection
  );

  const strategyOverviewDocumentId = useSelector((state: IBaseStore) =>
    selectStrategyOverviewDocumentId(state)
  );

  const tooltip = useSelector((state: IBaseStore) =>
    selectStrategyOverviewTooltip(state)
  );

  return (
    <FormCardHeader
      title={ElectionsLabels.STRATEGY_OVERVIEW}
      tooltip={tooltip}
      actionElement={
        isSomething(activeElection) &&
        strategyOverviewDocumentId !== undefined ? (
          <ElectionsDocumentDownloadButton
            label={ElectionsLabels.VIEW_STRATEGY_OVERVIEW_PDF}
            electionRoundId={activeElection.value.electionRoundId}
            documentId={strategyOverviewDocumentId}
          />
        ) : (
          <></>
        )
      }
      isAccordionCard={isAccordion}
    />
  );
};
