import { Circle } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { MonospaceNumber } from "../../../../../components/MonospaceNumber/MonospaceNumber";
import { ALL_NAME_PLACEHOLDER } from "../../../../../constants/InvestmentBreakdownConstants";
import { UnrealizedValueLabels } from "../../../../../constants/LabelAndTooltipConstants";
import { selectLastPointerType } from "../../../../../redux/selectors";
import { ICarryChartData } from "../../../../../types/dataTypes";
import { getCurrencyFormattedValueWithZeroDefault } from "../../../../../utils/formatters";
import styles from "./CarryChart.module.scss";

export const CarryChartTooltip = ({
  activeData,
  onLinkClick,
  color,
}: {
  activeData: ICarryChartData;
  onLinkClick: () => void;
  color: string;
}) => {
  const lastPointerType = useSelector(selectLastPointerType);

  return (
    <div className={styles.tooltip}>
      <div>
        <ul className={styles.itemList}>
          <li key={activeData.mdmFundId} className={styles.item}>
            <Circle
              sx={{ fontSize: 10 }}
              className={styles.circle}
              style={{ color }}
            />
            <span className={styles.itemLabel}>
              <span>
                {activeData.fundShortName !== ALL_NAME_PLACEHOLDER
                  ? activeData.fundShortName
                  : activeData.businessUnitName}
              </span>
              :{" "}
            </span>
            <MonospaceNumber
              value={activeData.totalUnrealized}
              className={styles.itemValue}
              valueFormatter={getCurrencyFormattedValueWithZeroDefault}
            />
          </li>
          {lastPointerType !== "mouse" &&
            activeData.fundShortName === ALL_NAME_PLACEHOLDER && (
              <Button variant="text" onPointerDown={onLinkClick}>
                {UnrealizedValueLabels.VIEW_UNDERLYING_FUNDS}
              </Button>
            )}
        </ul>
      </div>
    </div>
  );
};
