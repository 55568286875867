import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { MonospaceNumber } from "../../../../../components/MonospaceNumber/MonospaceNumber";
import { ALL_NAME_PLACEHOLDER } from "../../../../../constants/InvestmentBreakdownConstants";
import { UnrealizedValueLabels } from "../../../../../constants/LabelAndTooltipConstants";
import { selectLastPointerType } from "../../../../../redux/selectors";
import { IInvestmentChartData } from "../../../../../types/dataTypes";
import { getCurrencyFormattedValueWithZeroDefault } from "../../../../../utils/formatters";
import styles from "./InvestmentChart.module.scss";

export const InvestmentChartTooltip = ({
  activeData,
  onLinkClick,
}: {
  activeData: IInvestmentChartData;
  onLinkClick: () => void;
}) => {
  const lastPointerType = useSelector(selectLastPointerType);

  const unrealizedValue = activeData.unrealizedValue;
  const remainingCapitalExists =
    unrealizedValue?.remainingCapitalInvested !== 0;
  const gainLossExists = unrealizedValue?.gainLoss !== 0;

  return (
    <div className={styles.tooltip}>
      <div>
        <ul className={styles.itemList}>
          <li key={activeData.mdmFundId} className={styles.item}>
            <span className={`${styles.tooltipDataRow} ${styles.tooltipLabel}`}>
              {activeData.fundShortName !== ALL_NAME_PLACEHOLDER
                ? activeData.fundShortName
                : activeData.businessUnitName}
            </span>
            {remainingCapitalExists && (
              <div className={styles.tooltipDataRow}>
                <span className={styles.tooltipLabel}>
                  {UnrealizedValueLabels.REMAINING_INVESTMENT}
                </span>
                <MonospaceNumber
                  value={unrealizedValue?.remainingCapitalInvested as number}
                  className={styles.itemValue}
                  valueFormatter={getCurrencyFormattedValueWithZeroDefault}
                />
              </div>
            )}
            {gainLossExists && (
              <div className={styles.tooltipDataRow}>
                <span className={styles.tooltipLabel}>
                  {UnrealizedValueLabels.GAIN_LOSS}
                </span>
                <span className={styles.itemValue}>
                  <MonospaceNumber
                    value={unrealizedValue?.gainLoss as number}
                    className={styles.itemValue}
                    valueFormatter={getCurrencyFormattedValueWithZeroDefault}
                  />
                </span>
              </div>
            )}
            {remainingCapitalExists && gainLossExists && (
              <div
                className={`${styles.tooltipDataRow} ${styles.tooltipTotalData}`}
              >
                <span className={styles.tooltipLabel}>
                  {UnrealizedValueLabels.TOTAL_UNREALIZED_VALUE}
                </span>
                <MonospaceNumber
                  value={
                    unrealizedValue?.optionalAndMandatoryInvestments as number
                  }
                  className={styles.itemValue}
                  valueFormatter={getCurrencyFormattedValueWithZeroDefault}
                />
              </div>
            )}
            {lastPointerType !== "mouse" &&
              activeData.fundShortName === ALL_NAME_PLACEHOLDER && (
                <Button variant="text" onPointerDown={onLinkClick}>
                  {UnrealizedValueLabels.VIEW_UNDERLYING_FUNDS}
                </Button>
              )}
          </li>
        </ul>
      </div>
    </div>
  );
};
