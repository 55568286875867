export enum PathFragment {
  BANK_ACCOUNTS = "bank-accounts",
  COMMITMENTS = "commitments",
  DASHBOARD = "dashboard",
  DISTRIBUTIONS = "distributions",
  DOCUMENTS = "documents",
  ELECT = "elect",
  ELECTIONS = "sbs-elections",
  ELECTION_WORKFLOW = "/sbs-elections/:investmentVehicleId/:electionRoundId",
  ELIGIBILITY = "eligibility",
  EQUITY = "equity",
  INVESTMENT_BREAKDOWN = "investment-breakdown",
  INVESTMENTS_AND_CARRY = "investments-and-carry",
  OVERVIEW = "overview",
  REVIEW = "review",
  SBS = "sbs-",
  STRATEGIES = "strategies",
  EQUITY_VALUE = "equity-value",
  ELECTION_RESULTS = "election-results",
  ELECTION_EDITOR = "election-editor",
  ADMIN_EQUITY = "employee-data",
  ADMIN_CLIENT = "client-data",
  USER_AGREEMENTS = "user-agreements",
  TERMS_OF_USE = "terms-of-use",
}
