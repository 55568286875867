import { Grid } from "@mui/material";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";

import { getMonospaceCellRenderer } from "../../../../../components/Grid/MonospaceCellRenderer/MonospaceCellRenderer";
import { GridAsTable } from "../../../../../components/GridAsTable/GridAsTable";
import { GridHeaderWithTooltip } from "../../../../../components/GridHeaderWithTooltip/GridHeaderWithTooltip";
import { TooltipCellRenderer } from "../../../../../components/TooltipCellRenderer/TooltipCellRenderer";
import {
  CommitmentsLabels,
  CommitmentsTooltips,
} from "../../../../../constants/LabelAndTooltipConstants";
import { useGridExtensions } from "../../../../../hooks/useGridExtensions";
import { ICommitmentLOF } from "../../../../../types/dataTypes";
import {
  getCurrencyFormattedValue,
  getRoundedPercentFormatted,
} from "../../../../../utils/formatters";

interface ILifeOfFundsGridProps {
  lifeOfFundCommitments: ICommitmentLOF[];
}

export const LifeOfFundsGrid = (props: ILifeOfFundsGridProps) => {
  const { lifeOfFundCommitments } = props;

  const [, setIsGridReady] = useState<boolean>(false);

  const gridRef = useRef<AgGridReact>(null);

  const {
    setHeaderHeight,
    resizeColumns,
    onGridReady: OnGridReadyCommon,
  } = useGridExtensions("viewByColumn");

  const onGridReady = (params: GridReadyEvent) => {
    setIsGridReady(true);
    OnGridReadyCommon(params);
  };

  const lofColumnDefs: ColDef<ICommitmentLOF>[] = useMemo(
    () => [
      {
        field: "fundName",
        headerName: CommitmentsLabels.FUND,
        minWidth: 150,
        cellRenderer: TooltipCellRenderer,
      },
      {
        field: "electedAmount",
        headerName: CommitmentsLabels.ELECTED_AMOUNT,
        minWidth: 150,
        type: "rightAligned",
        cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
        headerComponent: GridHeaderWithTooltip,
        headerComponentParams: {
          labelClass: "ag-sub-header-group-cell-label",
          textClass: "ag-sub-header-group-text",
          rightAlign: true,
          tooltipContent: CommitmentsTooltips.LOF_ELECTED_AMNT_TOOLTIP,
        },
      },
      {
        field: "capitalCalled",
        headerName: CommitmentsLabels.CAPITAL_CALLED,
        minWidth: 150,
        type: "rightAligned",
        cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
        headerComponent: GridHeaderWithTooltip,
        headerComponentParams: {
          labelClass: "ag-sub-header-group-cell-label",
          textClass: "ag-sub-header-group-text",
          rightAlign: true,
          tooltipContent: CommitmentsTooltips.LOF_CAPITAL_CALLED_TOOLTIP,
        },
      },
      {
        field: "capitalCalledPercent",
        headerName: CommitmentsLabels.CAPITAL_CALLED_PERCENT,
        minWidth: 150,
        type: "rightAligned",
        cellRenderer: getMonospaceCellRenderer(getRoundedPercentFormatted),
      },
    ],
    []
  );

  return (
    <Grid item xs={12} className={`ag-theme-alpine`}>
      <GridAsTable<ICommitmentLOF>
        ref={gridRef}
        rowData={lifeOfFundCommitments}
        columnDefs={lofColumnDefs}
        domLayout="autoHeight"
        onFirstDataRendered={setHeaderHeight}
        onColumnResized={setHeaderHeight}
        cacheQuickFilter={true}
        suppressAggFuncInHeader={true}
        suppressContextMenu={true}
        suppressCellFocus={true}
        onRowDataUpdated={resizeColumns}
        onGridSizeChanged={resizeColumns}
        onDisplayedColumnsChanged={resizeColumns}
        onGridReady={onGridReady}
        suppressMenuHide={true}
      />
    </Grid>
  );
};
