import { usePDF } from "@react-pdf/renderer";
import {
  ErrorStringConstants,
  getElectCardData,
  getSubmission,
  isSomething,
  mapElectionRoundConfigurationToModel,
  nothing,
  openAlert,
  Optional,
  parseJsonToSubmissionResponse,
  some,
} from "common";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import sanitize from "sanitize-filename";

import { ElectionAgreementDocument } from "../features/Elections/Shared/ElectionAgreementDocument/ElectionAgreementDocument";

const IV_NAME_TOKEN = "IV_NAME";
const SUBMISSION_DATE_TOKEN = "SUBMISSION_DATE";
const FILE_NAME_TEMPLATE = `SBS Election Submission Agreement_${IV_NAME_TOKEN}_${SUBMISSION_DATE_TOKEN}.pdf`;

export const useDownloadElectionAgreement = () => {
  const dispatch = useDispatch();
  const [instance, setInstance] = usePDF({});
  const [electionIVInformation, setElectionIVInformation] =
    useState<Optional<{ name: string; submissionDate: Date }>>(nothing);
  const [isRequestingData, setIsRequestingData] = useState(false);

  useEffect(() => {
    if (
      isRequestingData &&
      instance.url &&
      isSomething(electionIVInformation)
    ) {
      if (instance.error) {
        dispatch(
          openAlert({
            severity: "error",
            message: ErrorStringConstants.DOWNLOAD_FAILED,
          })
        );
        setIsRequestingData(false);
        return;
      }

      const { name, submissionDate } = electionIVInformation.value;
      const filename = FILE_NAME_TEMPLATE.replace(
        IV_NAME_TOKEN,
        sanitize(name)
      ).replace(
        SUBMISSION_DATE_TOKEN,
        submissionDate.toISOString().split("T")[0].replaceAll("-", "")
      );
      const link = document.createElement("a");
      link.href = instance.url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsRequestingData(false);
    }
  }, [
    dispatch,
    instance.error,
    instance.url,
    isRequestingData,
    electionIVInformation,
  ]);

  const downloadPDF = useCallback(
    async (
      electionRoundId: string,
      investmentVehicleId: number,
      isAdmin: boolean
    ) => {
      setIsRequestingData(true);

      const jsonResponse = await getSubmission(
        electionRoundId,
        investmentVehicleId,
        isAdmin
      );

      if (jsonResponse === undefined) {
        dispatch(
          openAlert({
            severity: "error",
            message: ErrorStringConstants.DOWNLOAD_FAILED,
          })
        );
        setIsRequestingData(false);
        return;
      }

      const {
        electionIVConfiguration,
        savedWorkflowStateResponse,
        electionRoundConfiguration,
      } = parseJsonToSubmissionResponse(jsonResponse);
      const electionRoundConfigurationModel =
        mapElectionRoundConfigurationToModel(electionRoundConfiguration);

      const electionAmount = getElectCardData(
        electionRoundConfigurationModel,
        electionIVConfiguration,
        savedWorkflowStateResponse
      );

      const ivName = electionIVConfiguration.investmentVehicle.name;
      if (
        !isSomething(
          savedWorkflowStateResponse.electionStages.reviewAndSign.submitDate
        )
      ) {
        throw new Error("");
      }
      const submissionDate =
        savedWorkflowStateResponse.electionStages.reviewAndSign.submitDate
          .value;

      const currentElectionIVInformation = {
        name: ivName,
        submissionDate: submissionDate,
      };

      setElectionIVInformation(some(currentElectionIVInformation));

      setInstance(
        ElectionAgreementDocument({
          workflowState: savedWorkflowStateResponse,
          roundConfig: electionRoundConfiguration,
          ivConfig: electionIVConfiguration,
          electionAmount: electionAmount,
        })
      );
    },
    [dispatch, setInstance]
  );

  return downloadPDF;
};
