import { Grid, Stack, Typography } from "@mui/material";
import { CopyToClipboardButton, TextEditor } from "common";
import React, { useState } from "react";

import styles from "./ElectionEditor.module.scss";

export const ElectionsAdminPage = () => {
  const markdown = "";
  const [formattedText, setFormattedText] = useState("");
  const updateText = (input: string) => {
    const scappedInput = input.replace(/\n/g, "  \\n").replaceAll('"', '\\"');
    setFormattedText(scappedInput);
  };

  return (
    <Grid
      className={styles.page}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography variant="h2">Election text editor</Typography>
      </Grid>
      <Grid item xs={6} className={styles.gridItem}>
        <Typography variant="h4">Formatted text</Typography>
        <TextEditor markdown={markdown} onChange={updateText} />
      </Grid>
      <Grid item xs={6} className={styles.gridItem}>
        <Typography variant="h4">
          Unformatted text (Use this to copy into payload)
        </Typography>
        <Stack direction="row" justifyContent="space-evenly">
          <Typography variant="body1">{formattedText}</Typography>
          <CopyToClipboardButton text={formattedText}></CopyToClipboardButton>
        </Stack>
      </Grid>
    </Grid>
  );
};
