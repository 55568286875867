import { Grid } from "@mui/material";
import { GridReadyEvent, RowClassParams } from "ag-grid-community";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";

import { getMonospaceCellRenderer } from "../../../../../components/Grid/MonospaceCellRenderer/MonospaceCellRenderer";
import { GridAsTable } from "../../../../../components/GridAsTable/GridAsTable";
import { TooltipCellRenderer } from "../../../../../components/TooltipCellRenderer/TooltipCellRenderer";
import { CommitmentsLabels } from "../../../../../constants/LabelAndTooltipConstants";
import { useGridExtensions } from "../../../../../hooks/useGridExtensions";
import {
  IForecastedCapCallByStrategy,
  IForecastedCapCalls,
} from "../../../../../types/dataTypes";
import {
  groupForecastedCapitalCallsByStrategy,
  sortForecastedCapitalCallsAndAddTotalsRow,
} from "../../../../../utils/commitmentsUtils";
import { getCurrencyFormattedValue } from "../../../../../utils/formatters";

interface IForecastedCapitalCallsGridProps {
  forecastedCapitalCalls: IForecastedCapCalls;
}

export const ForecastedCapitalCallsGrid = (
  props: IForecastedCapitalCallsGridProps
) => {
  const { forecastedCapitalCalls } = props;
  const forecastedCapCallsByStrategy: IForecastedCapCallByStrategy[] =
    groupForecastedCapitalCallsByStrategy(forecastedCapitalCalls.capitalCalls);

  const [, setIsGridReady] = useState<boolean>(false);

  const gridRef = useRef<AgGridReact>(null);

  const {
    setHeaderHeight,
    resizeColumns,
    onGridReady: OnGridReadyCommon,
  } = useGridExtensions("viewByColumn");

  const onGridReady = (params: GridReadyEvent) => {
    setIsGridReady(true);
    OnGridReadyCommon(params);
  };

  const forecastedColumnDefs: ColDef<IForecastedCapCallByStrategy>[] = useMemo(
    () => [
      {
        field: "strategyName",
        headerName: CommitmentsLabels.STRATEGY,
        minWidth: 160,
        cellRenderer: TooltipCellRenderer,
      },
      {
        field: "amount",
        headerName: CommitmentsLabels.AMOUNT,
        minWidth: 150,
        type: "rightAligned",
        cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
        headerComponentParams: {
          labelClass: "ag-sub-header-group-cell-label",
          textClass: "ag-sub-header-group-text",
          rightAlign: true,
        },
      },
    ],
    []
  );

  // sort the strategies alphabetically and add totals row
  const forecastedCapCallsByStrategyWithTotal: IForecastedCapCallByStrategy[] =
    useMemo(
      () =>
        sortForecastedCapitalCallsAndAddTotalsRow(forecastedCapCallsByStrategy),
      [forecastedCapCallsByStrategy]
    );

  // styles the totals row differently
  const getRowStyle = (params: RowClassParams) => {
    if (params.node.data.strategyId === undefined) {
      return {
        fontWeight: "500",
        borderBottom: "1px solid black",
      };
    }
  };

  return (
    <Grid item xs={12} className={`ag-theme-alpine`}>
      <GridAsTable<IForecastedCapCallByStrategy>
        ref={gridRef}
        rowData={forecastedCapCallsByStrategyWithTotal}
        columnDefs={forecastedColumnDefs}
        domLayout="autoHeight"
        onFirstDataRendered={setHeaderHeight}
        onColumnResized={setHeaderHeight}
        cacheQuickFilter={true}
        suppressAggFuncInHeader={true}
        suppressContextMenu={true}
        suppressCellFocus={true}
        onRowDataUpdated={resizeColumns}
        onGridSizeChanged={resizeColumns}
        onDisplayedColumnsChanged={resizeColumns}
        onGridReady={onGridReady}
        suppressMenuHide={true}
        getRowStyle={getRowStyle}
      />
    </Grid>
  );
};
