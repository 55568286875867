import { IFinancingBalance } from "../types/dataTypes";

export const hasFinancingData = (
  financingData: IFinancingBalance,
  withLoanUtilization: LoansCardUtilization
): boolean => {
  return (
    financingData.firmFinancingBalance !== 0 ||
    financingData.thirdPartyFinancingBalance !== 0 ||
    financingData.thirdPartyPendingLoanBalance !== 0 ||
    financingData.totalFinancingBalance !== 0 ||
    (withLoanUtilization === LoansCardUtilization.INCLUDE_UTILIZATION &&
      (financingData.thirdPartyLoanLimit !== 0 ||
        financingData.thirdPartyRemainingLoanCapacity !== 0))
  );
};

export enum LoansCardUtilization {
  INCLUDE_UTILIZATION,
  IGNORE_UTILIZATION,
}

/*
Helper function that takes financing data and validates if should show the loan card
*/
export const showLoanCard = (
  financingData: IFinancingBalance,
  setShowFinancingData: React.Dispatch<React.SetStateAction<boolean>>,
  withLoanUtilization: LoansCardUtilization
) => {
  try {
    //Show loans card if any of the next values isn't zero
    const showLoandCardYN = hasFinancingData(
      financingData,
      withLoanUtilization
    );
    setShowFinancingData(showLoandCardYN);
  } catch {
    setShowFinancingData(false);
  }
};
