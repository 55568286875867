import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: "6px",
          borderRadius: "4px",
          backgroundColor: colors.bx_slate_shade,
        },
        bar1Indeterminate: {
          backgroundColor: colors.cobalt,
        },
        bar2Indeterminate: {
          backgroundColor: colors.cobalt,
        },
      },
    },
  },
};

export const CustomLinearProgressTheme =
  partialTheme.components?.MuiLinearProgress;
