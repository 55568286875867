import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { BankAccountConstants } from "../../../constants/BankAccountConstants";
import { EntityType } from "../../../constants/enums";
import { StringConstants } from "../../../constants/StringConstants";
import {
  reviewAndSignFormBankAccounts,
  submitMultipleBankAccountChanges,
} from "../../../redux/reducers/bankAccountsReducer";
import { IBaseStore } from "../../../redux/store";
import { postSubmitBankAccounts } from "../../../services/bankAccountsService";
import {
  BankAccountDocument,
  BankAccountDocumentInfo,
  BankAccountDocumentUploadStatus,
  IVBankAccounts,
  TReviewAndSignFormBankAccounts,
} from "../../../types/bankAccountDataTypes";
import { IESignature } from "../../../types/dataTypes";
import { isSomething } from "../../../types/typeGuards";
import { ElectionDialog } from "../../Elections/ElectionWorkflow/Shared/ElectionDialog/ElectionDialog";
import { DownloadAgreementDialog } from "../DownloadAgreementDialog/DownloadAgreementDialog";
import styles from "./ReviewAndSignDialog.module.scss";
import {
  ReviewAndSignForm,
  ReviewAndSignFormState,
} from "./ReviewAndSignForm/ReviewAndSignForm";
import { SubmissionErrorDialog } from "./SubmissionErrorDialog/SubmissionErrorDialog";

export interface ReviewAndSignDialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  retryFunction: () => void;
  hasUnsubmittedChanges: boolean;
}

enum ReviewAndSignDialogStage {
  FORM,
  DOWNLOAD_AGREEMENT,
  ERROR,
}

export const ReviewAndSignDialog = (props: ReviewAndSignDialogProps) => {
  const { setOpen, retryFunction, hasUnsubmittedChanges } = props;
  const dispatch = useDispatch();
  const [dialogStage, setDialogStage] = useState<ReviewAndSignDialogStage>(
    ReviewAndSignDialogStage.FORM
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);
  const [eSignature, setESignature] = useState<IESignature>();
  const [submittedBankAccounts, setSubmittedBankAccounts] = useState<
    IVBankAccounts[]
  >([]);
  const bankAccounts = useSelector(reviewAndSignFormBankAccounts);

  const handleCloseDownloadModal = () => {
    retryFunction();
    setOpen(false);
  };

  const { isBankAccountsAdminMode } = useSelector(
    (store: IBaseStore) => store.bankAccounts
  );

  const { investmentVehicles } = useSelector(
    (store: IBaseStore) => store.bankAccounts.serverData
  );

  const isOrganization = useMemo(
    () =>
      investmentVehicles
        .map((iv) => iv.entityType)
        .some((et) => et === EntityType.ORGANIZATION),
    [investmentVehicles]
  );

  const defaultValue: ReviewAndSignFormState = {
    reviewAndSign: {
      signature: "",
      phoneNumber: "",
      signatureInMyCapacityAs: "",
      agreesToTerms: false,
    },
  };

  const reviewAndSignForm = useForm<ReviewAndSignFormState>({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const watchAllFields = reviewAndSignForm.watch();

  const [documents, setDocuments] = useState<BankAccountDocument[]>([]);

  const checkRequiredFieldsCompleted = useCallback(() => {
    reviewAndSignForm
      .trigger([
        "reviewAndSign.agreesToTerms",
        "reviewAndSign.signature",
        "reviewAndSign.phoneNumber",
        "reviewAndSign.signatureInMyCapacityAs",
      ])
      .then((allCompleted) => {
        const validDocs =
          documents.length === 0 ||
          !documents.some(
            (doc: BankAccountDocument) =>
              doc.uploadStatus !== BankAccountDocumentUploadStatus.SUCCESS
          );
        setNextButtonDisabled(!(allCompleted && validDocs));
      });
  }, [reviewAndSignForm, documents]);

  useEffect(() => {
    if (dialogStage === ReviewAndSignDialogStage.FORM && !loading) {
      checkRequiredFieldsCompleted();
    }
  }, [checkRequiredFieldsCompleted, loading, watchAllFields, dialogStage]);

  const handleNext = useCallback(async () => {
    setLoading(true);
    setNextButtonDisabled(true);
    const { reviewAndSign: reviewAndSignState } = reviewAndSignForm.getValues();

    const bankAccountsToSubmit: IVBankAccounts[] = bankAccounts.flatMap(
      (IVData: TReviewAndSignFormBankAccounts) => {
        const {
          investmentVehicle: assignedIV,
          contributionDebitAccount,
          distributionDepositAccount,
        } = IVData;
        // Skip if no changes to IV
        if (
          !isSomething(contributionDebitAccount) &&
          !isSomething(distributionDepositAccount)
        ) {
          return [];
        }
        const bankAccountsToAdd: IVBankAccounts[] = [];
        if (isSomething(contributionDebitAccount)) {
          bankAccountsToAdd.push({
            ...contributionDebitAccount.value,
            assignedIV,
          });
        }
        if (isSomething(distributionDepositAccount)) {
          bankAccountsToAdd.push({
            ...distributionDepositAccount.value,
            assignedIV,
          });
        }
        return bankAccountsToAdd;
      }
    );

    const eSignature: IESignature = {
      phone: reviewAndSignState.phoneNumber,
      signature: reviewAndSignState.signature,
      agreesToTerms: reviewAndSignState.agreesToTerms,
      alternateSignatoryCapacity: isOrganization
        ? reviewAndSignState.signatureInMyCapacityAs
        : undefined,
    };

    setSubmittedBankAccounts(bankAccountsToSubmit);

    const documentInfo: BankAccountDocumentInfo[] = documents.map(
      (document: BankAccountDocument) => document.fileInfo
    );

    postSubmitBankAccounts(
      bankAccountsToSubmit,
      eSignature,
      isBankAccountsAdminMode,
      documentInfo
    )
      .then((res: unknown) => {
        if (!res) {
          /* This was causing the UI to continue to download agreement
           even if the request failed */
          throw new Error("There was an issue processing this request");
        }
        setESignature(eSignature);
        setDialogStage(ReviewAndSignDialogStage.DOWNLOAD_AGREEMENT);
        dispatch(
          submitMultipleBankAccountChanges({
            investmentVehicleIds: [
              ...new Set(
                bankAccountsToSubmit
                  .map(({ assignedIV }) => {
                    if (!assignedIV) return undefined;
                    return assignedIV.axiomId;
                  })
                  .filter((value) => value !== undefined) as number[]
              ),
            ],
          })
        );
      })
      .catch(() => {
        setNextButtonDisabled(false);
        setDialogStage(ReviewAndSignDialogStage.ERROR);
      })
      .finally(() => setLoading(false));
  }, [
    reviewAndSignForm,
    bankAccounts,
    isOrganization,
    isBankAccountsAdminMode,
    dispatch,
    documents,
  ]);

  return (
    <>
      <ElectionDialog
        title={BankAccountConstants.REVIEW_AND_SIGN_BANK_ACCOUNT}
        content={
          <ReviewAndSignForm
            reviewAndSignForm={reviewAndSignForm}
            isOrganization={isOrganization}
            bankAccounts={bankAccounts}
            documents={documents}
            setDocuments={setDocuments}
          />
        }
        open={
          dialogStage === ReviewAndSignDialogStage.FORM ||
          dialogStage === ReviewAndSignDialogStage.ERROR
        }
        handleClose={() => setOpen(false)}
        handleNext={handleNext}
        cancelButtonLabel={StringConstants.CANCEL}
        nextButtonLabel={BankAccountConstants.AGREE_AND_SUBMIT_TO_TREASURY}
        nextButtonDisabled={nextButtonDisabled || !hasUnsubmittedChanges}
        showOverlayComponent={loading}
        overlayComponent={<LoadingIndicator className={styles.loading} />}
        stackActionButtonsOnMobile={true}
      />
      <DownloadAgreementDialog
        open={dialogStage === ReviewAndSignDialogStage.DOWNLOAD_AGREEMENT}
        onClose={handleCloseDownloadModal}
        eSignature={eSignature}
        bankAccounts={submittedBankAccounts}
      />
      <SubmissionErrorDialog
        open={dialogStage === ReviewAndSignDialogStage.ERROR}
        handleClose={() => setDialogStage(ReviewAndSignDialogStage.FORM)}
      />
    </>
  );
};
