import { datadogRum } from "@datadog/browser-rum";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";

import { EnvironmentResolver } from "../services/environmentResolver";

const OPTIONAL_COOKIE_NAME = "bx_enable_optional_cookies";
const ENABLED = "enabled";
const DISABLED = "disabled";

export const areOptionalCookiesEnabled = () =>
  Cookies.get(OPTIONAL_COOKIE_NAME) === ENABLED;
export const areOptionalCookiesConfigured = () =>
  Cookies.get(OPTIONAL_COOKIE_NAME) !== undefined;

export const enableOptionalCookies = () => {
  Cookies.set(OPTIONAL_COOKIE_NAME, ENABLED, { expires: 365 });
  ReactGA.gtag("consent", "update", {
    ad_storage: "granted",
    ad_user_data: "granted",
    ad_personalization: "granted",
    analytics_storage: "granted",
  });
  datadogRum.setTrackingConsent("granted");
};

export const disableOptionalCookies = () => {
  Cookies.set(OPTIONAL_COOKIE_NAME, DISABLED);
  Cookies.remove(
    `_ga_${EnvironmentResolver.ENV.REACT_APP_GOOGLE_ANALYTICS_TRACKER}`
  );
  Cookies.remove("_ga");
  ReactGA.gtag("consent", "update", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
  });
  datadogRum.setTrackingConsent("not-granted");
  Cookies.remove("_dd_s");
};

export const initializeReactGA = (forceCookies = false) => {
  const enableOptionalCookies = forceCookies || areOptionalCookiesEnabled();

  if (
    EnvironmentResolver.ENV.REACT_APP_GOOGLE_ANALYTICS_TRACKER !== undefined
  ) {
    ReactGA.gtag("consent", "default", {
      ad_storage: enableOptionalCookies ? "granted" : "denied",
      ad_user_data: enableOptionalCookies ? "granted" : "denied",
      ad_personalization: enableOptionalCookies ? "granted" : "denied",
      analytics_storage: enableOptionalCookies ? "granted" : "denied",
    });
    ReactGA.initialize(
      `G-${EnvironmentResolver.ENV.REACT_APP_GOOGLE_ANALYTICS_TRACKER}`,
      {
        gaOptions: {
          cookieFlags: "SameSite=None;Secure",
        },
        gtagOptions: {
          user_properties: {
            bx_env: EnvironmentResolver.ENV.REACT_APP_ENV,
          },
        },
      }
    );
  }
};
