import { createAction } from "@reduxjs/toolkit";

import { DataLoadStatus, DownloadRequestStatus } from "../../constants/enums";
import {
  IInteralInvestmentClient,
  IInternalInvestmentClientDataDownload,
  IInvestmentDataDto,
  IInvestmentVehicleByClientByPeriod,
} from "../../types/dataTypes";
import { IElectionClientMapping } from "../../types/electionDataTypes";
import { Optional } from "../../types/typeUtils";

export const setInternalInvestmentDataFilters =
  createAction<IInvestmentVehicleByClientByPeriod>(
    "SET_INTERNAL_INVESTMENT_DATA_FILTERS"
  );

export const reqInternalInvestmentData =
  createAction<IInvestmentVehicleByClientByPeriod>(
    "REQ_INTERNAL_INVESTMENT_DATA"
  );

export const recvInternalInvestmentData = createAction<
  Optional<IInvestmentDataDto>
>("RECV_INTERNAL_INVESTMENT_DATA");

export const errInternalInvestmentData = createAction<DataLoadStatus>(
  "ERR_INTERNAL_INVESTMENT_DATA"
);

export const reqInternalInvestmentClients = createAction(
  "REQ_INTERNAL_INVESTMENT_CLIENTS"
);
export const recvInternalInvestmentClients = createAction<
  Optional<IInteralInvestmentClient[]>
>("RECV_INTERNAL_INVESTMENT_CLIENTS");
export const errInternalInvestmentClients = createAction<DataLoadStatus>(
  "ERR_INTERNAL_INVESTMENT_CLIENTS"
);

export const reqElectionClientMappings = createAction(
  "REQ_ELECTIONS_CLIENT_MAPPINGS"
);
export const recvElectionClientMappings = createAction<
  Optional<IElectionClientMapping[]>
>("RECV_ELECTIONS_CLIENT_MAPPINGS");
export const errElectionClientMappings = createAction<DataLoadStatus>(
  "ERR_ELECTIONS_CLIENT_MAPPINGS"
);

export const reqSelectedClient = createAction<IInteralInvestmentClient>(
  "REQ_SELECTED_CLIENT"
);

export const reqAllClientData =
  createAction<IInternalInvestmentClientDataDownload>("REQ_ALL_CLIENT_DATA");

export const recvAllClientData = createAction<Optional<IInvestmentDataDto[]>>(
  "RECV_ALL_CLIENT_DATA"
);

export const errAllClientData = createAction<DataLoadStatus>(
  "ERR_ALL_CLIENT_DATA"
);

export const setDownloadReqStatus = createAction<DownloadRequestStatus>(
  "SET_DOWNLOAD_REQ_STATUS"
);

export const resetSelectedClient = createAction<void>("RESET_SELECTED_CLIENT");

export const setPerformanceDetailsFilter = createAction<string>(
  "SET_PERFORMANCE_DETAILS_FILTER"
);
