import { createSelector } from "@reduxjs/toolkit";
import { isSomething } from "common";

import {
  selectElectionRoundConfiguration,
  selectElectionWorkflowStateLocal,
} from "./electionSelectors";

export const selectResidentialInformation = createSelector(
  selectElectionWorkflowStateLocal,
  (electionWorkflowState) => {
    return electionWorkflowState.electionStages.eligibility
      .residentialInformation;
  }
);

export const selectResidentialInformationTooltip = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.stages.eligibility
      .residentialInformationTooltip;
  }
);

export const selectEligibilityTooltips = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    const eligibilityStageConfig =
      electionRoundConfiguration.stages.eligibility;
    const accreditedInvestorStatusTooltip =
      eligibilityStageConfig.accreditedInvestorStatusTooltip;
    const qualifiedPurchaserStatusTooltip =
      eligibilityStageConfig.qualifiedPurchaserStatusTooltip;
    const notEligibleTooltip = eligibilityStageConfig.notEligibleTooltip;
    const residentialInformationTooltip =
      eligibilityStageConfig.residentialInformationTooltip;

    return {
      accreditedInvestorStatusTooltip: isSomething(
        accreditedInvestorStatusTooltip
      )
        ? accreditedInvestorStatusTooltip.value
        : "",
      qualifiedPurchaserStatusTooltip: isSomething(
        qualifiedPurchaserStatusTooltip
      )
        ? qualifiedPurchaserStatusTooltip.value
        : "",
      notEligibleTooltip: isSomething(notEligibleTooltip)
        ? notEligibleTooltip.value
        : "",
      residentialInformationTooltip: isSomething(residentialInformationTooltip)
        ? residentialInformationTooltip.value
        : "",
    };
  }
);

export const selectEligibilityStatuses = createSelector(
  selectElectionWorkflowStateLocal,
  (electionWorkflowState) => {
    const eligibleStatus =
      electionWorkflowState.electionStages.eligibility.eligibleStatus;
    const accreditedInvestorStatus = eligibleStatus.accreditedInvestorStatus;
    const qualifiedPurchaserStatus = eligibleStatus.qualifiedPurchaserStatus;
    return {
      accreditedInvestorStatus: {
        hasIndividualNetWorth: accreditedInvestorStatus.hasIndividualNetWorth,
        hasIndividualIncome: accreditedInvestorStatus.hasIndividualIncome,
        isProfessional: accreditedInvestorStatus.isProfessional,
      },
      qualifiedPurchaserStatus: {
        hasInvestmentsInBlackstoneFunds:
          qualifiedPurchaserStatus.hasInvestmentsInBlackstoneFunds,
        isCompany: qualifiedPurchaserStatus.isCompany,
        isTrust: qualifiedPurchaserStatus.isTrust,
        isIndividualInvestment: qualifiedPurchaserStatus.isIndividualInvestment,
      },
      isNotEligible: eligibleStatus.isNotEligible,
    };
  }
);
