import {
  ElectionsLabels,
  FormCardHeader,
  isSomething,
  Markdown,
  nothing,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import { selectLegalAttestation } from "../../../../../redux/selectors";
import { IBaseStore } from "../../../../../redux/store";
import { ElectionCard } from "../../Shared/ElectionCard";
import styles from "./LegalAttestationCard.module.scss";

interface ILegalAttestationCardProps {
  form: JSX.Element;
  hasErrors: boolean;
}

export const LegalAttestationCard = (props: ILegalAttestationCardProps) => {
  const { form, hasErrors } = props;

  const legalAttestation = useSelector((state: IBaseStore) =>
    selectLegalAttestation(state)
  );

  return (
    <>
      {isSomething(legalAttestation) && (
        <ElectionCard error={hasErrors}>
          <FormCardHeader
            title={ElectionsLabels.LEGAL_ATTESTATION}
            subTitle={ElectionsLabels.LEGAL_ATTESTATION_HEADER_LINE}
            required={true}
            tooltip={nothing}
            hasError={hasErrors}
          />
          <div className={styles.cardContent}>
            <Markdown text={legalAttestation.value} />
          </div>
          {form}
        </ElectionCard>
      )}
    </>
  );
};
