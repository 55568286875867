import { Info } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { ElectionsLabels } from "common";
import React from "react";

import styles from "./TaxForms.module.scss";

interface ITaxFormsProps {
  showInfoIcon?: boolean;
}

export const TaxForms = (props: ITaxFormsProps) => {
  const { showInfoIcon = false } = props;

  return (
    <Stack direction="row" className={styles.actionItem}>
      {showInfoIcon && <Info color="info" />}
      <Typography>
        {!showInfoIcon && <b>{ElectionsLabels.PLEASE_NOTE}</b>}
        {ElectionsLabels.TAX_MESSAGE}
      </Typography>
    </Stack>
  );
};
