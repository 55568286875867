import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ElectionsLabels, ElectionWorkflowStageId } from "common";
import React from "react";

import styles from "./UnsavedChangesModal.module.scss";

interface IUnsavedChangesModalProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  handleGoWithoutSave: () => void;
  handleGoWithSave: () => void;
  activeStageId: ElectionWorkflowStageId;
  isAdminResetModal?: boolean;
}

const UnsavedChangesModalLabels = (
  stageId: ElectionWorkflowStageId,
  isAdminResetModal = false
) => {
  if (isAdminResetModal) {
    return {
      body: ElectionsLabels.RESET_ADMIN_MODAL_BODY,
      accept: ElectionsLabels.RESET_ADMIN_MODAL_ACCEPT,
      decline: ElectionsLabels.RESET_ADMIN_MODAL_DECLINE,
    };
  }

  switch (stageId) {
    case ElectionWorkflowStageId.BANK_ACCOUNT:
      return {
        body: ElectionsLabels.YOU_HAVE_UNSAVED_CHANGES_BA,
        accept: ElectionsLabels.YOU_HAVE_UNSAVED_CHANGES_ACCEPT_BA,
        decline: ElectionsLabels.YOU_HAVE_UNSAVED_CHANGES_DECLINE_BA,
      };
    default:
      return {
        body: ElectionsLabels.YOU_HAVE_UNSAVED_CHANGES,
        accept: ElectionsLabels.YOU_HAVE_UNSAVED_CHANGES_ACCEPT,
        decline: ElectionsLabels.YOU_HAVE_UNSAVED_CHANGES_DECLINE,
      };
  }
};

export const UnsavedChangesModal = (props: IUnsavedChangesModalProps) => {
  const { body, accept, decline } = UnsavedChangesModalLabels(
    props.activeStageId,
    props.isAdminResetModal
  );

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleGoWithoutSaveClick = () => {
    props.handleGoWithoutSave();
    handleClose();
  };

  const handleGoWithSaveClick = () => {
    props.handleGoWithSave();
    handleClose();
  };

  return (
    <Dialog open={props.open} className={styles.dialog}>
      <DialogContent>
        <Stack direction="row">
          <Typography>{body}</Typography>
          <IconButton className={styles.close} onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleGoWithoutSaveClick}>
          {decline}
        </Button>
        <Button id={styles.saveChangesButton} onClick={handleGoWithSaveClick}>
          {accept}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
