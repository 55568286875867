import { Circle } from "@mui/icons-material";
import { Tooltip, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";

import { BreakpointConstants } from "../../constants/BreakpointConstants";
import { selectLastPointerType } from "../../redux/selectors";
import {
  IHoverableChartLegend,
  IHoverableChartLegends,
} from "../../types/dataTypes";
import { replaceBlackstoneString } from "../../utils/formatters";
import styles from "./HoverableChartLegends.module.scss";

interface HoverableChartLegendProps {
  value: string;
  color: string;
  xOffset: number;
  yOffset: number;
  chartIndex?: number;
  activeIndex?: number | undefined;
  setActiveIndex?: (val: number | undefined) => void;
  onPointerDown?: (
    value: string,
    event: React.PointerEvent<HTMLDivElement>
  ) => void;
  isClickable?: boolean;
  overrideText?: string;
  suppressTooltip?: boolean;
}

const HoverableChartLegend = ({
  value,
  color,
  xOffset,
  yOffset,
  chartIndex,
  activeIndex,
  setActiveIndex,
  onPointerDown,
  isClickable,
  overrideText,
  suppressTooltip,
}: HoverableChartLegendProps) => {
  const text = replaceBlackstoneString(value);
  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );

  const handlePointerDown = (event: React.PointerEvent<HTMLDivElement>) => {
    if (onPointerDown) {
      onPointerDown(value, event);
    }
  };

  const lastPointerType = useSelector(selectLastPointerType);

  return (
    <li className={styles.chartLegend}>
      <Tooltip
        title={overrideText ?? text}
        placement={isMobile ? "top" : "bottom-start"}
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [xOffset, yOffset],
              },
            },
          ],
          className: clsx(
            (suppressTooltip || lastPointerType !== "mouse") && styles.hidden
          ),
        }}
        enterTouchDelay={0}
        leaveTouchDelay={5000}
      >
        <div
          className={styles.legendText}
          onPointerDown={handlePointerDown}
          onMouseEnter={() => {
            return setActiveIndex ? setActiveIndex(chartIndex) : undefined;
          }}
          onMouseLeave={() => {
            return setActiveIndex ? setActiveIndex(undefined) : undefined;
          }}
        >
          <Circle className={styles.circle} style={{ color: color }} />
          <span
            className={`${styles.label} ${
              activeIndex !== undefined && chartIndex === activeIndex
                ? styles.active
                : ""
            }`}
            style={{ cursor: isClickable ? "pointer" : "default" }}
          >
            {text}
          </span>
        </div>
      </Tooltip>
    </li>
  );
};

export const HoverableChartLegends = ({
  legends,
  activeIndex,
  setActiveIndex,
}: IHoverableChartLegends) => {
  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );

  const tooltipXOffset = isMobile ? 6 : 56;
  const tooltipYOffset = isMobile ? -24 : -14;

  return (
    <ul className={styles.chartLegends}>
      {legends &&
        legends.map((legend: IHoverableChartLegend, index) => {
          return (
            <HoverableChartLegend
              key={index}
              value={legend.value}
              color={legend.color}
              xOffset={tooltipXOffset}
              yOffset={tooltipYOffset}
              onPointerDown={legend.handleClick}
              isClickable={legend.isClickable}
              chartIndex={legend.chartIndex}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              overrideText={legend.overrideText}
              suppressTooltip={legend.suppressTooltip}
            />
          );
        })}
    </ul>
  );
};
