import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";

import {
  BankAccountConstants,
  BankAccountDocumentUploadErrorMessages,
} from "../../../../../constants/BankAccountConstants";
import {
  BankAccountDocResponse,
  postBankAccountDocument,
} from "../../../../../services/bankAccountsService";
import {
  BankAccountDocument,
  BankAccountDocumentError,
  BankAccountDocumentUploadStatus,
} from "../../../../../types/bankAccountDataTypes";
import styles from "./MultiFileUpload.module.scss";

interface IMultiFileUploadProps {
  files: BankAccountDocument[];
  setFiles: (newFiles: BankAccountDocument[]) => void;
}

const twentyMBInBytes = 20 * 1024 * 1024;

export const MultiFileUpload = ({ files, setFiles }: IMultiFileUploadProps) => {
  const onUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const uploadedFiles: File[] = Array.from(event.target.files);
      const newVals: BankAccountDocument[] = uploadedFiles.map((file: File) => {
        return {
          file: file,
          uploadStatus: BankAccountDocumentUploadStatus.NOT_REQUESTED,
          fileInfo: {
            id: "",
            name: file.name,
            type: BankAccountConstants.SUPPORTING_MATERIALS,
          },
        } as BankAccountDocument;
      });

      const combinedVals: BankAccountDocument[] = [...files, ...newVals];

      setFiles(combinedVals);

      combinedVals.forEach(async (doc: BankAccountDocument, idx: number) => {
        if (
          doc.uploadStatus !== BankAccountDocumentUploadStatus.NOT_REQUESTED
        ) {
          return;
        }
        try {
          const bankAccountDocumentResponse: BankAccountDocResponse =
            await postBankAccountDocument(doc.file);
          if (bankAccountDocumentResponse.error === undefined) {
            doc.fileInfo = {
              ...doc.fileInfo,
              id: bankAccountDocumentResponse.id,
            };
            doc.uploadStatus = BankAccountDocumentUploadStatus.SUCCESS;
          } else {
            doc.uploadStatus = BankAccountDocumentUploadStatus.FAILURE;
            doc.error =
              doc.file.size > twentyMBInBytes
                ? BankAccountDocumentError.MAX_FILE_SIZE
                : bankAccountDocumentResponse.error;
          }
        } catch (error) {
          doc.uploadStatus = BankAccountDocumentUploadStatus.FAILURE;
          doc.error =
            doc.file.size > twentyMBInBytes
              ? BankAccountDocumentError.MAX_FILE_SIZE
              : BankAccountDocumentError.UNKNOWN;
        } finally {
          const updatedDocs: BankAccountDocument[] = [
            ...combinedVals.slice(0, idx),
            doc,
            ...combinedVals.slice(idx + 1),
          ];
          setFiles(updatedDocs);
        }
      });
    }
  };

  const removeDoc = useCallback(
    (idx: number) => {
      setFiles([...files.slice(0, idx), ...files.slice(idx + 1)]);
    },
    [files, setFiles]
  );

  return (
    <>
      <Button
        className={styles.selectButton}
        component="label"
        role={undefined}
        color="primary"
        variant="contained"
      >
        {"Select Files"}
        <input
          className={styles.input}
          type="file"
          onChange={(e) => {
            onUpload(e);
          }}
          multiple
        />
      </Button>
      {files.map((doc: BankAccountDocument, idx: number) => {
        return (
          <Box key={idx} className={styles.documentBox}>
            <Stack direction="column">
              <Typography variant="body1">{doc.fileInfo.name}</Typography>
              {doc.error && (
                <Typography className={styles.error}>
                  {BankAccountDocumentUploadErrorMessages[doc.error]}
                </Typography>
              )}
              {(doc.uploadStatus ===
                BankAccountDocumentUploadStatus.NOT_REQUESTED ||
                doc.uploadStatus ===
                  BankAccountDocumentUploadStatus.IN_PROGRESS) && (
                <LinearProgress />
              )}
            </Stack>
            <IconButton
              onClick={() => removeDoc(idx)}
              className={styles.removeDocument}
            >
              <Close />
            </IconButton>
          </Box>
        );
      })}
    </>
  );
};
