import clsx from "clsx";
import React from "react";

import styles from "./MonospaceNumber.module.scss";

interface MonospaceNumberProps {
  value: number | undefined;
  className?: string;
  id?: string;
  valueFormatter: (value: number | undefined) => string;
}

export const MonospaceNumber = (props: MonospaceNumberProps) => {
  const valueString = props.valueFormatter(props.value);

  return (
    <span
      className={clsx(styles.monospaceNumber, props.className)}
      id={props.id}
    >
      {valueString}
      {/*
        If we have a positive number, append a trailing space to the string, this
        leaves room for the closing parenthesis that is placed around negative
        numbers and ensure our positive and negative numbers will align.
      */}
      {props.value && props.value >= 0 ? <>&nbsp;</> : null}
    </span>
  );
};
