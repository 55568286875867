import { arrayOfAll } from "./typeUtils";

export type PageSize = 50 | 100 | 250;

const arrayOfAllPageSize = arrayOfAll<PageSize>();
export const PAGE_SIZE_OPTIONS = arrayOfAllPageSize([50, 100, 250]);

export const isValidPageSize = (value: unknown): value is PageSize => {
  return PAGE_SIZE_OPTIONS.includes(value as never);
};
