import { createSelector } from "@reduxjs/toolkit";

import {
  selectElectionRoundConfiguration,
  selectElectionWorkflowStateLocal,
} from "./electionSelectors";

export const selectFinancingContent = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.stages.elect.financingCardContent;
  }
);

export const selectReallocationQuestion = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.stages.elect.reallocationTooltip;
  }
);

export const selectReviewAndSignConfiguration = createSelector(
  selectElectionRoundConfiguration,
  (configuration) => configuration.stages.reviewAndSign
);

export const selectReviewAndSignState = createSelector(
  selectElectionWorkflowStateLocal,
  (workflowState) => workflowState.electionStages.reviewAndSign
);
