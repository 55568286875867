import { Text, View } from "@react-pdf/renderer";
import {
  BrowserLabels,
  contatctEmail as contactEmail,
  DisclosureLabels,
} from "common";
import React from "react";

import { styles } from "../ElectionAgreementDocumentStyleSheet";

export const DisclosurePage = () => {
  const browsers = [
    BrowserLabels.CHROME,
    BrowserLabels.EDGE,
    BrowserLabels.FIREFOX,
    BrowserLabels.SAFARI,
  ];

  return (
    <>
      <View break>
        <Text style={styles.h2}>{DisclosureLabels.E_SIGN_DISCLOSURES}</Text>
      </View>
      <View>
        <Text style={styles.subtitle}>{DisclosureLabels.SUBTITLE}</Text>
      </View>
      <View>
        <Text style={styles.paragraph}>{DisclosureLabels.TERMS_LINE_1}</Text>
      </View>
      <View style={[styles.paragraph, styles.multipleText]}>
        <Text>{DisclosureLabels.TERMS_LINE_2_1}</Text>
        <Text style={styles.link}>{contactEmail}</Text>
        <Text>{DisclosureLabels.TERMS_LINE_2_2}</Text>
      </View>
      <View>
        <Text style={styles.paragraph}>{DisclosureLabels.TERMS_LINE_3}</Text>
      </View>
      <View>
        <Text style={styles.paragraph}>{DisclosureLabels.TERMS_LINE_4}</Text>
      </View>
      {browsers.map((textLine: string, index: number) => (
        <View key={index}>
          <Text style={styles.text}>{textLine}</Text>
        </View>
      ))}
      <View style={[styles.paragraph, styles.paddingTop]}>
        <Text>{DisclosureLabels.CONTACT_INFORMATION}</Text>
        <Text style={styles.link}>{contactEmail}</Text>
      </View>
    </>
  );
};
