import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

import { StockSymbol } from "../../constants/enums";
import { EquityLabel } from "../../constants/LabelAndTooltipConstants";
import { EquityCardTableV2Row, IBXEquityCardData } from "../../types/dataTypes";
import { getEquityCardTableV2Rows } from "../../utils/equityCardUtils";
import {
  getCurrencyFormattedValue,
  getRoundedNumberWithZeroDefault,
} from "../../utils/formatters";
import { MonospaceNumber } from "../MonospaceNumber/MonospaceNumber";
import styles from "./EquityCardTableV2.module.scss";

interface IEquityCardTableV2Props {
  hasViewRestrictedEquity: boolean;
  hasViewV2DashboardEquityCardEntitlement?: boolean;
  equityCardData: IBXEquityCardData;
  selectedStockSymbol: StockSymbol;
}

export const EquityCardTableV2 = (props: IEquityCardTableV2Props) => {
  const { equityCardData, hasViewRestrictedEquity, selectedStockSymbol } =
    props;
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="right">
              {selectedStockSymbol} {EquityLabel.UNITS}
            </TableCell>
            <TableCell align="right">
              {selectedStockSymbol} {EquityLabel.VALUE}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getEquityCardTableV2Rows(
            equityCardData,
            hasViewRestrictedEquity
          ).map((row: EquityCardTableV2Row, index: number) => (
            <TableRow
              key={index}
              className={row.isTotal ? styles.totalsRow : ""}
            >
              <TableCell>{row.label}</TableCell>
              <TableCell align="right">
                <MonospaceNumber
                  value={row.bxUnits}
                  valueFormatter={getRoundedNumberWithZeroDefault}
                />
              </TableCell>
              <TableCell align="right">
                <MonospaceNumber
                  value={row.bxValue}
                  valueFormatter={getCurrencyFormattedValue}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
