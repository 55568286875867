import { Box, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";

import { OptionalBooleanSelectorLabels } from "../../constants/LabelAndTooltipConstants";
import { isSomething } from "../../types/typeGuards";
import { nothing, Optional, some } from "../../types/typeUtils";
import { SingleSelect } from "../SingleSelect/SingleSelect";
import styles from "./OptionalBooleanSelector.module.scss";

interface IOptionalBooleanSelectorProps {
  label: string;
  currentValue: Optional<boolean>;
  onChange: (value: Optional<boolean>) => void;
}

export const OptionalBooleanSelector = ({
  label,
  currentValue,
  onChange,
}: IOptionalBooleanSelectorProps) => {
  const formCurrentValue = isSomething(currentValue)
    ? currentValue.value
      ? OptionalBooleanSelectorLabels.YES
      : OptionalBooleanSelectorLabels.NO
    : OptionalBooleanSelectorLabels.ALL;

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    onChange(
      value === OptionalBooleanSelectorLabels.ALL
        ? nothing
        : some(value === OptionalBooleanSelectorLabels.YES)
    );
  };

  const dropdownValues: string[] = [
    OptionalBooleanSelectorLabels.ALL,
    OptionalBooleanSelectorLabels.YES,
    OptionalBooleanSelectorLabels.NO,
  ];

  return (
    <Box component="div" className={styles.container}>
      <Typography variant={"h6"} className={styles.label}>
        {label}
      </Typography>
      <SingleSelect
        handleChange={handleChange}
        selection={formCurrentValue}
        options={dropdownValues}
      />
    </Box>
  );
};
