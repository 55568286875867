import React from "react";

import {
  DashboardCardTable,
  IDashboardCardTableTopLevelRow,
} from "../../../../../components/DashboardCardTable/DashboardCardTable";
import {
  OverviewLabel,
  OverviewTooltip,
  UnrealizedValueLabels,
} from "../../../../../constants/LabelAndTooltipConstants";

interface InvestmentCardTableProps {
  remainingCapitalInvested?: number;
  gainLoss?: number;
  total?: number;
}

export const InvestmentCardTable = ({
  remainingCapitalInvested,
  gainLoss,
  total,
}: InvestmentCardTableProps) => {
  const getTableRows = () => {
    const rows: IDashboardCardTableTopLevelRow[] = [];

    if (remainingCapitalInvested) {
      rows.push({
        name: OverviewLabel.REMAINING_INVESTMENT,
        value: remainingCapitalInvested,
        tooltipParagraphs: [OverviewTooltip.REMAINING_INVESTMENT],
        isOpen: false,
        setIsOpen: () => void 0,
      });
    }
    if (gainLoss) {
      rows.push({
        name: OverviewLabel.GAIN_LOSS,
        value: gainLoss,
        tooltipParagraphs: [OverviewTooltip.UNREALIZED_GAIN_LOSS],
        isOpen: false,
        setIsOpen: () => void 0,
      });
    }

    return rows;
  };

  return (
    <DashboardCardTable
      header={OverviewLabel.OPTIONAL_AND_MANDATORY}
      headerCols={[""]}
      rows={getTableRows()}
      totalRow={
        total
          ? {
              name: UnrealizedValueLabels.TOTAL_UNREALIZED_VALUE,
              value: total,
            }
          : undefined
      }
    />
  );
};
