import { Link } from "@mui/material";
import {
  AgreementPdfRequest,
  BankAccountConstants,
  ErrorStringConstants,
  getUser,
  IBaseStore,
  IESignature,
  isSomething,
  IVBankAccounts,
  Maybe,
  openAlert,
  StringConstants,
} from "common";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAgreementDocument } from "../../../services/bankAccountsService";
import { ElectionDialog } from "../../Elections/ElectionWorkflow/Shared/ElectionDialog/ElectionDialog";
import styles from "./DownloadAgreementDialog.module.scss";

interface IDownloadAgreementDialogProps {
  open: boolean;
  onClose: () => void;
  eSignature: IESignature | undefined;
  bankAccounts: IVBankAccounts[];
}

export const DownloadAgreementDialog = (
  props: IDownloadAgreementDialogProps
) => {
  const { eSignature, bankAccounts, open, onClose } = props;

  const dispatch = useDispatch();

  const { isBankAccountsAdminMode } = useSelector(
    (state: IBaseStore) => state.bankAccounts
  );

  const handleDownload = useCallback(async () => {
    const user = getUser();

    if (eSignature) {
      const payload: AgreementPdfRequest = {
        investmentVehicleBankAccounts: bankAccounts.map((account) => ({
          ...account,
          investmentVehicleMdmId: account.assignedIV.mdmId,
          bankAccountAssignment: account.assignments,
          main: account.main,
          intermediary: isSomething(account.intermediary)
            ? account.intermediary.value
            : undefined,
        })),
        documents: [],
        effectiveDate: new Date(),
        requestedBy: `${user?.profile.firstName} ${user?.profile.lastName}`,
        eSignature: eSignature,
      };

      const response: Maybe<{ fileName: string; blob: Blob }> =
        await getAgreementDocument(payload, isBankAccountsAdminMode);

      if (!response?.blob) {
        dispatch(
          openAlert({
            severity: "error",
            message: ErrorStringConstants.DOWNLOAD_FAILED,
          })
        );
      } else {
        const downloadUrl = window.URL.createObjectURL(response.blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = response.fileName;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        a.remove();
      }
    }
  }, [bankAccounts, dispatch, eSignature, isBankAccountsAdminMode]);

  return (
    <ElectionDialog
      title={BankAccountConstants.BANK_ACCOUNT_AGREEMENT}
      content={
        <p className={styles.message}>
          {BankAccountConstants.BANK_ACCOUNT_PROCESSING_REQUEST_LEFT}
          <Link href={`mailto:${StringConstants.BXWEALTH_SUPPORT_EMAIL}`}>
            {StringConstants.BXWEALTH_SUPPORT_EMAIL}
          </Link>
          {BankAccountConstants.BANK_ACCOUNT_PROCESSING_REQUEST_RIGHT}
        </p>
      }
      open={open}
      handleClose={onClose}
      handleNext={handleDownload}
      cancelButtonLabel={"Done"}
      nextButtonLabel={"Download Agreement"}
    />
  );
};
