import { Text, View } from "@react-pdf/renderer";
import {
  AgreementAcknowledgeLabels,
  ElectionsLabels,
  formatDateMMMDDYYYY,
  getFormattedLocalTime,
  getUser,
  IReviewAndSignStage,
  isSomething,
} from "common";
import React from "react";

import { styles as commonStyles } from "../ElectionAgreementDocumentStyleSheet";
import { styles } from "./SignSubmitPageStyleSheet";

interface ISignSubmitPageProps {
  reviewAndSignStage: IReviewAndSignStage;
}
export const SignSubmitPage = (props: ISignSubmitPageProps) => {
  const { reviewAndSignStage } = props;

  const user = getUser();

  const items = [
    AgreementAcknowledgeLabels.BULLET_LINE_1,
    AgreementAcknowledgeLabels.BULLET_LINE_2,
    AgreementAcknowledgeLabels.BULLET_LINE_3,
    AgreementAcknowledgeLabels.BULLET_LINE_4,
  ];

  return (
    <>
      <View break>
        <Text style={commonStyles.h2}>{ElectionsLabels.SIGN_AND_SUBMIT}</Text>
      </View>
      <View>
        <Text style={commonStyles.subtitle}>
          {AgreementAcknowledgeLabels.HEADER_LINE}
        </Text>
      </View>
      {items.map((v, i) => (
        <View key={i}>
          <Text style={styles.listItem}>{v}</Text>
        </View>
      ))}
      {isSomething(reviewAndSignStage.signature) && (
        <View style={styles.signatureContainer}>
          <Text style={styles.signature}>
            {reviewAndSignStage.signature.value}
          </Text>
        </View>
      )}
      {user && (
        <>
          <View style={styles.container}>
            <Text
              style={styles.textBold}
            >{`${ElectionsLabels.USER_NAME}: `}</Text>
            <Text>{`${user.profile.firstName} ${user.profile.lastName}`}</Text>
          </View>
          <View style={styles.container}>
            <Text
              style={styles.textBold}
            >{`${ElectionsLabels.USER_EMAIL_ADDRESS}: `}</Text>
            <Text>{`${user.profile.email}`}</Text>
          </View>
        </>
      )}
      {isSomething(reviewAndSignStage.submitDate) && (
        <View style={styles.container}>
          <Text style={styles.textBold}>{`${ElectionsLabels.TIME}: `}</Text>
          <Text>{`${formatDateMMMDDYYYY(
            reviewAndSignStage.submitDate.value
          )} ${getFormattedLocalTime(
            reviewAndSignStage.submitDate.value
          )}`}</Text>
        </View>
      )}
    </>
  );
};
