import { CheckCircle, SaveAlt } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import {
  ElectionsLabels,
  reqClearActiveElection,
  setSubmissionRequested,
} from "common";
import React from "react";
import { useDispatch } from "react-redux";

import { useDownloadElectionAgreement } from "../../../../../hooks/pdfHooks";
import { TaxForms } from "../../../Shared/TaxForms/TaxForms";
import styles from "./SubmitDialog.module.scss";

interface SubmitDialogProps {
  open: boolean;
  investmentVehicleId: number;
  electionRoundId: string;
  onCloseDialog: () => void;
  closeButtonLabel: string;
  isAdmin: boolean;
}

export const SubmitDialog = (props: SubmitDialogProps) => {
  const {
    open,
    investmentVehicleId,
    electionRoundId,
    onCloseDialog,
    closeButtonLabel,
    isAdmin,
  } = props;

  const dispatch = useDispatch();

  const downloadElectionAgreement = useDownloadElectionAgreement();

  const downloadPDF = async () =>
    await downloadElectionAgreement(
      electionRoundId,
      investmentVehicleId,
      isAdmin
    );

  const handleCloseDialog = () => {
    dispatch(reqClearActiveElection());
    dispatch(setSubmissionRequested(false));
    onCloseDialog();
  };

  return (
    <Dialog open={open}>
      <DialogTitle variant="h3">
        {ElectionsLabels.CONGRATULATIONS_YOUVE_SUBMITTED}
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" className={styles.actionItem}>
          <CheckCircle color="success" />
          <Stack alignItems="flex-start">
            <Typography>
              {ElectionsLabels.SUBMISSION_CONFIRMATION_MESSAGE}
            </Typography>
            <Button
              className={styles.downloadButton}
              endIcon={<SaveAlt />}
              onClick={downloadPDF}
            >
              {ElectionsLabels.DOWNLOAD_ELECTION_AGREEMENT}
            </Button>
          </Stack>
        </Stack>
        <TaxForms showInfoIcon={true} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>{closeButtonLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};
