import {
  InvestmentsTabsDisplayOrder,
  PageWithSubTabs,
  PathFragment,
  StringConstants,
} from "common";
import React from "react";

import InvestmentEntityDropdownWrapper from "../../common/components/InvestmentEntityDropdownWrapper/InvestmentEntityDropdownWrapper";
import { InvestmentsPages } from "../../constants/Pages/SubTabPages";

const InvestmentsAndCarryPageWrapper = () => {
  return (
    <PageWithSubTabs
      pageMenu={<InvestmentEntityDropdownWrapper />}
      pageHeader={StringConstants.INVESTMENTS_AND_CARRY_DETAILS}
      pages={InvestmentsPages}
      tabDisplayOrder={InvestmentsTabsDisplayOrder}
      defaultTab={`${PathFragment.OVERVIEW}`}
    />
  );
};

export default InvestmentsAndCarryPageWrapper;
