import { StyleSheet } from "@react-pdf/renderer";

import { signColumn } from "../ElectionAmountTableHeader/ElectionAmountTableHeaderStyleSheet";

export const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    width: 531,
    fontFamily: "Guardian Sans",
  },
  strategy: {
    width: 120,
    textAlign: "left",
  },
  remainingCommitment: {
    width: 94,
  },
  mandatoryCommitment: {
    width: 64 + signColumn,
  },
  election: {
    width: 86 + signColumn,
  },
  totalCommitment: {
    width: 84 + signColumn,
  },
  forecastedInvestment: {
    width: 78,
  },
});
