import { PathFragment, ReviewAndSignStage } from "common";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RelativePath } from "../../../../constants/Paths";
import { AdminUIStore } from "../../../../redux/store";
import { useElectionQueryParams } from "../../Hooks/useElectionQueryParamsHook";

export const ReviewAndSignStageWrapper = () => {
  const navigate = useNavigate();

  const { mdmInvestmentVehicleId, electionRoundId } = useElectionQueryParams();

  const { isBankAccountsAdmin } = useSelector(
    (state: AdminUIStore) => state.entitlements
  );

  const navigateToReviewAndRevise = () => {
    navigate(
      `/${PathFragment.ELECTIONS}/${electionRoundId}/elector-view/${mdmInvestmentVehicleId}/${RelativePath.ELECTION_REVIEW}`
    );
  };

  return (
    <ReviewAndSignStage
      canReadBankAccounts={isBankAccountsAdmin}
      onCloseSubmitDialog={navigateToReviewAndRevise}
    />
  );
};
