import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  CardFooter,
  ElectionsLabels,
  emptyIfNothing,
  FormCardHeader,
  getCurrencyFormattedValueWithZeroDefault,
  IIVStrategy,
  isSomething,
  MonospaceNumber,
  selectIVStrategyConfigurations,
  selectRemainingCommitmentFootnote,
  selectRemainingCommitmentTooltip,
  zeroIfNaN,
  zeroIfNothing,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import { ElectionCard } from "../../Shared/ElectionCard";
import styles from "./RemainingExpectedCommitmentsCard.module.scss";

export const RemainingExpectedCommitmentsCard = () => {
  const tooltip = useSelector(selectRemainingCommitmentTooltip);
  const footnotes = useSelector(selectRemainingCommitmentFootnote);
  const strategyConfigsForIV: IIVStrategy[] = useSelector(
    selectIVStrategyConfigurations
  );
  const numberOfStartegies = strategyConfigsForIV.length;
  const mappedStrategies = strategyConfigsForIV.map((k) => ({
    ...k,
    strategyName: emptyIfNothing(k.strategyName),
  }));
  const sortedStrategies = mappedStrategies.sort((a, b) =>
    a.strategyName.localeCompare(b.strategyName)
  );
  const strategiesWithValues = mappedStrategies.reduce((a, b) => {
    a[b.strategyId] = zeroIfNothing(b.remainingCommitment);
    return a;
  }, {} as { [key: number]: number });
  const strategyCells: JSX.Element[] = [];
  let total = 0;

  for (const strategy of sortedStrategies) {
    total += strategiesWithValues[strategy.strategyId];
    strategyCells.push(
      <TableRow key={strategy.strategyId}>
        <TableCell key={strategy.strategyName}>
          {strategy.strategyName}
        </TableCell>
        <TableCell
          key={strategiesWithValues[strategy.strategyId]}
          align="right"
        >
          <MonospaceNumber
            value={strategiesWithValues[strategy.strategyId]}
            valueFormatter={getCurrencyFormattedValueWithZeroDefault}
          />
        </TableCell>
      </TableRow>
    );
  }

  return numberOfStartegies > 0 && zeroIfNaN(total) > 0 ? (
    <ElectionCard>
      <FormCardHeader
        title={ElectionsLabels.ANTICIPATED_PRIOR_PERIODS_CAPITAL_CALLS}
        bottomBorder
        tooltip={tooltip}
      />
      <TableContainer id={styles.remainingCommitmentsTable}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{ElectionsLabels.STRATEGY}</TableCell>
              <TableCell align="right">{ElectionsLabels.AMOUNT}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{strategyCells}</TableBody>
          {numberOfStartegies > 1 && (
            <TableFooter>
              <TableRow id={styles.totalRow}>
                <TableCell>{ElectionsLabels.TOTAL}</TableCell>
                <TableCell align="right">
                  {getCurrencyFormattedValueWithZeroDefault(total)}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {isSomething(footnotes) && <CardFooter footerLines={[footnotes.value]} />}
    </ElectionCard>
  ) : (
    <></>
  );
};
