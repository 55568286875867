import { BankAccountStage } from "../features/Elections/ElectionWorkflow/BankAccountStage/BankAccountStage";
import { ElectStage } from "../features/Elections/ElectionWorkflow/ElectStage/ElectStage";
import { Eligibility } from "../features/Elections/ElectionWorkflow/EligibilityStage/Eligibility";
import { StrategyStage } from "../features/Elections/ElectionWorkflow/StrategyStage/StrategyStage";
import { ElectionWorkflowStageId } from "../types/electionDataTypes";
import { ElectionWorkflowPagesType } from "./IElectionWorkflowPage";
import { ElectionsLabels } from "./LabelAndTooltipConstants";

export const CommonElectionWorkflowPages: Partial<ElectionWorkflowPagesType> = {
  [ElectionWorkflowStageId.ELIGIBILITY]: {
    name: ElectionsLabels.ELIGIBILITY,
    hasError: false,
    validStepperNavigationStages: [ElectionWorkflowStageId.OVERVIEW],
    element: Eligibility,
  },
  [ElectionWorkflowStageId.STRATEGIES]: {
    name: ElectionsLabels.STRATEGIES,
    hasError: false,
    validStepperNavigationStages: [
      ElectionWorkflowStageId.OVERVIEW,
      ElectionWorkflowStageId.ELIGIBILITY,
      ElectionWorkflowStageId.ELECT,
    ],
    element: StrategyStage,
    suppressFooter: true,
  },
  [ElectionWorkflowStageId.ELECT]: {
    name: ElectionsLabels.ELECT,
    hasError: false,
    validStepperNavigationStages: [
      ElectionWorkflowStageId.OVERVIEW,
      ElectionWorkflowStageId.ELIGIBILITY,
      ElectionWorkflowStageId.STRATEGIES,
    ],
    element: ElectStage,
  },
  [ElectionWorkflowStageId.BANK_ACCOUNT]: {
    name: ElectionsLabels.BANK_ACCOUNT,
    hasError: false,
    validStepperNavigationStages: [
      ElectionWorkflowStageId.OVERVIEW,
      ElectionWorkflowStageId.ELIGIBILITY,
      ElectionWorkflowStageId.STRATEGIES,
      ElectionWorkflowStageId.ELECT,
    ],
    element: BankAccountStage,
  },
};
