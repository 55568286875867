import { Grid } from "@mui/material";
import {
  CardFooter,
  getFirstDayOfQuarter,
  getPortfolioPerformanceFooter,
  getUnrealizedRealizedAsOfLine,
  IBalancePerformanceData,
  IDashboardCardContentProps,
  IHistoricalSummaryDatum,
  InvestmentBreakdownKey,
  InvestmentHistoryLabel,
  InvestmentHistoryValueItem,
  InvestmentValueDisplay,
  isSomething,
  MUIConstants,
  PeriodType,
  SectionHeader,
  some,
  trimData,
} from "common";
import React, { useEffect, useMemo, useState } from "react";

import { TitleWithPeriodToggle } from "../../../components/TitleWithPeriodToggle/TitleWithPeriodToggle";
import colors from "../../../styles/_colors.scss";
import { initializeChartDataAndToolbar } from "../../../utils/investmentHistoryUtils";
import { PortfolioPerformanceChart } from "./PortfolioPerformanceChart/PortfolioPerformanceChart";
import { PortfolioPerformanceToolbar } from "./PortfolioPerformanceToolbar/PortfolioPerformanceToolbar";

const portfolioPerformanceDataTypes: InvestmentHistoryValueItem[] = [
  {
    key: InvestmentBreakdownKey.RETURN_OF_CAPITAL,
    excelColumnName: InvestmentHistoryLabel.REALIZED_ROC,
    labelOverride: InvestmentHistoryLabel.RETURN_OF_CAPITAL,
    color: colors.dark_avocado,
    isSelected: true,
    isUnrealized: false,
  },
  {
    key: InvestmentBreakdownKey.REALIZED_GAIN_LOSS,
    excelColumnName: InvestmentHistoryLabel.REALIZED_INVESTMENT_INCOME_LOSS,
    labelOverride: InvestmentHistoryLabel.INVESTMENT_GAIN_LOSS,
    color: colors.avocado,
    isSelected: true,
    isUnrealized: false,
  },
  {
    key: InvestmentBreakdownKey.REALIZED_CARRIED_INTEREST,
    excelColumnName: InvestmentHistoryLabel.REALIZED_CARRY,
    labelOverride: InvestmentHistoryLabel.REALIZED_CARRY_INVESTMENT_FEES,
    color: colors.avocado_shade,
    isSelected: true,
    isUnrealized: false,
  },
  {
    key: InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED,
    excelColumnName: InvestmentHistoryLabel.REMAINING_INVESTMENT,
    labelOverride: InvestmentHistoryLabel.REMAINING_INVESTMENT,
    color: colors.dark_taupe,
    isSelected: true,
    isUnrealized: true,
  },
  {
    key: InvestmentBreakdownKey.UNREALIZED_GAIN_LOSS,
    excelColumnName: InvestmentHistoryLabel.UNREALIZED_INVESTMENT_INCOME_LOSS,
    labelOverride: InvestmentHistoryLabel.INVESTMENT_GAIN_LOSS,
    color: colors.taupe,
    isSelected: true,
    isUnrealized: true,
  },
  {
    key: InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST,
    excelColumnName: InvestmentHistoryLabel.UNREALIZED_CARRY,
    labelOverride: InvestmentHistoryLabel.CARRY,
    color: colors.taupe_shade,
    isSelected: true,
    isUnrealized: true,
  },
];

export const PortfolioPerformance = (
  props: IDashboardCardContentProps<IBalancePerformanceData>
) => {
  const { historicalSummaryGrouped, asOfDate } = props.data;

  const [graphData, setGraphData] = useState<IHistoricalSummaryDatum[]>([]);
  const [periodType, setPeriodType] = useState<PeriodType>(
    PeriodType.QUARTERLY
  );

  const [isCumulative, setIsCumulative] = useState<boolean>(false);
  const valueDisplay =
    InvestmentValueDisplay.CUMULATIVE_UNREALIZED_NONCUMULATIVE_REALIZED;
  const [graphInitialized, setGraphInitialized] = useState<boolean>(false);
  const [dataTypes, setDataTypes] = useState<InvestmentHistoryValueItem[]>([]);
  const [highlightedBar, setHighlightedBar] =
    useState<InvestmentBreakdownKey | null>(null);

  const firstQuarterDate = useMemo(() => {
    return asOfDate.earliestAsOfDate;
  }, [asOfDate]);

  const [disabled, setDisable] = useState<boolean>(false);

  useEffect(() => {
    if (isSomething(historicalSummaryGrouped)) {
      const trimmedData = trimData(
        historicalSummaryGrouped.value[periodType],
        portfolioPerformanceDataTypes.map((type) => type.key),
        valueDisplay
      );
      setGraphData(trimmedData);
      initializeChartDataAndToolbar(
        trimmedData,
        portfolioPerformanceDataTypes,
        setDataTypes,
        setGraphInitialized
      );
    }
  }, [historicalSummaryGrouped, valueDisplay, periodType]);

  useEffect(() => {
    setDisable(graphData && graphData.length <= 0);
  }, [graphData]);

  return (
    <Grid container>
      <TitleWithPeriodToggle
        sectionHeader={SectionHeader.PORTFOLIO_PERFORMANCE}
        sectionSubHeader={getUnrealizedRealizedAsOfLine(
          asOfDate.latestAsOfDateWithUnrealizedData,
          some(asOfDate.latestAsOfDate)
        )}
        tabSelected={"PortfolioPerformance"}
        periodType={periodType}
        setPeriodType={setPeriodType}
        dataTypes={dataTypes}
        setDataTypes={setDataTypes}
        valueDisplay={valueDisplay}
        historicalSummaryData={graphData}
        toggleSize={3}
      />
      <Grid
        container
        columnSpacing={MUIConstants.DASHBOARD_WIDGET_COLUMN_SPACING}
      >
        <Grid item xs={12} md={9}>
          <PortfolioPerformanceChart
            historicalSummaryData={graphData}
            firstQuarterDate={firstQuarterDate}
            periodType={periodType}
            isCumulative={isCumulative}
            dataTypes={dataTypes}
            highlightedBar={highlightedBar}
            graphInitialized={graphInitialized}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <PortfolioPerformanceToolbar
            disabled={disabled}
            periodType={periodType}
            setPeriodType={setPeriodType}
            dataTypes={[...dataTypes]}
            setDataTypes={setDataTypes}
            isCumulative={isCumulative}
            setIsCumulative={setIsCumulative}
            historicalSummaryData={graphData}
            setHighlightedBar={setHighlightedBar}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CardFooter
          footerLines={[
            getPortfolioPerformanceFooter(
              getFirstDayOfQuarter(asOfDate.earliestAsOfDate)
            ),
          ]}
        />
      </Grid>
    </Grid>
  );
};
