import { Text, View } from "@react-pdf/renderer";
import { ElectionsLabels, isSomething, mapYesOrNo, Optional } from "common";
import React from "react";

import { styles } from "../ElectionAgreementDocumentStyleSheet";
import { QuestionResponse } from "../QuestionResponses/QuestionResponse/QuestionResponse";

interface ILegalAttestationProps {
  ivName: string;
  legalAttestation: Optional<string>;
  hasAcceptedLegalAttestation: boolean;
}
export const LegalAttestationPage = (props: ILegalAttestationProps) => {
  const { ivName, legalAttestation, hasAcceptedLegalAttestation } = props;

  return (
    <>
      <View>
        <Text style={styles.h1}>{ivName}</Text>
        <Text style={styles.h2}>{ElectionsLabels.LEGAL_ATTESTATION}</Text>
      </View>
      <View>
        {isSomething(legalAttestation) && (
          <Text style={styles.paragraph}>{legalAttestation.value}</Text>
        )}
      </View>
      <QuestionResponse
        question={ElectionsLabels.LEGAL_ATTESTATION_CHECKBOX_LINE}
        response={mapYesOrNo(hasAcceptedLegalAttestation)}
      />
    </>
  );
};
