import { ExpandLess, KeyboardArrowRight } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ElectionAccordionObject, Markdown } from "common";
import React from "react";

import {
  GetIsExpanded,
  useHandleExpand,
} from "../../../../../../hooks/electionHooks";
import styles from "./ElectionProcessStagesAccordion.module.scss";

export interface IElectionProcessStagesAccordionProps {
  accordionId: ElectionAccordionObject;
  accordionSummary: string;
  accordionDetails: string;
}

export const ElectionProcessStagesAccordion = (
  props: IElectionProcessStagesAccordionProps
) => {
  const { accordionId, accordionSummary, accordionDetails } = props;

  const expanded = GetIsExpanded(accordionId);

  const handleExpand = useHandleExpand(accordionId, expanded);

  return (
    <Accordion
      className={`${styles.accordion} ${styles.electionProcessStages} ${styles.accordionContainer}`}
      expanded={expanded}
      onChange={handleExpand}
      disableGutters={true}
    >
      <AccordionSummary
        className={styles.summary}
        expandIcon={
          expanded ? (
            <ExpandLess className={styles.icon} />
          ) : (
            <KeyboardArrowRight className={styles.icon} />
          )
        }
      >
        <div>{accordionSummary}</div>
      </AccordionSummary>
      <AccordionDetails className={styles.details}>
        <div>
          <Markdown text={accordionDetails} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
