import { Link } from "@mui/material";
import React, { ReactElement } from "react";

import { DisclosureLabels } from "../constants/LabelAndTooltipConstants";
import { StringConstants } from "../constants/StringConstants";

export const renderTextWithPhoneNumberBold = (source: string): ReactElement => {
  const parts = source.split(DisclosureLabels.PHONE_NUMBER);

  return (
    <>
      {parts.map((text, index) => (
        <React.Fragment key={index}>
          {text}
          {index < parts.length - 1 && (
            <strong>{DisclosureLabels.PHONE_NUMBER}</strong>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export const renderTextWithSupportLink = (source: string): ReactElement => {
  const parts = source.split(StringConstants.BXWEALTH_SUPPORT_EMAIL);

  return (
    <>
      {parts.map((text, index) => (
        <React.Fragment key={index}>
          {text}
          {index < parts.length - 1 && (
            <Link href={`mailto:${StringConstants.BXWEALTH_SUPPORT_EMAIL}`}>
              {StringConstants.BXWEALTH_SUPPORT_EMAIL}
            </Link>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export const renderTextWithPhoneNumberAndSupportLink = (
  source: string
): ReactElement => {
  const parts = source.split(DisclosureLabels.PHONE_NUMBER);

  return (
    <>
      {parts.map((text, index) => (
        <React.Fragment key={index}>
          {renderTextWithSupportLink(text)}
          {index < parts.length - 1 && (
            <strong>{DisclosureLabels.PHONE_NUMBER}</strong>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export const renderTextWithAppSupportLink = (source: string): ReactElement => {
  const parts = source.split(StringConstants.BXWEALTH_SUPPORT_EMAIL);

  return (
    <>
      {parts.map((text, index) => (
        <React.Fragment key={index}>
          {text}
          {index < parts.length - 1 && (
            <Link href={StringConstants.MAIL_TO_BXWEALTH_SUPPORT_EMAIL}>
              {StringConstants.BXWEALTH_SUPPORT_EMAIL}
            </Link>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
