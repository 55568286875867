import { ExpandLess, KeyboardArrowRight } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Stack,
  Typography,
} from "@mui/material";
import { BankAccountConstants, contatctEmail, DisclosureLabels } from "common";
import React, { useState } from "react";

import { renderTextWithPhoneNumberAndSupportLink } from "../../../../../utils/specialRendersUtils";
import styles from "./ESignDisclosureBox.module.scss";

export const ESignDisclosureBox = () => {
  const [termsAndConditionsExpanded, setTermsAndConditionsExpanded] =
    useState<boolean>(false);

  return (
    <Card className={styles.container}>
      <span className={styles.title}>
        {BankAccountConstants.E_SIGN_DISCLOSURES}
      </span>
      <Accordion
        className={styles.accordion}
        expanded={termsAndConditionsExpanded}
        onChange={() =>
          setTermsAndConditionsExpanded(!termsAndConditionsExpanded)
        }
        disableGutters={true}
      >
        <AccordionSummary
          className={styles.accordionSummary}
          expandIcon={
            termsAndConditionsExpanded ? (
              <ExpandLess className={styles.icon} />
            ) : (
              <KeyboardArrowRight className={styles.icon} />
            )
          }
        >
          <div>{BankAccountConstants.TERMS_AND_CONDITIONS}</div>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <Stack className={styles.termsAndConditions}>
            <Typography
              variant="h4"
              align="center"
              className={styles.termsLine1}
            >
              {DisclosureLabels.SUBTITLE}
            </Typography>
            <Typography variant="h4" className={styles.termsLine2}>
              {DisclosureLabels.TERMS_LINE_1}
            </Typography>
            <Typography variant="body1">
              {renderTextWithPhoneNumberAndSupportLink(
                `${DisclosureLabels.TERMS_LINE_2_1} ${contatctEmail} ${DisclosureLabels.TERMS_LINE_2_2}`
              )}
            </Typography>
            <Typography variant="body1">
              {DisclosureLabels.TERMS_LINE_3}
            </Typography>
            <Typography variant="body1">
              {DisclosureLabels.TERMS_LINE_4}
            </Typography>
            <ul className={styles.browsersList}>
              <li>
                <Typography variant="body1">
                  {DisclosureLabels.CHROME}
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  {DisclosureLabels.FIREFOX}
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  {DisclosureLabels.MICROSOFT_EDGE}
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  {DisclosureLabels.SAFARI}
                </Typography>
              </li>
            </ul>
            <Typography variant="body1">
              {renderTextWithPhoneNumberAndSupportLink(
                `${DisclosureLabels.CONTACT_INFORMATION} ${contatctEmail}`
              )}
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};
