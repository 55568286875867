import { Close } from "@mui/icons-material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { IconButton, Link, Stack, Typography } from "@mui/material";
import { FiltersLabels } from "common";
import React from "react";

import styles from "./FilterPanel.module.scss";

export interface IFilterPanelProps {
  handleClose: () => void;
  handleApplyFilters: () => void;
  handleResetFilters: () => void;
  hasErrors: boolean;
  hasWarnings: boolean;
  children: React.ReactNode;
}

export const FilterPanel = ({
  handleClose,
  handleApplyFilters,
  handleResetFilters,
  hasErrors,
  hasWarnings,
  children,
}: IFilterPanelProps) => {
  return (
    <div className={styles.filterPanel}>
      <div className={styles.header}>
        <div className={styles.label}>
          <FilterAltOutlinedIcon />
          <Typography variant={"h5"}>{FiltersLabels.FILTERS}</Typography>
        </div>
        <IconButton
          className={styles.close}
          onClick={() => {
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </div>
      <div className={styles.panelContent}>{children}</div>
      <Stack direction="row" className={styles.buttons}>
        <Typography variant={"h5"} className={styles.reset}>
          <Link
            component={"button"}
            className={styles.text}
            onClick={handleResetFilters}
          >
            {FiltersLabels.RESET_FILTERS}
          </Link>
        </Typography>
        <Stack
          direction="column"
          alignItems="flex-end"
          textAlign="right"
          className={styles.apply}
        >
          <Typography variant={"h5"}>
            <Link
              component={"button"}
              className={styles.text}
              onClick={handleApplyFilters}
              disabled={hasErrors}
              title={
                hasErrors ? FiltersLabels.PLEASE_RESOLVE_ERRORS : undefined
              }
            >
              {FiltersLabels.APPLY_FILTERS}
            </Link>
          </Typography>
          {hasWarnings && (
            <Typography variant="subtitle1" className={styles.selectionWarning}>
              {FiltersLabels.APPLY_FILTER_WARNING}
            </Typography>
          )}
        </Stack>
      </Stack>
    </div>
  );
};
