import { FileDownloadOutlined } from "@mui/icons-material";
import { CardHeader, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { CardFooter } from "../../../../components/CardFooter/CardFooter";
import { DataCard } from "../../../../components/DataCard/DataCard";
import { IconButtonWithTooltip } from "../../../../components/IconButtonWithTooltip/IconButtonWithTooltip";
import {
  CommitmentsLabels,
  getAsOfLine,
  SectionHeader,
} from "../../../../constants/LabelAndTooltipConstants";
import { StringConstants } from "../../../../constants/StringConstants";
import { useFetchDatasetIfIdDefined } from "../../../../hooks/dataFetchHooks";
import { useExcelDownload } from "../../../../hooks/useExcelDownload";
import { reqInternalInvestmentData } from "../../../../redux/actions/internalInvestmentActions";
import {
  selectAnnualCommitments,
  selectFilteredBy,
  selectInternalInvestmentDataLoadStatus,
} from "../../../../redux/selectors";
import { isSomething } from "../../../../types/typeGuards";
import { isInProgress, isUnsuccessful } from "../../../../utils/dataLoadUtils";
import { worksheetGenerators } from "../../../../utils/excelUtils/excelGenerationUtils";
import { AnnualCommitmentsGrid } from "./AnnualCommitmentsGrid/AnnualCommitmentsGrid";

export const AnnualCommitments = () => {
  const filteredBy = useSelector(selectFilteredBy);
  const annualCommitments = useSelector(selectAnnualCommitments);
  const internalInvestmentDataLoadStatus = useSelector(
    selectInternalInvestmentDataLoadStatus
  );

  const retryFunction = useFetchDatasetIfIdDefined(
    reqInternalInvestmentData,
    filteredBy,
    internalInvestmentDataLoadStatus
  );

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    const fileName = `${SectionHeader.ANNUAL_COMMITMENTS}`;
    await handleExcelDownload(worksheetGenerators.AnnualCommitments, fileName);
  };

  return (
    <DataCard
      failed={isUnsuccessful(internalInvestmentDataLoadStatus)}
      loading={isInProgress(internalInvestmentDataLoadStatus)}
      noData={
        !isSomething(annualCommitments) ||
        annualCommitments.value.commitments.length === 0
      }
      hideIfNoData={true}
      onReload={retryFunction}
    >
      {isSomething(annualCommitments) && (
        <>
          <CardHeader
            title={
              <Typography variant="h3">
                {SectionHeader.ANNUAL_COMMITMENTS}
              </Typography>
            }
            subheader={
              <Typography variant="subtitle1">
                {getAsOfLine(annualCommitments.value.asOfDate)}
              </Typography>
            }
            action={
              <IconButtonWithTooltip
                onClickFunction={handleDownloadClick}
                buttonIcon={FileDownloadOutlined}
                hoverText={StringConstants.DOWNLOAD}
              />
            }
          />
          <Grid container flex="column">
            <AnnualCommitmentsGrid
              annualCommitments={annualCommitments.value.commitments}
            />
          </Grid>

          <CardFooter footerLines={[CommitmentsLabels.COMMITMENTS_FOOTNOTE]} />
        </>
      )}
    </DataCard>
  );
};
