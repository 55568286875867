import { CardHeader, Grid, Typography } from "@mui/material";
import {
  CardFooter,
  DashboardLabel,
  DataCard,
  EquityLabel,
  formatDateMMMDDYYYYOrNull,
  getCurrencyFormattedTwoDPValueWithZeroDefault,
  IBaseStore,
  InfoTooltip,
  isEmptyResponse,
  isUnsuccessful,
  MUIConstants,
  reqEquityData,
  reqStockData,
  SectionHeader,
  StockSymbol,
  useFetchDatasetIfIdDefined,
  useFetchDatasetWithoutId,
} from "common";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { EquityCardChart } from "../../../../components/EquityCardChart/EquityCardChart";
import { EquityCardTableV2 } from "../../../../components/EquityCardTableV2/EquityCardTableV2";
import { StockSymbolToggle } from "../../../../components/StockSymbolToggle/StockSymbolToggle";
import { selectEquityDataCards } from "../../../../redux/selectors/equitySelectors";

interface IEquityProps {
  hasViewRestrictedEquity: boolean;
}

export const EquityV2 = (props: IEquityProps) => {
  const { hasViewRestrictedEquity } = props;

  const { equityDataLoadStatus, stockDataLoadStatus, activeEquityDataId } =
    useSelector((store: IBaseStore) => store.equityData);

  const [selectedStockSymbol, setSelectedStockSymbol] = useState<StockSymbol>(
    StockSymbol.BX
  );

  const { loading, cards } = useSelector((store: IBaseStore) =>
    selectEquityDataCards(store)
  );

  const currentCard = cards.find((x) => x.symbol === selectedStockSymbol);

  const data = currentCard && currentCard.card;

  const userStockTypes = useMemo(() => {
    return loading ? [] : cards.filter((x) => !!x.card).map((x) => x.symbol);
  }, [cards, loading]);

  useEffect(() => {
    if (userStockTypes.length) {
      setSelectedStockSymbol(userStockTypes[0]);
    }
  }, [userStockTypes]);

  const equityRetryFunction = useFetchDatasetIfIdDefined(
    reqEquityData,
    activeEquityDataId,
    equityDataLoadStatus
  );

  const stockRetryFunction = useFetchDatasetWithoutId(
    reqStockData,
    stockDataLoadStatus
  );

  return (
    <DataCard
      hide={data === undefined}
      loading={loading}
      failed={isUnsuccessful(equityDataLoadStatus, stockDataLoadStatus)}
      noData={isEmptyResponse(equityDataLoadStatus, stockDataLoadStatus)}
      onReload={[equityRetryFunction, stockRetryFunction]}
    >
      <CardHeader
        title={
          <>
            <Typography variant="h3">{SectionHeader.EQUITY}</Typography>
            {/* {data && isSomething(data.vestedAndExchangeableAsOfDate) ? (
              <Typography variant="subtitle1">
              // remove as of date until we decide on what date information to display for Equity cards
                {getAsOfLine(
                  new Date(data.vestedAndExchangeableAsOfDate.value)
                )}
              </Typography>
            ) : (
              <></>
            )} */}
          </>
        }
        action={
          userStockTypes.length > 1 ? (
            <StockSymbolToggle
              availableStockSymbols={userStockTypes}
              selectedStockSymbol={selectedStockSymbol}
              onChange={setSelectedStockSymbol}
            />
          ) : null
        }
      />
      {data !== undefined && (
        <>
          <Grid
            container
            columnSpacing={MUIConstants.DASHBOARD_WIDGET_COLUMN_SPACING}
          >
            <EquityCardChart equityCardData={data} />
            <Grid
              item
              xs={12}
              md={MUIConstants.DASHBOARD_WIDGET_RIGHT_ITEM_SIZE}
            >
              <Typography variant="body2">
                {EquityLabel.UNVESTED_EQUITY_OVERVIEW}
              </Typography>
              <EquityCardTableV2
                hasViewRestrictedEquity={hasViewRestrictedEquity}
                equityCardData={data}
                selectedStockSymbol={selectedStockSymbol}
              />
              <br />
              <Typography variant="body2">
                {EquityLabel.STOCK_NYSE(selectedStockSymbol)}:{" "}
                {getCurrencyFormattedTwoDPValueWithZeroDefault(
                  data.latestBXStockValue.value
                )}
                <InfoTooltip
                  tooltipContent={DashboardLabel.Equity.STOCK_PRICE_TOOLTIP(
                    data.latestBXStockValue.asOfDate
                  )}
                />
              </Typography>
              <Typography variant="body2">
                {EquityLabel.NEXT_VESTING_DATE}:{" "}
                {formatDateMMMDDYYYYOrNull(data.nextVestingDate)}
              </Typography>
            </Grid>
          </Grid>
          <CardFooter footerLines={[DashboardLabel.Equity.FOOTER]} />
        </>
      )}
    </DataCard>
  );
};
