import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    width: 531,
    alignItems: "center",
    fontFamily: "Guardian Sans",
    justifyContent: "flex-end",
  },
  election: {
    width: 86,
  },
  totalCommitment: {
    width: 90,
  },
  forecastedInvestment: {
    width: 76,
  },
});
