import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    paddingBottom: 8,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    width: 531,
    alignItems: "center",
    fontFamily: "Guardian Sans",
  },
  boldFont: {
    fontWeight: 500,
  },
  signColumn: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    width: 6,
  },
  strategy: {
    width: 120,
    textAlign: "left",
  },
  remainingCommitment: {
    width: 94,
  },
  mandatoryCommitment: {
    width: 70,
  },
  election: {
    width: 92,
  },
  totalCommitment: {
    width: 90,
  },
  forecastedInvestment: {
    width: 78,
  },
});
