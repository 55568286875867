import {
  ElectionWorkflowLabels,
  ElectionWorkflowStageId,
  ElectionWorkflowStageIdToLabel,
  ElectorStatusExcelConstants,
  getDateAndTime,
  IElectionsForElectionRoundRow,
} from "common";
import ExcelJS from "exceljs";

import { WorksheetGeneratorParams } from "../../types/excelTypes";

export interface IElectorStatusRow {
  mdmInvestmentVehicleId: number;
  name: string;
  step: string;
  reopenedDate: string;
  submissionDateTime: string;
  permissionedUserNames: string;
}

const electorStatusColumns: Partial<ExcelJS.Column>[] = [
  {
    header: "Investment Vehicle Id",
    key: ElectorStatusExcelConstants.KEYS.IV_ID,
    width: ElectorStatusExcelConstants.SIZES.MEDIUM,
  },
  {
    header: "Investment Vehicle Name",
    key: ElectorStatusExcelConstants.KEYS.IV_NAME,
    width: ElectorStatusExcelConstants.SIZES.BIG,
  },
  {
    header: "Current Step",
    key: ElectorStatusExcelConstants.KEYS.STEP,
    width: ElectorStatusExcelConstants.SIZES.MEDIUM,
  },
  {
    header: "Submitted Date",
    key: ElectorStatusExcelConstants.KEYS.SUBMITTED_DATE,
    width: ElectorStatusExcelConstants.SIZES.BIG,
  },
  {
    header: "Reopened Date",
    key: ElectorStatusExcelConstants.KEYS.REOPENED_DATE,
    width: ElectorStatusExcelConstants.SIZES.BIG,
  },
  {
    header: "Permissioned Users",
    key: ElectorStatusExcelConstants.KEYS.PERMISSIONED_USERS,
    width: ElectorStatusExcelConstants.SIZES.BIG,
  },
];

const getRows = (
  data: IElectionsForElectionRoundRow[] | undefined
): IElectorStatusRow[] => {
  const electorResponseRows: IElectorStatusRow[] = [];
  if (data === undefined) {
    return electorResponseRows;
  }

  electorResponseRows.push(
    ...data.map((e) => {
      return {
        mdmInvestmentVehicleId: e.mdmInvestmentVehicleId,
        name: e.name,
        step:
          ElectionWorkflowStageIdToLabel[
            e.currentStage as ElectionWorkflowStageId
          ] ?? ElectionWorkflowLabels.UNKNOWN,
        submissionDateTime: getDateAndTime(e.submissionDateTime, true),
        reopenedDate: getDateAndTime(e.reopenedDate, true),
        permissionedUserNames: e.permissionedUserNames.join(", "),
      };
    })
  );

  return electorResponseRows;
};

export const buildElectorStatusWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const { settings, workbook } = params;
  const worksheet = workbook.addWorksheet("Electors", {
    views: [
      {
        state: "frozen",
        ySplit: 1,
      },
    ],
  });
  worksheet.columns = electorStatusColumns;
  worksheet.addRows(getRows(settings.electorResponse));
  const headerRow = worksheet.getRow(1);
  headerRow.font = { ...headerRow.font, bold: true };
  headerRow.alignment = { ...headerRow.alignment, horizontal: "left" };
  return workbook;
};
