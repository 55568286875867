import { Grid } from "@mui/material";
import {
  ElectionAccordionObject,
  ElectionsLabels,
  getElectGridWarnings,
  IElectionDecision,
  IElectStage,
  IElectStageWarning,
  isSomething,
  nothing,
  openAlert,
  Optional,
  resetStageValidation,
  setUnacknowledgedElectStageWarnings,
} from "common";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Control,
  useFieldArray,
  useForm,
  UseFormSetValue,
  useFormState,
  UseFormTrigger,
  useWatch,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { useMoveStages } from "../../../../hooks/electionHooks";
import {
  selectCanRequestAdditional,
  selectCanUseFinancing,
  selectElectStage,
  selectExistingLoanBalance,
  selectFinancingPercent,
  selectHasIVMoreThanOneStrategy,
  selectIVStrategyConfigurations,
  selectLoanLimit,
  selectOfferAmount,
} from "../../../../redux/selectors";
import { IBaseStore } from "../../../../redux/store";
import { AccordionElectionCard } from "../Shared/AccordionElectionCard/AccordionElectionCard";
import { OfferAmountCard } from "../Shared/OfferAmountCard/OfferAmountCard";
import { StrategyOverviewContent } from "../Shared/StrategyOverview/StrategyOverviewContent/StrategyOverviewContent";
import { StrategyOverviewHeader } from "../Shared/StrategyOverview/StrategyOverviewHeader/StrategyOverviewHeader";
import { CurrentInvestmentPortfolioCard } from "./CurrentInvestmentPortfolioCard/CurrentInvestmentPortfolioCard";
import { ElectCard } from "./ElectCard/ElectCard";
import { ElectionWarningDialog } from "./ElectionWarningDialogs/ElectionWarningDialog";
import styles from "./ElectStage.module.scss";
import { FinancingOptionsCard } from "./FinancingOptionsCard/FinancingOptionsCard";
import { ReallocationCard } from "./ReallocationCard/ReallocationCard";

export interface IElectStageError {
  errorMessage: string;
  isTotalError: boolean;
}

export interface IElectStageFormProps {
  control: Control<IElectStage>;
  trigger: UseFormTrigger<IElectStage>;
  error?: IElectStageError;
  setValue?: UseFormSetValue<IElectStage>;
}

export const ElectStage = () => {
  const dispatch = useDispatch();

  // gets overview stage from election workflow state
  const electStage = useSelector(selectElectStage);

  const strategyConfigsForIV = useSelector(selectIVStrategyConfigurations);

  const canRequestAdditional = useSelector(selectCanRequestAdditional);

  const offerAmount = useSelector(selectOfferAmount);

  const canUseFinancing = useSelector(selectCanUseFinancing);
  const financingPercent = useSelector(selectFinancingPercent);
  const existingLoanBalance = useSelector(selectExistingLoanBalance);
  const loanLimit = useSelector(selectLoanLimit);

  // sets the the legal attestation react hook form
  // not setting a form mode since we wanna trigger the validation manually
  const { trigger, ...electStageFormMethods } = useForm<IElectStage>({
    defaultValues: electStage,
    shouldFocusError: true,
    mode: "onChange",
  });

  const electCardRef = useRef<HTMLFormElement>(null);
  const reallocationRef = useRef<HTMLFormElement>(null);

  // access to form errors
  const { errors } = useFormState({
    control: electStageFormMethods.control,
  });

  const { fields } = useFieldArray({
    control: electStageFormMethods.control,
    name: "elections",
  });

  const [electCardError, setElectCardError] = useState<
    IElectStageError | undefined
  >(undefined);
  const [reallocationCardError, setReallocationCardError] = useState<
    IElectStageError | undefined
  >(undefined);

  const hasIVMoreThanOneStrategy = useSelector(selectHasIVMoreThanOneStrategy);

  const { stageValidationRequested, unAcknowledgedElectStageWarnings } =
    useSelector((state: IBaseStore) => state.viewData);

  const moveStages = useMoveStages();

  const isWarningDialogOpen = useMemo(() => {
    return isSomething(unAcknowledgedElectStageWarnings);
  }, [unAcknowledgedElectStageWarnings]);

  const setErrorToast = useCallback(
    (message: string) =>
      dispatch(
        openAlert({
          severity: "error",
          message,
          hideDuration: 5000,
        })
      ),
    [dispatch]
  );

  const setWarnings = useCallback(
    (warnings: Optional<IElectStageWarning>) => {
      dispatch(setUnacknowledgedElectStageWarnings(warnings));
    },
    [dispatch]
  );

  /*
  the field leve validations should run validations on every page load
    so watch those fields and manually trigger validation
  */
  const electedAmounts: IElectionDecision[] = useWatch({
    control: electStageFormMethods.control,
    name: "elections",
  });

  useMemo(() => {
    const validateFields = async () => {
      electedAmounts.forEach(async (f: IElectionDecision, idx: number) => {
        await trigger(`elections.${idx}.electedAmount`);
      });
    };

    validateFields();
  }, [trigger, electedAmounts]);

  useEffect(() => {
    // condition to make useEffect hook not run on initial render
    // we must run the validation only when next button/tab was clicked
    // so, stageValidationRequested must be set to 0 after switching to next stage again
    if (stageValidationRequested) {
      // declaring the fn inside the hook since we would'nt have it as dependency
      const triggerFormValidations = async () => {
        // triggers the form validation
        const result = await trigger();
        // checks if the form has a valid state so that data can be saved, then allowed to go to next stage
        // there could be more than one form to be validated in other stages
        if (result) {
          // no errors, check for warnings!!!
          const electStageWarning: Optional<IElectStageWarning> =
            getElectGridWarnings(
              electStage.elections,
              strategyConfigsForIV,
              canRequestAdditional,
              offerAmount,
              canUseFinancing ? financingPercent : nothing,
              existingLoanBalance,
              loanLimit
            );

          setWarnings(electStageWarning);

          if (!isSomething(electStageWarning)) {
            moveStages();
          }
        } else {
          /*
          if there are errors determine which to show. They should take precedence in this order:
            1. individual strategy errors: opens alert that strategy amount is invalid
            2. total election error: opens alert saying that user is either below elecion
                  minimum or above offer amount (with no request additional)
            3. reallocation question has no response: opens alert that question must have a 
                  response
          */
          let stageErrorMessage = "";
          let errorRef = null;

          if (errors.useReallocation) {
            // check for reallocation error
            stageErrorMessage = errors.useReallocation.message ?? "";
            setReallocationCardError({
              errorMessage: stageErrorMessage,
              isTotalError: false,
            });
            errorRef = reallocationRef;
          } else {
            setReallocationCardError(undefined);
          }

          if (errors.elections) {
            // check for elect card errors (these take precendence over reallocation errors)
            let newElectCardError = undefined;
            if (errors.elections?.root?.message) {
              // if there is a top level elections error, set the error message
              newElectCardError = {
                errorMessage: errors.elections?.root?.message,
                isTotalError: true,
              };
            }

            for (let i = 0; i < fields.length; i++) {
              if (errors.elections[i]?.electedAmount?.message) {
                // if any individual strategy has an error, display invalid strategy message
                newElectCardError = {
                  errorMessage: ElectionsLabels.INVALID_STRATEGY_AMOUNT,
                  isTotalError: false,
                };
                break;
              }
            }
            setElectCardError(newElectCardError);
            stageErrorMessage = newElectCardError?.errorMessage ?? "";
            errorRef = electCardRef;
          } else {
            setElectCardError(undefined);
          }

          setErrorToast(stageErrorMessage);
          //scroll to the bottom where the error was thrown
          errorRef?.current?.scrollIntoView({
            behavior: "smooth",
          });
        }
      };
      dispatch(resetStageValidation());
      triggerFormValidations();
    }
  }, [
    dispatch,
    stageValidationRequested,
    electStageFormMethods,
    trigger,
    fields.length,
    errors,
    setErrorToast,
    moveStages,
    electStage.elections,
    canRequestAdditional,
    strategyConfigsForIV,
    offerAmount,
    setWarnings,
    canUseFinancing,
    financingPercent,
    existingLoanBalance,
    loanLimit,
  ]);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <OfferAmountCard />
      </Grid>
      <AccordionElectionCard
        headerComponent={<StrategyOverviewHeader isAccordion={true} />}
        cardContentComponent={<StrategyOverviewContent />}
        accordionId={ElectionAccordionObject.ELECT_STRATEGY_OVERVIEW}
      />
      <form ref={electCardRef} className={styles.electForm}>
        <ElectCard
          control={electStageFormMethods.control}
          trigger={trigger}
          error={electCardError}
          setValue={electStageFormMethods.setValue}
        />
      </form>
      <FinancingOptionsCard />
      {hasIVMoreThanOneStrategy && (
        <form ref={reallocationRef} className={styles.reallocationForm}>
          <ReallocationCard
            control={electStageFormMethods.control}
            trigger={trigger}
            error={reallocationCardError}
          />
        </form>
      )}
      <CurrentInvestmentPortfolioCard />
      {isSomething(unAcknowledgedElectStageWarnings) && (
        <ElectionWarningDialog
          open={isWarningDialogOpen}
          warningInfo={unAcknowledgedElectStageWarnings.value}
        />
      )}
    </Grid>
  );
};
