import { PathFragment, Review } from "common";
import React from "react";

import { useActiveElection } from "../Hooks/useActiveElectionHook";
import { useElectionQueryParams } from "../Hooks/useElectionQueryParamsHook";

export const ElectionReviewWrapper = () => {
  const { electionRoundId, mdmInvestmentVehicleId } = useElectionQueryParams();
  useActiveElection();

  return (
    <Review
      incompletePath={`/${PathFragment.ELECTIONS}/${electionRoundId}/elector-view/${mdmInvestmentVehicleId}`}
      notFoundPath={`/${PathFragment.ADMIN_CLIENT}`}
      electionListPath={`/${PathFragment.ELECTIONS}/${electionRoundId}`}
    />
  );
};
