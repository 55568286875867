import { Chip, Tooltip } from "@mui/material";
import clsx from "clsx";
import { ElectionsLabels } from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectElectionRoundConfiguration } from "../../../../../../redux/selectors";
import styles from "./DaysLeftChip.module.scss";

export const DaysLeftChip = () => {
  const getChipColorByDaysLeft = (daysLeft: number): string => {
    if (daysLeft < 0) {
      return styles.expired;
    } else if (daysLeft <= 2) {
      return styles.critical;
    } else if (daysLeft <= 7) {
      return styles.considerate;
    } else {
      return styles.ample;
    }
  };

  const ONE_DAY_MS = useMemo(() => 1000 * 60 * 60 * 24, []);

  const { electionSubmissionDeadline } = useSelector(
    selectElectionRoundConfiguration
  );

  const getMillisecondTimeUntil = (deadline: Date) => {
    return deadline.getTime() - new Date().getTime();
  };

  const remainingDays = useMemo(() => {
    const timeInMs = getMillisecondTimeUntil(electionSubmissionDeadline);
    return Math.floor(timeInMs / ONE_DAY_MS);
  }, [electionSubmissionDeadline, ONE_DAY_MS]);

  const getChipMessage = (daysLeft: number, dueDate: Date) => {
    if (daysLeft < 0) {
      return ElectionsLabels.DEADLINE_PASSED;
    }
    if (daysLeft < 1) {
      return ElectionsLabels.DUE_AT(dueDate);
    }
    return ElectionsLabels.DAYS_LEFT(daysLeft);
  };

  return (
    <Tooltip
      title={ElectionsLabels.SUBMISSION_DEADLINE(electionSubmissionDeadline)}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
      placement={"bottom-end"}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <Chip
        className={clsx(styles.chip, getChipColorByDaysLeft(remainingDays))}
        label={getChipMessage(remainingDays, electionSubmissionDeadline)}
        size="small"
      />
    </Tooltip>
  );
};
