import ClearIcon from "@mui/icons-material/Clear";
import {
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import { ElectionsLabels, StringConstants } from "common";
import React, { forwardRef, useState } from "react";

import styles from "./TextFieldWithClear.module.scss";

interface ITextFieldWithClearProps<T> extends Omit<TextFieldProps, "value"> {
  value: T;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClear: () => void;
  errorMsg?: string;
  showErrorAsTooltip?: boolean;
  customOnBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  startAdornment?: JSX.Element;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  hasValue?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
}

/*
  Component which renders the proper Election Warning Dialog based on the type of warning provided.
  */
export const TextFieldWithClear = forwardRef(
  <T,>(
    props: ITextFieldWithClearProps<T>,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const {
      value,
      onChange,
      handleClear,
      errorMsg,
      showErrorAsTooltip = false,
      customOnBlur,
      inputComponent,
      startAdornment,
      hasValue,
      onKeyDown,
      autoFocus,
      ...otherProps
    } = props;

    // manage hover and focused states for showing errors
    const [isTextFieldHovered, setIsTextFieldHovered] = useState(false);
    const [isTextFieldFocused, setIsTextFieldFocused] = useState(autoFocus);

    // mange clear icon hover state - conditionally tooltip on hover
    //    AND when no error tooltip is shown for the cell
    const [isClearHovered, setIsClearHovered] = useState(false);

    const handleMouseEnterTextField = () => {
      setIsTextFieldHovered(true);
    };

    const handleMouseLeaveTextField = () => {
      setIsTextFieldHovered(false);
    };

    const handleMouseEnterClearIcon = () => {
      setIsClearHovered(true);
    };

    const handleMouseLeaveClearIcon = () => {
      setIsClearHovered(false);
    };

    /**
     * Sets the value of the text field and enables save button
     */
    const onBlur = () => {
      setIsTextFieldFocused(false);
    };

    /*
  error tooltip should only be open if:
  - field is being hovered over
  - field is not in context (i.e. not clicked into)
  - field has an error
  */
    const isErrorTooltipOpen = (errorMessage: string | undefined) => {
      return (
        showErrorAsTooltip &&
        isTextFieldHovered &&
        !isTextFieldFocused &&
        errorMessage !== undefined
      );
    };

    return (
      <Tooltip title={errorMsg} open={isErrorTooltipOpen(errorMsg)}>
        <TextField
          className={styles.textField}
          ref={ref}
          {...otherProps}
          value={value}
          placeholder={ElectionsLabels.ENTER_AMOUNT}
          autoComplete={"off"}
          error={errorMsg !== undefined}
          onChange={onChange}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            onBlur();
            if (customOnBlur) {
              customOnBlur(e);
            }
          }}
          onFocus={() => {
            setIsTextFieldHovered(false);
            setIsTextFieldFocused(true);
          }}
          onMouseEnter={handleMouseEnterTextField}
          onMouseLeave={handleMouseLeaveTextField}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          InputProps={{
            startAdornment: startAdornment,
            endAdornment:
              // show a clear icon when a value is entered and in field/hovering over it
              //  otherwise, render div with some empty space to ensure overall text field keeps same width
              (value || hasValue) &&
              (isTextFieldHovered || isTextFieldFocused) ? (
                <Tooltip
                  title={StringConstants.CLEAR}
                  open={isClearHovered && !isErrorTooltipOpen(errorMsg)}
                >
                  <ClearIcon
                    className={styles.clearTextField}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                    onMouseUp={(e) => {
                      if (e.button === 0) {
                        setIsClearHovered(false);
                        handleClear();
                      }
                    }}
                    onMouseEnter={handleMouseEnterClearIcon}
                    onMouseLeave={handleMouseLeaveClearIcon}
                  />
                </Tooltip>
              ) : (
                <div className={styles.empty}></div>
              ),
            inputComponent: inputComponent,
          }}
        />
      </Tooltip>
    );
  }
);

TextFieldWithClear.displayName = "TextFieldWithClear";
