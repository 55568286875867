import { StyleSheet } from "@react-pdf/renderer";

import colors from "../../../../../styles/_colors.scss";

export const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontFamily: "Guardian Sans",
    fontSize: 12,
    paddingBottom: 2,
  },
  listItem: {
    fontSize: 12,
    fontFamily: "Guardian Sans",
    paddingBottom: 2,
  },
  textBold: {
    fontWeight: 500,
  },
  signatureContainer: {
    marginTop: 12,
    marginBottom: 12,
  },
  signature: {
    padding: 8,
    textAlign: "left",
    fontSize: 24,
    fontFamily: "DancingScript",
    fontWeight: 600,
    borderColor: colors.bx_slate_shade,
    borderWidth: 1,
    borderRadius: 1,
  },
});
