import { Circle } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { MonospaceNumber } from "../../../../components/MonospaceNumber/MonospaceNumber";
import { ResponsiveRechartsWrapper } from "../../../../components/ResponsiveRechartsWrapper/ResponsiveRechartsWrapper";
import { BreakpointConstants } from "../../../../constants/BreakpointConstants";
import {
  InvestmentBreakdownKey,
  PeriodType,
} from "../../../../constants/enums";
import {
  chartMargins,
  lineChartsCursor,
  mobile_chartMargins,
  RechartsConstants,
  xAxisTick,
  yAxisTick,
} from "../../../../constants/RechartsSVGStyles";
import colors from "../../../../styles/_colors.scss";
import { IHistoricalSummaryDatum } from "../../../../types/dataTypes";
import { InvestmentHistoryValueItem } from "../../../../types/investmentHistoryTypes";
import { getTicksAndLabels } from "../../../../utils/charting";
import {
  getCurrencyFormattedValue,
  getLabelFromCamelCase,
} from "../../../../utils/formatters";
import {
  calculateDateDomain,
  calculateDateTicks,
  getActiveData,
  getDataAccesor,
  getQuarterlyLabel,
  getYearLabelByQuarter,
  getYearLabelByQuarterWithSuffix,
  getYearlySuffix,
} from "../../../../utils/historicalSummaryUtils";
import { InvestmentBreakDownValueSelectors } from "../../../../utils/investmentBreakdownUtils";
import styles from "./PortfolioBalanceChart.module.scss";

interface PortfolioBalanceChartProps {
  summaryData: IHistoricalSummaryDatum[];
  firstQuarterDate: Date;
  periodType: PeriodType;
  dataTypes: InvestmentHistoryValueItem[];
  highlightedLine: InvestmentBreakdownKey | null;
  setHighlightedLine: (value: InvestmentBreakdownKey | null) => void;
}

export const PortfolioBalanceChart = (props: PortfolioBalanceChartProps) => {
  const [dateTicks, setDateTicks] = useState<number[]>([]);
  const [yAxisTicks, setYAxisTicks] = useState<number[]>([]);
  const [yAxisTickLabels, setYAxisTickLabels] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<number[]>([0, 0]);
  // controls where vertical reference lines (between years) appear on graph
  const [referenceLinePlacements, setReferenceLinePlacements] = useState<
    number[]
  >([]);

  const { summaryData, periodType, dataTypes } = props;

  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );

  useEffect(() => {
    // render all tick labels if the user has less than two years of data
    // otherwise render current quarter and first quarter of previous years
    const activeQuarters = getActiveData(dataTypes, summaryData).map(
      (datum) => datum.displayQuarterAsOfDate
    );

    const calculatedDateTicks = calculateDateTicks(activeQuarters);
    setDateTicks(calculatedDateTicks);
    const calculatedRange = calculateDateDomain(
      activeQuarters,
      periodType,
      true
    );
    setDateRange(calculatedRange);
    const activeLines = dataTypes.filter((line) => line.isSelected);

    const values: number[] = [];
    summaryData.forEach((datum) =>
      activeLines.forEach((item) =>
        values.push(
          InvestmentBreakDownValueSelectors[item.key](datum.cumulative)
        )
      )
    );

    // determine if reference line should appear after a data point
    //  a whole number year denotes Q4 data, so only display reference line
    //  after those, excluding the last value, since no line is needed
    //  at the end of the graph. Quarters are zero-indexed.
    const refLines = summaryData
      .slice(0, summaryData.length - 1)
      .map((datum) => datum.displayQuarterAsOfDate.valueOf())
      .filter((quarter) => quarter % 4 === 3);

    setReferenceLinePlacements(refLines);

    const tickInfo = getTicksAndLabels(values);

    setYAxisTicks(tickInfo.tickValues);
    setYAxisTickLabels(tickInfo.tickLabels);
  }, [summaryData, periodType, dataTypes]);

  const formatYAxisTicks = (value: number, index: number): string => {
    return yAxisTickLabels[index] ?? "";
  };

  return (
    <div className={styles.bar}>
      <ResponsiveRechartsWrapper>
        <LineChart
          data={getActiveData(dataTypes, summaryData)}
          margin={isMobile ? mobile_chartMargins : chartMargins}
        >
          <Tooltip<number, string>
            cursor={lineChartsCursor}
            content={({ payload, label }) => {
              let yearlyLabelSuffix: string | undefined = undefined;
              if (payload && payload.length) {
                if (payload[0].payload) {
                  yearlyLabelSuffix = getYearlySuffix(
                    payload[0].payload.asOfDate,
                    props.firstQuarterDate,
                    false
                  );
                }
              }
              return (
                <div className={styles.tooltip}>
                  <div>
                    <p className={styles.label}>
                      {periodType === PeriodType.QUARTERLY
                        ? getQuarterlyLabel(label)
                        : getYearLabelByQuarterWithSuffix(
                            label,
                            yearlyLabelSuffix
                          )}
                    </p>
                    <ul className={styles.itemList}>
                      {payload &&
                        payload.map((item, index) => (
                          <li
                            key={`${item.dataKey}${index}`}
                            className={styles.item}
                          >
                            <div className={styles.itemLabel}>
                              <Circle
                                sx={{ fontSize: 10 }}
                                className={styles.circle}
                                style={{ color: item.color }}
                              ></Circle>
                              <span>{item.name}:</span>
                            </div>
                            <span>
                              <MonospaceNumber
                                value={item.value}
                                className={styles.itemValue}
                                valueFormatter={getCurrencyFormattedValue}
                              />
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              );
            }}
          />
          <CartesianGrid
            vertical={false}
            strokeDasharray="2 2"
            stroke={colors.slate}
          />
          <XAxis
            type={"number"}
            axisLine={false}
            dataKey={(data) => data.asOfDate}
            domain={[dateRange[0], dateRange[1]]}
            tick={xAxisTick}
            tickLine={false}
            ticks={dateTicks.length === 0 ? [""] : dateTicks}
            tickFormatter={
              periodType === PeriodType.QUARTERLY &&
              summaryData.length <=
                RechartsConstants.MAX_QUARTERS_TO_LABEL_INDIVIDUALLY
                ? getQuarterlyLabel
                : getYearLabelByQuarter
            }
          />
          <YAxis
            axisLine={true}
            domain={["dataMin", "dataMax"]}
            tick={yAxisTick}
            tickFormatter={formatYAxisTicks}
            tickLine={false}
            ticks={
              /* When no data is selected, it will render $0 in the middle of the chart by default, 
              this will force the chart to render $0 at the bottom */
              yAxisTicks.length === 1 && yAxisTicks[0] === 0
                ? [0, 1]
                : yAxisTicks
            }
            type="number"
          />
          <ReferenceLine y={0} stroke={colors.dark_grey} />
          {referenceLinePlacements.map((item, idx) => {
            return (
              <ReferenceLine
                key={idx}
                x={item + (periodType === PeriodType.YEARLY ? 2.0 : 0.5)} // need to move line over so it is between two points
                stroke={colors.slate}
                strokeDasharray="2 2"
              />
            );
          })}
          {dataTypes
            .filter((type) =>
              summaryData.some(
                (datum) =>
                  InvestmentBreakDownValueSelectors[type.key](
                    datum.cumulative
                  ) != 0
              )
            )
            .map((item) => (
              <Line
                key={item.key}
                dataKey={getDataAccesor(item.key, true)}
                stroke={item.color}
                strokeWidth={2}
                opacity={
                  props.highlightedLine === null ||
                  props.highlightedLine === item.key
                    ? 1
                    : 0.2
                }
                name={item.labelOverride ?? getLabelFromCamelCase(item.key)}
                type={"monotone"}
                dot={false}
                activeDot={{
                  fill: colors.white,
                  stroke: item.color,
                  strokeWidth: 2,
                }}
              />
            ))}
        </LineChart>
      </ResponsiveRechartsWrapper>
    </div>
  );
};
