import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ElectionsLabels, TableHeaderWithTooltip } from "common";
import React from "react";
import { useSelector } from "react-redux";

import {
  selectFundSortedStrategies,
  selectHasForecastedDeployment,
  selectStrategiesStageConfig,
} from "../../../../../../../redux/selectors";
import { IBaseStore } from "../../../../../../../redux/store";
import { StrategyAndFundRows } from "./StrategyAndFundRows/StrategyAndFundRows";
import styles from "./StrategyOverviewTable.module.scss";

export const StrategyOverviewTable = () => {
  const fundSortedStrategies = useSelector((state: IBaseStore) =>
    selectFundSortedStrategies(state)
  );

  const hasForecastedDeployment = useSelector((state: IBaseStore) =>
    selectHasForecastedDeployment(state)
  );

  const stageConfig = useSelector((state: IBaseStore) =>
    selectStrategiesStageConfig(state)
  );

  return (
    <TableContainer id={styles.strategyOverviewTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell id={styles.strategyHeader}>
              {ElectionsLabels.STRATEGY}
            </TableCell>
            <TableCell id={styles.ppmHeader}>{ElectionsLabels.PPM}</TableCell>
            <TableCell align="right">
              <TableHeaderWithTooltip
                headerName={ElectionsLabels.LIMITED_PARTNER_COMMITMENT}
                tooltipText={stageConfig.lpCommitmentTooltip}
                isRichContent
              />
            </TableCell>
            <TableCell align="right">
              <TableHeaderWithTooltip
                headerName={ElectionsLabels.BLACKSTONE_COMMITMENT}
                tooltipText={stageConfig.blackstoneCommitmentTooltip}
                isRichContent
              />
            </TableCell>
            <TableCell align="right">
              {ElectionsLabels.TOTAL_FUND_SIZE}
            </TableCell>
            {hasForecastedDeployment && (
              <>
                <TableCell align="right">
                  {ElectionsLabels.INVESTMENT_PERIOD_YEARS}
                </TableCell>
                <TableCell align="right">
                  <TableHeaderWithTooltip
                    headerName={ElectionsLabels.TOTAL_SUBJECT_TO_ELECTION}
                    tooltipText={stageConfig.totalSubjectToElectionTooltip}
                    isRichContent
                  />
                </TableCell>
                <TableCell align="right">
                  <TableHeaderWithTooltip
                    headerName={
                      ElectionsLabels.FORECASTED_CAPITAL_DEPLOYMENT_PERCENTAGE
                    }
                    tooltipText={
                      stageConfig.forecastedDeploymentPercentageTooltip
                    }
                    isRichContent
                  />
                </TableCell>
                <TableCell align="right">
                  <TableHeaderWithTooltip
                    headerName={ElectionsLabels.FORECASTED_CAPITAL_DEPLOYMENT}
                    tooltipText={stageConfig.forecastedDeploymentTooltip}
                    isRichContent
                  />
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {fundSortedStrategies.map((strategy) => (
            <StrategyAndFundRows
              key={strategy.strategyId}
              strategy={strategy}
              hasForecastedDeployment={hasForecastedDeployment}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
