import { CustomCellRendererProps } from "ag-grid-react";
import React from "react";

import { Optional } from "../../../types/typeUtils";
import { MonospaceOptionalNumber } from "../../MonospaceNumber/MonospaceOptionalNumber";

export const getMonospaceOptionalCellRenderer = (
  formatter: (value: Optional<number>) => string
) => {
  const MonospaceOptionalCellRenderer = (
    cellRendererParams: CustomCellRendererProps
  ) => (
    <MonospaceOptionalNumber
      value={cellRendererParams.value}
      valueFormatter={formatter}
    />
  );
  return MonospaceOptionalCellRenderer;
};
