import { createSelector } from "@reduxjs/toolkit";

import { ElectStageConfiguration } from "../../types/electionDataTypes";
import { isSomething } from "../../types/typeGuards";
import { getElectCardData } from "../../utils/electionsUtils";
import {
  selectElectionIVConfiguration,
  selectElectionRoundConfiguration,
  selectElectionWorkflowStateLocal,
} from "./electionSelectors";

export const selectElectCardData = createSelector(
  selectElectionRoundConfiguration,
  selectElectionWorkflowStateLocal,
  selectElectionIVConfiguration,
  (electionRoundConfiguration, electionWorkflowState, ivConfiguration) => {
    return getElectCardData(
      electionRoundConfiguration,
      ivConfiguration,
      electionWorkflowState
    );
  }
);

export const selectElectCardTooltip = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.stages.elect.electionsCardTooltip;
  }
);

export const selectRemainingCommitmentTooltip = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.stages.overview
      .remainingExpectedCommitment.tooltip;
  }
);

export const selectRemainingCommitmentFootnote = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.stages.overview
      .remainingExpectedCommitment.footnote;
  }
);

export const selectElectCardGridTooltips = createSelector(
  selectElectionRoundConfiguration,
  selectElectionIVConfiguration,
  (electionRoundConfiguration, ivConfiguration) => {
    const electStageconfig: ElectStageConfiguration =
      electionRoundConfiguration.stages.elect;
    const remainingCommitmentTooltip =
      electStageconfig.remainingCommitmentTooltip;
    const mandatoryCommitmentTooltip =
      electStageconfig.mandatoryCommitmentTooltip;
    const totalForecastedInvestmentTooltip =
      electStageconfig.totalForecastedInvestmentTooltip;

    return {
      remainingCommitmentTooltip: isSomething(remainingCommitmentTooltip)
        ? remainingCommitmentTooltip.value
        : "",
      mandatoryCommitmentTooltip: isSomething(mandatoryCommitmentTooltip)
        ? mandatoryCommitmentTooltip.value
        : "",
      totalForecastedInvestmentTooltip: isSomething(
        totalForecastedInvestmentTooltip
      )
        ? totalForecastedInvestmentTooltip.value
        : "",
      mandatoryCommitmentYear:
        electionRoundConfiguration.mandatoryCommitmentYear,
      offeredAmount: ivConfiguration.offeredAmount,
    };
  }
);

export const selectCanRequestAdditional = createSelector(
  selectElectionIVConfiguration,
  (ivConfiguration) => ivConfiguration.canRequestAdditional
);

export const selectOfferAmount = createSelector(
  selectElectionIVConfiguration,
  (ivConfiguration) => ivConfiguration.offeredAmount
);

export const selectElectionMinimum = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.totalElectionMinimum;
  }
);

export const selectIVStrategyConfigurations = createSelector(
  selectElectionIVConfiguration,
  (ivConfiguration) => ivConfiguration.strategies
);

export const selectStrategyConfigurations = createSelector(
  [selectElectionRoundConfiguration, selectIVStrategyConfigurations],
  (electionRoundConfiguration, selectIVStrategyConfigurations) => {
    return electionRoundConfiguration.strategies.filter((electionStrategy) =>
      selectIVStrategyConfigurations.some(
        (ivStrategy) => ivStrategy.strategyId === electionStrategy.strategyId
      )
    );
  }
);
