import React from "react";

import styles from "./CommitmentsPage.module.scss";
import { AnnualCommitments } from "./components/AnnualCommitments/AnnualCommitments";
import { ForecastedCapitalCalls } from "./components/ForecastedCapitalCalls/ForecastedCapitalCalls";
import { LifeOfFundCommitments } from "./components/LifeOfFundCommitments/LifeOfFundCommitments";

export const Commitments = () => {
  return (
    <div className={styles.commitments}>
      <div className={styles.widgets}>
        <AnnualCommitments />
        <LifeOfFundCommitments />
        <ForecastedCapitalCalls />
      </div>
    </div>
  );
};
