import { Card } from "@mui/material";
import React from "react";

import { InfoTooltip } from "../../../../../components/InfoTooltip/InfoTooltip";
import { MonospaceNumber } from "../../../../../components/MonospaceNumber/MonospaceNumber";
import styles from "./Callout.module.scss";

interface ICalloutProps {
  label: string;
  headerTooltipParagraphs?: string[] | undefined;
  tooltipParagraphs?: string[] | undefined;
  value: number | string;
  showHeaderTooltip?: boolean | undefined;
  showTooltip?: boolean | undefined;
  formatter?: (val: number | undefined) => string;
}

export const Callout = (props: ICalloutProps) => {
  const {
    label,
    headerTooltipParagraphs,
    tooltipParagraphs,
    value,
    showHeaderTooltip = false,
    showTooltip = false,
    formatter,
  } = props;

  return (
    <Card className={styles.callout}>
      <div className={styles.calloutLabel}>
        <span>{label}</span>
        {showHeaderTooltip && (
          <InfoTooltip tooltipContent={headerTooltipParagraphs ?? [""]} />
        )}
      </div>
      <span className={styles.calloutValue}>
        {typeof value === "number" && formatter !== undefined ? (
          <MonospaceNumber value={value} valueFormatter={formatter} />
        ) : (
          <span>{value}</span>
        )}
        {showTooltip && (
          <InfoTooltip
            className={styles.tooltipBody}
            tooltipContent={tooltipParagraphs ?? [""]}
          />
        )}
      </span>
    </Card>
  );
};
