import {
  calculateNonCumulativeValues,
  convertToRoundedValidNumber,
  getPeriodLabel,
  IHistoricalSummaryDatum,
  IHistoricalSummaryGrouped,
  IInvestmentBreakdownDatum,
  InvestmentBreakdownKey,
  InvestmentHistoryValueItem,
  isSomething,
  nothing,
  Optional,
  PeriodType,
  QuarterDate,
  some,
} from "common";

import { RechartsConstants } from "../constants/RechartsSVGStyles";
import {
  checkDateIsQuarterEnd,
  checkDateIsYearEnd,
  getQuarterFromDate,
} from "../redux/sagas/internalInvestmentDataSagas";
import { InvestmentBreakDownValueSelectors } from "./investmentBreakdownUtils";

function mapHistoricalSource(
  datum: IInvestmentBreakdownDatum,
  index: number,
  allData: IInvestmentBreakdownDatum[],
  periodType: PeriodType
): IHistoricalSummaryDatum {
  const asOfDate = getAsOfDate(datum);
  // for yearly data, need the ytd to have date of dec 31
  let displayDate = asOfDate;
  if (periodType === PeriodType.YEARLY && !checkDateIsYearEnd(asOfDate)) {
    displayDate = new Date(asOfDate.getFullYear(), 11, 31);
  }
  return {
    displayQuarterAsOfDate: new QuarterDate(displayDate),
    asOfDate: new QuarterDate(asOfDate),
    periodName: getPeriodLabel(asOfDate, periodType),
    cumulative: {
      investment: datum.investment,
      realizedProceeds: datum.realizedProceeds,
      unrealizedValue: datum.unrealizedValue,
    },
    nonCumulative: calculateNonCumulativeValues(datum, allData[index - 1]),
  } as IHistoricalSummaryDatum;
}

function roundHistoricalSummaryValues(
  datum: IHistoricalSummaryDatum
): IHistoricalSummaryDatum {
  return {
    ...datum,
    cumulative: roundDatum(datum.cumulative),
    nonCumulative: roundDatum(datum.nonCumulative),
  } as IHistoricalSummaryDatum;
}

const roundDatum = (datum: IInvestmentBreakdownDatum) => {
  return {
    ...datum,
    investment: isSomething(datum.investment)
      ? some({
          ...datum.investment.value,
          capitalInvested: convertToRoundedValidNumber(
            datum.investment.value.capitalInvested
          ),
          netCashFlow: convertToRoundedValidNumber(
            datum.investment.value.netCashFlow
          ),
        })
      : nothing,
    realizedProceeds: isSomething(datum.realizedProceeds)
      ? some({
          ...datum.realizedProceeds.value,
          optionalAndMandatoryInvestments: convertToRoundedValidNumber(
            datum.realizedProceeds.value.optionalAndMandatoryInvestments
          ),
          returnOfCapital: convertToRoundedValidNumber(
            datum.realizedProceeds.value.returnOfCapital
          ),
          gainLoss: convertToRoundedValidNumber(
            datum.realizedProceeds.value.gainLoss
          ),
          carriedInterest: convertToRoundedValidNumber(
            datum.realizedProceeds.value.carriedInterest
          ),
          total: convertToRoundedValidNumber(
            datum.realizedProceeds.value.total
          ),
        })
      : nothing,
    unealizedValue: isSomething(datum.unrealizedValue)
      ? some({
          ...datum.unrealizedValue.value,
          optionalAndMandatoryInvestments: convertToRoundedValidNumber(
            datum.unrealizedValue.value.optionalAndMandatoryInvestments
          ),
          remainingCapitalInvested: convertToRoundedValidNumber(
            datum.unrealizedValue.value.remainingCapitalInvested
          ),
          gainLoss: convertToRoundedValidNumber(
            datum.unrealizedValue.value.gainLoss
          ),
          carriedInterest: convertToRoundedValidNumber(
            datum.unrealizedValue.value.carriedInterest
          ),
          total: convertToRoundedValidNumber(datum.unrealizedValue.value.total),
        })
      : nothing,
  };
};

const getAsOfDate = (datum: IInvestmentBreakdownDatum) => {
  return isSomething(datum.investment)
    ? datum.investment.value.asOfDate
    : isSomething(datum.realizedProceeds)
    ? datum.realizedProceeds.value.asOfDate
    : isSomething(datum.unrealizedValue)
    ? datum.unrealizedValue.value.asOfDate
    : new Date(); //shouldn't ever get here. All rows should have some kind of data.
};

export const getDataAccesor = (
  key: InvestmentBreakdownKey,
  isCumulative: boolean
) => {
  return (datum: IHistoricalSummaryDatum) => {
    return InvestmentBreakDownValueSelectors[key](
      isCumulative ? datum.cumulative : datum.nonCumulative
    );
  };
};

export function groupHistoricalSummary(
  historicalSummary: IInvestmentBreakdownDatum[],
  latestAsOfDateWithUnrealizedData: Optional<Date>
): IHistoricalSummaryGrouped {
  // as if should be sorted or not and if data is already filtered
  const sortedHistorical = [...historicalSummary];
  sortedHistorical.sort(
    (a, b) => getAsOfDate(a).valueOf() - getAsOfDate(b).valueOf()
  );

  const latestDate = getAsOfDate(sortedHistorical[sortedHistorical.length - 1]);
  const latestMonth = latestDate.getUTCMonth();
  const latestYear = latestDate.getFullYear();

  /*
  for yearly data, we need to combine the latest quarter realized values with the latest quarter that has unrealized value
  */
  const sortedHistoricalForYearly = sortedHistorical.filter((datum) => {
    const asOfDate = getAsOfDate(datum);
    return asOfDate.getFullYear() !== latestYear;
  });

  if (isSomething(latestAsOfDateWithUnrealizedData)) {
    // find the latest quarter with unrealized and combine with latest quarter overall
    const unrealizedData = sortedHistorical.filter((datum) => {
      const asOfDate = getAsOfDate(datum);
      return (
        latestAsOfDateWithUnrealizedData.value.getFullYear() ===
          asOfDate.getFullYear() &&
        latestAsOfDateWithUnrealizedData.value.getMonth() ===
          asOfDate.getMonth()
      );
    });
    if (unrealizedData.length) {
      sortedHistoricalForYearly.push({
        ...sortedHistorical[sortedHistorical.length - 1],
        unrealizedValue: unrealizedData[0].unrealizedValue,
      });
    }
  } else {
    // if none found, just push the latest quarter as is
    sortedHistoricalForYearly.push(
      sortedHistorical[sortedHistorical.length - 1]
    );
  }

  // filter quarterly data for 2020 Q3 and beyond
  const finalizedQuarterlyData = sortedHistorical
    .filter((datum) => {
      const asOfDate = getAsOfDate(datum);
      return (
        asOfDate.getUTCFullYear() > 2020 ||
        (asOfDate.getUTCFullYear() === 2020 && asOfDate.getMonth() >= 8)
      );
    })
    .map((...args) => mapHistoricalSource(...args, PeriodType.QUARTERLY))
    .map((x: IHistoricalSummaryDatum) => roundHistoricalSummaryValues(x));

  // to include year to date, add yearly data if its the last month of a year OR if its the latest date
  const yearlyData = sortedHistoricalForYearly
    .filter((datum) => {
      const asOfDate = getAsOfDate(datum);
      return (
        asOfDate.getUTCFullYear() >= 2020 &&
        ((asOfDate.getUTCMonth() === latestMonth &&
          asOfDate.getFullYear() === latestYear) ||
          asOfDate.getUTCMonth() === 11)
      );
    })
    .map((...args) => mapHistoricalSource(...args, PeriodType.YEARLY))
    .map((x: IHistoricalSummaryDatum) => roundHistoricalSummaryValues(x));

  return {
    [PeriodType.QUARTERLY]: finalizedQuarterlyData,
    [PeriodType.YEARLY]: yearlyData,
  };
}

export const getActiveData = (
  dataTypes: InvestmentHistoryValueItem[],
  data: IHistoricalSummaryDatum[]
): IHistoricalSummaryDatum[] => {
  return data.filter((datum) =>
    dataTypes
      .filter((type) => type.isSelected)
      .some(
        (item) =>
          InvestmentBreakDownValueSelectors[item.key](datum.cumulative) !== 0
      )
  );
};

export const calculateDateTicks = (activeQuarters: QuarterDate[]): number[] => {
  if (
    activeQuarters.length <=
    RechartsConstants.MAX_QUARTERS_TO_LABEL_INDIVIDUALLY
  ) {
    return activeQuarters.map((quarter) => quarter.valueOf());
  } else {
    // calculate number of quarters in incomplete year at the start of the data
    const leadingQuarters = activeQuarters.findIndex(
      (date) => date.quarter === 0
    );
    // calcualte number of quarters in incomplete year at end of data
    const trailingQuarters =
      (activeQuarters[activeQuarters.length - 1].quarter + 1) % 4;

    const yearTicks: number[] = [];
    if (leadingQuarters > 1) {
      // if more than one leading quarter add a tick centered on the number of columns
      yearTicks.push(activeQuarters[0].valueOf() + leadingQuarters / 2 - 0.5);
    }
    for (
      let i = leadingQuarters;
      i < activeQuarters.length - trailingQuarters;
      i += 4
    ) {
      // add a centered tick for each full year between leading and trailing quarters
      yearTicks.push(activeQuarters[i].valueOf() + 1.5);
    }
    if (trailingQuarters > 1) {
      // if more than one trailing quarter add a tick centered on the number of columns
      yearTicks.push(
        activeQuarters[activeQuarters.length - 1].valueOf() -
          trailingQuarters / 2 +
          0.5
      );
    }
    return yearTicks;
  }
};

export const calculateDateDomain = (
  activeQuarters: QuarterDate[],
  periodType: PeriodType,
  doNotAddPadding?: boolean
): number[] => {
  // conditionally add padding on either side for some breathing room
  const padding = periodType === PeriodType.QUARTERLY ? 0.5 : 2;
  const min =
    (activeQuarters[0]?.valueOf() ?? 0) - (doNotAddPadding ? 0 : padding);
  const max =
    (activeQuarters[activeQuarters.length - 1]?.valueOf() ?? 0) +
    (doNotAddPadding ? 0 : padding);
  return [min, max];
};

export const formatYAxisTicks = (
  value: number,
  index: number,
  yAxisTickLabels: string[]
): string => {
  return yAxisTickLabels[index] ?? "";
};

export const getYear = (asOfQuarter: number): number => {
  return Math.floor(asOfQuarter / 4);
};

export const getYearLabel = (asOfYear: number): string => {
  return String(asOfYear);
};

export const getYearLabelByQuarter = (asOfQuarter: number): string => {
  return String(getYear(asOfQuarter));
};

export const getQuarterlyLabel = (asOfQuarter: number): string => {
  const year = getYear(asOfQuarter);
  const quarter = (asOfQuarter % 4) + 1;
  return `${year} Q${quarter}`;
};

export const getQuarterlyLabelWithQTD = (asOfQuarter: number): string => {
  const asOfDate = new Date(asOfQuarter);
  const year = getYear(asOfQuarter);
  const quarter = (asOfQuarter % 4) + 1;
  let label = `${year} Q${quarter}`;
  if (!checkDateIsQuarterEnd(asOfDate)) {
    label += " QTD";
  }
  return label;
};

export const getYearLabelByQuarterWithSuffix = (
  asOfQuarter: number,
  suffix?: string
): string => {
  let label = String(getYear(asOfQuarter));
  if (suffix) {
    label += ` ${suffix}`;
  }
  return label;
};

export const getYearlySuffix = (
  asOfDate: Date,
  firstQuarterDate: Date,
  checkYTD: boolean
): string | undefined => {
  let yearlyLabelSuffix: string | undefined = undefined;
  const firstQuarter = getQuarterFromDate(firstQuarterDate);
  const firstQuarterYear = firstQuarterDate.getFullYear();
  const curLabelYear = asOfDate.getFullYear();
  if (checkYTD && !checkDateIsYearEnd(asOfDate)) {
    yearlyLabelSuffix = "YTD";
  } else if (firstQuarterYear === curLabelYear) {
    if (firstQuarter === "Q4") {
      yearlyLabelSuffix = "Q4";
    } else if (firstQuarter !== "Q1") {
      yearlyLabelSuffix = `${firstQuarter}-Q4`;
    }
  }
  return yearlyLabelSuffix;
};

export const hasCapitalInvestedData = (
  historicalSummary: IInvestmentBreakdownDatum[]
): boolean => {
  return (
    historicalSummary.length > 0 &&
    isSomething(historicalSummary[0].investment) &&
    historicalSummary[0].investment.value.capitalInvested !== 0
  );
};
