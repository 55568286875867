import { Stack, TextField } from "@mui/material";
import { AddBankAccountState, obfuscateAccountNumber } from "common";
import React, { useState } from "react";
import { ControllerRenderProps, FieldError } from "react-hook-form";

import FieldLabelWithTooltip from "../../../../../components/FieldLabelWithTooltip/FieldLabelWithTooltip";
import styles from "./TextFieldInput.module.scss";

type TextFieldInputKeys =
  | ControllerRenderProps<AddBankAccountState, "accountHolderName">
  | ControllerRenderProps<AddBankAccountState, "accountNumber">
  | ControllerRenderProps<AddBankAccountState, "confirmAccountId">
  | ControllerRenderProps<AddBankAccountState, "bankId">
  | ControllerRenderProps<AddBankAccountState, "bankName">
  | ControllerRenderProps<
      AddBankAccountState,
      "intermediaryAccount.accountHolderName"
    >
  | ControllerRenderProps<
      AddBankAccountState,
      "intermediaryAccount.accountNumber"
    >
  | ControllerRenderProps<
      AddBankAccountState,
      "intermediaryAccount.confirmAccountId"
    >
  | ControllerRenderProps<AddBankAccountState, "intermediaryAccount.bankId">
  | ControllerRenderProps<AddBankAccountState, "intermediaryAccount.bankName">;

export interface ITextFieldInputProps {
  value: string;
  field: TextFieldInputKeys;
  label: string;
  placeHolderText: string;
  tooltip?: string | React.ReactNode;
  error?: FieldError;
  triggerOnBlur?: () => void;
  shouldObfuscate?: boolean;
  customHandleInputChange?: (value: string) => string;
  disabled?: boolean;
}

export const TextFieldInput = (props: ITextFieldInputProps) => {
  const {
    value,
    field,
    placeHolderText,
    error,
    triggerOnBlur,
    shouldObfuscate,
    customHandleInputChange,
    disabled = false,
  } = props;

  const [displayValue, setDisplayValue] = useState(
    shouldObfuscate ? obfuscateAccountNumber(value) : value
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // when field is numeric, do not allow anything other than digits to be added
    const inputValue = customHandleInputChange
      ? customHandleInputChange(event.target.value)
      : event.target.value;
    setDisplayValue(inputValue);
    field.onChange(inputValue);
  };

  const handleBlur = () => {
    // obfuscate input on blur if required
    const unFocusedValue = shouldObfuscate
      ? obfuscateAccountNumber(value)
      : value;
    setDisplayValue(unFocusedValue);
    field.onBlur();
    if (triggerOnBlur && unFocusedValue.length > 0) {
      triggerOnBlur();
    }
  };

  const handleFocus = () => {
    setDisplayValue(value);
  };

  return (
    <Stack className={styles.stack}>
      <FieldLabelWithTooltip {...props} />
      <TextField
        className={styles.textInput}
        disabled={disabled}
        variant="outlined"
        autoComplete={"off"}
        placeholder={placeHolderText}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={displayValue}
        error={error !== undefined}
        helperText={error !== undefined ? error.message : undefined}
      />
    </Stack>
  );
};
