import {
  BankAccountConstants,
  BankAccountTooltips,
} from "../constants/BankAccountConstants";
import {
  AccountNumberType,
  AccountType,
  AddBankAccountState,
  BankAccountAssignment,
  BankAccountsResponse,
  BankIdType,
  GPBankAccount,
  GPInvestmentVehicle,
  GPPendingBankAccount,
  IBankAccount,
  IBankAccountsStore,
  SelectableBankAccount,
  TReviewAndSignFormBankAccounts,
  UserAddedBankAccount,
  ViewOrEditBankAccount,
} from "../types/bankAccountDataTypes";
import { isSomething } from "../types/typeGuards";
import { nothing, Optional, some } from "../types/typeUtils";

export const pendingAccountToSelectableAccount = (
  pending: GPPendingBankAccount
): SelectableBankAccount => {
  return {
    main: pending.main,
    intermediary: pending.intermediary,
    investmentVehicle: pending.investmentVehicle,
    requestId: isSomething(pending.requestId) ? pending.requestId.value : 0,
    assignments: pending.assignments,
    lastUpdated: pending.submittedDate,
    isApproved: false,
  };
};

export const userAddedToSelectableAccount = (
  userAdded: UserAddedBankAccount
): SelectableBankAccount => {
  return {
    main: userAdded.main,
    intermediary: userAdded.intermediary,
    requestId: 0,
    assignments: userAdded.assignedTo,
    lastUpdated: nothing,
    isApproved: false,
  };
};

export const wssAccountToSelectableAccount = (
  wss: GPBankAccount
): SelectableBankAccount => {
  return {
    main: wss.bankAccount.main,
    intermediary: wss.bankAccount.intermediary,
    investmentVehicle: wss.investmentVehicle,
    requestId: 0,
    assignments: wss.bankAccount.assignments,
    lastUpdated: some(wss.bankAccount.main.lastModified),
    isApproved: true,
  };
};

export const isEligibleContributionAccount = (account: GPBankAccount) => {
  return (
    account.bankAccount.main.currency === "USD" &&
    account.bankAccount.main.country === "US" &&
    account.bankAccount.main.accountType === AccountType.CHECKING &&
    !isSomething(account.bankAccount.intermediary)
  );
};

export function getBankAccountOptions(
  serverData: BankAccountsResponse,
  userAddedAccounts: UserAddedBankAccount[],
  assignment: BankAccountAssignment,
  includePending: boolean
): { [investmentVehicleId: number]: SelectableBankAccount[] } {
  const investmentVehicles = serverData.investmentVehicles.map(
    (iv: GPInvestmentVehicle) => iv.axiomId
  );
  const allOptions: { [investmentVehicleId: number]: SelectableBankAccount[] } =
    {};
  investmentVehicles.forEach((investmentVehicleId: number) => {
    let options = serverData.bankAccounts
      .filter((acc) =>
        assignment === BankAccountAssignment.CONTRIBUTION
          ? isEligibleContributionAccount(acc)
          : true
      )
      .map(wssAccountToSelectableAccount);
    const userAdded = userAddedAccounts.filter(
      (account) =>
        account.assignedTo.includes(assignment) ||
        account.eligibleFor.includes(assignment)
    );

    if (userAdded) {
      options.push(...userAdded.map(userAddedToSelectableAccount));
    }

    if (includePending) {
      const pending = serverData.pendingBankAccounts
        .filter(
          (account: GPPendingBankAccount) =>
            account.investmentVehicle.axiomId === investmentVehicleId
        )
        .find((account: GPPendingBankAccount) =>
          account.assignments.includes(assignment)
        );
      if (pending) options = [pendingAccountToSelectableAccount(pending)];
    }
    allOptions[investmentVehicleId] = options;
  });
  return allOptions;
}

export const customOnBankIdChange = (
  value: string,
  selectedBankIdType: BankIdType
) => {
  if (selectedBankIdType === BankIdType.ABA_ROUTING_NUMBER) {
    // should only be numeric input
    return value.replace(/\D/g, "");
  } else if (selectedBankIdType === BankIdType.SWIFT) {
    // should be alphanumeric input
    return value.replace(/\W/g, "");
  } else if (selectedBankIdType === BankIdType.SORT) {
    // should be numeric or numeric with - separating every 2 digits
    const formattedValue = value.replace(/[^0-9-]/g, "");
    return formattedValue.replace(/(?<!\d)\-(?!\d{2})/g, "");
  } else {
    return value;
  }
};

export const customBankIdValidation = (
  data: string,
  selectedBankIdType: BankIdType
) => {
  if (data.length === 0) {
    return BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD;
  }
  if (selectedBankIdType === BankIdType.ABA_ROUTING_NUMBER) {
    if (data.length !== 9) {
      return BankAccountConstants.MUST_BE_NINE_DIGITS;
    }
    return true;
  } else if (selectedBankIdType === BankIdType.SWIFT) {
    if (data.length < 8 || data.length > 11) {
      return BankAccountConstants.MUST_BE_8_11_ALPHANUMERIC;
    }
    return true;
  } else if (selectedBankIdType === BankIdType.SORT) {
    // checks for 6 digits or format XX-XX-XX
    const regex = /^(?:\d{6}|(?:\d{2}-){2}\d{2})$/;
    if (!regex.test(data)) {
      return BankAccountConstants.MUST_BE_SIX_DIGITS;
    }
    return true;
  } else if (data.length > 150) {
    return BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR;
  }
  return true;
};

export const findActiveAccount = (
  assignment: BankAccountAssignment,
  investmentVehicleId: number | undefined,
  serverResponse: BankAccountsResponse
): Optional<SelectableBankAccount> => {
  const pendingAccount = serverResponse.pendingBankAccounts.find(
    (account) =>
      account.assignments.includes(assignment) &&
      (!investmentVehicleId ||
        account.investmentVehicle.id === investmentVehicleId)
  );
  if (pendingAccount) {
    return some(pendingAccountToSelectableAccount(pendingAccount));
  }
  const wssAccount = serverResponse.bankAccounts.find(
    (account) =>
      account.bankAccount.assignments.includes(assignment) &&
      (!investmentVehicleId ||
        account.investmentVehicle.id === investmentVehicleId)
  );
  if (wssAccount) {
    return some(wssAccountToSelectableAccount(wssAccount));
  }
  return nothing;
};

/*
  helper function to determine which purposes an added account is eligble for
  For each purpose type, it records the reasons(if any) that an account is ineligible for the purpose

    - A contribution account is eligible if 
      - New Account is US checking account
      - New account has no linked account
      - Contributions account is not under treasury review(no pending contribution accounts)
    - A distribution account is elgible if
      - Distributions account is not under treasury review(no pending distribution accounts)
  */
export const determinePurposeIneligibility = (
  userAddedAccount: AddBankAccountState,
  pendingContributionAccountRequestId: Optional<number>,
  pendingDistributionAccountRequestId: Optional<number>,
  usCountryName: string,
  usCurrencyCode: string
): { [x in BankAccountAssignment]: string[] } => {
  const distReasons: string[] = [];
  const conReasons: string[] = [];

  if (isSomething(pendingDistributionAccountRequestId))
    distReasons.push(
      BankAccountTooltips.DISTRIBUTION_UNDER_REVIEW(
        pendingDistributionAccountRequestId.value
      )
    );

  if (isSomething(pendingContributionAccountRequestId)) {
    conReasons.push(
      BankAccountTooltips.CONTRIBUTION_UNDER_REVIEW(
        pendingContributionAccountRequestId.value
      )
    );
  } else {
    if (userAddedAccount.accountType !== AccountType.CHECKING)
      conReasons.push(BankAccountTooltips.ACCOUNT_MUST_BE_CHECKING);
    if (userAddedAccount.country !== usCountryName)
      conReasons.push(BankAccountTooltips.MUST_BE_US);
    if (userAddedAccount.currency !== usCurrencyCode)
      conReasons.push(BankAccountTooltips.MUST_BE_USD);
    if (userAddedAccount.hasIntermediaryAccount)
      conReasons.push(BankAccountTooltips.LINKED_ACC_CANNOT_BE_CONTRIBUTION);
  }

  return {
    [BankAccountAssignment.CONTRIBUTION]: conReasons,
    [BankAccountAssignment.DISTRIBUTION]: distReasons,
    [BankAccountAssignment._]: [],
  };
};

export const getDefaultAddAccountValues = (
  usCountryCode: string,
  usCurrencyCode: string,
  canAutoAssignPurposes: boolean,
  assignmentType: BankAccountAssignment,
  pendingContributionRequestId: Optional<number>,
  pendingDistributionRequestId: Optional<number>
): AddBankAccountState => {
  return {
    bankAccountUniqueId: "",
    accountHolderName: "",
    accountType: AccountType.CHECKING,
    country: usCountryCode,
    currency: usCurrencyCode,
    accountNumberType: AccountNumberType.ACCOUNT_NUMBER,
    accountNumber: "",
    bankIdType: BankIdType.ABA_ROUTING_NUMBER,
    bankId: "",
    confirmAccountId: "",
    bankName: "",
    hasIntermediaryAccount: false,
    intermediaryAccount: {
      bankAccountUniqueId: "",
      accountHolderName: "",
      accountType: AccountType.INTERMEDIARY,
      accountNumberType: AccountNumberType.ACCOUNT_NUMBER,
      accountNumber: "",
      bankIdType: BankIdType.ABA_ROUTING_NUMBER,
      bankId: "",
      confirmAccountId: "",
      country: "",
      currency: "",
      bankName: "",
    },
    canAutoAssignPurposes: canAutoAssignPurposes,
    autoAssign: {
      [BankAccountAssignment.CONTRIBUTION]: {
        isChecked:
          (canAutoAssignPurposes &&
            !isSomething(pendingContributionRequestId)) ||
          assignmentType === BankAccountAssignment.CONTRIBUTION,
        isEligible:
          (canAutoAssignPurposes &&
            !isSomething(pendingContributionRequestId)) ||
          assignmentType === BankAccountAssignment.CONTRIBUTION,
      },
      [BankAccountAssignment.DISTRIBUTION]: {
        isChecked:
          (canAutoAssignPurposes &&
            !isSomething(pendingDistributionRequestId)) ||
          assignmentType === BankAccountAssignment.DISTRIBUTION,
        isEligible:
          (canAutoAssignPurposes &&
            !isSomething(pendingDistributionRequestId)) ||
          assignmentType === BankAccountAssignment.DISTRIBUTION,
      },
      [BankAccountAssignment._]: {
        isChecked: false,
        isEligible: false,
      },
    },
  };
};

export const formatBankAccountForDropdown = (account: IBankAccount): string => {
  const { bankName, accountNumber } = account;
  const bank = (
    bankName.length > 20 ? bankName.substring(0, 20) : bankName
  ).trim();
  return [bank, accountNumber.slice(-4)].join("...");
};

export const hasUnsubmittedChangesContributionForIV = (
  unsubmittedChanges: IBankAccountsStore["unsubmittedChanges"],
  investmentVehicleId?: number
): boolean => {
  return (
    !!investmentVehicleId &&
    unsubmittedChanges[investmentVehicleId] &&
    unsubmittedChanges[investmentVehicleId][BankAccountAssignment.CONTRIBUTION]
  );
};

export const hasUnsubmittedChangesDistributionForIV = (
  unsubmittedChanges: IBankAccountsStore["unsubmittedChanges"],
  investmentVehicleId?: number
): boolean => {
  return (
    !!investmentVehicleId &&
    unsubmittedChanges[investmentVehicleId] &&
    unsubmittedChanges[investmentVehicleId][BankAccountAssignment.DISTRIBUTION]
  );
};

export const getReviewAndSignFormBankAccounts = (
  selectedBankAccounts: IBankAccountsStore["selectedBankAccounts"],
  investmentVehicles: IBankAccountsStore["serverData"]["investmentVehicles"],
  unsubmittedChanges: IBankAccountsStore["unsubmittedChanges"]
): TReviewAndSignFormBankAccounts[] => {
  return Object.entries(selectedBankAccounts).map(
    ([currIVId, bankAccounts]) => {
      const investmentVehicleId = Number(currIVId);
      const investmentVehicle = investmentVehicles.find(
        (iv) => iv.axiomId === investmentVehicleId
      ) as GPInvestmentVehicle;
      const unsubmittedContribution = hasUnsubmittedChangesContributionForIV(
        unsubmittedChanges,
        investmentVehicleId
      );
      const unsubmittedDistribution = hasUnsubmittedChangesDistributionForIV(
        unsubmittedChanges,
        investmentVehicleId
      );
      const IVData: TReviewAndSignFormBankAccounts = {
        contributionDebitAccount: nothing,
        distributionDepositAccount: nothing,
        investmentVehicle,
      };
      if (
        unsubmittedContribution &&
        isSomething(bankAccounts.contributionBankAccount)
      ) {
        IVData.contributionDebitAccount = bankAccounts.contributionBankAccount;
      }
      if (
        unsubmittedDistribution &&
        isSomething(bankAccounts.distributionBankAccount)
      ) {
        IVData.distributionDepositAccount =
          bankAccounts.distributionBankAccount;
      }
      return IVData;
    }
  );
};

export function sortBankAccounts<
  T extends IBankAccount | ViewOrEditBankAccount
>(bankAccounts: T[]) {
  return bankAccounts.sort((a, b) => {
    const accountHolderComparison = a.accountHolderName.localeCompare(
      b.accountHolderName,
      undefined,
      {
        sensitivity: "base",
      }
    );

    if (accountHolderComparison !== 0) {
      return accountHolderComparison;
    }

    const bankNameComparison = a.bankName.localeCompare(b.bankName, undefined, {
      sensitivity: "base",
    });

    if (bankNameComparison !== 0) {
      return bankNameComparison;
    }

    return a.accountNumber.localeCompare(b.accountNumber, undefined, {
      sensitivity: "base",
    });
  });
}

export const formatBankAccountForDialog = (
  bankAccount: Optional<SelectableBankAccount>
): string => {
  if (!isSomething(bankAccount)) return BankAccountConstants.NO_UPDATE;
  const main = bankAccount.value.main;
  const formattedBankAccount = [
    main.bankName,
    " (",
    main.bankId,
    ") ",
    BankAccountConstants.ACCOUNT_ENDING_IN,
    " ",
    main.accountNumber.slice(-4),
  ];
  if (isSomething(bankAccount.value.intermediary)) {
    const intermediary = bankAccount.value.intermediary.value;
    const label =
      intermediary.accountType === AccountType.CORRESPONDENT
        ? BankAccountConstants.CORRESPONDENT
        : BankAccountConstants.INTERMEDIARY;
    formattedBankAccount.push(
      "\n",
      label,
      " ",
      intermediary.bankName,
      " (",
      intermediary.bankId,
      ") ",
      BankAccountConstants.ACCOUNT_ENDING_IN,
      " ",
      intermediary.accountNumber.slice(-4)
    );
  }
  return formattedBankAccount.join("");
};
