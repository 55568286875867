export enum RelativePath {
  HOME = "/",
  LOGIN = "/login",
}

export enum SearchParam {
  CODE = "code",
  ERROR = "error",
  FROM_LOGOUT = "fromLogout",
}
