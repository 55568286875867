import { Info } from "@mui/icons-material";
import { Box, Link, Stack, Typography } from "@mui/material";
import {
  convertElectionWorkflowStateToUpdateSource,
  ElectionsLabels,
  ElectionWorkflowStageId,
  isSomething,
  reqPutElectionWorkflowState,
} from "common";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IBaseStore } from "../../../../redux/store";
import { ElectionDialog } from "../../ElectionWorkflow/Shared/ElectionDialog/ElectionDialog";
import styles from "./ReopenElectionModal.module.scss";

interface IReopenElectionModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReopenElectionModal = (props: IReopenElectionModalProps) => {
  const {
    electionWorkflowStateLocal,
    electionRoundConfiguration,
    electionIVConfiguration,
  } = useSelector((state: IBaseStore) => state.selectedElection);

  const dispatch = useDispatch();

  const handleClickReviseElection = useCallback(() => {
    if (
      isSomething(electionWorkflowStateLocal) &&
      isSomething(electionRoundConfiguration) &&
      isSomething(electionIVConfiguration)
    ) {
      dispatch(
        reqPutElectionWorkflowState({
          ...electionWorkflowStateLocal.value,
          targetState: convertElectionWorkflowStateToUpdateSource({
            ...electionWorkflowStateLocal.value,
            electionRoundConfigurationVersion:
              electionRoundConfiguration.value.version,
            ivConfigurationVersion: electionIVConfiguration.value.version,
            currentStage: ElectionWorkflowStageId.OVERVIEW,
          }),
        })
      );
    }
  }, [
    electionWorkflowStateLocal,
    electionRoundConfiguration,
    electionIVConfiguration,
    dispatch,
  ]);

  const submissionDeadline = useMemo(() => {
    if (isSomething(electionRoundConfiguration)) {
      return (
        electionRoundConfiguration.value.electionSubmissionDeadline ??
        electionRoundConfiguration.value.systemCloseDate
      );
    }
  }, [electionRoundConfiguration]);

  const ModalContent = () => {
    return (
      <Stack id={styles.content}>
        <Stack className={styles.calloutMessage} direction={"row"}>
          <Info className={styles.infoIcon} />
          <Typography className={styles.calloutText}>
            {ElectionsLabels.REVISE_ELECTION_MESSAGE}
          </Typography>
        </Stack>
        <Typography className={styles.otherText}>
          {ElectionsLabels.NOT_ABLE_RESUBMIT_AFTER_DEADLINE}
          <Box component="span" className={styles.mediumUnderline}>
            {ElectionsLabels.SUBMISSION_DEADLINE_MESSAGE(submissionDeadline)}
          </Box>
        </Typography>
        <Typography className={styles.otherText}>
          {ElectionsLabels.PLEASE_CONTACT}
          <Link href={`mailto:${ElectionsLabels.ELECTION_CONTACT_EMAIL}`}>
            {ElectionsLabels.ELECTION_CONTACT_EMAIL}
          </Link>
          {"."}
        </Typography>
      </Stack>
    );
  };

  return (
    <ElectionDialog
      title={ElectionsLabels.DO_YOU_WANT_TO_REVISE}
      content={<ModalContent />}
      handleNext={handleClickReviseElection}
      open={props.open}
      handleClose={() => props.setOpen(false)}
      nextButtonLabel={ElectionsLabels.YES}
      cancelButtonLabel={ElectionsLabels.NO}
    />
  );
};
