/**
 * NavigationTabs Component
 *
 * Renders navigation tabs with optional sub-navigation, dynamically adjusted based on user permissions.
 * Supports both vertical and horizontal layouts.
 *
 * Props:
 * - isVerticalTab (boolean): Determines the orientation of the tabs (vertical or horizontal).
 */

import { Menu, MenuItem, Tab, Tabs } from "@mui/material";
import clsx from "clsx";
import {
  EnvironmentResolver,
  SectionHeader,
  useDoesCurrentPathMatch,
} from "common";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RelativePath } from "../../../constants/Paths";
import { AdminUIStore } from "../../../redux/store";
import styles from "./NavigationTabs.module.scss";

interface INavigationTabsProps {
  isVerticalTab: boolean;
}

interface NavTabData {
  label: string;
  path?: RelativePath;
  subTabs?: { label: string; path: RelativePath }[];
}

/**
 * getAuthorizedTabs Function
 *
 * Determines which tabs to display based on the user's admin status.
 *
 * @param {boolean} isElectionsAdmin - Indicates if the user is an Elections Admin.
 * @returns {NavTabData[]} Array of objects representing the tabs the user is authorized to view.
 */
const getAuthorizedTabs = (
  hasInternalInvestmentAdminEntitlement: boolean,
  isElectionsAdmin: boolean
): NavTabData[] => {
  const navTabsData: NavTabData[] = [];

  if (hasInternalInvestmentAdminEntitlement) {
    navTabsData.push({
      label: SectionHeader.CLIENT_DATA_PAGE,
      path: RelativePath.CLIENT_DATA,
    });
  }

  if (isElectionsAdmin) {
    navTabsData.push({
      label: SectionHeader.SBS_ELECTION_ADMIN_PAGE,
      path: RelativePath.ELECTION_ADMIN,
      subTabs: [
        {
          label: SectionHeader.ELECTION_ADMIN_PAGE,
          path: RelativePath.ELECTION_ADMIN.replace(
            ":electionRoundId",
            EnvironmentResolver.ENV.REACT_APP_ELECTION_ROUND_ID
          ) as RelativePath,
        },
        {
          label: SectionHeader.ELECTION_EDITOR_PAGE,
          path: RelativePath.ELECTION_EDITOR,
        },
      ],
    });
  }

  return navTabsData;
};

export const NavigationTabs = ({ isVerticalTab }: INavigationTabsProps) => {
  const navigate = useNavigate();

  const { hasInternalInvestmentAdminEntitlement, isElectionsAdmin } =
    useSelector((store: AdminUIStore) => store.entitlements);

  const navTabsData = useMemo(
    () =>
      getAuthorizedTabs(
        hasInternalInvestmentAdminEntitlement,
        isElectionsAdmin
      ),
    [hasInternalInvestmentAdminEntitlement, isElectionsAdmin]
  );

  const [selectedIndexTab, setSelectedIndexTab] = useState(0);
  const [subTabsMenuPosition, setSubTabsMenuPosition] =
    useState<null | HTMLElement>(null);
  const isOpen = Boolean(subTabsMenuPosition);
  const doesCurrentPathMatch = useDoesCurrentPathMatch();

  /**
   * handleTabClick Function
   *
   * Manages tab selection, navigation, and sub-menu display positioning.
   *
   * When a tab with sub-tabs is clicked, this function sets the current event target (the clicked tab)
   * as the anchor element. This ensures that the sub-menu is positioned directly below or adjacent to
   * the tab, maintaining a logical and intuitive UI flow.
   *
   * @param {React.MouseEvent<HTMLDivElement>} event - The click event.
   * @param {number} index - The index of the clicked tab.
   */
  const handleTabClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, index: number) => {
      setSelectedIndexTab(index);
      const { path, subTabs } = navTabsData[index];
      if (subTabs?.length) {
        /*
          The event.currentTarget has the position value of where the subTabs Menu has to be rendered.
          Therefore, it is saved in the subTabsMenuPosition variable.
          Then, the Menu component which renders the subTabs uses that position value to set the anchorEl prop.
        */
        setSubTabsMenuPosition(event.currentTarget);
      } else if (path) {
        navigate(path);
      }
    },
    [navTabsData, navigate]
  );

  /**
   * handleMenuClick Function
   *
   * Handles clicks on sub-menu items, navigating to the associated path.
   *
   * @param {number} index - The index of the clicked sub-menu item.
   */
  const handleMenuClick = useCallback(
    (index: number) => {
      const path = navTabsData[selectedIndexTab]?.subTabs?.[index]?.path;
      if (path) {
        navigate(path);
      }
    },
    [navTabsData, selectedIndexTab, navigate]
  );

  /**
   * handleClose Function
   *
   * Closes the sub-menu by resetting the anchor element to null.
   */
  const handleClose = useCallback(() => {
    setSubTabsMenuPosition(null);
  }, []);

  return (
    <>
      <Tabs
        orientation={isVerticalTab ? "vertical" : "horizontal"}
        value={selectedIndexTab}
        aria-label="Navigation Tabs"
        id={styles.navTabs}
        TabIndicatorProps={{
          className: styles.indicator,
        }}
      >
        {navTabsData.map(({ label, path }, index) => (
          <Tab
            className={clsx(
              styles.navTab,
              path &&
                doesCurrentPathMatch(path.split("/")[1]) &&
                styles.navTabSelected
            )}
            key={index}
            label={label}
            onClick={(event) => handleTabClick(event, index)}
          />
        ))}
      </Tabs>
      {navTabsData[selectedIndexTab]?.subTabs && (
        <Menu
          anchorEl={subTabsMenuPosition}
          open={isOpen}
          onClose={handleClose}
        >
          {navTabsData[selectedIndexTab].subTabs?.map((subTab, index) => (
            <MenuItem key={index} onClick={() => handleMenuClick(index)}>
              {subTab.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
