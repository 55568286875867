import { Card, CardHeader, Typography } from "@mui/material";
import React, { useCallback } from "react";

import { DataLoadStatus } from "../../constants/enums";
import { isSomething } from "../../types/typeGuards";
import { Optional } from "../../types/typeUtils";
import { FailedToLoadError } from "../FailedToLoadError/FailedToLoadError";
import { InfoTooltip } from "../InfoTooltip/InfoTooltip";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { NoDataAvailableError } from "../NoDataAvailableError/NoDataAvailableError";
import styles from "./DashboardCard.module.scss";

export interface IDashboardCardContentProps<T> {
  data: T;
  className?: string;
  retryRequest: () => void;
}

interface IDashboardCardProps<T> {
  /** Element to be instantiated with data. */
  cardContentComponent: (props: IDashboardCardContentProps<T>) => JSX.Element;
  /** Class to be applied to content element (optional). */
  cardContentClassName?: string;
  /** ActionCreator that, on dispatch, will request for and store data. */
  retryRequest: () => void;
  /** data to be display */
  data: Optional<T>;
  /** Data loaded status flag, which is flipped upon data storage. */
  dataLoadStatus: DataLoadStatus;
  /** Card title to be displayed on top left (optional). */
  header?: string;
  headerTooltipParagraphs?: string[];
  /**
   * Selector for data as of subHeader (optional).
   * If not provided, then subheader will not be displayed.
   */
  subHeader?: string | undefined;
  headerClassName?: string;
  /** Whether the card should hide due to a condition */
  hide?: boolean;
  /** Whether the card should hide when empty data */
  hideOnEmpty?: boolean;
  /** Additional class name for any styling overrides (optional). */
  className?: string;
  id?: string;
  showHeaderWhenNoData?: boolean;
}

/**
 * Generic component that encapsulates common dashboard card styling (incl. header and subheaer),
 * data fetching upon component initialization, and data loading indicator.
 */
export const DashboardCard = <T,>(props: IDashboardCardProps<T>) => {
  const {
    cardContentComponent: CardContentComponent,
    cardContentClassName: cardContentClass,
    retryRequest,
    data,
    dataLoadStatus,
    header,
    headerTooltipParagraphs,
    hide = false,
    hideOnEmpty = false,
    subHeader = undefined,
    headerClassName,
    className,
    id,
    showHeaderWhenNoData = false,
  } = props;

  const hideCard = hide || (hideOnEmpty && !isSomething(data));

  const CardContent = useCallback(() => {
    switch (dataLoadStatus) {
      case DataLoadStatus.SUCCESSFUL: {
        if (isSomething(data)) {
          return (
            <>
              {header && (
                <CardHeader
                  className={headerClassName}
                  title={
                    <Typography variant="h3">
                      {header}
                      {headerTooltipParagraphs && (
                        <InfoTooltip tooltipContent={headerTooltipParagraphs} />
                      )}
                    </Typography>
                  }
                  subheader={
                    subHeader && (
                      <Typography variant="subtitle1">{subHeader}</Typography>
                    )
                  }
                />
              )}
              <CardContentComponent
                className={cardContentClass}
                data={data.value}
                retryRequest={retryRequest}
              />
            </>
          );
        } else {
          return (
            <>
              {showHeaderWhenNoData && header && (
                <CardHeader
                  className={headerClassName}
                  title={
                    <Typography variant="h3">
                      {header}
                      {headerTooltipParagraphs && (
                        <InfoTooltip tooltipContent={headerTooltipParagraphs} />
                      )}
                    </Typography>
                  }
                  subheader={
                    subHeader && (
                      <Typography variant="subtitle1">{subHeader}</Typography>
                    )
                  }
                />
              )}
              <NoDataAvailableError />
            </>
          );
        }
      }
      case DataLoadStatus.UNSUCCESSFUL: {
        return <FailedToLoadError retryRequest={retryRequest} />;
      }
      case DataLoadStatus.NOT_REQUESTED:
      case DataLoadStatus.LOADING: {
        return <LoadingIndicator />;
      }
      case DataLoadStatus.EMPTY_RESPONSE: {
        return <NoDataAvailableError />;
      }
    }
  }, [
    CardContentComponent,
    cardContentClass,
    data,
    dataLoadStatus,
    header,
    headerClassName,
    headerTooltipParagraphs,
    retryRequest,
    showHeaderWhenNoData,
    subHeader,
  ]);

  return hideCard ? (
    <></>
  ) : (
    <Card className={`${className} ${styles.dashboardCard}`} id={id}>
      <CardContent />
    </Card>
  );
};
