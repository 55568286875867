import { StyleSheet } from "@react-pdf/renderer";

export const signColumn = 6;
export const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    width: 531,
    alignItems: "center",
    fontFamily: "Guardian Sans",
    fontWeight: 500,
    overflowWrap: "break-word",
  },
  signColumn: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    width: signColumn,
    fontSize: 12,
  },
  strategy: {
    width: 120,
    textAlign: "left",
  },
  remainingCommitment: {
    width: 82,
  },
  mandatoryCommitment: {
    width: 64,
  },
  election: {
    width: 88,
  },
  totalCommitment: {
    width: 86,
  },
  forecastedInvestment: {
    width: 78,
  },
});
