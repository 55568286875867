import { FileDownloadOutlined } from "@mui/icons-material";
import { CardHeader, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { CardFooter } from "../../../../components/CardFooter/CardFooter";
import { DataCard } from "../../../../components/DataCard/DataCard";
import { IconButtonWithTooltip } from "../../../../components/IconButtonWithTooltip/IconButtonWithTooltip";
import { InfoTooltip } from "../../../../components/InfoTooltip/InfoTooltip";
import {
  CommitmentsLabels,
  CommitmentsTooltips,
  getAsOfLine,
  SectionHeader,
} from "../../../../constants/LabelAndTooltipConstants";
import { StringConstants } from "../../../../constants/StringConstants";
import { useFetchDatasetIfIdDefined } from "../../../../hooks/dataFetchHooks";
import { useExcelDownload } from "../../../../hooks/useExcelDownload";
import { reqInternalInvestmentData } from "../../../../redux/actions/internalInvestmentActions";
import {
  selectCommitmentsForClient,
  selectFilteredBy,
  selectInternalInvestmentDataLoadStatus,
} from "../../../../redux/selectors";
import { isSomething } from "../../../../types/typeGuards";
import { isInProgress, isUnsuccessful } from "../../../../utils/dataLoadUtils";
import { worksheetGenerators } from "../../../../utils/excelUtils/excelGenerationUtils";
import { ForecastedCapitalCallsGrid } from "./ForecastedCapitalCallsGrid/ForecastedCapitalCallsGrid";

export const ForecastedCapitalCalls = () => {
  const filteredBy = useSelector(selectFilteredBy);
  const internalInvestmentDataLoadStatus = useSelector(
    selectInternalInvestmentDataLoadStatus
  );
  const commitmentData = useSelector(selectCommitmentsForClient);

  const retryFunction = useFetchDatasetIfIdDefined(
    reqInternalInvestmentData,
    filteredBy,
    internalInvestmentDataLoadStatus
  );

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    const fileName = `${SectionHeader.FORECASTED_CAPITAL_CALLS}`;
    await handleExcelDownload(
      worksheetGenerators.ForecastedCapitalCalls,
      fileName
    );
  };

  const forecastedAmountTooltipContent = (
    <>
      {CommitmentsTooltips.FORECASTED_TOOLTIP[0]}
      <ul>
        <li>{CommitmentsTooltips.FORECASTED_TOOLTIP[1]}</li>
        <li>{CommitmentsTooltips.FORECASTED_TOOLTIP[2]}</li>
      </ul>
    </>
  );

  return (
    <DataCard
      failed={isUnsuccessful(internalInvestmentDataLoadStatus)}
      loading={isInProgress(internalInvestmentDataLoadStatus)}
      noData={
        !isSomething(commitmentData) ||
        !isSomething(commitmentData.value.forecastedCapitalCalls) ||
        commitmentData.value.forecastedCapitalCalls.value.capitalCalls
          .length === 0
      }
      hideIfNoData={true}
      onReload={retryFunction}
    >
      {
        // if we have data, we can show the forecasted card contents
        isSomething(commitmentData) &&
          isSomething(commitmentData.value.forecastedCapitalCalls) && (
            <>
              <CardHeader
                title={
                  <Stack direction={"row"}>
                    <Typography variant="h3">
                      {SectionHeader.FORECASTED_CAPITAL_CALLS}
                    </Typography>
                    <InfoTooltip
                      tooltipContent={forecastedAmountTooltipContent}
                    />
                  </Stack>
                }
                subheader={
                  <Typography variant="subtitle1">
                    {getAsOfLine(
                      commitmentData.value.forecastedCapitalCalls.value.asOfDate
                    )}
                  </Typography>
                }
                action={
                  <IconButtonWithTooltip
                    onClickFunction={handleDownloadClick}
                    buttonIcon={FileDownloadOutlined}
                    hoverText={StringConstants.DOWNLOAD}
                  />
                }
              />
              <Grid container flex="column">
                <ForecastedCapitalCallsGrid
                  forecastedCapitalCalls={
                    commitmentData.value.forecastedCapitalCalls.value
                  }
                />
              </Grid>

              <CardFooter
                footerLines={[CommitmentsLabels.FORECASTED_FOOTNOTE]}
              />
            </>
          )
      }
    </DataCard>
  );
};
