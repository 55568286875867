import { createReducer } from "@reduxjs/toolkit";

import { DataLoadStatus } from "../../constants/enums";
import { IUserAgreementsStore } from "../../types/storeTypes";
import {
  errUserAgreements,
  putUserAgreement,
  recvUserAgreements,
  reqUserAgreements,
} from "../actions/userAgreementsActions";

const defaultState: IUserAgreementsStore = {
  userAgreements: [],
  userAgreementsLoadStatus: DataLoadStatus.NOT_REQUESTED,
};

export const userAgreementsReducer = createReducer<IUserAgreementsStore>(
  defaultState,
  (builder) => {
    builder.addCase(reqUserAgreements, (state) => {
      state.userAgreementsLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvUserAgreements, (state, action) => {
      state.userAgreements = action.payload;
      if (action.payload.length > 0) {
        state.userAgreementsLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.userAgreementsLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errUserAgreements, (state) => {
      state.userAgreementsLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    });
    builder.addCase(putUserAgreement, (state) => {
      state.userAgreementsLoadStatus = DataLoadStatus.LOADING;
    });
  }
);
