import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { useSelector } from "react-redux";

import { IAlert } from "../../types/storeTypes";
import styles from "./Alert.module.scss";

/**
 * Implementation assumes that only one alert can be shown at a time
 * Would need to be changed to support multiple simultaneous alerts, if/when required
 */

interface IAlertProps<T> {
  alertSelector: (store: T) => IAlert;
  handleClose: () => void;
}

export const Alert = <T,>(props: IAlertProps<T>) => {
  const { alertSelector } = props;
  const alert = useSelector(alertSelector);

  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={
        alert.hideDuration === undefined ? 6000 : alert.hideDuration
      }
      onClose={props.handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <MuiAlert
        className={styles.alertToast}
        onClose={props.handleClose}
        severity={alert?.severity}
      >
        {alert?.message}
      </MuiAlert>
    </Snackbar>
  );
};
