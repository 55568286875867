import {
  Alert,
  AlertTitle,
  Button,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  EnvironmentResolver,
  ErrorStringConstants,
  LoadingIndicator,
  StringConstants,
  useLogin,
  useRefresh,
} from "common";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";

import styles from "./Login.module.scss";

export const AuthCard = () => {
  const auth = useAuth();
  const [email, setEmail] = useState<string>("");

  const handleLogin = () =>
    auth.signinRedirect({
      extraQueryParams: {
        login_hint: email,
        identity_provider: StringConstants.BLACKSTONE_IDP_NAME, // we know only BX employees access the admin site
      },
    });

  const {
    isAuthInProgress,
    isLogout,
    initiateSeamlessSSO,
    logoutUser,
    navigateAwayFromLogin,
  } = useLogin();

  const initiateRefresh = useRefresh();

  useEffect(() => {
    if (isLogout && auth.isAuthenticated) {
      logoutUser();
    } else if (auth.isAuthenticated) {
      navigateAwayFromLogin();
    } else if (auth.user?.refresh_token) {
      initiateRefresh();
    } else {
      initiateSeamlessSSO();
    }
  }, [
    auth.isAuthenticated,
    auth.user?.refresh_token,
    initiateRefresh,
    initiateSeamlessSSO,
    isLogout,
    logoutUser,
    navigateAwayFromLogin,
  ]);

  if (isAuthInProgress) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {auth.error && (
        <Alert className={styles.alert} severity="error">
          <AlertTitle className={styles.alertTitle}>
            {ErrorStringConstants.ERROR}
          </AlertTitle>
          {auth.error.message}
        </Alert>
      )}
      <Box
        className={styles.loginForm}
        component="form"
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <TextField
          autoComplete={"off"}
          className={styles.loginInput}
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <Button className={styles.loginButton} type="submit">
          {StringConstants.LOGIN}
        </Button>
      </Box>
      <Box
        className={styles.footerLinks}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Link
          className={styles.linkForm}
          href={EnvironmentResolver.ENV.LOGIN_HELP_LINK_HREF}
        >
          {StringConstants.HELP}
        </Link>
        <Typography className={styles.splitter}> | </Typography>
        <Link
          className={styles.linkForm}
          href={"https://www.blackstone.com/"}
          target="_blank"
        >
          {StringConstants.BLACKSTONE_HOME}
        </Link>
        <Typography className={styles.splitter}> | </Typography>
        <Link
          className={styles.linkForm}
          target="_blank"
          href="https://www.blackstone.com/privacy#onlinePrivacyNotice"
        >
          {StringConstants.PRIVACY_POLICY}
        </Link>
      </Box>
    </>
  );
};
