import { FileDownloadOutlined } from "@mui/icons-material";
import {
  ErrorStringConstants,
  getElectionRoundConfigurationData,
  IconButtonWithTooltip,
  IElectionsForElectionRoundRow,
  openAlert,
  StringConstants,
  useExcelDownload,
  worksheetGenerators,
} from "common";
import React from "react";
import { useDispatch } from "react-redux";
import sanitize from "sanitize-filename";

import styles from "./ExportElectorStatusButton.module.scss";

interface IExportElectorStatusButtonProps {
  id: string | undefined;
  className?: string;
  disabled: boolean;
  data: IElectionsForElectionRoundRow[];
}

const ExportElectorStatusButton = ({
  id,
  className,
  disabled,
  data,
}: IExportElectorStatusButtonProps) => {
  const dispatch = useDispatch();
  const handleExcelDownload = useExcelDownload();

  const handleClick = async () => {
    if (!id) return;

    const electionRoundResp = await getElectionRoundConfigurationData(id).then(
      (r) => {
        return r;
      },
      () => {
        return undefined;
      }
    );

    if (electionRoundResp === undefined) {
      dispatch(
        openAlert({
          severity: "error",
          message: ErrorStringConstants.NO_ELECTIONS,
        })
      );
      return;
    }

    const generator = worksheetGenerators.ElectorStatus;
    const fileName = `ElectionStatus_${sanitize(electionRoundResp.name)}`;
    await handleExcelDownload(generator, fileName, { electorResponse: data });
  };

  return (
    <IconButtonWithTooltip
      onClickFunction={handleClick}
      buttonIcon={FileDownloadOutlined}
      hoverText={StringConstants.DOWNLOAD}
      disabled={disabled}
      className={`${styles.exportButton} ${className}`}
    />
  );
};

export default ExportElectorStatusButton;
