import {
  EntitySelectLabels,
  getEntityLabel,
  IEntity,
  MultiSelect,
} from "common";
import React from "react";

interface IEntitySelectorProps {
  options: IEntity[];
  selection: IEntity[];
  onChange: (value: IEntity[]) => void;
}

export const EntitySelector = ({
  options,
  selection,
  onChange,
}: IEntitySelectorProps) => {
  const ALL_VALUES_OPTION = {
    oId: -1,
    name: EntitySelectLabels.ALL,
  };
  const dropdownOptions: IEntity[] = [...options, ALL_VALUES_OPTION];

  const getId = (option: IEntity): number => {
    return option.oId;
  };

  const getStringToSearch = (option: IEntity): string => {
    return `${getEntityLabel(option)} ${option.oId}`;
  };

  const onChangeEvent = (newValuesSelected: IEntity[]) => {
    let newValuesSelectedCopy = [...newValuesSelected];
    if (newValuesSelectedCopy.find((x) => x.oId === ALL_VALUES_OPTION.oId)) {
      // if "All" was checked and something else is checked, uncheck all
      if (selection.length === 0) {
        newValuesSelectedCopy = newValuesSelectedCopy.filter(
          (value) => value.oId !== ALL_VALUES_OPTION.oId
        );
        // if "All" gets checked, clear values so that everything else is unchecked
      } else {
        newValuesSelectedCopy = [];
      }
    }
    onChange(newValuesSelectedCopy);
  };

  return (
    <MultiSelect
      options={dropdownOptions}
      value={selection.length === 0 ? [ALL_VALUES_OPTION] : selection}
      disabled={false}
      onChange={onChangeEvent}
      getStringToSearch={getStringToSearch}
      getLabel={getEntityLabel}
      getId={getId}
    />
  );
};
