import { Grid, Stack } from "@mui/material";
import clsx from "clsx";
import {
  AccountNumberType,
  AccountNumberTypeDisplays,
  AccountType,
  AddBankAccountState,
  BankAccountConstants,
  BankAccountDialogType,
  BankAccountTooltips,
  BankIdType,
  BankIdTypeDisplays,
  customBankIdValidation,
  customOnBankIdChange,
} from "common";
import React, { useCallback, useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import styles from "../BankAccountForm.module.scss";
import { DropdownInput } from "../DropdownInput/DropdownInput";
import { AccountIdTypeSampleImage } from "../Image/AccountIdTypeSampleImage";
import { AccountTypeRadioButtonInput } from "../RadioButtonInput/AccountTypeRadioButtonInput";
import { TextFieldInput } from "../TextFieldInput/TextFieldInput";

type IntermediaryAccountFormProps = {
  form: UseFormReturn<AddBankAccountState>;
  isCollapsed: boolean;
  bankAccountDialogType: BankAccountDialogType;
};

function IntermediaryAccountForm({
  form,
  isCollapsed,
  bankAccountDialogType,
}: IntermediaryAccountFormProps) {
  const selectedBankIdType: BankIdType = form.watch(
    "intermediaryAccount.bankIdType"
  );

  const checkIntermediaryRequirement = (value: string) => {
    if (!value && form.getValues().hasIntermediaryAccount) {
      return BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD;
    }
    return true;
  };

  const bankIdOnChange = useCallback(
    (value: string) => {
      return customOnBankIdChange(value, selectedBankIdType);
    },
    [selectedBankIdType]
  );

  const bankIdValidation = useCallback(
    (data: string) => {
      return form.getValues().hasIntermediaryAccount
        ? customBankIdValidation(data, selectedBankIdType)
        : true;
    },
    [selectedBankIdType, form]
  );

  useEffect(() => {
    if (isCollapsed) {
      form.resetField("intermediaryAccount");
    }
  }, [isCollapsed, form]);

  return (
    <Grid
      container
      className={clsx({
        [styles.FormColumnsContainer]: true,
        [styles.HideIntermediaryAccountForm]: isCollapsed,
      })}
    >
      {!isCollapsed && (
        <>
          <Stack className={styles.fieldsContainer}>
            <Stack className={styles.inputRow}>
              <Stack className={styles.rowItems}>
                <Controller
                  control={form.control}
                  name={"intermediaryAccount.accountHolderName"}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldInput
                      value={field.value}
                      field={field}
                      label={BankAccountConstants.ACCOUNT_HOLDER}
                      placeHolderText={
                        BankAccountConstants.ACCOUNT_HOLDER_PLACEHOLDER
                      }
                      triggerOnBlur={() => {
                        form.trigger("intermediaryAccount.accountHolderName");
                      }}
                      tooltip={BankAccountTooltips.ACCOUNT_HOLDER}
                      error={error}
                      disabled={
                        bankAccountDialogType === BankAccountDialogType.VIEW
                      }
                    />
                  )}
                  rules={{
                    validate: checkIntermediaryRequirement,
                    maxLength: {
                      value: 150,
                      message: BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                    },
                  }}
                />
              </Stack>
              <Stack className={styles.rowItems}>
                <Controller
                  control={form.control}
                  name={"intermediaryAccount.accountType"}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <AccountTypeRadioButtonInput
                      value={value}
                      onChange={onChange}
                      disabled={
                        bankAccountDialogType === BankAccountDialogType.VIEW
                      }
                      label={BankAccountConstants.ACCOUNT_TYPE}
                      tooltip={BankAccountTooltips.INTERMEDIARY_ACCOUNT_TYPE}
                      options={[
                        AccountType.INTERMEDIARY,
                        AccountType.CORRESPONDENT,
                      ]}
                      error={error}
                    />
                  )}
                  rules={{
                    validate: checkIntermediaryRequirement,
                  }}
                />
              </Stack>
            </Stack>
            <Stack className={styles.inputRow}>
              <Stack className={styles.rowItems}>
                <Controller
                  control={form.control}
                  name={"intermediaryAccount.accountNumberType"}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DropdownInput
                      value={value}
                      onChange={onChange}
                      label={BankAccountConstants.ACCOUNT_ID_TYPE}
                      options={Object.values(AccountNumberType)}
                      tooltip={<AccountIdTypeSampleImage />}
                      displayValueFunction={(value: string) => {
                        return AccountNumberTypeDisplays[
                          value as AccountNumberType
                        ];
                      }}
                      disabled={
                        bankAccountDialogType === BankAccountDialogType.VIEW
                      }
                      error={error}
                    />
                  )}
                  rules={{
                    validate: checkIntermediaryRequirement,
                  }}
                />
              </Stack>
              <Stack className={styles.rowItems}>
                <Controller
                  control={form.control}
                  name={"intermediaryAccount.accountNumber"}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldInput
                      value={field.value}
                      field={field}
                      label={BankAccountConstants.ACCOUNT_ID_NUMBER}
                      placeHolderText={
                        BankAccountConstants.ACCOUNT_ID_NUMBER_PLACEHOLDER
                      }
                      triggerOnBlur={() => {
                        form.trigger("intermediaryAccount.accountNumber");
                      }}
                      error={error}
                      shouldObfuscate={true}
                      disabled={
                        bankAccountDialogType === BankAccountDialogType.VIEW
                      }
                    />
                  )}
                  rules={{
                    validate: checkIntermediaryRequirement,
                    maxLength: {
                      value: 150,
                      message: BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                    },
                  }}
                />
              </Stack>
            </Stack>
            <Stack className={styles.inputRow}>
              <Controller
                control={form.control}
                name={"intermediaryAccount.confirmAccountId"}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldInput
                    value={field.value}
                    field={field}
                    label={BankAccountConstants.CONFIRM_ACCOUNT_ID}
                    placeHolderText={
                      BankAccountConstants.ACCOUNT_ID_NUMBER_PLACEHOLDER
                    }
                    triggerOnBlur={() => {
                      form.trigger("intermediaryAccount.confirmAccountId");
                    }}
                    error={error}
                    shouldObfuscate={true}
                    disabled={
                      bankAccountDialogType === BankAccountDialogType.VIEW
                    }
                  />
                )}
                rules={{
                  validate: (value: string) => {
                    const addedAccount: AddBankAccountState = form.getValues();
                    if (addedAccount.hasIntermediaryAccount) {
                      if (!value) {
                        return BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD;
                      }
                      if (
                        value !== addedAccount.intermediaryAccount.accountNumber
                      ) {
                        return BankAccountConstants.MUST_MATCH_ACCOUNT_ID;
                      }
                    }
                    return true;
                  },
                  maxLength: {
                    value: 150,
                    message: BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                  },
                }}
              />
            </Stack>
          </Stack>
          <Stack className={styles.fieldsContainer}>
            <Stack className={styles.inputRow}>
              <Stack className={styles.rowItems}>
                <Controller
                  control={form.control}
                  name={"intermediaryAccount.bankIdType"}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DropdownInput
                      value={value}
                      onChange={onChange}
                      label={BankAccountConstants.BANK_ID_TYPE}
                      options={Object.values(BankIdType)}
                      tooltip={<AccountIdTypeSampleImage />}
                      displayValueFunction={(value: string) => {
                        return BankIdTypeDisplays[value as BankIdType];
                      }}
                      disabled={
                        bankAccountDialogType === BankAccountDialogType.VIEW
                      }
                      error={error}
                    />
                  )}
                  rules={{
                    validate: checkIntermediaryRequirement,
                  }}
                />
              </Stack>
              <Stack className={styles.rowItems}>
                <Controller
                  control={form.control}
                  name={"intermediaryAccount.bankId"}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldInput
                      value={field.value}
                      field={field}
                      label={BankAccountConstants.BANK_ID_NUMBER}
                      placeHolderText={
                        BankAccountConstants.BANK_ID_NUMBER_PLACEHOLDER
                      }
                      triggerOnBlur={() => {
                        form.trigger("intermediaryAccount.bankId");
                      }}
                      customHandleInputChange={bankIdOnChange}
                      error={error}
                      disabled={
                        bankAccountDialogType === BankAccountDialogType.VIEW
                      }
                    />
                  )}
                  rules={{
                    validate: (data: string) => {
                      return bankIdValidation(data);
                    },
                  }}
                />
              </Stack>
            </Stack>
            <Stack className={styles.inputRow}>
              <Controller
                control={form.control}
                name={"intermediaryAccount.bankName"}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldInput
                    value={field.value}
                    field={field}
                    label={BankAccountConstants.BANK_NAME}
                    placeHolderText={BankAccountConstants.ENTER_BANK_NAME}
                    triggerOnBlur={() => {
                      form.trigger("intermediaryAccount.bankName");
                    }}
                    error={error}
                    disabled={
                      bankAccountDialogType === BankAccountDialogType.VIEW
                    }
                  />
                )}
                rules={{
                  validate: checkIntermediaryRequirement,
                  maxLength: {
                    value: 150,
                    message: BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                  },
                }}
              />
            </Stack>
          </Stack>
        </>
      )}
    </Grid>
  );
}

export default IntermediaryAccountForm;
