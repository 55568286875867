import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import DataGrid, {
  DataGridColumnData,
} from "../../../../components/DataGrid/DataGrid";
import { BankAccountConstants } from "../../../../constants/BankAccountConstants";
import {
  BankAccountDocument,
  TReviewAndSignFormBankAccounts,
} from "../../../../types/bankAccountDataTypes";
import { isSomething } from "../../../../types/typeGuards";
import { formatBankAccountForDialog } from "../../../../utils/bankAccountUtils";
import { listNames } from "../../../../utils/formatters";
import { ESignDisclosureBox } from "./ESignDisclosureBox/ESignDisclosureBox";
import { MultiFileUpload } from "./MultiFileUpload/MultiFileUpload";
import styles from "./ReviewAndSignForm.module.scss";
import { TextFieldInput } from "./TextFieldInput/TextFieldInput";

export interface ReviewAndSignState {
  signature: string;
  signatureInMyCapacityAs: string;
  phoneNumber: string;
  agreesToTerms: boolean;
}

export interface ReviewAndSignFormState {
  reviewAndSign: ReviewAndSignState;
}

interface ReviewAndSignFormProps {
  reviewAndSignForm: UseFormReturn<ReviewAndSignFormState>;
  isOrganization: boolean;
  bankAccounts: TReviewAndSignFormBankAccounts[];
  documents: BankAccountDocument[];
  setDocuments: (docs: BankAccountDocument[]) => void;
}

export const ReviewAndSignForm = ({
  reviewAndSignForm,
  isOrganization,
  bankAccounts,
  documents,
  setDocuments,
}: ReviewAndSignFormProps) => {
  const { register, control } = reviewAndSignForm;

  const columnData = useMemo(() => {
    const cols: DataGridColumnData[] = [{ title: "Purpose", maxWidth: 100 }];
    if (bankAccounts.length >= 2) {
      cols.push({ title: "Investment Vehicle", minWidth: 160 });
    }
    return cols.concat({ title: "Bank Account" });
  }, [bankAccounts.length]);

  const rowData = useMemo(() => {
    const shouldIncludeInvestmentVehicle = columnData.length === 3;
    const rows: string[][] = [];
    const ivNames: string[] = [];

    bankAccounts.forEach((IVData) => {
      // Skip if no changes to IV
      if (
        !isSomething(IVData.contributionDebitAccount) &&
        !isSomething(IVData.distributionDepositAccount)
      ) {
        return;
      }

      rows.push([
        "Contribution",
        ...(shouldIncludeInvestmentVehicle
          ? [IVData.investmentVehicle.name]
          : []),
        formatBankAccountForDialog(IVData.contributionDebitAccount),
      ]);

      rows.push([
        "Distribution",
        ...(shouldIncludeInvestmentVehicle
          ? [IVData.investmentVehicle.name]
          : []),
        formatBankAccountForDialog(IVData.distributionDepositAccount),
      ]);

      ivNames.push(IVData.investmentVehicle.name);
    });
    if (shouldIncludeInvestmentVehicle) {
      const sortedRows = rows.sort((a, b) => a[1].localeCompare(b[1]));
      return { rows: sortedRows, ivNames };
    }
    return {rows, ivNames};
  }, [columnData.length, bankAccounts]);

  const ivNames = [...new Set(rowData.ivNames)];

  return (
    <FormControl className={styles.reviewAndSignForm}>
      <Controller
        control={control}
        name={"reviewAndSign"}
        render={({ field }) => (
          <Grid container className={styles.container}>
            <Grid item>
              <Typography variant="body1">
                {BankAccountConstants.REVIEW_AND_SIGN_FIRST_PARAGRAPH}
              </Typography>
            </Grid>
            <DataGrid
              className={styles.bankAccountsInfo}
              columnData={columnData}
              rowData={rowData.rows}
            />
            <Grid item>
              <Typography variant="body1">
                {BankAccountConstants.REVIEW_AND_SIGN_SECOND_PARAGRAPH}
              </Typography>
            </Grid>
            {isOrganization && (
              <Typography variant="body1">
                {BankAccountConstants.SIGN_AUTHORIZATION_ON_BEHALF_OF}
                {listNames(ivNames)}
                {"."}
              </Typography>
            )}
            <Grid item>
              <FormControlLabel
                className={styles.termsAndConditionsCheckbox}
                control={
                  <Checkbox
                    {...register("reviewAndSign.agreesToTerms", {
                      required: true,
                    })}
                    checked={field.value.agreesToTerms}
                    onChange={(e) => {
                      field.onChange({
                        ...field.value,
                        agreesToTerms: e.currentTarget.checked,
                      });
                    }}
                  />
                }
                label={BankAccountConstants.TERMS_AND_CONDITIONS_CHECKBOX}
              />
            </Grid>
            <Grid item>
              <ESignDisclosureBox />
            </Grid>
            <Grid item>
              <Stack className={styles.signature}>
                <Typography variant="body2">
                  {
                    BankAccountConstants.TERMS_AND_CONDITIONS_SIGNATURE_TEXT_LINE
                  }
                </Typography>
                <TextFieldInput
                  register={register}
                  id={styles.signatureField}
                  field={field}
                  attributeName="signature"
                  label={BankAccountConstants.E_SIGNATURE}
                  placeHolderText={BankAccountConstants.SIGN_HERE}
                  required
                />
                {isOrganization && (
                  <TextFieldInput
                    register={register}
                    field={field}
                    attributeName="signatureInMyCapacityAs"
                    label={BankAccountConstants.SIGNING_IN_MY_CAPACITY_AS}
                    placeHolderText={
                      BankAccountConstants.SIGNING_IN_MY_CAPACITY_AS_PLACEHOLDER
                    }
                    required
                  />
                )}
                <TextFieldInput
                  register={register}
                  field={field}
                  attributeName="phoneNumber"
                  label={BankAccountConstants.PHONE}
                  placeHolderText={BankAccountConstants.PHONE_PLACEHOLDER}
                  required
                />
              </Stack>
            </Grid>
            <Grid item className={styles.documents}>
              <Typography className={styles.header}>
                {BankAccountConstants.SUPPORTING_DOCUMENTS}
              </Typography>
              <Typography variant="body1">
                {BankAccountConstants.PLEASE_ATTACH_SUPPORTING_DOCS}
              </Typography>
              <MultiFileUpload files={documents} setFiles={setDocuments} />
            </Grid>
          </Grid>
        )}
      />
    </FormControl>
  );
};
