import { ColDef } from "ag-grid-community";
import {
  DataLoadStatus,
  dateAndTimeFormatterWithEmpty,
  ElectionsLabels,
  ElectorViewLabels,
  formatDateAndTimeMMMDDYYYY,
  GridHeaderWithFilterAndSort,
  IElectionRound,
  IElectionsForElectionRound,
  IElectionsForElectionRoundRow,
  ISortOptions,
  isSomething,
  LinkCellRenderer,
  nothing,
  openAlert,
  Optional,
  reopenWorkflowState,
  reqElectionRoundConfiguration,
  reqElectionsForElectionRound,
  some,
  SortDirection,
  updateElectorsGridPage,
  updateElectorsGridSearchTerm,
  useDownloadElectionAgreement,
  useFetchDatasetIfIdDefined,
} from "common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RelativePath } from "../../../constants/Paths";
import { AdminUIStore } from "../../../redux/store";
import {
  ElectorViewGridColumn,
  filterElections,
  getActiveFiltersCount,
  searchElections,
  sortElections,
  sortElectionsForElectionRound,
} from "../../../utils/electorsGridUtils";
import CurrentStageChip from "../ElectionAdminPage/CurrentStageChip";
import { PermissionedUsersRow } from "../ElectionAdminPage/ElectorViewGrid/PermissionedUsersRow/PermissionedUsersRow";
import { ReopenButton } from "../ElectionAdminPage/ElectorViewGrid/ReopenButton/ReopenButton";

const DEFAULT_SORTING = {
  sortBy: ElectorViewGridColumn.name,
  orderBy: SortDirection.DESC,
};

export const useElectorViewGrid = () => {
  const dispatch = useDispatch();
  const { electionRoundId } = useParams();
  const { columnDefs, currentSort } =
    useActiveElectionGridDefinition(electionRoundId);
  const {
    allItems,
    currentPageItems,
    totalRows,
    totalFilters,
    pageSize,
    page,
    onSearch,
  } = useActiveElectionRows(currentSort);

  const { electionsForElectionRoundLoadStatus, currentElectionRoundId } =
    useSelector((state: AdminUIStore) => state.elections);

  const { electionRoundConfigurationLoadStatus } = useSelector(
    (state: AdminUIStore) => state.selectedElection
  );

  // This effect is to check when the page is loaded if the current data is for the same electionRoundId that the ElectionRoundId in the query param
  useEffect(() => {
    if (electionsForElectionRoundLoadStatus !== DataLoadStatus.SUCCESSFUL) {
      return;
    }

    if (electionRoundConfigurationLoadStatus !== DataLoadStatus.SUCCESSFUL) {
      return;
    }

    if (
      electionRoundId &&
      isSomething(currentElectionRoundId) &&
      currentElectionRoundId.value !== electionRoundId
    ) {
      dispatch(reqElectionRoundConfiguration({ electionRoundId }));
      dispatch(reqElectionsForElectionRound({ electionRoundId }));
    }
  }, [
    electionRoundId,
    electionsForElectionRoundLoadStatus,
    dispatch,
    currentElectionRoundId,
    electionRoundConfigurationLoadStatus,
  ]);

  const electionRound: Optional<IElectionRound> = electionRoundId
    ? some({ electionRoundId })
    : nothing;

  useFetchDatasetIfIdDefined(
    reqElectionsForElectionRound,
    electionRound,
    electionsForElectionRoundLoadStatus
  );

  useFetchDatasetIfIdDefined(
    reqElectionRoundConfiguration,
    electionRound,
    electionRoundConfigurationLoadStatus
  );

  return {
    electionRoundId,
    columnDefs,
    allItems,
    currentPageItems,
    totalRows,
    totalFilters,
    pageSize,
    page,
    onSearch,
  };
};

export const useActiveElectionGridDefinition = (
  electionRoundId: string | undefined
) => {
  const [currentSort, setCurrentSort] = useState<
    Optional<ISortOptions<ElectorViewGridColumn>>
  >(some(DEFAULT_SORTING));

  const { electorsGridOptions } = useSelector(
    (state: AdminUIStore) => state.elections
  );
  const handleGetLink = useCallback(
    (data: IElectionsForElectionRoundRow) => {
      if (electionRoundId && data.mdmInvestmentVehicleId) {
        const path = RelativePath.ELECTION.replace(
          ":electionRoundId",
          electionRoundId
        ).replace(
          ":mdmInvestmentVehicleId",
          data.mdmInvestmentVehicleId.toString()
        );

        return path;
      }

      return undefined;
    },
    [electionRoundId]
  );

  const downloadElectionAgreement = useDownloadElectionAgreement();

  const downloadPDF = useCallback(
    async (data: IElectionsForElectionRoundRow) => {
      if (electionRoundId && data.investmentVehicleId) {
        await downloadElectionAgreement(
          electionRoundId,
          data.investmentVehicleId,
          false
        );
      }
    },
    [electionRoundId, downloadElectionAgreement]
  );

  const handleSort = (
    newSortOptions: Optional<ISortOptions<ElectorViewGridColumn>>
  ) => {
    setCurrentSort(newSortOptions);
  };

  const { currentStage, wasReopened, wasSubmitted } =
    electorsGridOptions.filters;
  const columnDefs: ColDef<IElectionsForElectionRoundRow>[] = useMemo(
    () => [
      {
        field: "mdmInvestmentVehicleId",
        headerName: ElectorViewLabels.INVESTMENT_VEHICLE_ID,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: false,
          fieldToSortBy: ElectorViewGridColumn.mdmInvestmentVehicleId,
          handleSort: handleSort,
          currentSortOptions: currentSort,
        },
      },
      {
        field: "name",
        headerName: ElectorViewLabels.INVESTMENT_VEHICLE_NAME,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: false,
          fieldToSortBy: ElectorViewGridColumn.name,
          handleSort: handleSort,
          currentSortOptions: currentSort,
        },
        cellRenderer: (params: {
          value: string;
          data: IElectionsForElectionRoundRow;
        }) => (
          <LinkCellRenderer
            value={params.value}
            data={params.data}
            getLink={handleGetLink}
            target="_blank"
          />
        ),
      },
      {
        field: "currentStage",
        headerName: ElectorViewLabels.CURRENT_STEP,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon:
            isSomething(currentStage) && currentStage.value.length > 0,
          fieldToSortBy: ElectorViewGridColumn.currentStage,
          handleSort: handleSort,
          currentSortOptions: currentSort,
        },
        cellRenderer: (params: {
          value: number;
          data: IElectionsForElectionRoundRow;
        }) => <CurrentStageChip activeStageId={params.value} />,
      },
      {
        field: "submissionDateTime",
        headerName: ElectorViewLabels.SUBMITTED_DATE,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: isSomething(wasSubmitted),
          fieldToSortBy: ElectorViewGridColumn.submissionDateTime,
          handleSort: handleSort,
          currentSortOptions: currentSort,
        },
        cellRenderer: (params: {
          value: string;
          data: IElectionsForElectionRoundRow;
        }) => {
          if (!params.data || !params.data.submissionDateTime) return;

          const valueFormatted = formatDateAndTimeMMMDDYYYY(
            params.data.submissionDateTime
          );

          return !!params.data.submissionDateTime ? (
            <LinkCellRenderer
              value={valueFormatted}
              data={params.data}
              onClick={downloadPDF}
            />
          ) : (
            valueFormatted
          );
        },
      },
      {
        field: "reopenedDate",
        headerName: ElectorViewLabels.REOPENED_DATE,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: isSomething(wasReopened),
          fieldToSortBy: ElectorViewGridColumn.reopenedDate,
          handleSort: handleSort,
          currentSortOptions: currentSort,
        },
        valueFormatter: dateAndTimeFormatterWithEmpty,
      },
      {
        field: "permissionedUserNames",
        headerName: ElectorViewLabels.PERMISSIONED_USERS,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: false,
        },
        valueFormatter: () => "", //It is needed because the field is an object, otherwise we got a warning
        cellRenderer: (params: {
          value: string;
          data: IElectionsForElectionRoundRow;
        }) => (
          <PermissionedUsersRow
            permissionedUserNames={params.data.permissionedUserNames}
            key={params.data.investmentVehicleId}
          />
        ),
      },
      {
        headerName: ElectorViewLabels.REOPEN,
        headerComponent: GridHeaderWithFilterAndSort,
        headerComponentParams: {
          showFilterIcon: false,
        },
        valueFormatter: () => "", //It is needed because the field is an object, otherwise we got a warning
        cellRenderer: (params: {
          value: string;
          data: IElectionsForElectionRoundRow;
        }) => (
          <ReopenButton
            election={params.data}
            key={params.data.investmentVehicleId}
          />
        ),
      },
    ],
    [
      currentSort,
      handleGetLink,
      downloadPDF,
      currentStage,
      wasReopened,
      wasSubmitted,
    ]
  );

  return { columnDefs, currentSort };
};

export const useActiveElectionRows = (
  currentSort: Optional<ISortOptions<ElectorViewGridColumn>>
) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<{
    allItems: IElectionsForElectionRoundRow[];
    items: IElectionsForElectionRoundRow[];
    totalRows: number;
    totalFilters: number;
  }>({ allItems: [], items: [], totalRows: 0, totalFilters: 0 });

  const { electionsForElectionRound, electorsGridOptions } = useSelector(
    (state: AdminUIStore) => state.elections
  );

  const { page, pageSize, searchTerm, filters } = electorsGridOptions;

  const getLatestDate = (dates: Date[]): Date | undefined => {
    if (dates.length === 0) {
      return undefined;
    }

    return dates
      .map((date) => new Date(date))
      .reduce((latestDate, currentDate) => {
        return latestDate > currentDate ? latestDate : currentDate;
      });
  };

  const onSearch = useCallback(
    (term: Optional<string>) => {
      dispatch(updateElectorsGridSearchTerm(term));
      // send user back to page 1 if they search
      dispatch(updateElectorsGridPage(1));
    },
    [dispatch]
  );

  // This effect set the current Page on initialLoad
  useEffect(() => {
    const mappedElections = electionsForElectionRound.map(
      (mdmIV: IElectionsForElectionRound): IElectionsForElectionRoundRow => ({
        investmentVehicleId: mdmIV.investmentVehicle.investmentVehicleId,
        mdmInvestmentVehicleId: mdmIV.mdmInvestmentVehicleId,
        name: mdmIV.investmentVehicle.name,
        currentStage: mdmIV.currentStage,
        submissionDateTime: getLatestDate(mdmIV.submissionDateTimes),
        reopenedDate: getLatestDate(mdmIV.reopenedDates),
        permissionedUserNames: mdmIV.permissionedUsers
          .map((x) => `${x.firstName} ${x.lastName}`)
          .sort((name1, name2) => name1.localeCompare(name2)),
      })
    );

    const { exactMatches, beginsWithMatches, containingMatches } =
      searchElections(mappedElections, searchTerm);

    const sortArray = (array: IElectionsForElectionRoundRow[]) =>
      array.sort((election1, election2) =>
        sortElectionsForElectionRound(election1, election2, currentSort)
      );

    const sortedElections = sortElections(
      exactMatches,
      beginsWithMatches,
      containingMatches,
      currentSort
    );

    const allItems = sortArray(mappedElections);
    const filteredElections = sortedElections.filter((x) =>
      filterElections(x, filters)
    );
    const pageItems = filteredElections.slice(
      (page - 1) * pageSize,
      page * pageSize
    );
    return setData({
      allItems: allItems,
      items: pageItems,
      totalRows: filteredElections.length,
      totalFilters: getActiveFiltersCount(filters),
    });
  }, [
    searchTerm,
    currentSort,
    electionsForElectionRound,
    page,
    pageSize,
    filters,
  ]);

  return {
    allItems: data.allItems,
    currentPageItems: data.items,
    totalRows: data.totalRows,
    totalFilters: data.totalFilters,
    pageSize,
    page,
    onSearch,
  };
};

export const useReopenElection = () => {
  const dispatch = useDispatch();
  const { currentElectionRoundId } = useSelector(
    (state: AdminUIStore) => state.elections
  );
  const handleReopenWorkflowState = async (investmentVehicleId: number) => {
    if (!isSomething(currentElectionRoundId)) {
      return;
    }

    const result = await reopenWorkflowState(
      currentElectionRoundId.value,
      investmentVehicleId
    );

    if (!result) {
      dispatch(
        openAlert({
          severity: "error",
          message: ElectionsLabels.REOPEN_WORKFLOW_ERRORED,
        })
      );
      return;
    }

    dispatch(
      openAlert({
        severity: "success",
        message: ElectionsLabels.REOPEN_WORKFLOW_SUCCESSFULLY,
      })
    );

    dispatch(
      reqElectionsForElectionRound({
        electionRoundId: currentElectionRoundId.value,
      })
    );
  };

  return { handleReopenWorkflowState };
};
