import { Typography } from "@mui/material";
import { ElectionsLabels } from "common";
import { ElectionDialog } from "common/src/features/Elections/ElectionWorkflow/Shared/ElectionDialog/ElectionDialog";
import React from "react";

import { useReopenElection } from "../../../Hooks/ElectorViewGridHooks";

interface IReopenDialogProps {
  investmentVehicleId: number;
  mdmInvestmentVehicleId: number;
  name: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReopenDialog = ({
  investmentVehicleId,
  mdmInvestmentVehicleId,
  name,
  isOpen,
  setIsOpen,
}: IReopenDialogProps) => {
  const { handleReopenWorkflowState } = useReopenElection();
  const DialogContent = () => (
    <Typography>
      {ElectionsLabels.REOPEN_ELECTION_CONFIRMATION_MESSAGE(
        mdmInvestmentVehicleId,
        name
      )}
    </Typography>
  );

  const handleNext = () => {
    handleReopenWorkflowState(investmentVehicleId);
    setIsOpen(false);
  };

  return (
    <ElectionDialog
      title={ElectionsLabels.DO_YOU_WANT_TO_REVISE}
      content={<DialogContent />}
      handleNext={handleNext}
      open={isOpen}
      handleClose={() => setIsOpen(false)}
      nextButtonLabel={ElectionsLabels.YES}
      cancelButtonLabel={ElectionsLabels.NO}
    />
  );
};
