import ExcelJS from "exceljs";

import {
  InvestmentBreakdownKey,
  InvestmentValueDisplay,
} from "../../constants/enums";
import { IHistoricalSummaryDatum } from "../../types/dataTypes";
import { UnrealizedValues } from "../../types/electionDataTypes";
import { getFormattedDateYYYYMMDD } from "../formatters";
import { InvestmentBreakDownValueSelectors } from "../investmentBreakdownUtils";
import { isRealizedProperty } from "../investmentHistoryUtils";

export const ExcelConstants = {
  HEADERS: {
    AS_OF: "As Of",
    CURRENCY_USD: "Currency (USD)",
    PERIOD_END_DATE: "Period End Date",
    DEFAULT_HEADER_ROWS: 1,
  },
  KEYS: {
    BUSINESS_UNIT_NAME: "businessUnitName",
    CAPITAL_INVESTED: "capitalInvested",
    CARRY: "carry",
    CARRIED_INTEREST: "carriedInterest",
    CARRY_AND_INCENTIVE_FEES: "carryAndIncentiveFees",
    FUND: "fund",
    FUND_ID: "fundId",
    MDM_FUND_ID: "mdmFundId",
    FUND_NAME: "fundName",
    GROUP_BY_COL: "groupByCol",
    REALIZED_GAIN_LOSS: "realizedGainLoss",
    INVESTMENT_DATE: "investmentDate",
    UNREALIZED_GAIN_LOSS: "unrealizedGainLoss",
    PERIOD: "period",
    PERIOD_END_DATE: "periodEndDate",
    REALIZED_TOTAL: "realizedTotal",
    RETURN_OF_CAPITAL: "returnOfCapital",
    UNREALIZED_TOTAL: "unrealizedTotal",
    REMAINING_CAPITAL_INVESTED: "remainingCapitalInvested",
    VESTING_DATE: "vestingDate",
    AWARD: "award",
    SYMBOL: "symbol",
    UNITS_VESTED: "unitsVested",
    UNITS_UNVESTED: "unitsUnvested",
    UNVESTED_VALUE: "unvestedValue",
    DISTRIBUTION_DATE: "distributionDate",
    TICKER: "ticker",
    UNIT_TYPE: "unitType",
    DISTRIBUTION_PER_UNIT: "distributionPerUnit",
    UNITS_ENTITLED: "unitsEntitled",
    DIVIDEND_AMOUNT: "dividendAmount",
    STRATEGY_NAME: "strategyName",
    GAIN_LOSS: "gainLoss",
    TOTAL: "total",
    FORECASTED_CAP_CALL_AMNT: "amount",
    ELECTED_AMOUNT: "electedAmount",
    ELECTION_YEAR: "electionYear",
    CAPITAL_CALLED: "capitalCalled",
    CAPITAL_CALLED_PERCENT: "capitalCalledPercent",
    AMOUNT: "amount",
    VALUE_TYPE: "valueType",
    AS_OF_DATE: "asOfDate",
    STOCK_VALUE: "stockValue",
    VALUE: "value",
  },
  SIZES: {
    BIG: 45,
    MEDIUM: 35,
    SMALL: 20,
    XSMALL: 15,
  },
  VALUES: {
    NULL_VALUE: 0,
  },
  NUMBER_FORMATTERS: {
    COMMA_SEPARATED_INTEGER: "#,##0",
    DOLLAR: "$#,##0;($#,##0)",
    YYYYMMDD_DATE: "yyyy/mm/dd",
    PERCENT: "#0%",
  },
};

export const DATE_STYLE = {
  numFmt: ExcelConstants.NUMBER_FORMATTERS.YYYYMMDD_DATE,
  alignment: { horizontal: "left" },
};

export const formatHeaderRows = (
  worksheet: ExcelJS.Worksheet,
  numHeaderRows = ExcelConstants.HEADERS.DEFAULT_HEADER_ROWS
) => {
  for (let i = 1; i <= numHeaderRows; i++) {
    const headerRow = worksheet.getRow(i);
    headerRow.font = { ...headerRow.font, bold: true };
  }
};

export const formatBottomRow = (worksheet: ExcelJS.Worksheet) => {
  const lastRow = worksheet.getRow(worksheet.rowCount); // Get the last row
  lastRow.font = { ...lastRow.font, bold: true };
};

export const addIndent = (
  worksheet: ExcelJS.Worksheet,
  colm: number,
  startIndex: number,
  endIndex: number
) => {
  for (let i = startIndex; i <= endIndex; i++) {
    const cell = worksheet.getCell(i, colm);
    cell.alignment = { indent: cell.alignment?.indent ?? 0 + 1 };
  }
};

export const getAsOfHeader = (date: Date) => {
  return `(as of ${getFormattedDateYYYYMMDD(date, true)})`;
};

export const getDateRangeHeader = (earliestDate: Date, latestDate: Date) => {
  return `(${getFormattedDateYYYYMMDD(
    earliestDate,
    true
  )} - ${getFormattedDateYYYYMMDD(latestDate, true)})`;
};

/**
 * Return the date by adding UTC marker to it so that it has 0 offset and ExcelJS do not alter it while exporting
 * @returns Date
 */
export const getDateWithZeroOffset = (date: Date): Date => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
};

/*
 ** Gets the value of the property
 */
export const GetPropertyValue = (
  data: IHistoricalSummaryDatum,
  key: InvestmentBreakdownKey,
  valueDisplay: InvestmentValueDisplay
): number => {
  const defaultValue = ExcelConstants.VALUES.NULL_VALUE;
  switch (valueDisplay) {
    case InvestmentValueDisplay.CUMULATIVE: {
      return (
        InvestmentBreakDownValueSelectors[key](data.cumulative) || defaultValue
      );
    }
    case InvestmentValueDisplay.NONCUMULATIVE: {
      return (
        InvestmentBreakDownValueSelectors[key](data.nonCumulative) ||
        defaultValue
      );
    }
    case InvestmentValueDisplay.CUMULATIVE_UNREALIZED_NONCUMULATIVE_REALIZED: {
      return isRealizedProperty(key)
        ? InvestmentBreakDownValueSelectors[key](data.nonCumulative) ||
            defaultValue
        : InvestmentBreakDownValueSelectors[key](data.cumulative) ||
            defaultValue;
    }
    default: {
      return defaultValue;
    }
  }
};

export const hideRemainingCapitalInvestedColumn = (
  unrealized: UnrealizedValues
): boolean => {
  return (
    unrealized.unrealizedValues.find((x) => x.remainingCapitalInvested > 0) ===
    undefined
  );
};

export const hideGainLossColumn = (unrealized: UnrealizedValues): boolean => {
  return unrealized.unrealizedValues.find((x) => x.gainLoss > 0) === undefined;
};

export const hideCarryColumn = (unrealized: UnrealizedValues): boolean => {
  return (
    unrealized.unrealizedValues.find((x) => x.carriedInterest > 0) === undefined
  );
};
