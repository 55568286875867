import {
  Grid,
  MenuItem,
  Pagination,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useMemo } from "react";

import { BreakpointConstants } from "../../constants/BreakpointConstants";
import { PAGE_SIZE_OPTIONS, PageSize } from "../../types/pageSizeTypes";
import DataFilterSelect from "../DataFilterSelect/DataFilterSelect";
import styles from "./PaginationBar.module.scss";

export interface IPaginationBarProps {
  totalRows: number;
  rowsOnCurrentPage: number;
  pageSize: PageSize;
  currentPage: number;
  onPageSizeChange: (event: SelectChangeEvent<PageSize>) => void;
  onPageChange:
    | ((event: React.ChangeEvent<unknown>, page: number) => void)
    | undefined;
}

export const PaginationBar = (props: IPaginationBarProps) => {
  const {
    totalRows,
    rowsOnCurrentPage,
    pageSize,
    currentPage,
    onPageSizeChange,
    onPageChange,
  } = props;

  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );

  const currentOffset = pageSize * (currentPage - 1);

  const totalRowsText = useMemo(() => {
    if (rowsOnCurrentPage > 0) {
      const startFormatted = (currentOffset + 1).toLocaleString();
      const endFormatted = (currentOffset + rowsOnCurrentPage).toLocaleString();
      const totalFormatted = totalRows.toLocaleString();
      return `Viewing ${startFormatted}-${endFormatted} of ${totalFormatted}`;
    }
  }, [totalRows, currentOffset, rowsOnCurrentPage]);

  return (
    <>
      <Typography variant="body1" className={styles.viewingCount}>
        {totalRowsText}
      </Typography>
      <Grid className={styles.paginationPanel}>
        <div className={styles.rowsPerPage}>
          <div className={styles.paginationText}>{"Rows per page:"}</div>
          <DataFilterSelect<PageSize>
            className={styles.overriddenSelect}
            value={pageSize}
            onChange={onPageSizeChange}
            MenuProps={{
              sx: {
                "& .MuiMenu-list": {
                  padding: "0px",
                },
              },
              transformOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }}
          >
            {PAGE_SIZE_OPTIONS.map((size: PageSize, i: number) => (
              <MenuItem
                key={i}
                value={size}
                className={styles.overriddenMenuItem}
              >
                {size}
              </MenuItem>
            ))}
          </DataFilterSelect>
        </div>
        <Pagination
          page={currentPage}
          className={styles.pagination}
          // total number of pages
          count={Math.ceil(totalRows / pageSize)}
          onChange={onPageChange}
          size={isMobile ? "small" : "medium"}
        />
      </Grid>
    </>
  );
};
