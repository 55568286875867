import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { Grid } from "@mui/material";
import { GridReadyEvent } from "ag-grid-community";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";

import { DEFAULT_COLUMN_DEF } from "../../../../components/Grid/gridColumnConfig";
import { getMonospaceCellRenderer } from "../../../../components/Grid/MonospaceCellRenderer/MonospaceCellRenderer";
import { GridHeaderWithTooltip } from "../../../../components/GridHeaderWithTooltip/GridHeaderWithTooltip";
import { TickerCellRenderer } from "../../../../components/TickerCellRenderer/TickerCellRenderer";
import {
  DistributionsLabel,
  DistributionsTooltip,
} from "../../../../constants/LabelAndTooltipConstants";
import { useGridExtensions } from "../../../../hooks/useGridExtensions";
import { IDistributionsDatum } from "../../../../types/dataTypes";
import { dateFormatterWithEmpty } from "../../../../utils/agGridUtils";
import {
  getCurrencyFormattedValue,
  getExactly2DPNumberFormattedWithZeroDefault,
  getRoundedNumberWithZeroDefault,
} from "../../../../utils/formatters";
import styles from "./DistributionsGrid.module.scss";

export interface IDistributionsGridProps {
  distributionsData: IDistributionsDatum[];
}

export interface IDistributionsGridContext {
  filter: string | undefined;
}

export const DistributionsGrid = (props: IDistributionsGridProps) => {
  const { distributionsData } = props;

  const [, setIsGridReady] = useState<boolean>(false);

  const gridRef = useRef<AgGridReact>(null);

  const {
    setHeaderHeight,
    resizeColumns,
    onGridReady: OnGridReadyCommon,
  } = useGridExtensions("viewByColumn");

  const onGridReady = (params: GridReadyEvent) => {
    setIsGridReady(true);
    OnGridReadyCommon(params);
  };

  const distributionsColumnDefs: ColDef<IDistributionsDatum>[] = useMemo(
    () => [
      {
        field: "distributionDate",
        headerName: DistributionsLabel.DIST_DATE,
        pinned: true,
        maxWidth: 300,
        minWidth: 150,
        valueFormatter: dateFormatterWithEmpty,
      },
      {
        field: "ticker",
        headerName: DistributionsLabel.TICKER,
        maxWidth: 300,
        cellRenderer: TickerCellRenderer,
      },
      {
        field: "unitType",
        headerName: DistributionsLabel.UNIT_TYPE,
        maxWidth: 300,
      },
      {
        field: "distributionPerUnit",
        headerName: DistributionsLabel.DIST_PER_UNIT,
        type: "rightAligned",
        cellRenderer: getMonospaceCellRenderer(
          getExactly2DPNumberFormattedWithZeroDefault
        ),
        minWidth: 170,
      },
      {
        field: "unitsEntitled",
        headerName: DistributionsLabel.UNITS_ENTITLED,
        type: "rightAligned",
        cellRenderer: getMonospaceCellRenderer(getRoundedNumberWithZeroDefault),
        minWidth: 250,
      },
      {
        field: "dividendAmount",
        headerName: DistributionsLabel.PAYMENT_AMOUNT,
        headerComponent: GridHeaderWithTooltip,
        headerComponentParams: {
          tooltipContent: [DistributionsTooltip.PAYMENT_AMOUNT],
        },
        type: "rightAligned",
        cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
        minWidth: 150,
      },
    ],
    []
  );

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} className={`ag-theme-alpine`} id={styles.dataGrid}>
        <AgGridReact<IDistributionsDatum>
          ref={gridRef}
          rowData={distributionsData}
          defaultColDef={DEFAULT_COLUMN_DEF}
          columnDefs={distributionsColumnDefs}
          domLayout="autoHeight"
          onFirstDataRendered={setHeaderHeight}
          onColumnResized={setHeaderHeight}
          cacheQuickFilter={true}
          suppressAggFuncInHeader={true}
          suppressContextMenu={true}
          suppressCellFocus={true}
          onRowDataUpdated={resizeColumns}
          onGridSizeChanged={resizeColumns}
          onDisplayedColumnsChanged={resizeColumns}
          onGridReady={onGridReady}
          suppressMenuHide={true}
        />
      </Grid>
    </Grid>
  );
};
