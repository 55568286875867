import { useDispatch, useSelector } from "react-redux";

import {
  InvestmentValueDisplay,
  PeriodType,
  StockSymbol,
} from "../constants/enums";
import { ErrorStringConstants } from "../constants/ErrorStringConstants";
import { StringConstants } from "../constants/StringConstants";
import { openAlert } from "../redux/actions/entitlementActions";
import {
  selectActiveInvestmentEntityData,
  selectAnnualCommitments,
  selectCallout,
  selectForecastedCommitmentByStrategy,
  selectHistoricalSumaryGrouped,
  selectInvestmentBreakdownGrouped,
  selectLifeOfFundCommitments,
} from "../redux/selectors";
import { IBaseStore } from "../redux/store";
import { IExcelSettings, WorksheetGenerator } from "../types/excelTypes";
import { downloadFile } from "../utils/downloadUtils";
import { createExcel } from "../utils/excelUtils/excelGenerationUtils";
import { getFormattedDateYYYYMMDD } from "../utils/formatters";

const defaultExcelSettings = {
  valueDisplay: InvestmentValueDisplay.NONCUMULATIVE,
  periodType: PeriodType.QUARTERLY,
  showEmptyColumns: false,
  selectedStockSymbol: StockSymbol.BX,
};

export const useExcelDownload = () => {
  const equityData = useSelector((store: IBaseStore) => store.equityData);
  const selectedElection = useSelector(
    (store: IBaseStore) => store.selectedElection
  );
  const internalInvestmentData = useSelector(selectActiveInvestmentEntityData);

  const callout = useSelector(selectCallout);

  const { investmentBreakdownGrouped } = useSelector(
    selectInvestmentBreakdownGrouped
  );

  const { historicalSummaryGrouped } = useSelector(
    selectHistoricalSumaryGrouped
  );

  const { forecastedCommitmentByStrategy } = useSelector(
    selectForecastedCommitmentByStrategy
  );

  const lifeOfFundCommitments = useSelector(selectLifeOfFundCommitments);
  const annualCommitments = useSelector(selectAnnualCommitments);

  const dispatch = useDispatch();

  const handleExcelDownload = async (
    generator: WorksheetGenerator | undefined,
    filename: string,
    excelSettings?: Partial<IExcelSettings>
  ) => {
    try {
      if (generator !== undefined) {
        const params = {
          equityData,
          internalInvestmentData,
          callout,
          investmentBreakdownGrouped,
          historicalSummaryGrouped,
          forecastedCommitmentByStrategy,
          lifeOfFundCommitments,
          annualCommitments,
          isAdmin: false,
          settings: { ...defaultExcelSettings, ...excelSettings },
          selectedElection,
        };
        const file = await createExcel(generator, params);
        downloadFile(
          file,
          `${filename}_${getFormattedDateYYYYMMDD(new Date(), false)}.xlsx`
        );

        dispatch(
          openAlert({
            severity: "success",
            message: StringConstants.DOWNLOAD_COMPLETE,
          })
        );
      } else {
        dispatch(
          openAlert({
            severity: "error",
            message: StringConstants.NO_EXCEL_TEMPLATE,
          })
        );
      }
    } catch (e) {
      dispatch(
        openAlert({
          severity: "error",
          message: ErrorStringConstants.DOWNLOAD_FAILED,
        })
      );
    }
  };

  return handleExcelDownload;
};
