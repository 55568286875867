import { User } from "oidc-client-ts";

import { getActiveOIDCProviderDefinition } from "../utils/authUtils";

export const getUser = (): User | null => {
  const providerDefinition = getActiveOIDCProviderDefinition();
  const oidcStorage = localStorage.getItem(
    `oidc.user:${providerDefinition.AUTHORITY_URL_BASE}:${providerDefinition.CLIENT_ID}`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

// Produce an ID that can be used to uniquely identify the current application user.
export const getUserIdentifier = (
  user: User | null | undefined
): number | undefined => {
  // NB: For now we can only identify internal employees.
  return user?.profile?.employeeNumber !== undefined
    ? (user.profile.employeeNumber as number)
    : undefined;
};

export const getUserName = (user: User | null): string | undefined =>
  user?.profile?.name !== undefined ? user.profile.name : undefined;

export const getUserEmail = (user: User | null): string | undefined =>
  user?.profile?.email !== undefined ? user.profile.email : undefined;
