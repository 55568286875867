import { ElectionWorkflowPage, PathFragment } from "common";
import React from "react";
import { useSelector } from "react-redux";

import { HasEntitlementContainer } from "../../../common/components/HasEntitlementContainer/HasEntitlementContainer";
import { ElectionWorkflowPages } from "../../../constants/Pages/ElectionWorkflowPages";
import { AdminUIStore } from "../../../redux/store";
import { useActiveElection } from "../Hooks/useActiveElectionHook";
import { useElectionQueryParams } from "../Hooks/useElectionQueryParamsHook";

export const ElectionWorkflowPageWrapper = () => {
  const { electionRoundId, mdmInvestmentVehicleId } = useElectionQueryParams();
  useActiveElection();

  const { isBankAccountsAdmin } = useSelector(
    (state: AdminUIStore) => state.entitlements
  );

  return (
    <HasEntitlementContainer
      entitlementLoadStatusName="isElectionsAdminLoadStatus"
      entitlementName="isElectionsAdmin"
    >
      <ElectionWorkflowPage
        canReadBankAccounts={isBankAccountsAdmin}
        ElectionWorkflowPages={ElectionWorkflowPages}
        electionListPath={`/sbs-elections/${electionRoundId}`}
        electionDetailPath={`/${PathFragment.ELECTIONS}/${electionRoundId}/elector-view/${mdmInvestmentVehicleId}`}
        isAdmin
      />
    </HasEntitlementContainer>
  );
};
