import {
  BusinessUnitTreeLevel,
  ElectionPeriodTreeLevel,
  TreeNumber,
} from "../../constants/enums";
import {
  ALL_FUNDS_PLACEHOLDER,
  ALL_NAME_PLACEHOLDER,
} from "../../constants/InvestmentBreakdownConstants";
import {
  adminExcelCellPosition,
  defaultExcelCellPosition,
} from "../../constants/InvestmentsBreakdownExcelConstants";
import {
  PerformanceDetailsLabel,
  SectionHeader,
} from "../../constants/LabelAndTooltipConstants";
import { IInvestmentBreakdownViewModel } from "../../types/dataTypes";
import {
  IPerformanceDetailsRow,
  WorksheetGeneratorParams,
} from "../../types/excelTypes";
import { isSomething } from "../../types/typeGuards";
import { getFirstDayOfQuarter } from "../formatters";
import {
  ExcelConstants,
  formatHeaderRows,
  getAsOfHeader,
  getDateRangeHeader,
  getDateWithZeroOffset,
} from "./excelUtils";

export const buildInvestmentBreakdownWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const {
    workbook,
    investmentBreakdownGrouped,
    isAdmin,
    internalInvestmentData,
  } = params;
  const getExcelRow = (
    datum: IInvestmentBreakdownViewModel,
    groupByOverride?: string
  ): IPerformanceDetailsRow => {
    const row: IPerformanceDetailsRow = {
      businessUnitName: datum.businessUnitName,
      fund:
        datum.fundShortName === ALL_NAME_PLACEHOLDER
          ? datum.businessUnitName
          : datum.fundShortName,
      mdmFundId:
        datum.mdmFundId !== ALL_FUNDS_PLACEHOLDER ? datum.mdmFundId : undefined,
      period: datum.period,
      investmentDate: datum.investmentDate
        ? getDateWithZeroOffset(datum.investmentDate)
        : undefined,
      groupByCol: groupByOverride || datum.displayName,
      capitalInvested: isSomething(datum.investment)
        ? datum.investment.value.capitalInvested
        : ExcelConstants.VALUES.NULL_VALUE,
      returnOfCapital: isSomething(datum.realizedProceeds)
        ? datum.realizedProceeds.value.returnOfCapital
        : ExcelConstants.VALUES.NULL_VALUE,
      realizedGainLoss: isSomething(datum.realizedProceeds)
        ? datum.realizedProceeds.value.gainLoss
        : ExcelConstants.VALUES.NULL_VALUE,
      carryAndIncentiveFees: isSomething(datum.realizedProceeds)
        ? datum.realizedProceeds.value.carriedInterest
        : ExcelConstants.VALUES.NULL_VALUE,
      realizedTotal: isSomething(datum.realizedProceeds)
        ? datum.realizedProceeds.value.total
        : ExcelConstants.VALUES.NULL_VALUE,
      remainingCapitalInvested: isSomething(datum.unrealizedValue)
        ? datum.unrealizedValue.value.remainingCapitalInvested
        : ExcelConstants.VALUES.NULL_VALUE,
      unrealizedGainLoss: isSomething(datum.unrealizedValue)
        ? datum.unrealizedValue.value.gainLoss
        : ExcelConstants.VALUES.NULL_VALUE,
      carry: isSomething(datum.unrealizedValue)
        ? datum.unrealizedValue.value.carriedInterest
        : ExcelConstants.VALUES.NULL_VALUE,
      unrealizedTotal: isSomething(datum.unrealizedValue)
        ? datum.unrealizedValue.value.total
        : ExcelConstants.VALUES.NULL_VALUE,
    };
    return row;
  };

  if (isSomething(investmentBreakdownGrouped)) {
    const performanceDetailsData = investmentBreakdownGrouped.value;
    const totalData = performanceDetailsData[TreeNumber.ELECTION_PERIOD].filter(
      (datum) => datum.treeLevel === ElectionPeriodTreeLevel.ENTITY
    );

    // FUND
    const fundWorksheet = workbook.addWorksheet(
      `${SectionHeader.INVESTMENT_BREAKDOWN} Fund`,
      {
        views: [
          {
            state: "frozen",
            ySplit: 2,
          },
        ],
      }
    );
    const earliestDate = getFirstDayOfQuarter(
      internalInvestmentData.asOfDates.earliestAsOfDate
    );
    const latestDate = internalInvestmentData.asOfDates.latestAsOfDate;
    const latestQuarterEnd =
      internalInvestmentData.asOfDates.latestAsOfDateWithUnrealizedData;
    fundWorksheet.columns = isAdmin
      ? [
          {
            header: PerformanceDetailsLabel.BUSINESS_UNIT,
            key: ExcelConstants.KEYS.BUSINESS_UNIT_NAME,
            width: ExcelConstants.SIZES.SMALL,
            style: { alignment: { horizontal: "left" } },
          },
          {
            header: PerformanceDetailsLabel.MDM_FUND_ID,
            key: ExcelConstants.KEYS.MDM_FUND_ID,
            width: ExcelConstants.SIZES.XSMALL,
            style: { alignment: { horizontal: "left" } },
          },
          {
            header: PerformanceDetailsLabel.FUND,
            key: ExcelConstants.KEYS.FUND,
            width: ExcelConstants.SIZES.XSMALL,
          },
          {
            header: PerformanceDetailsLabel.INVESTMENT_DATE,
            key: ExcelConstants.KEYS.INVESTMENT_DATE,
            width: ExcelConstants.SIZES.XSMALL,
            style: {
              numFmt: ExcelConstants.NUMBER_FORMATTERS.YYYYMMDD_DATE,
              alignment: { horizontal: "left" },
            },
          },
          {
            header: PerformanceDetailsLabel.INVESTMENT,
            key: ExcelConstants.KEYS.GROUP_BY_COL,
            width: ExcelConstants.SIZES.MEDIUM,
          },
          {
            header: PerformanceDetailsLabel.CAPITAL_INVESTED,
            key: ExcelConstants.KEYS.CAPITAL_INVESTED,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.RETURN_OF_CAPITAL,
            key: ExcelConstants.KEYS.RETURN_OF_CAPITAL,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.GAIN_LOSS,
            key: ExcelConstants.KEYS.REALIZED_GAIN_LOSS,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.CARRY_AND_INCENTIVE_FEES,
            key: ExcelConstants.KEYS.CARRY_AND_INCENTIVE_FEES,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.REALIZED_TOTAL,
            key: ExcelConstants.KEYS.REALIZED_TOTAL,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.REMAINING_INVESTMENT,
            key: ExcelConstants.KEYS.REMAINING_CAPITAL_INVESTED,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.GAIN_LOSS,
            key: ExcelConstants.KEYS.UNREALIZED_GAIN_LOSS,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.CARRY,
            key: ExcelConstants.KEYS.CARRY,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.UNREALIZED_TOTAL,
            key: ExcelConstants.KEYS.UNREALIZED_TOTAL,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
        ]
      : [
          {
            header: PerformanceDetailsLabel.BUSINESS_UNIT,
            key: ExcelConstants.KEYS.BUSINESS_UNIT_NAME,
            width: ExcelConstants.SIZES.SMALL,
            style: { alignment: { horizontal: "left" } },
          },
          {
            header: PerformanceDetailsLabel.FUND,
            key: ExcelConstants.KEYS.FUND,
            width: ExcelConstants.SIZES.XSMALL,
          },
          {
            header: PerformanceDetailsLabel.INVESTMENT_DATE,
            key: ExcelConstants.KEYS.INVESTMENT_DATE,
            width: ExcelConstants.SIZES.XSMALL,
            style: {
              numFmt: ExcelConstants.NUMBER_FORMATTERS.YYYYMMDD_DATE,
              alignment: { horizontal: "left" },
            },
          },
          {
            header: PerformanceDetailsLabel.INVESTMENT,
            key: ExcelConstants.KEYS.GROUP_BY_COL,
            width: ExcelConstants.SIZES.MEDIUM,
          },
          {
            header: PerformanceDetailsLabel.CAPITAL_INVESTED,
            key: ExcelConstants.KEYS.CAPITAL_INVESTED,
            width: ExcelConstants.SIZES.MEDIUM,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.RETURN_OF_CAPITAL,
            key: ExcelConstants.KEYS.RETURN_OF_CAPITAL,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.GAIN_LOSS,
            key: ExcelConstants.KEYS.REALIZED_GAIN_LOSS,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.CARRY_AND_INCENTIVE_FEES,
            key: ExcelConstants.KEYS.CARRY_AND_INCENTIVE_FEES,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.REALIZED_TOTAL,
            key: ExcelConstants.KEYS.REALIZED_TOTAL,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.REMAINING_INVESTMENT,
            key: ExcelConstants.KEYS.REMAINING_CAPITAL_INVESTED,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.GAIN_LOSS,
            key: ExcelConstants.KEYS.UNREALIZED_GAIN_LOSS,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.CARRY,
            key: ExcelConstants.KEYS.CARRY,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
          {
            header: PerformanceDetailsLabel.UNREALIZED_TOTAL,
            key: ExcelConstants.KEYS.UNREALIZED_TOTAL,
            width: ExcelConstants.SIZES.XSMALL,
            style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
          },
        ];
    const businessData = performanceDetailsData[
      TreeNumber.BUSINESS_UNIT
    ].filter(
      (datum) => datum.treeLevel === BusinessUnitTreeLevel.BUSINESS_UNIT
    );
    const fundData = performanceDetailsData[TreeNumber.BUSINESS_UNIT].filter(
      (datum) => datum.treeLevel === BusinessUnitTreeLevel.FUND
    );
    const trancheData = performanceDetailsData[TreeNumber.BUSINESS_UNIT].filter(
      (datum) => datum.treeLevel === BusinessUnitTreeLevel.TRANCHE
    );
    businessData.sort((a, b) =>
      a.businessUnitName.localeCompare(b.businessUnitName)
    );
    fundData.sort((a, b) => a.fundShortName.localeCompare(b.fundShortName));
    const cellPosition = isAdmin
      ? adminExcelCellPosition
      : defaultExcelCellPosition;
    for (const businessUnit of businessData) {
      businessUnit.fundId = businessUnit.businessUnitName;
      const buRow = fundWorksheet.addRow(getExcelRow(businessUnit));
      buRow.font = { ...buRow.font, bold: true };
      const relevantFunds = fundData.filter(
        (fund) => fund.businessUnitId === businessUnit.businessUnitId
      );
      for (const fund of relevantFunds) {
        const investmentRow = fundWorksheet.addRow(getExcelRow(fund));
        investmentRow.font = { ...investmentRow.font, bold: true };
        investmentRow.getCell(cellPosition.businessUnitName).font = {
          ...investmentRow.font,
          bold: false,
        };
        const investmentCell = investmentRow.getCell(cellPosition.investment);
        const fundShortNameCell = investmentRow.getCell(
          cellPosition.fundShortName
        );
        investmentCell.alignment = { indent: 1 };
        fundShortNameCell.alignment = { indent: 1 };
        const relevantTranches = trancheData
          .filter((tranch) => tranch.fundShortName === fund.fundShortName)
          .sort((a, b) => a.trancheName.localeCompare(b.trancheName));
        for (const tranche of relevantTranches) {
          const trancheRow = fundWorksheet.addRow(getExcelRow(tranche));
          const fundShortNameCell = trancheRow.getCell(
            cellPosition.fundShortName
          );
          const groupByCell = trancheRow.getCell(cellPosition.investment);
          fundShortNameCell.alignment = { indent: 1 };
          groupByCell.alignment = { indent: 2 };
        }
      }
    }
    if (totalData.length) {
      const fundTotalRow = fundWorksheet.addRow(
        getExcelRow(totalData[0], PerformanceDetailsLabel.TOTAL)
      );
      fundTotalRow.font = { ...fundTotalRow.font, bold: true };
      if (isAdmin) {
        const fundIdCell = fundTotalRow.getCell(
          ExcelConstants.KEYS.MDM_FUND_ID
        );
        fundIdCell.value = null;
      }
    }
    fundWorksheet.insertRow(1, Array<undefined>(11));
    const capInvestedHeader = fundWorksheet.getCell(
      cellPosition.capitalInvestedHeader
    );
    capInvestedHeader.value = `Capital Invested ${getDateRangeHeader(
      earliestDate,
      latestDate
    )}`;
    const fundRealizedHeader = fundWorksheet.getCell(
      cellPosition.fundRealizedHeader
    );
    fundRealizedHeader.value = `Realized Proceeds ${getDateRangeHeader(
      earliestDate,
      latestDate
    )}`;
    fundRealizedHeader.style = {
      ...fundRealizedHeader.style,
      alignment: { horizontal: "center" },
    };
    const fundUnrealizedHeader = fundWorksheet.getCell(
      cellPosition.fundUnrealizedHeader
    );
    fundUnrealizedHeader.value = `Unrealized Value ${getAsOfHeader(
      isSomething(latestQuarterEnd) ? latestQuarterEnd.value : new Date()
    )}`;
    fundUnrealizedHeader.style = {
      ...fundUnrealizedHeader.style,
      alignment: { horizontal: "center" },
    };
    formatHeaderRows(fundWorksheet, 2);
    fundWorksheet.mergeCells(cellPosition.realizedProceedsHeader);
    fundWorksheet.mergeCells(cellPosition.unrealizedValueHeader);
  }
  return workbook;
};
