import React from "react";
import { ResponsiveContainer } from "recharts";

/**
 * Inelegant workaround to get recharts to really be responsive
 */

interface ResponsiveRechartsWrapperProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: JSX.Element;
}

export const ResponsiveRechartsWrapper = (
  props: ResponsiveRechartsWrapperProps
) => (
  <div
    style={{
      position: "relative",
      display: "block",
      height: "100%",
    }}
    {...props}
  >
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
      }}
    >
      <ResponsiveContainer width="100%">{props.children}</ResponsiveContainer>
    </div>
  </div>
);
