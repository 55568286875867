import { Stack, TextField } from "@mui/material";
import { obfuscateAccountNumber } from "common";
import React from "react";
import { ControllerRenderProps, UseFormRegister } from "react-hook-form";

import FieldLabelWithTooltip from "../../../../../components/FieldLabelWithTooltip/FieldLabelWithTooltip";
import {
  ReviewAndSignFormState,
  ReviewAndSignState,
} from "../ReviewAndSignForm";
import styles from "./TextFieldInput.module.scss";

export type FieldPath =
  | "bankAccounts"
  | "signature"
  | "signatureInMyCapacityAs"
  | "phoneNumber"
  | "agreesToTerms";

export interface ITextFieldInputProps {
  id?: string;
  field: ControllerRenderProps<ReviewAndSignFormState, "reviewAndSign">;
  register: UseFormRegister<ReviewAndSignFormState>;
  attributeName: string;
  label: string;
  placeHolderText?: string;
  tooltip?: string | React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  shouldObfuscate?: boolean;
}

export const TextFieldInput = (props: ITextFieldInputProps) => {
  const {
    id,
    field,
    register,
    attributeName,
    placeHolderText,
    label,
    tooltip,
    required = false,
    disabled = false,
    shouldObfuscate = false,
  } = props;

  const onChange = (
    field: ControllerRenderProps<ReviewAndSignFormState, `reviewAndSign`>,
    name: string
  ) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const originalValue: ReviewAndSignState = field.value;
      const updated: ReviewAndSignState = {
        ...originalValue,
        [name]: event.currentTarget.value,
      };
      field.onChange(updated);
    };
  };

  const getValue = () => {
    const fieldValue = field.value[attributeName as keyof ReviewAndSignState];
    return shouldObfuscate
      ? obfuscateAccountNumber(String(fieldValue))
      : fieldValue;
  };

  return (
    <Stack className={styles.stack}>
      <FieldLabelWithTooltip
        label={label}
        required={required}
        disabled={disabled}
        tooltip={tooltip}
      />
      <TextField
        {...register(
          `reviewAndSign.${attributeName as keyof ReviewAndSignState}`,
          required ? { required, minLength: 1 } : {}
        )}
        id={id}
        className={styles.textInput}
        variant="outlined"
        autoComplete={"off"}
        placeholder={placeHolderText}
        onChange={onChange(field, attributeName)}
        value={getValue()}
        required={required}
        disabled={disabled}
      />
    </Stack>
  );
};
