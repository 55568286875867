import { SubTabPageName } from "common";

export const InvestmentsTabsDisplayOrder: SubTabPageName[] = [
  SubTabPageName.INVESTMENTS_OVERVIEW,
  SubTabPageName.INVESTMENT_BREAKDOWN,
];

export const EquityTabsDisplayOrder: SubTabPageName[] = [
  SubTabPageName.VESTING_EVENTS,
  SubTabPageName.DISTRIBUTIONS,
];

export const ElectionTabsDisplayOrder: SubTabPageName[] = [
  SubTabPageName.ELECTION_EDITOR,
];
