import { StockSymbol } from "../constants/enums";
import { EquityLabel } from "../constants/LabelAndTooltipConstants";
import { IBXEquityCardData } from "../types/dataTypes";
import { getRoundedNumberWithZeroDefault } from "./formatters";

type EquityRowData = {
  name: string;
  value: number | string;
  valueFormatter?: (value: number | undefined) => string;
  subItems: EquityRowData[];
};

type Totals = {
  totalValue: EquityRowData;
  totalUnits: EquityRowData;
};

// Get the Total Rows Names and Subtotals for equity rows
export const getTotalsRowNamesAndSubTotals = (
  equityCardData: IBXEquityCardData,
  selectedStockSymbol: StockSymbol,
  hasViewRestrictedEquity: boolean
): Totals => {
  const totalValueSubItems: EquityRowData[] = [];
  const totalUnitsSubItems: EquityRowData[] = [];

  let totalUnits = equityCardData.totalUnvestedBXUnits;

  // Always include unvested values as sub-items
  totalValueSubItems.push({
    name: EquityLabel.UNVESTED,
    value:
      equityCardData.totalUnvestedBXUnits *
      equityCardData.latestBXStockValue.value,
    subItems: [],
  });
  totalUnitsSubItems.push({
    name: EquityLabel.UNVESTED,
    value: equityCardData.totalUnvestedBXUnits,
    valueFormatter: getRoundedNumberWithZeroDefault,
    subItems: [],
  });

  if (hasViewRestrictedEquity) {
    if (equityCardData.vestedExchangeableBXUnits > 0) {
      totalValueSubItems.push({
        name: EquityLabel.VESTED_EXCHANGEABLE,
        value:
          equityCardData.vestedExchangeableBXUnits *
          equityCardData.latestBXStockValue.value,
        subItems: [],
      });
      totalUnitsSubItems.push({
        name: EquityLabel.VESTED_EXCHANGEABLE,
        value: equityCardData.vestedExchangeableBXUnits,
        valueFormatter: getRoundedNumberWithZeroDefault,
        subItems: [],
      });
      totalUnits += equityCardData.vestedExchangeableBXUnits;
    }

    if (equityCardData.vestedRestrictedBXUnits > 0) {
      totalValueSubItems.push({
        name: EquityLabel.VESTED_RESTRICTED,
        value:
          equityCardData.vestedRestrictedBXUnits *
          equityCardData.latestBXStockValue.value,
        subItems: [],
      });
      totalUnitsSubItems.push({
        name: EquityLabel.VESTED_RESTRICTED,
        value: equityCardData.vestedRestrictedBXUnits,
        valueFormatter: getRoundedNumberWithZeroDefault,
        subItems: [],
      });
      totalUnits += equityCardData.vestedRestrictedBXUnits;
    }
  }

  const totalValueLabel =
    equityCardData.vestedExchangeableBXUnits > 0 ||
    equityCardData.vestedRestrictedBXUnits > 0
      ? EquityLabel.TOTAL_TOTAL_STOCK_VALUE(selectedStockSymbol)
      : EquityLabel.UNVESTED;

  const totalUnitsLabel =
    equityCardData.vestedExchangeableBXUnits > 0 ||
    equityCardData.vestedRestrictedBXUnits > 0
      ? EquityLabel.TOTAL_STOCK_UNITS(selectedStockSymbol)
      : EquityLabel.UNVESTED;

  // Order the sub-items
  const orderValueSubItems = [
    EquityLabel.UNVESTED,
    EquityLabel.VESTED_RESTRICTED,
    EquityLabel.VESTED_EXCHANGEABLE,
  ];

  const orderUnitsSubItems = [
    EquityLabel.UNVESTED,
    EquityLabel.VESTED_RESTRICTED,
    EquityLabel.VESTED_EXCHANGEABLE,
  ];

  const orderedTotalValueSubItems = totalValueSubItems.sort((a, b) => {
    return (
      orderValueSubItems.indexOf(a.name) - orderValueSubItems.indexOf(b.name)
    );
  });

  const orderedTotalUnitsSubItems = totalUnitsSubItems.sort((a, b) => {
    return (
      orderUnitsSubItems.indexOf(a.name) - orderUnitsSubItems.indexOf(b.name)
    );
  });

  return {
    totalValue: {
      name: totalValueLabel,
      value: totalUnits * equityCardData.latestBXStockValue.value,
      subItems:
        orderedTotalValueSubItems.length > 1 ? orderedTotalValueSubItems : [],
    },
    totalUnits: {
      name: totalUnitsLabel,
      value: totalUnits,
      valueFormatter: getRoundedNumberWithZeroDefault,
      subItems:
        orderedTotalUnitsSubItems.length > 1 ? orderedTotalUnitsSubItems : [],
    },
  };
};
