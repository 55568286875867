import { Button } from "@mui/material";
import React from "react";

import { useDownloadSingleDocument } from "../../hooks/documentHooks";

export interface IElectionsDocumentDownloadButtonProps {
  label: string;
  electionRoundId: string;
  documentId: number;
}

export const ElectionsDocumentDownloadButton = (
  props: IElectionsDocumentDownloadButtonProps
) => {
  const { label, electionRoundId, documentId } = props;

  const { downloadElectionSingleDocument } = useDownloadSingleDocument();

  const handleDocumentDownload = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    electionRoundId: string,
    documentId: number
  ) => {
    // clicking download should not expand/collapse collapsable card
    e.stopPropagation();
    await downloadElectionSingleDocument(electionRoundId, documentId);
  };

  return (
    <Button
      size="medium"
      variant="text"
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        handleDocumentDownload(e, electionRoundId, documentId)
      }
    >
      {label}
    </Button>
  );
};
