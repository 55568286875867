import React from "react";
import { useAuth } from "react-oidc-context";
import { Navigate, useLocation } from "react-router-dom";

import { Footer } from "../components/Footer/Footer";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { PageLoadError } from "../components/PageLoadError/PageLoadError";
import { RelativePath } from "../constants/Paths";
import { storePathForLoginRedirect } from "../utils/authUtils";

interface IAuthenticatedPageProps {
  pageElement: () => JSX.Element;
  pageLoadStatus: number;
  hideFooter?: boolean;
}

export const AuthenticatedPage = (props: IAuthenticatedPageProps) => {
  const location = useLocation();
  const { pageLoadStatus, hideFooter = false } = props;

  const auth = useAuth();

  // TODO: Logic regarding what to display based on the pageLoadStatus should probably
  // be deferred to another component, it isn't inherently related to auth.
  if (auth.isAuthenticated) {
    return pageLoadStatus < 400 ? (
      <>
        <props.pageElement />
        {!hideFooter && <Footer />}
      </>
    ) : (
      <PageLoadError errorCode={pageLoadStatus} />
    );
  }

  if (auth.isLoading) {
    return <LoadingIndicator />;
  }

  if (!auth.isAuthenticated || auth.error) {
    storePathForLoginRedirect(location.pathname);
    return <Navigate to={RelativePath.LOGIN} replace />;
  }
};
