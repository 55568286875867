import { WarningRounded } from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  Grid,
  Link,
  List,
  ListItem,
  Stack,
} from "@mui/material";
import {
  AccountNumberType,
  AccountNumberTypeDisplays,
  AccountType,
  AddBankAccountState,
  BankAccountAssignment,
  BankAccountConstants,
  BankAccountDialogType,
  BankAccountTooltips,
  BankIdType,
  BankIdTypeDisplays,
  customBankIdValidation,
  customOnBankIdChange,
  GPInvestmentVehicle,
  IBaseStore,
  ICountry,
  ICurrency,
  isSomething,
  NonUSAccountNumberTypes,
  NonUSBankIdTypes,
  selectBankAccountCountries,
  selectBankAccountCurrencies,
  USAccountNumberTypes,
  USBankIdTypes,
} from "common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";

import { AutoAssignField } from "./AutoAssignField/AutoAssignField";
import styles from "./BankAccountForm.module.scss";
import { DropdownInput } from "./DropdownInput/DropdownInput";
import { AccountIdTypeSampleImage } from "./Image/AccountIdTypeSampleImage";
import IntermediaryAccountForm from "./IntermediaryAccountForm/IntermediaryAccountForm";
import { AccountTypeRadioButtonInput } from "./RadioButtonInput/AccountTypeRadioButtonInput";
import { TextFieldInput } from "./TextFieldInput/TextFieldInput";

export interface IBankAccountFormProps {
  addBankAccountForm: UseFormReturn<AddBankAccountState>;
  assignmentType: BankAccountAssignment;
  isConfirmClose: boolean;
  showAutoAssign: boolean;
  // map of purpose to reasons why it is ineligible for the account being added
  purposeIneligibility: { [x in BankAccountAssignment]: string[] };
  bankAccountDialogType: BankAccountDialogType;
}

export const BankAccountForm = (props: IBankAccountFormProps) => {
  const {
    addBankAccountForm,
    assignmentType,
    isConfirmClose,
    showAutoAssign,
    purposeIneligibility,
    bankAccountDialogType,
  } = props;

  const [showIntermediaryAccForm, setShowIntermediaryAccForm] = useState(
    addBankAccountForm.getValues("hasIntermediaryAccount")
  );

  const bankAccountsForClient = useSelector(
    (state: IBaseStore) => state.bankAccounts.serverData
  );

  const bankAccountCountries = useSelector((state: IBaseStore) =>
    selectBankAccountCountries(state)
  );

  const bankAccountCurrencies = useSelector((state: IBaseStore) =>
    selectBankAccountCurrencies(state)
  );

  const { selectedBankAccounts } = useSelector(
    (store: IBaseStore) => store.bankAccounts
  );

  const getCountryIdByCode = useCallback(
    (countryCode: string): number => {
      const country: ICountry | undefined = bankAccountCountries.find(
        (country: ICountry) => country.countryCode === countryCode
      );
      return country ? country.id : -1;
    },
    [bankAccountCountries]
  );

  const getCountryNameByCode = (countryCode: string): string => {
    const country: ICountry | undefined = bankAccountCountries.find(
      (country: ICountry) => country.countryCode === countryCode
    );
    return country ? country.name : "";
  };

  const selectedBankIdType: BankIdType = addBankAccountForm.watch("bankIdType");
  const selectedCountry: string = addBankAccountForm.watch("country");

  const usCurrencyCode = useMemo(() => {
    const usCurrency =
      bankAccountCurrencies.find(
        (currency) =>
          currency.id === BankAccountConstants.USD_CURRENCY_ID_NUMBER
      ) ?? "";
    return usCurrency ? usCurrency.currencyCode : "";
  }, [bankAccountCurrencies]);

  const isUsedAsContribution = useMemo(
    () =>
      Object.values(selectedBankAccounts).some(
        (account) =>
          isSomething(account.contributionBankAccount) &&
          account.contributionBankAccount.value.main.bankAccountUniqueId ===
            addBankAccountForm.getValues("bankAccountUniqueId")
      ),
    [addBankAccountForm, selectedBankAccounts]
  );

  const [accountNumberTypeOptions, setAccountNumberTypeOptions] = useState<
    AccountNumberType[]
  >([AccountNumberType.ACCOUNT_NUMBER]);
  const [bankIdTypeOptions, setBankIdTypeOptions] = useState<BankIdType[]>([
    BankIdType.ABA_ROUTING_NUMBER,
  ]);

  const bankIdOnChange = useCallback(
    (value: string) => {
      return customOnBankIdChange(value, selectedBankIdType);
    },
    [selectedBankIdType]
  );

  const bankIdValidation = useCallback(
    (data: string) => {
      return customBankIdValidation(data, selectedBankIdType);
    },
    [selectedBankIdType]
  );

  useEffect(() => {
    const countryId = getCountryIdByCode(selectedCountry);
    const currentAccountNumberType =
      addBankAccountForm.getValues("accountNumberType");
    const currentBankIdType = addBankAccountForm.getValues("bankIdType");
    const newAccountNumberTypeOptions: AccountNumberType[] =
      countryId === BankAccountConstants.US_COUNTRY_ID_NUMBER
        ? USAccountNumberTypes
        : NonUSAccountNumberTypes;
    const newBankIdTypeOptions: BankIdType[] =
      countryId === BankAccountConstants.US_COUNTRY_ID_NUMBER
        ? USBankIdTypes
        : NonUSBankIdTypes;
    if (
      !newAccountNumberTypeOptions.some(
        (type) => type === currentAccountNumberType
      )
    ) {
      addBankAccountForm.setValue(
        "accountNumberType",
        newAccountNumberTypeOptions[0]
      );
    }
    if (!newBankIdTypeOptions.some((type) => type === currentBankIdType)) {
      addBankAccountForm.setValue("bankIdType", newBankIdTypeOptions[0]);
    }
    setAccountNumberTypeOptions(newAccountNumberTypeOptions);
    setBankIdTypeOptions(newBankIdTypeOptions);
  }, [addBankAccountForm, getCountryIdByCode, selectedCountry]);

  useEffect(() => {
    // need to re-validate bank Id when it changes or bank id type changes
    if (
      (addBankAccountForm.getFieldState("bankId").isDirty ||
        addBankAccountForm.getFieldState("bankIdType").isDirty) &&
      addBankAccountForm.getValues("bankId").length > 0
    ) {
      addBankAccountForm.trigger("bankId");
    }
  }, [selectedBankIdType, addBankAccountForm]);

  useEffect(() => {
    const oldVals = addBankAccountForm.getValues("autoAssign");
    addBankAccountForm.setValue("autoAssign", {
      [BankAccountAssignment.CONTRIBUTION]: {
        ...oldVals.Contribution,
        isEligible:
          purposeIneligibility[BankAccountAssignment.CONTRIBUTION].length === 0,
      },
      [BankAccountAssignment.DISTRIBUTION]: {
        ...oldVals.Distribution,
        isEligible:
          purposeIneligibility[BankAccountAssignment.DISTRIBUTION].length === 0,
      },
      [BankAccountAssignment._]: {
        isChecked: false,
        isEligible: false,
      },
    });
  }, [purposeIneligibility, addBankAccountForm]);

  const investmentVehicleIds = useMemo(() => {
    return bankAccountsForClient.investmentVehicles.map(
      (investmentVehicle: GPInvestmentVehicle) => investmentVehicle.axiomId
    );
  }, [bankAccountsForClient]);

  return (
    <>
      <FormControl className={styles.addBankAccountForm}>
        <Box className={styles.formContent}>
          <Stack className={styles.alerts}>
            {isConfirmClose && (
              <Alert
                className={styles.warningAlert}
                variant="standard"
                severity="warning"
                icon={<WarningRounded />}
              >
                <AlertTitle className={styles.title}>
                  {BankAccountConstants.CONFIRM_CLOSE_WARNING}
                </AlertTitle>
              </Alert>
            )}
            {purposeIneligibility[BankAccountAssignment.CONTRIBUTION].length >
              0 &&
              isUsedAsContribution && (
                <Alert
                  className={styles.warningAlert}
                  variant="standard"
                  severity="warning"
                  icon={<WarningRounded />}
                >
                  <AlertTitle className={styles.title}>
                    {BankAccountConstants.CONTRIBUTION_ASSIGNED_ACCOUNT_WARNING}
                    <List className={styles.contributionWarnings}>
                      {purposeIneligibility[
                        BankAccountAssignment.CONTRIBUTION
                      ].map((purpose, index) => (
                        <ListItem key={index}>{purpose}</ListItem>
                      ))}
                    </List>
                  </AlertTitle>
                </Alert>
              )}
          </Stack>
          <Grid container className={styles.FormColumnsContainer}>
            <Stack className={styles.fieldsContainer}>
              <Stack className={styles.inputRow}>
                <Stack className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"accountHolderName"}
                    render={({ field, fieldState: { error } }) => (
                      <TextFieldInput
                        value={field.value}
                        field={field}
                        label={BankAccountConstants.ACCOUNT_HOLDER}
                        placeHolderText={
                          BankAccountConstants.ACCOUNT_HOLDER_PLACEHOLDER
                        }
                        triggerOnBlur={() => {
                          addBankAccountForm.trigger("accountHolderName");
                        }}
                        tooltip={BankAccountTooltips.ACCOUNT_HOLDER}
                        error={error}
                        disabled={
                          bankAccountDialogType === BankAccountDialogType.VIEW
                        }
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                      maxLength: {
                        value: 150,
                        message:
                          BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                      },
                    }}
                  />
                </Stack>
                <Stack className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"accountType"}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <AccountTypeRadioButtonInput
                        value={value}
                        onChange={onChange}
                        disabled={
                          assignmentType ===
                            BankAccountAssignment.CONTRIBUTION ||
                          bankAccountDialogType === BankAccountDialogType.VIEW
                        }
                        label={BankAccountConstants.ACCOUNT_TYPE}
                        tooltip={BankAccountTooltips.ACCOUNT_TYPE}
                        options={[AccountType.CHECKING, AccountType.SAVINGS]}
                        error={error}
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    }}
                  />
                </Stack>
              </Stack>
              <Stack className={styles.inputRow}>
                <Stack className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"accountNumberType"}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DropdownInput
                        value={value}
                        onChange={onChange}
                        label={BankAccountConstants.ACCOUNT_ID_TYPE}
                        options={accountNumberTypeOptions}
                        disabled={
                          bankAccountDialogType === BankAccountDialogType.VIEW
                        }
                        tooltip={<AccountIdTypeSampleImage />}
                        error={error}
                        displayValueFunction={(value: string) => {
                          return AccountNumberTypeDisplays[
                            value as AccountNumberType
                          ];
                        }}
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    }}
                  />
                </Stack>
                <Stack className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"accountNumber"}
                    render={({ field, fieldState: { error } }) => (
                      <TextFieldInput
                        value={field.value}
                        field={field}
                        label={BankAccountConstants.ACCOUNT_ID_NUMBER}
                        placeHolderText={
                          BankAccountConstants.ACCOUNT_ID_NUMBER_PLACEHOLDER
                        }
                        triggerOnBlur={() => {
                          addBankAccountForm.trigger("accountNumber");
                        }}
                        error={error}
                        shouldObfuscate={true}
                        disabled={
                          bankAccountDialogType === BankAccountDialogType.VIEW
                        }
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                      maxLength: {
                        value: 150,
                        message:
                          BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack className={styles.inputRow}>
                <Controller
                  control={addBankAccountForm.control}
                  name={"confirmAccountId"}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldInput
                      value={field.value}
                      field={field}
                      label={BankAccountConstants.CONFIRM_ACCOUNT_ID}
                      placeHolderText={
                        BankAccountConstants.ACCOUNT_ID_NUMBER_PLACEHOLDER
                      }
                      triggerOnBlur={() => {
                        addBankAccountForm.trigger("confirmAccountId");
                      }}
                      error={error}
                      shouldObfuscate={true}
                      disabled={
                        bankAccountDialogType === BankAccountDialogType.VIEW
                      }
                    />
                  )}
                  rules={{
                    required:
                      BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    maxLength: {
                      value: 150,
                      message: BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                    },
                    validate: (value: string) => {
                      const accountId =
                        addBankAccountForm.getValues().accountNumber;
                      if (accountId !== value) {
                        return BankAccountConstants.MUST_MATCH_ACCOUNT_ID;
                      }
                      return true;
                    },
                  }}
                />
              </Stack>
            </Stack>
            <Stack className={styles.fieldsContainer}>
              <Stack className={styles.inputRow}>
                <Stack className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"country"}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <DropdownInput
                        value={value}
                        onChange={onChange}
                        label={BankAccountConstants.COUNTRY}
                        options={bankAccountCountries.map(
                          (country: ICountry) => country.countryCode
                        )}
                        disabled={
                          assignmentType ===
                            BankAccountAssignment.CONTRIBUTION ||
                          bankAccountDialogType === BankAccountDialogType.VIEW
                        }
                        error={error}
                        displayValueFunction={(value: string) => {
                          return getCountryNameByCode(value);
                        }}
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    }}
                  />
                </Stack>
                <Stack className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"currency"}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DropdownInput
                        value={value}
                        onChange={onChange}
                        label={BankAccountConstants.CURRENCY}
                        options={bankAccountCurrencies.map(
                          (currency: ICurrency) => currency.currencyCode
                        )}
                        disabled={
                          assignmentType ===
                            BankAccountAssignment.CONTRIBUTION ||
                          bankAccountDialogType === BankAccountDialogType.VIEW
                        }
                        error={error}
                        warningMessage={
                          value === usCurrencyCode
                            ? undefined
                            : BankAccountConstants.NON_US_CURRENCY_WARNING_MESSAGE
                        }
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    }}
                  />
                </Stack>
              </Stack>
              <Stack className={styles.inputRow}>
                <Stack className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"bankIdType"}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DropdownInput
                        value={value}
                        onChange={onChange}
                        label={BankAccountConstants.BANK_ID_TYPE}
                        options={bankIdTypeOptions}
                        disabled={
                          bankAccountDialogType === BankAccountDialogType.VIEW
                        }
                        tooltip={<AccountIdTypeSampleImage />}
                        error={error}
                        displayValueFunction={(value: string) => {
                          return BankIdTypeDisplays[value as BankIdType];
                        }}
                      />
                    )}
                    rules={{
                      required:
                        BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    }}
                  />
                </Stack>
                <Stack className={styles.rowItems}>
                  <Controller
                    control={addBankAccountForm.control}
                    name={"bankId"}
                    render={({ field, fieldState: { error } }) => (
                      <TextFieldInput
                        value={field.value}
                        field={field}
                        label={BankAccountConstants.BANK_ID_NUMBER}
                        placeHolderText={
                          BankAccountConstants.BANK_ID_NUMBER_PLACEHOLDER
                        }
                        triggerOnBlur={() => {
                          addBankAccountForm.trigger("bankId");
                        }}
                        error={error}
                        customHandleInputChange={bankIdOnChange}
                        disabled={
                          bankAccountDialogType === BankAccountDialogType.VIEW
                        }
                      />
                    )}
                    rules={{
                      validate: (data: string) => {
                        return bankIdValidation(data);
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack className={styles.inputRow}>
                <Controller
                  control={addBankAccountForm.control}
                  name={"bankName"}
                  render={({ field, fieldState: { error } }) => (
                    <TextFieldInput
                      value={field.value}
                      field={field}
                      label={BankAccountConstants.BANK_NAME}
                      placeHolderText={BankAccountConstants.ENTER_BANK_NAME}
                      triggerOnBlur={() => {
                        addBankAccountForm.trigger("bankName");
                      }}
                      error={error}
                      disabled={
                        bankAccountDialogType === BankAccountDialogType.VIEW
                      }
                    />
                  )}
                  rules={{
                    required:
                      BankAccountConstants.PLEASE_COMPLETE_REQUIRED_FIELD,
                    maxLength: {
                      value: 150,
                      message: BankAccountConstants.MUST_BE_LESS_THAN_150_CHAR,
                    },
                  }}
                />
              </Stack>
            </Stack>
          </Grid>
          <Stack direction="row" className={styles.LinkAccountsContainer}>
            {assignmentType === BankAccountAssignment.CONTRIBUTION ? null : (
              <Controller
                control={addBankAccountForm.control}
                name={"hasIntermediaryAccount"}
                render={({ field: { onChange } }) => (
                  <Link
                    className={
                      bankAccountDialogType === BankAccountDialogType.VIEW
                        ? `${styles.link} ${styles.disabledLink}`
                        : `${styles.link}`
                    }
                    component="button"
                    onClick={() => {
                      setShowIntermediaryAccForm((prevState) => {
                        onChange(!prevState);
                        return !prevState;
                      });
                    }}
                    disabled={
                      bankAccountDialogType === BankAccountDialogType.VIEW
                    }
                  >
                    <LinkIcon />
                    {showIntermediaryAccForm
                      ? BankAccountConstants.UNLINK_ACCOUNT
                      : BankAccountConstants.LINK_ACCOUNT}
                  </Link>
                )}
              />
            )}
          </Stack>
          <IntermediaryAccountForm
            isCollapsed={!showIntermediaryAccForm}
            form={addBankAccountForm}
            bankAccountDialogType={bankAccountDialogType}
          />
          {showAutoAssign &&
            bankAccountDialogType === BankAccountDialogType.ADD &&
            investmentVehicleIds.length === 1 && (
              <Controller
                control={addBankAccountForm.control}
                name={"autoAssign"}
                render={({ field }) => (
                  <AutoAssignField
                    label={"Assign to:"}
                    field={field}
                    assignmentReasonForIneligibility={purposeIneligibility}
                    investmentVehicleId={investmentVehicleIds[0]}
                  />
                )}
              />
            )}
        </Box>
      </FormControl>
    </>
  );
};
