import clsx from "clsx";
import React from "react";

import { isSomething } from "../../types/typeGuards";
import { Optional } from "../../types/typeUtils";
import styles from "./MonospaceNumber.module.scss";

interface MonospaceOptionalNumberProps {
  value: Optional<number>;
  className?: string;
  id?: string;
  valueFormatter: (value: Optional<number>) => string;
}

export const MonospaceOptionalNumber = (
  props: MonospaceOptionalNumberProps
) => {
  const valueString = props.valueFormatter(props.value);

  return (
    <span
      className={clsx(styles.monospaceNumber, props.className)}
      id={props.id}
    >
      {valueString}
      {/*
        If we have a positive number, append a trailing space to the string, this
        leaves room for the closing parenthesis that is placed around negative
        numbers and ensure our positive and negative numbers will align.
      */}
      {isSomething(props.value) && props.value.value >= 0 ? <>&nbsp;</> : null}
    </span>
  );
};
