import {
  ColDef,
  ColGroupDef,
  ExcelStyle,
  ValueGetterParams,
} from "ag-grid-community";
import clsx from "clsx";

import { InvestmentBreakdownKey } from "../../../constants/enums";
import { PerformanceDetailsLabel } from "../../../constants/LabelAndTooltipConstants";
import { IInvestmentBreakdownViewModel } from "../../../types/dataTypes";
import { getCurrencyFormattedValue } from "../../../utils/formatters";
import { InvestmentBreakDownValueSelectors } from "../../../utils/investmentBreakdownUtils";
import { getMonospaceCellRenderer } from "../../Grid/MonospaceCellRenderer/MonospaceCellRenderer";
import { GridHeaderWithSubHeader } from "../../GridHeaderWithSubHeader/GridHeaderWithSubHeader";
import { HighlightCellRenderer } from "./HighlightCellRenderer";
import { getIndentClass } from "./performanceDetailsGridUtils";

export const DEFAULT_COLUMN_DEF: ColDef<IInvestmentBreakdownViewModel> = {
  resizable: true,
  minWidth: 110,
  menuTabs: [],
  suppressHeaderMenuButton: true,
};

const wide_screen_min = 900;

const minColumnWidth = () => {
  const screenSize = window.innerWidth;

  if (screenSize < wide_screen_min) {
    return 150;
  }
  return 200;
};

const enum ColumnGroup {
  CapitalInvested = "capitalInvested",
  RealizedProceeds = "realizedProceeds",
  UnrealizedValues = "unrealizedValue",
}

const createGetter = (key: InvestmentBreakdownKey) => {
  return (params: ValueGetterParams<IInvestmentBreakdownViewModel, number>) =>
    params.data ? InvestmentBreakDownValueSelectors[key](params.data) : 0;
};

export const getColumnDefs = (
  realizedDate: Date | undefined,
  earliestUnrealizedDate: Date | undefined,
  unrealizedDate: Date | undefined
): ColGroupDef<IInvestmentBreakdownViewModel>[] => {
  return [
    {
      groupId: "viewByGroup",
      children: [
        {
          cellRendererParams: {
            suppressCount: true,
            innerRenderer: HighlightCellRenderer,
          },
          rowGroup: true,
          enableRowGroup: true,
          pinned: "left",
          minWidth: minColumnWidth(),
          cellClass: getIndentClass,
          valueGetter: (params: ValueGetterParams) => {
            return params.data === undefined
              ? undefined
              : params.data.displayName;
          },
          headerName: PerformanceDetailsLabel.FUND,
          showRowGroup: true,
          cellRenderer: "agGroupCellRenderer",
          colId: "viewByColumn",
        },
      ],
    },
    {
      headerName: PerformanceDetailsLabel.CAPITAL_INVESTED,
      headerGroupComponent: GridHeaderWithSubHeader,
      headerClass: ColumnGroup.CapitalInvested,
      headerGroupComponentParams: {
        subHeader: [
          PerformanceDetailsLabel.getCapitalInvestedHeader(
            realizedDate ?? new Date(),
            earliestUnrealizedDate ?? new Date()
          ),
        ],
      },
      children: [
        {
          headerName: PerformanceDetailsLabel.CAPITAL_INVESTED,
          valueGetter: createGetter(
            InvestmentBreakdownKey.ABSOLUTE_CAPITAL_INVESTED
          ),
          cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
          type: "rightAligned",
          minWidth: 200,
          headerClass: clsx(
            ColumnGroup.CapitalInvested,
            "ag-right-aligned-header"
          ),
          cellClass: ColumnGroup.CapitalInvested,
        },
      ],
    },
    {
      headerName: PerformanceDetailsLabel.REALIZED_PROCEEDS,
      headerGroupComponent: GridHeaderWithSubHeader,
      headerClass: ColumnGroup.RealizedProceeds,
      headerGroupComponentParams: {
        subHeader: [
          PerformanceDetailsLabel.getRealizedProceedsHeader(
            realizedDate ?? new Date(),
            earliestUnrealizedDate ?? new Date()
          ),
        ],
      },
      children: [
        {
          headerName: PerformanceDetailsLabel.RETURN_OF_CAPITAL,
          valueGetter: createGetter(InvestmentBreakdownKey.RETURN_OF_CAPITAL),
          cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
          type: "rightAligned",
          headerClass: clsx(
            ColumnGroup.RealizedProceeds,
            "ag-right-aligned-header"
          ),
          cellClass: ColumnGroup.RealizedProceeds,
        },
        {
          headerName: PerformanceDetailsLabel.GAIN_LOSS,
          valueGetter: createGetter(InvestmentBreakdownKey.REALIZED_GAIN_LOSS),
          cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
          type: "rightAligned",
          headerClass: clsx(
            ColumnGroup.RealizedProceeds,
            "ag-right-aligned-header"
          ),
          cellClass: ColumnGroup.RealizedProceeds,
        },
        {
          headerName: PerformanceDetailsLabel.CARRY_AND_INCENTIVE_FEES,
          valueGetter: createGetter(
            InvestmentBreakdownKey.REALIZED_CARRIED_INTEREST
          ),
          cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
          type: "rightAligned",
          headerClass: clsx(
            ColumnGroup.RealizedProceeds,
            "ag-right-aligned-header"
          ),
          cellClass: ColumnGroup.RealizedProceeds,
        },
        {
          headerName: PerformanceDetailsLabel.REALIZED_TOTAL,
          valueGetter: createGetter(
            InvestmentBreakdownKey.TOTAL_REALIZED_PROCEEDS
          ),
          cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
          type: "rightAligned",
          headerClass: clsx(
            ColumnGroup.RealizedProceeds,
            "ag-right-aligned-header"
          ),
          cellClass: ColumnGroup.RealizedProceeds,
        },
      ],
    },
    {
      headerName: PerformanceDetailsLabel.UNREALIZED_VALUE,
      headerGroupComponent: GridHeaderWithSubHeader,
      headerClass: ColumnGroup.UnrealizedValues,
      headerGroupComponentParams: {
        subHeader: [
          PerformanceDetailsLabel.getUnrealizedValueHeader(unrealizedDate),
        ],
      },
      children: [
        {
          headerName: PerformanceDetailsLabel.REMAINING_INVESTMENT,
          valueGetter: createGetter(
            InvestmentBreakdownKey.REMAINING_CAPITAL_INVESTED
          ),
          cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
          type: "rightAligned",
          headerClass: clsx(
            ColumnGroup.UnrealizedValues,
            "ag-right-aligned-header"
          ),
          cellClass: ColumnGroup.UnrealizedValues,
        },
        {
          headerName: PerformanceDetailsLabel.GAIN_LOSS,
          valueGetter: createGetter(
            InvestmentBreakdownKey.UNREALIZED_GAIN_LOSS
          ),
          cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
          type: "rightAligned",
          headerClass: clsx(
            ColumnGroup.UnrealizedValues,
            "ag-right-aligned-header"
          ),
          cellClass: ColumnGroup.UnrealizedValues,
        },
        {
          headerName: PerformanceDetailsLabel.CARRY,
          valueGetter: createGetter(
            InvestmentBreakdownKey.UNREALIZED_CARRIED_INTEREST
          ),
          cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
          type: "rightAligned",
          headerClass: clsx(
            ColumnGroup.UnrealizedValues,
            "ag-right-aligned-header"
          ),
          cellClass: ColumnGroup.UnrealizedValues,
        },
        {
          headerName: PerformanceDetailsLabel.UNREALIZED_TOTAL,
          valueGetter: createGetter(
            InvestmentBreakdownKey.TOTAL_UNREALIZED_VALUE
          ),
          cellRenderer: getMonospaceCellRenderer(getCurrencyFormattedValue),
          type: "rightAligned",
          headerClass: clsx(
            ColumnGroup.UnrealizedValues,
            "ag-right-aligned-header"
          ),
          cellClass: ColumnGroup.UnrealizedValues,
        },
      ],
    },
  ];
};

export const EXCEL_STYLES: ExcelStyle[] = [1, 2, 3].map((i: number) => {
  return {
    id: `indent-${i}`,
    alignment: {
      indent: i,
    },
    // note, dataType: 'String' required to ensure that numeric values aren't right-aligned
    dataType: "String",
  };
});
