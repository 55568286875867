import { Grid } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Cell, Pie, PieChart, Sector, Tooltip } from "recharts";

import { ResponsiveRechartsWrapper } from "../../../../../components/ResponsiveRechartsWrapper/ResponsiveRechartsWrapper";
import { ALL_FUNDS_PLACEHOLDER } from "../../../../../constants/InvestmentBreakdownConstants";
import { SectionHeader } from "../../../../../constants/LabelAndTooltipConstants";
import { PieConstants } from "../../../../../constants/PieConstants";
import { setPointerType } from "../../../../../redux/actions/viewActions";
import { selectLastPointerType } from "../../../../../redux/selectors";
import colors from "../../../../../styles/_colors.scss";
import { IInvestmentChartData } from "../../../../../types/dataTypes";
import { Optional, some } from "../../../../../types/typeUtils";
import { getChartCellColorByIndex } from "../../../../../utils/charting";
import { getCurrencyFormattedValueWithZeroDefault } from "../../../../../utils/formatters";
import styles from "./InvestmentChart.module.scss";
import { InvestmentChartTooltip } from "./InvestmentChartTooltip";

interface InvestmentChartProps {
  investmentChartData: IInvestmentChartData[];
  unrealizedInvestments: number | undefined;
  activeIndex: number | undefined;
  setActiveIndex: (val: number | undefined) => void;
  setSelectedBusinessUnit: (
    value: React.SetStateAction<Optional<IInvestmentChartData>>
  ) => void;
  verticalAlignCenter?: boolean;
}

const NoValueInvestmentChart: IInvestmentChartData[] = [
  {
    businessUnitName: "",
    fundShortName: "",
    mdmFundId: 0,
    unrealizedValue: {
      asOfDate: new Date(),
      remainingCapitalInvested: 0,
      gainLoss: 0,
      optionalAndMandatoryInvestments: 1,
      carriedInterest: 0,
      total: 0,
    },
  },
];

export const InvestmentChart = (props: InvestmentChartProps) => {
  const {
    investmentChartData,
    unrealizedInvestments,
    verticalAlignCenter,
    activeIndex,
    setActiveIndex,
    setSelectedBusinessUnit,
  } = props;

  const lastPointerType = useSelector(selectLastPointerType);
  const dispatch = useDispatch();

  const isClickable = useMemo(() => {
    return (
      investmentChartData.length > 0 &&
      investmentChartData[0].mdmFundId === ALL_FUNDS_PLACEHOLDER
    );
  }, [investmentChartData]);

  //Validates if the list of holdback per fund has 1 or more values
  const investmentChartHasValues = investmentChartData.length > 0;
  //Position for the X Axis from pie chart
  const xAxis = "50%";
  //Position for the Y Axis from pie chart
  const yAxis = !!verticalAlignCenter ? 115 : 94;
  const pieStyle = {
    top: 10,
    right: 0,
    bottom: 0,
    left: 0,
  };

  const onMouseEnter = (data: IInvestmentChartData, idx: number) => {
    setActiveIndex(idx);
  };

  const onMouseLeave = () => {
    setActiveIndex(undefined);
  };

  const handleSliceClick = useCallback(
    (
      investmentChartData: IInvestmentChartData,
      index: number,
      event: React.PointerEvent<SVGElement>
    ) => {
      event.stopPropagation();
      dispatch(setPointerType(event.pointerType));
      if (event.pointerType === "mouse") {
        if (investmentChartData.mdmFundId === ALL_FUNDS_PLACEHOLDER) {
          setSelectedBusinessUnit(some(investmentChartData));
          setActiveIndex(undefined);
        }
      } else {
        setActiveIndex(index);
      }
    },
    [dispatch, setActiveIndex, setSelectedBusinessUnit]
  );

  const onLinkClick = () => {
    if (activeIndex !== undefined) {
      setSelectedBusinessUnit(some(investmentChartData[activeIndex]));
      setActiveIndex(undefined);
    }
  };

  const renderActiveShape = ({
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  }: {
    cx: number;
    cy: number;
    innerRadius: number;
    outerRadius: number;
    startAngle: number;
    endAngle: number;
    fill: string;
  }) => {
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 10}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          cursor={isClickable ? "pointer" : "default"}
        />
      </g>
    );
  };

  return (
    <>
      <Grid item xs={12} md={12} className={styles.pie}>
        <ResponsiveRechartsWrapper
          onTouchStart={() => setActiveIndex(undefined)}
        >
          <PieChart
            onMouseLeave={onMouseLeave}
            className={styles.pie}
            margin={pieStyle}
          >
            {investmentChartHasValues && (
              <Tooltip<number, string>
                cursor={{
                  /* Recharts adds a gray hover when there's no data by default, 
                this sets the hover color to white, so we don't have a weird hover when there's no data selected*/
                  stroke:
                    investmentChartData.length === 0
                      ? colors.white
                      : colors.lightest_grey,
                }}
                content={() =>
                  activeIndex !== undefined &&
                  investmentChartData[activeIndex] && (
                    <InvestmentChartTooltip
                      activeData={investmentChartData[activeIndex]}
                      onLinkClick={onLinkClick}
                    />
                  )
                }
                wrapperStyle={{
                  pointerEvents: lastPointerType === "mouse" ? "none" : "all",
                  visibility: activeIndex === undefined ? "hidden" : "visible",
                }}
              />
            )}
            <text
              dx={xAxis}
              y={yAxis - 5}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieCenterText}
              fill={colors.bx_primary_grey_text}
            >
              {SectionHeader.UNREALIZED_INVESTMENTS}
            </text>
            <text
              dx={xAxis}
              y={yAxis + 25}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieCenterValue}
            >
              {getCurrencyFormattedValueWithZeroDefault(unrealizedInvestments)}
            </text>
            <Pie
              data={
                investmentChartHasValues
                  ? investmentChartData
                  : NoValueInvestmentChart
              }
              cx={xAxis}
              cy={yAxis}
              innerRadius={PieConstants.INNER_RADIUS}
              outerRadius={PieConstants.OUTER_RADIUS}
              fill={colors.white}
              blendStroke={investmentChartData.length <= 1}
              minAngle={investmentChartData.length > 1 ? 10 : 0}
              dataKey="unrealizedValue.optionalAndMandatoryInvestments"
              nameKey="businessUnitName"
              startAngle={90} // pie chart should begin at 12 o'clock position
              endAngle={-270} // pie chart should go clockwise, which is negative direction
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onPointerDown={handleSliceClick}
            >
              {investmentChartHasValues &&
                investmentChartData.map((investmentData, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      investmentData.unrealizedValue
                        .optionalAndMandatoryInvestments !== 0
                        ? getChartCellColorByIndex(index)
                        : colors.bx_slate
                    }
                    cursor={isClickable ? "pointer" : "default"}
                  />
                ))}
              {NoValueInvestmentChart.map((index) => (
                <Cell key={`cell-${index}`} fill={colors.medium_grey} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveRechartsWrapper>
      </Grid>
    </>
  );
};
