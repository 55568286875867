import { Typography } from "@mui/material";
import { SectionHeader } from "common";
import React from "react";

import styles from "./ElectionAdminPage.module.scss";
import ElectorViewGrid from "./ElectorViewGrid/ElectorViewGrid";

const ElectionAdminPage = () => (
  <div className={styles.page}>
    <div className={styles.pageHeader}>
      <Typography variant="h1">{SectionHeader.ELECTOR_VIEW}</Typography>
    </div>
    <div className={styles.pageContent}>
      <ElectorViewGrid />
    </div>
  </div>
);

export default ElectionAdminPage;
