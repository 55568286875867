import {
  DataLoadStatus,
  LoadingIndicator,
  NoDataAvailableError,
  reqAllEntitlements,
} from "common";
import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AdminUIStore } from "../../../redux/store";
import { AdminUIEntitlementStore } from "../../../types/storeTypes";

export interface IHasEntitlementContainerProperties {
  children: ReactNode;
  entitlementName: keyof AdminUIEntitlementStore;
  entitlementLoadStatusName: keyof AdminUIEntitlementStore;
  noDataChildren?: ReactNode;
}

export const HasEntitlementContainer = ({
  children,
  entitlementName,
  entitlementLoadStatusName,
  noDataChildren,
}: IHasEntitlementContainerProperties) => {
  const dispatch = useDispatch();

  const entitlements = useSelector((store: AdminUIStore) => store.entitlements);

  const entitlementToCheck = entitlements[entitlementName] as boolean;
  const entitlementLoadStatus = entitlements[
    entitlementLoadStatusName
  ] as DataLoadStatus;

  useEffect(() => {
    async function checkData() {
      if (entitlementLoadStatus === DataLoadStatus.NOT_REQUESTED) {
        dispatch(reqAllEntitlements());
      }
    }
    checkData();
  }, [entitlementLoadStatus, dispatch]);

  const nonSuccessComponent =
    entitlementLoadStatus === DataLoadStatus.UNSUCCESSFUL ||
    entitlementLoadStatus === DataLoadStatus.EMPTY_RESPONSE ||
    !entitlementToCheck ? (
      <NoDataAvailableError />
    ) : (
      <LoadingIndicator />
    );

  return entitlementToCheck &&
    entitlementLoadStatus === DataLoadStatus.SUCCESSFUL
    ? children
    : noDataChildren ?? nonSuccessComponent;
};
