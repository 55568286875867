import { createSelector } from "@reduxjs/toolkit";

import {
  IBankAccountsStore,
  ICountry,
  ICurrency,
} from "../../types/bankAccountDataTypes";
import { selectBaseStore } from "./baseStoreSelectors";

export const selectBankAccounts = createSelector(
  selectBaseStore,
  ({ bankAccounts }): IBankAccountsStore => bankAccounts
);

export const selectBankAccountCountries = createSelector(
  selectBankAccounts,
  ({ countries }): ICountry[] => countries
);

export const selectBankAccountCurrencies = createSelector(
  selectBankAccounts,
  ({ currencies }): ICurrency[] => currencies
);
