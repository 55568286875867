import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  InputAdornment,
  List,
  ListItem,
  Paper,
  TextField,
} from "@mui/material";
import React, {
  HTMLAttributes,
  ReactElement,
  useEffect,
  useState,
} from "react";

import styles from "./DropdownSearchBar.module.scss";

interface IDropdownSearchBarProps<T> {
  placeholder: string;
  getNoOptionsMessage?: (inputValue: string) => string | undefined;
  dropdownOptions: T[];
  onChange: (value: T | null) => void;
  getOptionLabel: (option: T) => string;
  filterAndSortDropdownOptions: (options: T[], currentInput: string) => T[];
  maxDropdownOptions?: number | undefined;
  className?: string | undefined;
  initialValue?: T;
}

export const DropdownSearchBar = <T,>({
  placeholder,
  dropdownOptions,
  onChange,
  getOptionLabel,
  filterAndSortDropdownOptions,
  maxDropdownOptions,
  getNoOptionsMessage,
  className,
  initialValue,
}: IDropdownSearchBarProps<T>) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredOptionsLength, setFilteredOptionsLength] = useState<number>(0);
  // if user has only entered 1 character, don't show a dropdown
  const hideDropdown = inputValue.length < 2;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    initialValue
      ? setInputValue(getOptionLabel(initialValue))
      : setInputValue("");
  }, [getOptionLabel, initialValue]);

  const renderPaper = (
    params: HTMLAttributes<HTMLElement>,
    optionsLength: number
  ): ReactElement<unknown, "div"> | null => {
    const { children, ...other } = params;

    if (hideDropdown) {
      return null;
    }
    return (
      <Paper {...other} className={styles.dropdown}>
        {children}
        {maxDropdownOptions && optionsLength > maxDropdownOptions && (
          <ListItem
            className={`${styles.option} ${styles.disabled}`}
          >{`Please specify your search further.`}</ListItem>
        )}
        {optionsLength == 0 && (
          <List>
            <ListItem className={`${styles.option} ${styles.disabled}`}>
              {getNoOptionsMessage === undefined
                ? "No options"
                : getNoOptionsMessage(inputValue)}
            </ListItem>
          </List>
        )}
      </Paper>
    );
  };

  const renderOption = (
    props: React.HTMLProps<HTMLLIElement>,
    option: T
  ): JSX.Element => (
    <li {...props} key={props.key} className={styles.option}>
      {typeof option === "string" ? option : getOptionLabel(option)}
    </li>
  );

  const filterOptions = (options: T[]) => {
    const newOptions = filterAndSortDropdownOptions(options, inputValue);
    setFilteredOptionsLength(newOptions.length);
    return newOptions.slice(0, maxDropdownOptions);
  };

  return (
    <Autocomplete
      className={className}
      freeSolo
      value={inputValue}
      options={dropdownOptions}
      // remove floating border and box shadow when when dropdown is hidden
      componentsProps={
        hideDropdown
          ? {
              popper: {
                sx: {
                  border: `0px`,
                  boxShadow: `0px`,
                },
              },
            }
          : undefined
      }
      filterOptions={filterOptions}
      onChange={(_, value) => onChange(value as T)}
      renderOption={renderOption}
      getOptionLabel={(option) => {
        return typeof option === "string" ? option : getOptionLabel(option);
      }}
      PaperComponent={(params) => renderPaper(params, filteredOptionsLength)}
      renderInput={(params) => (
        <div>
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={styles.inputAdornment}
                >
                  <SearchIcon className={styles.searchIcon} />
                </InputAdornment>
              ),
              endAdornment: <></>,
            }}
            fullWidth
            size="small"
            sx={{
              marginBottom: 0,
            }}
            placeholder={placeholder}
            onChange={handleInputChange}
          />
        </div>
      )}
    />
  );
};
