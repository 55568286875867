import { FileDownloadOutlined } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import {
  EquityTooltip,
  getAsOfLine,
  IBaseStore,
  IconButtonWithTooltip,
  InfoTooltip,
  SectionHeader,
  StringConstants,
  useExcelDownload,
  worksheetGenerators,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import styles from "./ActiveAwardDetailsCardHeader.module.scss";

interface ActiveAwardDetailsCardHeaderProps {
  asOfDate?: Date;
}

export const ActiveAwardDetailsCardHeader = (
  props: ActiveAwardDetailsCardHeaderProps
) => {
  const { asOfDate } = props;
  const { selectedStockSymbol } = useSelector(
    (store: IBaseStore) => store.viewData
  );

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    await handleExcelDownload(
      worksheetGenerators.ActiveAwardDetails,
      `${SectionHeader.ACTIVE_AWARD_DETAILS}_${selectedStockSymbol}`,
      { selectedStockSymbol: selectedStockSymbol }
    );
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid container item alignItems="center" xs={10}>
        <Box className={styles.titleContainer}>
          <Box className={styles.title}>
            <Typography variant="h3">
              {SectionHeader.ACTIVE_AWARD_DETAILS}
            </Typography>
            <InfoTooltip
              tooltipContent={[EquityTooltip.ACTIVE_AWARDS_DETAILS]}
            />
          </Box>
          <Box>
            {asOfDate ? (
              <Typography variant="subtitle1">
                {getAsOfLine(asOfDate)}
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <IconButtonWithTooltip
          onClickFunction={handleDownloadClick}
          buttonIcon={FileDownloadOutlined}
          hoverText={StringConstants.DOWNLOAD}
        />
      </Grid>
    </Grid>
  );
};
