import { Card } from "@mui/material";
import React from "react";

import { InfoTooltip } from "../../../../../components/InfoTooltip/InfoTooltip";
import { MonospaceNumber } from "../../../../../components/MonospaceNumber/MonospaceNumber";
import { IStockDataValue } from "../../../../../types/dataTypes";
import { getCurrencyFormattedTwoDPValueWithZeroDefault } from "../../../../../utils/formatters";
import styles from "./StockCallout.module.scss";
import { StockChart } from "./StockChart/StockChart";

interface ICalloutProps {
  label: string;
  headerTooltipParagraphs?: string[] | undefined;
  value: number;
  showHeaderTooltip?: boolean | undefined;
  showChart?: boolean | undefined;
  chartData?: IStockDataValue[] | undefined;
}

export const StockCallout = (props: ICalloutProps) => {
  const {
    label,
    headerTooltipParagraphs,
    value,
    showHeaderTooltip = false,
    showChart = false,
    chartData = [],
  } = props;

  return (
    <Card className={styles.callout}>
      <span className={styles.calloutSubLeft}>
        <div className={styles.calloutLabel}>
          <span>{label}</span>
          {showHeaderTooltip && (
            <InfoTooltip tooltipContent={headerTooltipParagraphs ?? [""]} />
          )}
        </div>
        <span className={styles.calloutValue}>
          <MonospaceNumber
            value={value}
            valueFormatter={getCurrencyFormattedTwoDPValueWithZeroDefault}
          />
        </span>
      </span>
      {showChart && (
        <span className={styles.calloutSubRight}>
          <StockChart chartData={chartData} />
        </span>
      )}
    </Card>
  );
};
