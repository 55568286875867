import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { FormLabel, Stack } from "@mui/material";
import { IconWithTooltip, InfoTooltip } from "common";
import React from "react";

import styles from "./FieldLabelWithTooltip.module.scss";

type FieldLabelWithTooltipProps = {
  label: string;
  tooltip?: string | React.ReactNode;
  warningMessage?: string;
  required?: boolean;
  disabled?: boolean;
};

const FieldLabelWithTooltip = ({
  label,
  tooltip,
  required = true,
  disabled = false,
  warningMessage,
}: FieldLabelWithTooltipProps) => {
  return (
    <Stack direction="row" alignItems="center">
      <FormLabel
        className={styles.inputLabel}
        disabled={disabled}
        required={required}
      >
        <span>{label}</span>
      </FormLabel>
      {tooltip ? (
        <InfoTooltip
          className={styles.tooltip}
          tooltipContent={tooltip}
          leftMargin={4}
        />
      ) : null}
      {warningMessage && (
        <IconWithTooltip
          icon={WarningRoundedIcon}
          hoverText={warningMessage}
          iconClassName={styles.warningIcon}
        />
      )}
    </Stack>
  );
};

export default FieldLabelWithTooltip;
