import { Stack } from "@mui/material";
import {
  AccountAssignmentRow,
  BankAccountAssignment,
  GPBankAccount,
  IElectionWorkflowPageProps,
  isSomething,
  openAlert,
  resetStageValidation,
  selectHasUSBankAccount,
} from "common";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { useMoveStages } from "../../../../hooks/electionHooks";
import { IBaseStore } from "../../../../redux/store";
import { BankInfoCard } from "./BankInfoCard/BankInfoCard";

export interface IBankAccountStageFormState {
  hasUSBankAccount: string | null;
  bankAccountAssignments: AccountAssignmentRow[];
}

export const BankAccountStage = (props: IElectionWorkflowPageProps) => {
  const { isAdmin = false } = props;
  const dispatch = useDispatch();

  const { stageValidationRequested } = useSelector(
    (state: IBaseStore) => state.viewData
  );

  const { bankAccounts } = useSelector(
    (state: IBaseStore) => state.bankAccounts.serverData
  );

  const showDirectDebitRequirementCard: boolean = useMemo(() => {
    // show this card when there are no approved Distribution bank accounts
    return (
      bankAccounts.filter((bankAccount: GPBankAccount) => {
        return bankAccount.bankAccount.assignments.some(
          (assignment) => assignment === BankAccountAssignment.DISTRIBUTION
        );
      }).length === 0
    );
  }, [bankAccounts]);

  const moveStages = useMoveStages();

  const hasUSBankAccount = useSelector((state: IBaseStore) =>
    selectHasUSBankAccount(state)
  );

  const bankAccountFormDefaultValues = {
    hasUSBankAccount: isSomething(hasUSBankAccount)
      ? hasUSBankAccount.value.toString()
      : null,
    bankAccountAssignments: [],
  };

  const bankAccountStageForm = useForm<IBankAccountStageFormState>({
    mode: "onChange",
    defaultValues: bankAccountFormDefaultValues,
  });

  const bankInfoRef = useRef<HTMLFormElement>(null);
  const [hasBankInfoCardErrors, setHasBankInfoCardErrors] =
    useState<boolean>(false);

  const setErrorToast = useCallback(
    (message: string) =>
      dispatch(
        openAlert({
          severity: "error",
          message,
          hideDuration: 5000,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    // condition to make useEffect hook not run on initial render
    // we must run the validation only when next button/tab was clicked
    // so, stageValidationRequested must be set to 0 after switching to next stage again
    if (stageValidationRequested) {
      // declaring the fn inside the hook since we would'nt have it as dependency
      const triggerFormValidations = async () => {
        // triggers the form validation
        const result = await bankAccountStageForm.trigger();

        // checks if the form has a valid state so that data can be saved, then allowed to go to next stage
        // there could be more than one form to be validated in other stages
        if (result) {
          //this will be done after saving to the api successfully later
          moveStages();
        } else {
          const assignmentError = await bankAccountStageForm.getFieldState(
            "bankAccountAssignments"
          ).error;

          let errorToastMessage = "";
          let errorRef: HTMLFormElement | null = null;

          if (assignmentError && assignmentError.message) {
            setHasBankInfoCardErrors(true);
            errorToastMessage = assignmentError.message;
            errorRef = bankInfoRef.current;
          } else {
            setHasBankInfoCardErrors(false);
          }

          setErrorToast(errorToastMessage);
          //scroll to where the error was thrown
          errorRef?.current?.scrollIntoView({
            behavior: "smooth",
          });
        }
      };

      dispatch(resetStageValidation());
      triggerFormValidations();
    }
  }, [
    dispatch,
    stageValidationRequested,
    bankAccountStageForm,
    showDirectDebitRequirementCard,
    setErrorToast,
    moveStages,
  ]);

  return (
    <Stack>
      <form ref={bankInfoRef}>
        <BankInfoCard
          form={bankAccountStageForm}
          hasErrors={hasBankInfoCardErrors}
          isAdmin={isAdmin}
        />
      </form>
    </Stack>
  );
};
