import Grid from "@mui/material/Grid";
import React, { useState } from "react";

import { StockSymbol } from "../../constants/enums";
import {
  DashboardLabel,
  EquityLabel,
} from "../../constants/LabelAndTooltipConstants";
import { MUIConstants } from "../../constants/MUIConstants";
import { IBXEquityCardData } from "../../types/dataTypes";
import { getTotalsRowNamesAndSubTotals } from "../../utils/EquityRowUtils";
import {
  formatDateMMMDDYYYYOrNull,
  getCurrencyFormattedTwoDPValueWithZeroDefault,
} from "../../utils/formatters";
import { DashboardCardTable } from "../DashboardCardTable/DashboardCardTable";
import { EquityCardChart } from "../EquityCardChart/EquityCardChart";

interface IEquityRowProps {
  equityCardData: IBXEquityCardData;
  selectedStockSymbol: StockSymbol;
  headerCols?: string[];
  hasViewRestrictedEquity: boolean;
}

export const EquityRow = (props: IEquityRowProps) => {
  const {
    equityCardData,
    headerCols,
    selectedStockSymbol,
    hasViewRestrictedEquity,
  } = props;

  // States to controll the Row Expand/Collapse of the DashboardCardTable
  //Set expand/collapse for total unvested
  const [isTotalRowOpen, setTotalRowOpen] = useState(false);
  //Set expand/collapse for number of unvested
  const [isTotalUnitsRowOpen, setTotalUnitsRowOpen] = useState(false);
  //Set expand/collapse for bx nyse
  const [isBXNYSERowOpen, setBXNYSERowOpen] = useState(false);
  //Set expand/collapse for next vesting
  const [isNextVestingRowOpen, setNextVestingRowOpen] = useState(false);

  const { totalValue, totalUnits } = getTotalsRowNamesAndSubTotals(
    equityCardData,
    selectedStockSymbol,
    hasViewRestrictedEquity
  );

  const rows = [
    {
      name: totalValue.name,
      value: totalValue.value,
      isOpen: isTotalRowOpen,
      setIsOpen: setTotalRowOpen,
      disableCollapse: true,
      children:
        totalValue.subItems.length !== 0 ? totalValue.subItems : undefined,
    },
    {
      name: totalUnits.name,
      value: totalUnits.value,
      valueFormatter: totalUnits.valueFormatter,
      isOpen: isTotalUnitsRowOpen,
      setIsOpen: setTotalUnitsRowOpen,
      disableCollapse: true,
      children:
        totalUnits.subItems.length !== 0 ? totalUnits.subItems : undefined,
    },
    {
      name: EquityLabel.STOCK_NYSE(selectedStockSymbol),
      value: getCurrencyFormattedTwoDPValueWithZeroDefault(
        equityCardData.latestBXStockValue.value
      ),
      tooltipParagraphs: [
        DashboardLabel.Equity.STOCK_PRICE_TOOLTIP(
          equityCardData.latestBXStockValue.asOfDate
        ),
      ],
      isOpen: isBXNYSERowOpen,
      setIsOpen: setBXNYSERowOpen,
    },
    {
      name: EquityLabel.NEXT_VESTING_DATE,
      value: formatDateMMMDDYYYYOrNull(equityCardData.nextVestingDate),
      isOpen: isNextVestingRowOpen,
      setIsOpen: setNextVestingRowOpen,
    },
  ];

  return (
    <Grid
      container
      columnSpacing={MUIConstants.DASHBOARD_WIDGET_COLUMN_SPACING}
    >
      <EquityCardChart equityCardData={equityCardData} />
      <Grid item xs={12} md={MUIConstants.DASHBOARD_WIDGET_RIGHT_ITEM_SIZE}>
        <DashboardCardTable
          header={EquityLabel.UNVESTED_EQUITY_OVERVIEW}
          headerCols={headerCols ? headerCols : []}
          rows={rows}
        />
      </Grid>
    </Grid>
  );
};
