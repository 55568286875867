import {
  downloadFile,
  getFormattedDateYYYYMMDD,
  IBaseStore,
  IEmployee,
  isSomething,
  selectActiveInvestmentEntityData,
  selectAnnualCommitments,
  selectCallout,
  selectForecastedCommitmentByStrategy,
  selectHistoricalSumaryGrouped,
  selectInvestmentBreakdownGrouped,
  selectLifeOfFundCommitments,
  StockSymbol,
  worksheetGenerators,
} from "common";
import ExcelJS from "exceljs";
import { useSelector } from "react-redux";
import sanitize from "sanitize-filename";

import { DataCategoryName, defaultExcelSettings } from "./adminDownloadExcel";

export const useEquityAdminDownload = () => {
  const equityData = useSelector((store: IBaseStore) => store.equityData);
  const isAdmin = true;

  const selectedElection = useSelector(
    (store: IBaseStore) => store.selectedElection
  );
  const internalInvestmentData = useSelector(selectActiveInvestmentEntityData);

  const callout = useSelector(selectCallout);

  const { investmentBreakdownGrouped } = useSelector(
    selectInvestmentBreakdownGrouped
  );

  const { historicalSummaryGrouped } = useSelector(
    selectHistoricalSumaryGrouped
  );

  const { forecastedCommitmentByStrategy } = useSelector(
    selectForecastedCommitmentByStrategy
  );

  const lifeOfFundCommitments = useSelector(selectLifeOfFundCommitments);
  const annualCommitments = useSelector(selectAnnualCommitments);

  const handleDownload = async (
    selectedEmployee: IEmployee,
    supportedStocks: StockSymbol[],
    onSuccess: () => void,
    onFailure: () => void
  ) => {
    try {
      // set up excel workbook
      const workbook = new ExcelJS.Workbook();
      workbook.created = new Date();

      //start with Equity Overview
      supportedStocks.forEach((stock) => {
        const showActiveAward = isSomething(equityData.equityData[stock]);

        const generator = worksheetGenerators[DataCategoryName.EQUITY_OVERVIEW];
        const params = {
          workbook,
          equityData,
          internalInvestmentData,
          callout,
          investmentBreakdownGrouped,
          historicalSummaryGrouped,
          forecastedCommitmentByStrategy,
          lifeOfFundCommitments,
          annualCommitments,
          isAdmin,
          settings: {
            showEmptyColumns: defaultExcelSettings.showEmptyColumns,
            valueDisplay: defaultExcelSettings.valueDisplay,
            periodType: defaultExcelSettings.periodType,
            selectedStockSymbol: stock,
          },
          selectedElection,
        };
        if (showActiveAward && generator !== undefined) {
          generator(params);
        }
      });

      // do Active award details sheet
      // for each stock in selected stocks:
      supportedStocks.forEach((stock) => {
        const showActiveAward = isSomething(equityData.equityData[stock]);

        const generator =
          worksheetGenerators[DataCategoryName.ACTIVE_AWARD_DETAILS];
        const params = {
          workbook,
          equityData,
          internalInvestmentData,
          callout,
          investmentBreakdownGrouped,
          historicalSummaryGrouped,
          forecastedCommitmentByStrategy,
          lifeOfFundCommitments,
          annualCommitments,
          isAdmin,
          settings: {
            showEmptyColumns: defaultExcelSettings.showEmptyColumns,
            valueDisplay: defaultExcelSettings.valueDisplay,
            periodType: defaultExcelSettings.periodType,
            selectedStockSymbol: stock,
          },
          selectedElection,
        };
        if (showActiveAward && generator !== undefined) {
          generator(params);
        }
      });

      // do the distributions pages
      supportedStocks.forEach((stock) => {
        const showActiveAward = isSomething(equityData.equityData[stock]);

        const generator =
          worksheetGenerators[DataCategoryName.EQUITY_DISTRIBUTIONS];
        const params = {
          workbook,
          equityData,
          internalInvestmentData,
          callout,
          investmentBreakdownGrouped,
          historicalSummaryGrouped,
          forecastedCommitmentByStrategy,
          lifeOfFundCommitments,
          annualCommitments,
          isAdmin,
          settings: {
            showEmptyColumns: defaultExcelSettings.showEmptyColumns,
            valueDisplay: defaultExcelSettings.valueDisplay,
            periodType: defaultExcelSettings.periodType,
            selectedStockSymbol: stock,
          },
          selectedElection,
        };
        if (showActiveAward && generator !== undefined) {
          generator(params);
        }
      });

      // create file blob from generated excel
      const excelFileBlob = await workbook.xlsx.writeBuffer();

      // sanitized filename
      const employeeFileName = `Admin Equity Export_${sanitize(
        selectedEmployee.name
      )}_${selectedEmployee.id}_${getFormattedDateYYYYMMDD(
        new Date(),
        false
      )}.xlsx`;

      // create file and download
      const employeeFile = new File([excelFileBlob], employeeFileName);
      downloadFile(employeeFile, employeeFileName);

      onSuccess();
    } catch (error) {
      onFailure();
    }
  };

  return handleDownload;
};
