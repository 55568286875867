import { AddWorksheetOptions, Workbook } from "exceljs";

import { StockSymbol } from "../../constants/enums";
import {
  ActiveAwardDetailsFileLabel,
  DistributionsLabel,
  EquityLabel,
  EquityOverviewUnitTypes,
  EquityOverviewWorkSheet,
  SectionHeader,
} from "../../constants/LabelAndTooltipConstants";
import { StringConstants } from "../../constants/StringConstants";
import {
  IAward,
  IDistributionsDatum,
  IEquityDatum,
  IStockDataValue,
  IVestingData,
} from "../../types/dataTypes";
import {
  IActiveAwardDetailsRow,
  IDistributionsRow,
  IEquityOverviewRow,
  WorksheetGeneratorParams,
} from "../../types/excelTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing } from "../../types/typeUtils";
import { convertFromOptional } from "../converters";
import { getAsOfDateAndTime, getFormattedDateYYYYMMDD } from "../formatters";
import {
  ExcelConstants,
  formatBottomRow,
  formatHeaderRows,
  getDateWithZeroOffset,
} from "./excelUtils";

const distributionColumns = [
  {
    header: DistributionsLabel.DIST_DATE,
    key: ExcelConstants.KEYS.DISTRIBUTION_DATE,
    width: ExcelConstants.SIZES.SMALL,
    style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.YYYYMMDD_DATE },
  },
  {
    header: DistributionsLabel.TICKER,
    key: ExcelConstants.KEYS.TICKER,
    width: ExcelConstants.SIZES.XSMALL,
  },
  {
    header: DistributionsLabel.UNIT_TYPE,
    key: ExcelConstants.KEYS.UNIT_TYPE,
    width: ExcelConstants.SIZES.XSMALL,
  },
  {
    header: DistributionsLabel.DIST_PER_UNIT,
    key: ExcelConstants.KEYS.DISTRIBUTION_PER_UNIT,
    width: ExcelConstants.SIZES.MEDIUM,
  },
  {
    header: DistributionsLabel.UNITS_ENTITLED,
    key: ExcelConstants.KEYS.UNITS_ENTITLED,
    width: ExcelConstants.SIZES.MEDIUM,
    style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.COMMA_SEPARATED_INTEGER },
  },
  {
    header: DistributionsLabel.PAYMENT_AMOUNT,
    key: ExcelConstants.KEYS.DIVIDEND_AMOUNT,
    width: ExcelConstants.SIZES.MEDIUM,
    style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
  },
];

/**
 * Maps IDistributionsDatum object to be IDistributionsRow object
 * @param distribution - The distribution item
 * @returns A list of distribution rows
 */
const mapToDistributionRows = (
  distribution: IDistributionsDatum
): IDistributionsRow => {
  return {
    ...distribution,
    distributionDate: getDateWithZeroOffset(distribution.distributionDate),
    distributionPerUnit: Number(distribution.distributionPerUnit),
    ticker: distribution.ticker,
    unitType: distribution.unitType,
  } as IDistributionsRow;
};

const getActiveAwardDetailsData = (
  equityData: IEquityDatum,
  stockData: IStockDataValue[],
  selectedStockSymbol: StockSymbol
): IActiveAwardDetailsRow[] => {
  const rows: IActiveAwardDetailsRow[] = [];
  let totVestedUnits = 0;
  let totUnvestedUnit = 0;
  let totUnvestedValue = 0;

  const latestStockValue = stockData[0];

  equityData.awards.forEach((award: IAward) => {
    if (award.nextVestingDate.toLocaleDateString() === "Invalid Date") {
      return;
    }

    award.vestingData.forEach((vestingData: IVestingData) => {
      const isPastVestingDate = vestingData.date < new Date();
      const unitsVested = isPastVestingDate ? vestingData.quantity : 0;
      const unitsUnvested = vestingData.quantity - unitsVested;

      const awardRow: IActiveAwardDetailsRow = {
        award: award.name,
        vestingDate: getDateWithZeroOffset(vestingData.date),
        symbol: selectedStockSymbol,
        vesting: vestingData.date,
        grantDate: award.grantDate,
        finalVestingDate: award.finalVestingDate,
        unitsVested: unitsVested,
        unitsUnvested: unitsUnvested,
        unvestedValue: unitsUnvested * latestStockValue.value,
      };

      totVestedUnits += unitsVested;
      totUnvestedUnit += unitsUnvested;
      totUnvestedValue += unitsUnvested * latestStockValue.value;

      rows.push(awardRow);
    });
  });

  rows.sort(
    (a, b) =>
      a.grantDate.getTime() - b.grantDate.getTime() ||
      a.finalVestingDate.getTime() - b.finalVestingDate.getTime()
  );

  const totalRow: IActiveAwardDetailsRow = {
    award: EquityLabel.ACTIVE_AWARDS_DETAILS_FOOTER,
    symbol: "",
    vestingDate: "",
    grantDate: new Date(""),
    finalVestingDate: new Date(""),
    vesting: new Date(""),
    unitsVested: totVestedUnits,
    unitsUnvested: totUnvestedUnit,
    unvestedValue: totUnvestedValue,
  };

  rows.push(totalRow);

  return rows;
};

/**
 * Maps IEquityDatum object to be IEquityOverviewRow object
 * @param equityData - The equity data item.
 * @param stockData - The Stock value data.
 * @param selectedSymbol - the Selected Stock Symbol.
 * @returns A list of distribution rows
 */
const mapEquityDataToEquityOverviewRow = (
  equityData: IEquityDatum,
  stockData: IStockDataValue[],
  selectedStockSymbol: StockSymbol
): IEquityOverviewRow[] => {
  const rows: IEquityOverviewRow[] = [];

  const stockValue = stockData[0]?.value || 0;

  const unvestedRow: IEquityOverviewRow = {
    symbol: selectedStockSymbol,
    unitType: EquityOverviewUnitTypes.UNVESTED(selectedStockSymbol),
    valueType: EquityOverviewUnitTypes.UNVESTED(selectedStockSymbol),
    asOfDate: getFormattedDateYYYYMMDD(equityData.asOfDate, true),
    amount: equityData.totalUnvestedUnits,
    stockValue: stockValue,
    value: equityData.totalUnvestedUnits * stockValue,
  };
  // Add unvested row first
  rows.push(unvestedRow);

  // Add restricted row if applicable
  if (
    equityData.vestedRestrictedUnits &&
    equityData.vestedRestrictedUnits !== nothing &&
    equityData.vestedAndExchangeableAsOfDate
  ) {
    const restrictedRow: IEquityOverviewRow = {
      symbol: selectedStockSymbol,
      unitType: EquityOverviewUnitTypes.VESTED_RESTRICTED(selectedStockSymbol),
      valueType: EquityOverviewUnitTypes.VESTED_RESTRICTED(selectedStockSymbol),
      asOfDate: getFormattedDateYYYYMMDD(
        convertFromOptional(equityData.vestedAndExchangeableAsOfDate) ||
          new Date(),
        true
      ),
      amount: convertFromOptional(equityData.vestedRestrictedUnits) || 0,
      stockValue: stockValue,
      value:
        (convertFromOptional(equityData.vestedRestrictedUnits) || 0) *
        stockValue,
    };

    rows.push(restrictedRow);
  }

  // Add exchangeable row if applicable
  if (
    equityData.vestedExchangeableUnits &&
    equityData.vestedExchangeableUnits !== nothing &&
    equityData.vestedAndExchangeableAsOfDate
  ) {
    const exchangeableRow: IEquityOverviewRow = {
      symbol: selectedStockSymbol,
      unitType:
        EquityOverviewUnitTypes.VESTED_EXCHANGEABLE(selectedStockSymbol),
      valueType:
        EquityOverviewUnitTypes.VESTED_EXCHANGEABLE(selectedStockSymbol),
      asOfDate: getFormattedDateYYYYMMDD(
        convertFromOptional(equityData.vestedAndExchangeableAsOfDate) ||
          new Date(),
        true
      ),
      amount: convertFromOptional(equityData.vestedExchangeableUnits) || 0,
      stockValue: stockValue,
      value:
        (convertFromOptional(equityData.vestedExchangeableUnits) || 0) *
        stockValue,
    };

    rows.push(exchangeableRow);
  }

  return rows;
};

export const buildEquityOverviewWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const { workbook, equityData, settings, isAdmin } = params;
  const selectedStockSymbol = settings.selectedStockSymbol;
  const stockEquityData = equityData.equityData[selectedStockSymbol];
  const stockData = equityData.stockData[selectedStockSymbol];

  if (isSomething(stockEquityData) && isSomething(stockData)) {
    const worksheet = workbook.addWorksheet(
      isAdmin
        ? `${SectionHeader.EQUITY_OVERVIEW} ${selectedStockSymbol}`
        : SectionHeader.EQUITY_OVERVIEW,
      {
        views: [
          {
            state: "frozen",
            ySplit: ExcelConstants.HEADERS.DEFAULT_HEADER_ROWS,
          },
        ],
      }
    );
    worksheet.columns = [
      {
        header: EquityOverviewWorkSheet.UNIT_TYPE,
        key: ExcelConstants.KEYS.UNIT_TYPE,
        width: ExcelConstants.SIZES.BIG,
      },
      {
        header: EquityOverviewWorkSheet.AS_OF,
        key: ExcelConstants.KEYS.AS_OF_DATE,
        width: ExcelConstants.SIZES.SMALL,
      },
      {
        header: EquityOverviewWorkSheet.AMOUNT,
        key: ExcelConstants.KEYS.AMOUNT,
        width: ExcelConstants.SIZES.SMALL,
        style: {
          numFmt: ExcelConstants.NUMBER_FORMATTERS.COMMA_SEPARATED_INTEGER,
        },
      },
      {},
      {
        header: EquityOverviewWorkSheet.VALUE_TYPE,
        key: ExcelConstants.KEYS.VALUE_TYPE,
        width: ExcelConstants.SIZES.BIG,
      },
      {
        header: EquityOverviewWorkSheet.VALUE(getAsOfDateAndTime(new Date())),
        key: ExcelConstants.KEYS.VALUE,
        width: ExcelConstants.SIZES.BIG,
        style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
      },
    ];

    const data = mapEquityDataToEquityOverviewRow(
      stockEquityData.value,
      stockData.value,
      selectedStockSymbol
    );

    data.forEach((element: IEquityOverviewRow) => {
      worksheet.addRow(element);
    });

    formatHeaderRows(worksheet);
  }
  return workbook;
};

export const buildActiveAwardDetailsWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const { workbook, equityData, settings, isAdmin } = params;
  {
    const selectedStockSymbol = settings.selectedStockSymbol;
    const stockEquityData = equityData.equityData[selectedStockSymbol];
    const stockData = equityData.stockData[selectedStockSymbol];
    if (isSomething(stockEquityData) && isSomething(stockData)) {
      const worksheet = workbook.addWorksheet(
        isAdmin
          ? `${SectionHeader.ACTIVE_AWARD_DETAILS} ${selectedStockSymbol}`
          : SectionHeader.ACTIVE_AWARD_DETAILS,
        {
          views: [
            {
              state: "frozen",
              ySplit: ExcelConstants.HEADERS.DEFAULT_HEADER_ROWS,
            },
          ],
        }
      );
      worksheet.columns = [
        {
          header: ActiveAwardDetailsFileLabel.AWARD,
          key: ExcelConstants.KEYS.AWARD,
          width: ExcelConstants.SIZES.MEDIUM,
        },
        {
          header: ActiveAwardDetailsFileLabel.VESTING_DATE,
          key: ExcelConstants.KEYS.VESTING_DATE,
          width: ExcelConstants.SIZES.XSMALL,
          style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.YYYYMMDD_DATE },
        },
        {
          header: ActiveAwardDetailsFileLabel.TICKER,
          key: ExcelConstants.KEYS.SYMBOL,
          width: ExcelConstants.SIZES.XSMALL,
        },
        {
          header: ActiveAwardDetailsFileLabel.UNITS_VESTED,
          key: ExcelConstants.KEYS.UNITS_VESTED,
          width: ExcelConstants.SIZES.XSMALL,
          style: {
            numFmt: ExcelConstants.NUMBER_FORMATTERS.COMMA_SEPARATED_INTEGER,
          },
        },
        {
          header: ActiveAwardDetailsFileLabel.UNITS_UNVESTED,
          key: ExcelConstants.KEYS.UNITS_UNVESTED,
          width: ExcelConstants.SIZES.XSMALL,
          style: {
            numFmt: ExcelConstants.NUMBER_FORMATTERS.COMMA_SEPARATED_INTEGER,
          },
        },
        {
          header: `${
            ActiveAwardDetailsFileLabel.UNVESTED_VALUE
          } ${getAsOfDateAndTime(new Date())}`,
          key: ExcelConstants.KEYS.UNVESTED_VALUE,
          width: ExcelConstants.SIZES.BIG,
          style: { numFmt: ExcelConstants.NUMBER_FORMATTERS.DOLLAR },
        },
      ];
      worksheet.getColumn(2).style.alignment = { horizontal: "left" };
      const orderedActiveAwardDetails: IActiveAwardDetailsRow[] =
        getActiveAwardDetailsData(
          stockEquityData.value,
          stockData.value,
          selectedStockSymbol
        );
      orderedActiveAwardDetails.forEach((element: IActiveAwardDetailsRow) => {
        worksheet.addRow(element);
      });
      formatHeaderRows(worksheet);
      formatBottomRow(worksheet);
    }
  }
  return workbook;
};

/**
 * Builds the excel workbook for equity distributions
 * @param workbook - The workbook
 * @returns A workbook
 */
export const buildEquityDistributionsWorksheet = (
  params: WorksheetGeneratorParams
): Workbook => {
  const { workbook, equityData, settings, isAdmin } = params;
  const selectedStockSymbol = settings.selectedStockSymbol;
  const distributionData = equityData.distributionsData[selectedStockSymbol];
  if (isSomething(distributionData)) {
    const selectedStock = settings.selectedStockSymbol;
    const distributionsData = distributionData.value.filter((distribution) => {
      return distribution.ticker === selectedStock;
    });
    const worksheetName = isAdmin
      ? `${StringConstants.EQUITY} ${StringConstants.DISTRIBUTIONS} ${selectedStockSymbol}`
      : `${StringConstants.EQUITY} ${StringConstants.DISTRIBUTIONS}`;
    const worksheetOptions: Partial<AddWorksheetOptions> = {
      views: [
        {
          state: "frozen",
          ySplit: 1,
        },
      ],
    };
    const worksheet = workbook.addWorksheet(worksheetName, worksheetOptions);
    worksheet.columns = distributionColumns;
    worksheet.getColumn(1).style.alignment = { horizontal: "left" };
    const rows: IDistributionsRow[] = distributionsData.map(
      mapToDistributionRows
    );
    worksheet.addRows(rows);
    formatHeaderRows(worksheet);
  }
  return workbook;
};
