import {
  CalendarMonthOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { MenuItem, Select, Stack } from "@mui/material";
import {} from "common";
import React from "react";

import { IconButtonWithTooltip } from "../../components/IconButtonWithTooltip/IconButtonWithTooltip";
import { InvestmentValueDisplay, PeriodType } from "../../constants/enums";
import { StringConstants } from "../../constants/StringConstants";
import { useExcelDownload } from "../../hooks/useExcelDownload";
import { IHistoricalSummaryDatum } from "../../types/dataTypes";
import { WorksheetGeneratorName } from "../../types/excelTypes";
import { InvestmentHistoryValueItem } from "../../types/investmentHistoryTypes";
import { worksheetGenerators } from "../../utils/excelUtils/excelGenerationUtils";
import { getLabelFromCamelCase } from "../../utils/formatters";
import styles from "./PeriodToggle.module.scss";

interface PeriodToggleProps {
  tabSelected: WorksheetGeneratorName;
  periodType: PeriodType;
  setPeriodType: (value: PeriodType) => void;
  dataTypes: InvestmentHistoryValueItem[];
  setDataTypes: (value: InvestmentHistoryValueItem[]) => void;
  valueDisplay: InvestmentValueDisplay;
  historicalSummaryData: IHistoricalSummaryDatum[];
}

const periodTypeOrder = [PeriodType.QUARTERLY, PeriodType.YEARLY];

export interface ExcelRow {
  [key: string]: string | number;
}

export const PeriodToggle = (props: PeriodToggleProps) => {
  const { valueDisplay, periodType, tabSelected } = props;

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    const generator = worksheetGenerators[tabSelected];
    const fileName = `Investment History_${getLabelFromCamelCase(
      props.tabSelected
    )}`;
    await handleExcelDownload(generator, fileName, {
      valueDisplay: valueDisplay,
      periodType,
    });
  };

  return (
    <Stack direction="row" alignItems="center" className={styles.periodToggle}>
      <Select
        className={styles.periodSelector}
        disabled={
          props.historicalSummaryData === undefined ||
          props.historicalSummaryData.length === 0
        }
        onChange={(e) => props.setPeriodType(e.target.value as PeriodType)}
        renderValue={(value) => {
          return (
            <div className={styles.periodSelectorInput}>
              <CalendarMonthOutlined className={styles.calendar} />
              <span className={styles.selectorText}>{value}</span>
            </div>
          );
        }}
        value={props.periodType}
      >
        {periodTypeOrder.map((e: string, i: number) => (
          <MenuItem key={i} value={e}>
            {e}
          </MenuItem>
        ))}
      </Select>
      <IconButtonWithTooltip
        buttonIcon={FileDownloadOutlined}
        hoverText={StringConstants.DOWNLOAD}
        onClickFunction={() => handleDownloadClick()}
        disabled={
          props.historicalSummaryData === undefined ||
          props.historicalSummaryData.length === 0
        }
      />
    </Stack>
  );
};
