import { Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { MonospaceNumber } from "../../../../../components/MonospaceNumber/MonospaceNumber";
import { ResponsiveRechartsWrapper } from "../../../../../components/ResponsiveRechartsWrapper/ResponsiveRechartsWrapper";
import { BreakpointConstants } from "../../../../../constants/BreakpointConstants";
import { UpcomingVestingEventsLabel } from "../../../../../constants/LabelAndTooltipConstants";
import {
  xAxisLabelDy,
  xAxisTick,
  yAxisLabelDx,
  yAxisTick,
} from "../../../../../constants/RechartsSVGStyles";
import colors from "../../../../../styles/_colors.scss";
import {
  IDailyVestingEvent,
  IMonthlyVestingEvent,
  INextVestingEvent,
} from "../../../../../types/dataTypes";
import { getTicksAndLabels } from "../../../../../utils/charting";
import {
  formatDateMMMDDYYYYOrNull,
  getCurrencyFormattedValue,
} from "../../../../../utils/formatters";
import styles from "./VestingEventsChart.module.scss";

interface VestingEventsChartProps {
  monthlyVestingEvents: IMonthlyVestingEvent[];
}

/**
 * Custom Hook that tells if the top labels or the bottom labels of the bars are overlapping
 * @param barsCount - The number of bars
 * @returns A boolean
 */
const useLabelOverlappingState = (barsCount: number): boolean => {
  const [isLabelOverlapping, setIsLabelOverlapping] = useState<boolean>(false);

  const isExtraSmallWidth = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );
  const isSmallWidth = useMediaQuery(
    `(min-width:${BreakpointConstants.SMALL_MIN_WIDTH}px) and (max-width:${BreakpointConstants.SMALL_MAX_WIDTH}px)`
  );
  const isMediumWidth = useMediaQuery(
    `(min-width:${BreakpointConstants.MEDIUM_MIN_WIDTH}px) and (max-width:${BreakpointConstants.MEDIUM_MAX_WIDTH}px)`
  );
  const isLargeWidth = useMediaQuery(
    `(min-width:${BreakpointConstants.LARGE_MIN_WIDTH}px) and (max-width:${BreakpointConstants.LARGE_MAX_WIDTH}px)`
  );
  const isExtraLargeWidth = useMediaQuery(
    `(min-width:${BreakpointConstants.EXTRA_LARGE_MIN_WIDTH}px)`
  );

  useEffect(() => {
    if (
      (isExtraSmallWidth && barsCount > 4) ||
      (isSmallWidth && barsCount > 7) ||
      (isMediumWidth && barsCount > 4) ||
      (isLargeWidth && barsCount > 9) ||
      (isExtraLargeWidth && barsCount > 16)
    ) {
      setIsLabelOverlapping(true);
    } else {
      setIsLabelOverlapping(false);
    }
  }, [
    isExtraSmallWidth,
    isSmallWidth,
    isMediumWidth,
    isLargeWidth,
    isExtraLargeWidth,
    barsCount,
  ]);

  return isLabelOverlapping;
};

/**
 * Builds the values of the y axis ticks
 * when no data is selected, it will render $0 in the middle of the chart by default,
 * this will force the chart to render $0 at the bottom
 * @param yAxisTicks - The list of y axis ticks
 * @returns A list of numbers
 */
const buildYAxisTicks = (yAxisTicks: number[]): number[] => {
  return yAxisTicks.length === 1 && yAxisTicks[0] === 0 ? [0, 1] : yAxisTicks;
};

/**
 * Sets the hover color to white, so we don't have a weird hover when there's no data selected
 * @param dateTicks - The list of date ticks
 * @returns A option object
 */
const getTooltipCursorProps = (dateTicks: string[]): object => {
  return {
    fill: dateTicks.length === 0 ? colors.white : colors.lightest_grey,
  };
};

const buildXReferenceLines = (
  monthlyVestingEvents: IMonthlyVestingEvent[]
): number[] => {
  const refLines: number[] = [];
  let prev = monthlyVestingEvents[0];
  monthlyVestingEvents.forEach((curr) => {
    if (prev.date.getFullYear() != curr.date.getFullYear()) {
      refLines.push(curr.key - 1);
      prev = curr;
    }
  });
  return refLines;
};

/**
 * Builds the reference lines
 * @param x - The position on the x axis
 * @param x - The key of the reference line
 * @returns  JSX.Element
 */
const CustomReferenceLine = (x: number, key: number) => {
  return (
    <ReferenceLine
      x={x + 0.5}
      key={key}
      stroke={colors.slate}
      strokeDasharray="2 2"
    />
  );
};

/**
 * Builds the next vesting date list item
 * @param nextVestingEvent - The next vesting event
 * @param nextVestingIdx - The next vesting event index
 * @returns  JSX.Element
 */
const BuildNextVestingDate = (
  nextVestingEvent: INextVestingEvent,
  nextVestingIdx: number
) => {
  return (
    <tr key={`vestingEvent_${nextVestingIdx}`} className={styles.item}>
      <td className={styles.itemLabel}>{nextVestingEvent.name}</td>
      <td className={styles.itemValue}>
        <MonospaceNumber
          value={nextVestingEvent.value}
          valueFormatter={getCurrencyFormattedValue}
        />
      </td>
    </tr>
  );
};

/**
 * Builds the daily vesting date list
 * @param dailyVestingEvent - The daily vesting event
 * @param dailyVestingIdx - The daily vesting event index
 * @returns  JSX.Element
 */
const BuildDailyVestingEvents = (
  dailyVestingEvent: IDailyVestingEvent,
  dailyVestingIdx: number
) => {
  return (
    <div key={`dailyVestingEvent_${dailyVestingIdx}`}>
      {dailyVestingIdx > 0 && <hr />}
      <table className={styles.itemList}>
        <tbody>
          <tr className={styles.item}>
            <td className={styles.dailyVestingEventDate}>
              {formatDateMMMDDYYYYOrNull(dailyVestingEvent.date)}
            </td>
            <td className={styles.dailyVestingEventValue}>
              <MonospaceNumber
                value={dailyVestingEvent.value}
                valueFormatter={getCurrencyFormattedValue}
              />
            </td>
          </tr>
          {dailyVestingEvent.nextVestingEvents &&
            dailyVestingEvent.nextVestingEvents.map(BuildNextVestingDate)}
        </tbody>
      </table>
    </div>
  );
};

export const VestingEventsChart = ({
  monthlyVestingEvents,
}: VestingEventsChartProps) => {
  const [yAxisTicks, setYAxisTicks] = useState<number[]>([]);
  const [yAxisTickLabels, setYAxisTickLabels] = useState<string[]>([]);
  const [referenceLines, setReferenceLines] = useState<number[]>([]);
  const [ticks, setTicks] = useState<number[]>([]);
  const isLabelOverlapping = useLabelOverlappingState(
    monthlyVestingEvents.length
  );

  const formatYAxisTicks = (value: number, index: number): string => {
    return yAxisTickLabels[index] ?? "";
  };

  const getMonthYearTickLabel = (value: number): string => {
    return monthlyVestingEvents[value]?.label ?? "";
  };

  const getYearTickLabel = (value: number): string => {
    return monthlyVestingEvents[Math.floor(value)]?.label.split(" ")[1] ?? "";
  };

  const calculateTicks = (
    vestingEvents: IMonthlyVestingEvent[],
    isOverlapping: boolean
  ): number[] => {
    if (isOverlapping) {
      const newticks: number[] = [];
      let prev = vestingEvents[0];
      vestingEvents.forEach((curr) => {
        const isLastVestingEvent = curr.key === vestingEvents.length - 1;
        if (
          prev.date.getFullYear() != curr.date.getFullYear() ||
          isLastVestingEvent
        ) {
          const diff = isLastVestingEvent ? 0 : 0.5;
          const newTick = (curr.key - prev.key) / 2 - diff + prev.key;
          newticks.push(newTick);
          prev = curr;
        }
      });
      return newticks;
    }

    return vestingEvents.map((vestingEvent) => vestingEvent.key);
  };

  useEffect(() => {
    const tickInfo = getTicksAndLabels(
      monthlyVestingEvents.map(
        (monthlyVestingEvent) => monthlyVestingEvent.value
      )
    );

    const calculatedTicks = calculateTicks(
      monthlyVestingEvents,
      isLabelOverlapping
    );
    setTicks(calculatedTicks);
    setReferenceLines(buildXReferenceLines(monthlyVestingEvents));
    setYAxisTicks(tickInfo.tickValues);
    setYAxisTickLabels(tickInfo.tickLabels);
  }, [monthlyVestingEvents, isLabelOverlapping]);

  return (
    <Grid item xs={12} md={12} className={styles.chart}>
      <ResponsiveRechartsWrapper>
        <BarChart
          data={monthlyVestingEvents}
          barCategoryGap="25%"
          maxBarSize={50}
          margin={{
            top: 32,
            right: 24,
            left: 24,
            bottom: 32,
          }}
          stackOffset={"sign"}
        >
          <Tooltip
            cursor={getTooltipCursorProps([])}
            content={({ payload }) => {
              return (
                <div className={styles.tooltip}>
                  {payload &&
                    payload
                      .flatMap((item) => item.payload)
                      .flatMap(
                        (monthlyVestingEvent: IMonthlyVestingEvent) =>
                          monthlyVestingEvent.dailyVestingEvents
                      )
                      .map(BuildDailyVestingEvents)}
                </div>
              );
            }}
          />
          <CartesianGrid
            vertical={false}
            strokeDasharray="2 2"
            stroke={colors.slate}
          />
          <XAxis
            type={"number"}
            axisLine={false}
            dataKey="key"
            tick={xAxisTick}
            domain={[-0.5, monthlyVestingEvents.length - 0.5]}
            tickFormatter={
              isLabelOverlapping ? getYearTickLabel : getMonthYearTickLabel
            }
            ticks={ticks.length === 0 ? [""] : ticks}
            tickLine={false}
            interval={0}
          >
            <Label
              className={styles.mainAxisLabel}
              value={UpcomingVestingEventsLabel.FUTURE_VESTING_DATES}
              position={"insideBottom"}
              dy={xAxisLabelDy}
              offset={-8}
            ></Label>
          </XAxis>
          <YAxis
            axisLine={true}
            tickLine={false}
            domain={["dataMin", "dataMax"]}
            tick={yAxisTick}
            tickFormatter={formatYAxisTicks}
            type="number"
            ticks={buildYAxisTicks(yAxisTicks)}
          >
            <Label
              className={styles.mainAxisLabel}
              value={UpcomingVestingEventsLabel.VALUE}
              angle={270}
              dx={yAxisLabelDx}
              offset={-8}
            ></Label>
          </YAxis>
          <ReferenceLine y={0} stroke={colors.dark_grey} />
          {referenceLines.map(CustomReferenceLine)}
          <Bar
            dataKey="value"
            fill={colors.avocado}
            label={{
              position: "top",
              formatter: getCurrencyFormattedValue,
              offset: 8,
              display: isLabelOverlapping ? "none" : "block",
            }}
          />
        </BarChart>
      </ResponsiveRechartsWrapper>
    </Grid>
  );
};
