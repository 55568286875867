import { Box } from "@mui/material";
import {
  AdminClientLabels,
  IBaseStore,
  isInProgress,
  LoadingIndicator,
  NoDataAvailableError,
  selectCanViewCommitmentsPage,
  selectCanViewDashboard,
  selectCanViewInvestmentsAndCarry,
} from "common";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import { HasEntitlementContainer } from "../../common/components/HasEntitlementContainer/HasEntitlementContainer";
import { RelativePath } from "../../constants/Paths";
import { AdminUIStore } from "../../redux/store";
import CommitmentsWrapper from "../Commitments/CommitmentsWrapper";
import DashboardWrapper from "../Dashboard/DashboardWrapper";
import InvestmentsAndCarryPageWrapper from "../InvestmentsAndCarry/InvestmentsAndCarryPageWrapper";
import { NavigationTabs } from "../SubHeader/NavigationTabs/NavigationTabs";
import { SubHeader } from "../SubHeader/SubHeader";
import styles from "./ClientData.module.scss";

export const ClientData = () => {
  const { internalInvestmentAdminEntitlementLoadStatus } = useSelector(
    (state: AdminUIStore) => state.entitlements
  );

  const internalInvestmentData = useSelector(
    (state: AdminUIStore) => state.internalInvestmentData
  );

  const canViewDashboard = useSelector((store: IBaseStore) =>
    selectCanViewDashboard(store, true)
  );

  const canViewInvestmentsAndCarryPage = useSelector((store: IBaseStore) =>
    selectCanViewInvestmentsAndCarry(store, true)
  );

  const canViewCommitmentsPage = useSelector(selectCanViewCommitmentsPage);

  const { pathname } = location;
  const clientId = pathname.split("/")[2];

  const SetRouteByData = () => {
    if (
      isInProgress(
        internalInvestmentAdminEntitlementLoadStatus,
        internalInvestmentData.internalInvestmentDataLoadStatus
      ) ||
      clientId === undefined
    ) {
      return (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
        </div>
      );
    }

    return canViewDashboard ? (
      <Navigate
        to={RelativePath.DASHBOARD.replace(":clientId", clientId.toString())}
      />
    ) : canViewInvestmentsAndCarryPage ? (
      <Navigate
        to={RelativePath.INVESTMENTS_AND_CARRY.replace(
          ":clientId",
          clientId.toString()
        )}
      />
    ) : canViewCommitmentsPage ? (
      <Navigate
        to={RelativePath.COMMITMENTS.replace(":clientId", clientId.toString())}
      />
    ) : (
      <NoDataAvailableError />
    );
  };

  return (
    <>
      <HasEntitlementContainer
        entitlementLoadStatusName="internalInvestmentAdminEntitlementLoadStatus"
        entitlementName="hasInternalInvestmentAdminEntitlement"
      >
        <SubHeader />
        <Routes>
          <Route
            path={"/:clientId/*"}
            element={
              <>
                <NavigationTabs />
                <Box className={styles.internalInvestmentsContent}>
                  <Routes>
                    {canViewDashboard && (
                      <Route path="/dashboard" element={<DashboardWrapper />} />
                    )}
                    {canViewInvestmentsAndCarryPage && (
                      <Route
                        path="/investments-and-carry/*"
                        element={<InvestmentsAndCarryPageWrapper />}
                      />
                    )}
                    {canViewCommitmentsPage && (
                      <Route
                        path="/commitments"
                        element={<CommitmentsWrapper />}
                      />
                    )}
                    <Route path={"/*"} element={SetRouteByData()} />
                  </Routes>
                </Box>
              </>
            }
          />
          <Route
            path={""}
            element={
              <Box className={styles.landingPage}>
                <Box
                  component="img"
                  alt={"Landing Page background"}
                  src="/assets/images/AdminLanding.png"
                  className={styles.landingImage}
                />
                <h1 className={styles.landingText}>
                  {AdminClientLabels.PLEASE_SEARCH}
                </h1>
              </Box>
            }
          />
        </Routes>
      </HasEntitlementContainer>
    </>
  );
};
