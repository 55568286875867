import { createReducer } from "@reduxjs/toolkit";
import {
  clearElectionGridFilters,
  DataLoadStatus,
  errElectionsForElectionRound,
  IElectionsFilter,
  isSomething,
  nothing,
  recvElectionsForElectionRound,
  reqElectionsForElectionRound,
  setElectionsGridFilters,
  some,
  updateElectorsGridPage,
  updateElectorsGridPageSize,
  updateElectorsGridSearchTerm,
} from "common";

import { AdminUIElectionsStore } from "../../types/storeTypes";

const defaultFilters: IElectionsFilter = {
  currentStage: nothing,
  wasReopened: nothing,
  wasSubmitted: nothing,
};
const defaultState: AdminUIElectionsStore = {
  currentElectionRoundId: nothing,
  electionsForElectionRound: [],
  electionsForElectionRoundLoadStatus: DataLoadStatus.NOT_REQUESTED,
  electorsGridOptions: {
    page: 1,
    pageSize: 50,
    searchTerm: nothing,
    filters: defaultFilters,
  },
};

export const electionsReducer = createReducer<AdminUIElectionsStore>(
  defaultState,
  (builder) => {
    builder.addCase(reqElectionsForElectionRound, (state, action) => {
      state.currentElectionRoundId = some(action.payload.electionRoundId);
      state.electionsForElectionRoundLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(recvElectionsForElectionRound, (state, action) => {
      if (isSomething(action.payload)) {
        state.electionsForElectionRound = action.payload.value;
        state.electionsForElectionRoundLoadStatus = DataLoadStatus.SUCCESSFUL;
      } else {
        state.electionsForElectionRound = [];
        state.electionsForElectionRoundLoadStatus =
          DataLoadStatus.EMPTY_RESPONSE;
      }
    });
    builder.addCase(errElectionsForElectionRound, (state) => {
      state.electionsForElectionRound = [];
      state.electionsForElectionRoundLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    });
    builder.addCase(updateElectorsGridSearchTerm, (state, action) => {
      state.electorsGridOptions.searchTerm = action.payload;
    });
    builder.addCase(updateElectorsGridPage, (state, action) => {
      state.electorsGridOptions.page = action.payload;
    });
    builder.addCase(updateElectorsGridPageSize, (state, action) => {
      state.electorsGridOptions.pageSize = action.payload;
    });
    builder.addCase(setElectionsGridFilters, (state, action) => {
      state.electorsGridOptions.filters = action.payload;
      state.electorsGridOptions.page = 1;
    });
    builder.addCase(clearElectionGridFilters, (state) => {
      state.electorsGridOptions.filters = defaultFilters;
    });
  }
);
