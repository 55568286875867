import { Link, Typography } from "@mui/material";
import { BankAccountConstants, StringConstants } from "common";
import React from "react";

import { ElectionDialog } from "../../../Elections/ElectionWorkflow/Shared/ElectionDialog/ElectionDialog";

interface SubmitFailedDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const SubmissionErrorDialog = ({
  open,
  handleClose,
}: SubmitFailedDialogProps) => {
  return (
    <ElectionDialog
      title={BankAccountConstants.SUBMISSION_FAILED}
      content={
        <Typography>
          {BankAccountConstants.SUBMISSION_FAILED_MESSAGE}{" "}
          {BankAccountConstants.PLEASE_TRY_AGAIN}
          <div>
            <Link href={`mailto:${StringConstants.BXWEALTH_SUPPORT_EMAIL}`}>
              {StringConstants.BXWEALTH_SUPPORT_EMAIL}
            </Link>
            {BankAccountConstants.FOR_ASSISTANCE}
          </div>
        </Typography>
      }
      open={open}
      handleClose={handleClose}
      handleNext={() => {
        return;
      }}
      cancelButtonLabel={StringConstants.DONE}
      showNextButton={false}
      isErrorDialog
    />
  );
};
