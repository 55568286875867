import {
  EquityHeader,
  EquityTabsDisplayOrder,
  PageWithSubTabs,
  PathFragment,
} from "common";
import React from "react";

import { EquityPages } from "../../constants/Pages/SubTabPages";
import styles from "./EquityAdminWrapper.module.scss";

const EquityAdminWrapper = () => {
  return (
    <PageWithSubTabs
      pageHeader={<EquityHeader />}
      pages={EquityPages}
      tabDisplayOrder={EquityTabsDisplayOrder}
      defaultTab={`${PathFragment.EQUITY_VALUE}`}
      className={styles.equityAdminPage}
    />
  );
};

export default EquityAdminWrapper;
