import { Button, Stack } from "@mui/material";
import { BankAccountConstants } from "common";
import React from "react";

import styles from "./AddAccountCell.module.scss";

interface AddAccountHeaderProps {
  disabled: boolean;
  openReviewAndSignDialog: () => void;
}

export const AddAccountHeader = ({
  disabled,
  openReviewAndSignDialog,
}: AddAccountHeaderProps) => {
  return (
    <Stack direction="row" className={styles.addAccountCell}>
      <Button
        size="small"
        disabled={disabled}
        onClick={openReviewAndSignDialog}
      >
        {BankAccountConstants.SUBMIT_CHANGES}
      </Button>
    </Stack>
  );
};
