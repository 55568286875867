import { Box } from "@mui/material";
import React from "react";

import styles from "./DataGrid.module.scss";

export type DataGridColumnData = {
  title: string;
  minWidth?: number;
  maxWidth?: number;
};

type DataGridProps = {
  columnData: DataGridColumnData[];
  rowData: string[][];
  className?: string;
};

const DataGrid = ({ columnData, rowData, className }: DataGridProps) => {
  const gridTemplateColumns = columnData
    .map(
      (column) =>
        `minmax(${
          column.minWidth ? String(column.minWidth).concat("px") : "auto"
        }, ${column.maxWidth ? String(column.maxWidth).concat("px") : "1fr"})`
    )
    .join(" ");

  return (
    <Box
      className={`${styles.DataGrid} ${className}`}
      sx={{
        display: "grid",
        gridTemplateColumns,
      }}
    >
      {columnData.map((col, index) => (
        <Box key={index} className={styles.DataGridColumn}>
          {col.title}
        </Box>
      ))}

      {/* Render rows and cells */}
      {rowData.map((row, rowIndex) =>
        row.map((cell, cellIndex) => (
          <Box
            key={`cell-${rowIndex}-${cellIndex}`}
            className={styles.DataGridCell}
          >
            {cell}
          </Box>
        ))
      )}
    </Box>
  );
};

export default DataGrid;
