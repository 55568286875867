import { createSelector } from "@reduxjs/toolkit";

import { DataLoadStatus } from "../../constants/enums";
import { DEFAULT_ENTITY_STATE } from "../../types/defaultTypes";
import {
  IEntityDataStore,
  IInternalInvestmentDataStore,
} from "../../types/storeTypes";
import { isSomething } from "../../types/typeGuards";
import { getEntityDataById, getEntityId } from "../../utils/entityUtils";
import { IBaseStore } from "../store";

const selectIsClientDataOnly = (state: IBaseStore, isClientDataOnly = false) =>
  isClientDataOnly;

export const selectBaseStore = (state: IBaseStore) => state;
// selects the internal investment data state
// it contains: filteredBy, entities, internalInvesmentDataLoadStatus
export const selectInternalInvestmentData = createSelector(
  selectBaseStore,
  ({ internalInvestmentData }): IInternalInvestmentDataStore =>
    internalInvestmentData
);
// selects the data load status of the internal investment data
export const selectInternalInvestmentDataLoadStatus = createSelector(
  [selectInternalInvestmentData],
  ({ internalInvestmentDataLoadStatus }): DataLoadStatus => {
    return internalInvestmentDataLoadStatus;
  }
);
export const selectFilteredBy = createSelector(
  selectInternalInvestmentData,
  (internalInvestmentsData) => internalInvestmentsData.filteredBy
);
// selects a single client or investment vehicle data from the state based on the filter by object
// if filterBy contains clientId and investmentVehicle it will retrieve the investment vehicle data
// using the investment vehicle id on the other hand when investment vehicle id is undefined the client data
// will be retrieved by using the clientId
export const selectActiveInvestmentEntityData = createSelector(
  [selectInternalInvestmentData, selectIsClientDataOnly],
  ({ filteredBy, entities }, isClientDataOnly): IEntityDataStore => {
    if (!isSomething(filteredBy)) {
      return DEFAULT_ENTITY_STATE;
    }
    const clientId = filteredBy.value.clientId;
    const investmentVehicleId = isClientDataOnly
      ? undefined
      : filteredBy.value.investmentVehicleId;
    const selectedId = getEntityId(clientId, investmentVehicleId);

    return getEntityDataById(entities, selectedId);
  }
);

export const selectLastPointerType = (store: IBaseStore) =>
  store.viewData.lastPointerType;
