import { Edit, FileDownloadOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import {
  buildIVByElectionRoundTitle,
  ElectionsLabels,
  ElectionWorkflowStageId,
  isSomething,
} from "common";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useDownloadElectionAgreement } from "../../../hooks/pdfHooks";
import { selectElectionRoundDocuments } from "../../../redux/selectors";
import { IBaseStore } from "../../../redux/store";
import { ElectionsCard } from "../ElectionWorkflow/ReviewAndSignStage/ElectionsCard/ElectionsCard";
import { ElectionCard } from "../ElectionWorkflow/Shared/ElectionCard";
import { BackToElectionsButton } from "../Shared/BackToElectionsButton/BackToElectionsButton";
import { DownloadDocumentButton } from "../Shared/DownloadDocumentButton/DownloadDocumentButton";
import { TaxForms } from "../Shared/TaxForms/TaxForms";
import styles from "./ElectionReview.module.scss";
import { ReopenElectionModal } from "./ReopenElectionModal/ReopenElectionModal";

export interface IReviewProps {
  notFoundPath: string;
  incompletePath: string;
  electionListPath: string;
}

export const Review = ({
  notFoundPath,
  incompletePath,
  electionListPath,
}: IReviewProps) => {
  const navigate = useNavigate();
  const { electionRoundId } = useParams();

  const {
    electionRoundConfiguration,
    activeElection,
    electionIVConfiguration,
    electionWorkflowStateLocal,
  } = useSelector((state: IBaseStore) => state.selectedElection);

  const documents = useSelector(selectElectionRoundDocuments);

  const [isReviseModalOpen, setIsReviseModalOpen] = useState<boolean>(false);

  const downloadElectionAgreement = useDownloadElectionAgreement();

  const handleClickDownloadPDF = async () => {
    if (
      isSomething(activeElection) &&
      isSomething(electionWorkflowStateLocal)
    ) {
      await downloadElectionAgreement(
        activeElection.value.electionRoundId,
        activeElection.value.investmentVehicleId,
        electionWorkflowStateLocal.value.isAdmin
      );
    }
  };

  useEffect(() => {
    if (isSomething(electionWorkflowStateLocal)) {
      if (
        electionWorkflowStateLocal.value.currentStage ===
          ElectionWorkflowStageId.UNKNOWN ||
        electionWorkflowStateLocal.value.currentStage ===
          ElectionWorkflowStageId.NOT_STARTED
      ) {
        return navigate(notFoundPath);
      }
      if (
        electionWorkflowStateLocal.value.currentStage <
        ElectionWorkflowStageId.COMPLETED
      ) {
        return navigate(incompletePath);
      }
    }
  }, [electionWorkflowStateLocal, navigate, notFoundPath, incompletePath]);

  const showReviseButton = useMemo(() => {
    if (isSomething(electionRoundConfiguration)) {
      const currentTime = new Date();
      return (
        currentTime.valueOf() <
        electionRoundConfiguration.value.electionSubmissionDeadline.valueOf()
      );
    }
  }, [electionRoundConfiguration]);

  const handleClickReviseElection = () => {
    setIsReviseModalOpen(true);
  };

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <BackToElectionsButton backUrl={electionListPath} />
        <ElectionCard className={styles.electionCard}>
          <div className={styles.title}>
            <Typography variant="h1">
              {buildIVByElectionRoundTitle(
                electionIVConfiguration,
                electionRoundConfiguration
              )}
            </Typography>
            {electionRoundId !== undefined && (
              <DownloadDocumentButton
                outline={true}
                documents={documents}
                electionRoundId={electionRoundId}
              />
            )}
            <Button
              className={styles.rightButton}
              onClick={handleClickDownloadPDF}
              endIcon={<FileDownloadOutlined />}
            >
              {ElectionsLabels.DOWNLOAD_SIGNED_ELECTION_AGREEMENT}
            </Button>
            {showReviseButton && (
              <Button onClick={handleClickReviseElection} endIcon={<Edit />}>
                {ElectionsLabels.REVISE_ELECTION}
              </Button>
            )}
          </div>
          <ElectionsCard />
          <hr className={styles.footerLine} />
          <TaxForms />
        </ElectionCard>
        <ReopenElectionModal
          open={isReviseModalOpen}
          setOpen={setIsReviseModalOpen}
        />
      </div>
    </div>
  );
};
