import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";

import styles from "./SearchBar.module.scss";

interface SearchBarProps {
  className?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  searchIconPosition?: "end" | "start";
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  disabled?: boolean;
  onClear?: () => void;
}

export const SearchBar = (props: SearchBarProps) => {
  const clearButton =
    props.value !== undefined && props.value.length > 0 ? (
      <IconButton onClick={props.onClear}>
        <ClearIcon />
      </IconButton>
    ) : (
      <></>
    );

  const startAdornment =
    props.searchIconPosition == "start" ? (
      <InputAdornment position="start">
        <SearchIcon className={styles.searchIcon} />
      </InputAdornment>
    ) : undefined;

  const endAdornment =
    props.searchIconPosition == "end" ? (
      <InputAdornment position="end">
        <SearchIcon className={styles.searchIcon} />
        {clearButton}
      </InputAdornment>
    ) : (
      <InputAdornment position="end">{clearButton}</InputAdornment>
    );

  return (
    <TextField
      size="small"
      placeholder={props.placeholder}
      fullWidth
      id={styles.searchBar}
      className={`${props.className} ${props.disabled ? styles.disabled : ""}`}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      value={props.value || ""}
      autoComplete="off"
      disabled={props.disabled}
      InputProps={{
        endAdornment: endAdornment,
        startAdornment: startAdornment,
      }}
      sx={{
        marginBottom: 0,
      }}
    />
  );
};
