import { InvestmentBreakdownKey, InvestmentHistoryLabel } from "common";

import colors from "../styles/_colors.scss";

export interface InvestmentHistoryValueItem {
  key: InvestmentBreakdownKey;
  excelColumnName?: string;
  labelOverride?: string;
  color: string;
  isSelected: boolean;
  isUnrealized?: boolean;
  isReadOnly?: boolean;
}

export const cashFlowDataTypes: InvestmentHistoryValueItem[] = [
  {
    key: InvestmentBreakdownKey.TOTAL_REALIZED_PROCEEDS,
    color: colors.avocado,
    isSelected: true,
    labelOverride: InvestmentHistoryLabel.REALIZED_PROCEEDS,
  },
  {
    key: InvestmentBreakdownKey.CAPITAL_INVESTED,
    color: colors.cobalt,
    isSelected: true,
  },
  {
    key: InvestmentBreakdownKey.NET_CASH_FLOW,
    color: colors.white,
    isSelected: false,
    isReadOnly: true,
    labelOverride: InvestmentHistoryLabel.NET_CASH_FLOW,
  },
];
