import { Typography } from "@mui/material";
import {
  ElectionsLabels,
  ElectionWorkflowStageId,
  ElectionWorkflowStageIdToLabel,
  EntitySelector,
  IEntity,
} from "common";
import React from "react";

export interface ICurrentStatusFilterProps {
  onChange: (value: number[]) => void;
  selectedItems: number[];
}
export const CurrentStageFilter = ({
  onChange,
  selectedItems,
}: ICurrentStatusFilterProps) => {
  const electionStages: IEntity[] = Object.values(ElectionWorkflowStageId)
    .filter(
      (value) =>
        value !== ElectionWorkflowStageId.UNKNOWN && typeof value !== "string"
    ) /* We are filtering UNKNOWN because it is an error state and the user should never see it. 
      Aditional the comparsion to string is needed otherwise we get duplicated the keys / values more info on next linkhttps://lumin8media.com/blog/get-the-keys-of-a-numeric-typescript-enum */
    .map((electionStage) => {
      const oId = electionStage as ElectionWorkflowStageId;
      const label = ElectionWorkflowStageIdToLabel[oId] as string;
      return {
        oId,
        name: label,
      };
    });

  const selection = electionStages.filter((x) => selectedItems.includes(x.oId));

  const onEntitySelectorChanges = (items: IEntity[]) =>
    onChange(items.map((item) => item.oId));

  return (
    <>
      <Typography variant={"h6"} marginBottom="4px">
        {ElectionsLabels.CURRENT_STAGE}
      </Typography>
      <EntitySelector
        options={electionStages}
        selection={selection}
        onChange={onEntitySelectorChanges}
      />
    </>
  );
};
