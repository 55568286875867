import {
  ElectionResultConstants,
  getDateAndTime,
  IElectionResultRow,
  IResultResponse,
  Json,
} from "common";
import ExcelJS from "exceljs";

import { WorksheetGeneratorParams } from "../../types/excelTypes";

const electionResultColumns: Partial<ExcelJS.Column>[] = [
  {
    header: "Investment Vehicle Id",
    key: ElectionResultConstants.KEYS.IV_ID,
    width: ElectionResultConstants.SIZES.MEDIUM,
  },
  {
    header: "Investment Vehicle Name",
    key: ElectionResultConstants.KEYS.IV_NAME,
    width: ElectionResultConstants.SIZES.MEDIUM,
  },
  {
    header: "Is Resident - United States",
    key: ElectionResultConstants.KEYS.IS_US_RESIDENT,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Is Resident - New York",
    key: ElectionResultConstants.KEYS.IS_NY_RESIDENT,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Is Accredited",
    key: ElectionResultConstants.KEYS.IS_ACCREDITED,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Is Accredited - Individual Net Worth",
    key: ElectionResultConstants.KEYS.IS_ACCREDITED_INDIVIDUAL_NET,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Is Accredited - Individual Income",
    key: ElectionResultConstants.KEYS.IS_ACCREDITED_INDIVIDUAL,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Is Accredited - Professional",
    key: ElectionResultConstants.KEYS.IS_ACCREDITED_PRO,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Is Qualified",
    key: ElectionResultConstants.KEYS.IS_QUALIFIED,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Is Qualified - Individual Net Worth",
    key: ElectionResultConstants.KEYS.IS_QUALIFIED_INDIVIDUAL_NET,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Is Qualified - Company",
    key: ElectionResultConstants.KEYS.IS_QUALIFIED_COMPANY,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Is Qualified - Trust",
    key: ElectionResultConstants.KEYS.IS_QUALIFIED_TRUST,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Is Qualified - Individual Investments",
    key: ElectionResultConstants.KEYS.IS_QUALIFIED_INDIVIDUAL,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Not Qualified / Accredited",
    key: ElectionResultConstants.KEYS.NOT_QUALIFIED_ACCREDITED,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Can Reallocate",
    key: ElectionResultConstants.KEYS.CAN_REALLOCATE,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Strategy Name",
    key: ElectionResultConstants.KEYS.STRATEGY_NAME,
    width: ElectionResultConstants.SIZES.MEDIUM,
  },
  {
    header: "Offered Amount",
    key: ElectionResultConstants.KEYS.OFFERED_AMOUNT,
    width: ElectionResultConstants.SIZES.MEDIUM,
    style: {
      numFmt: ElectionResultConstants.COMMA_SEPARATED_DOLLAR,
      alignment: { horizontal: "right" },
    },
  },
  {
    header: "Additional Request Limit",
    key: ElectionResultConstants.KEYS.ADDITIONAL_LIMIT,
    width: ElectionResultConstants.SIZES.MEDIUM,
    style: {
      numFmt: ElectionResultConstants.COMMA_SEPARATED_DOLLAR,
      alignment: { horizontal: "right" },
    },
  },
  {
    header: "Strategy Max",
    key: ElectionResultConstants.KEYS.STRATEGY_MAX,
    width: ElectionResultConstants.SIZES.MEDIUM,
    style: {
      numFmt: ElectionResultConstants.COMMA_SEPARATED_DOLLAR,
      alignment: { horizontal: "right" },
    },
  },
  {
    header: "Elected Amount (w/o Additional)",
    key: ElectionResultConstants.KEYS.ELECTED_AMOUNT,
    width: ElectionResultConstants.SIZES.MEDIUM,
    style: {
      numFmt: ElectionResultConstants.COMMA_SEPARATED_DOLLAR,
      alignment: { horizontal: "right" },
    },
  },
  {
    header: "Additional Requested",
    key: ElectionResultConstants.KEYS.ADDITIONAL_REQUESTED,
    width: ElectionResultConstants.SIZES.MEDIUM,
    style: {
      numFmt: ElectionResultConstants.COMMA_SEPARATED_DOLLAR,
      alignment: { horizontal: "right" },
    },
  },
  {
    header: "Over Max",
    key: ElectionResultConstants.KEYS.OVER_MAX,
    width: ElectionResultConstants.SIZES.MEDIUM,
    style: {
      numFmt: ElectionResultConstants.COMMA_SEPARATED_DOLLAR,
      alignment: { horizontal: "right" },
    },
  },
  {
    header: "Submitted",
    key: ElectionResultConstants.KEYS.IS_SUBMITTED,
    width: ElectionResultConstants.SIZES.SMALL,
    style: { alignment: { horizontal: "center" } },
  },
  {
    header: "Submitted On",
    key: ElectionResultConstants.KEYS.SUBMIT_DATE,
    width: ElectionResultConstants.SIZES.BIG,
  },
  {
    header: "Submitted By User Email Address",
    key: ElectionResultConstants.KEYS.USER_EMAIL,
    width: ElectionResultConstants.SIZES.BIG,
  },
  {
    header: "Submitted By Name",
    key: ElectionResultConstants.KEYS.USER_NAME,
    width: ElectionResultConstants.SIZES.MEDIUM,
  },
  {
    header: "UPN",
    key: ElectionResultConstants.KEYS.USER_UPN,
    width: ElectionResultConstants.SIZES.MEDIUM,
  },
];

const getRows = (
  data: Json<IResultResponse[]> | undefined
): IElectionResultRow[] => {
  const electionResultRows: IElectionResultRow[] = [];

  if (data === undefined) {
    return electionResultRows;
  }

  for (let i = 0; i < data.length; i++) {
    electionResultRows.push({
      ivId: data[i].mdmInvestmentVehicleId,
      ivName: data[i].investmentVehicleName,
      isNYResident: data[i].eligibleStatus.isNYResident,
      isUSResident: data[i].eligibleStatus.isUSResident,
      isAccredited: data[i].eligibleStatus.isAccredited,
      isAccreditedIndividualNet:
        data[i].eligibleStatus.accreditedInvestorStatus.hasIndividualNetWorth,
      isAccreditedIndividual:
        data[i].eligibleStatus.accreditedInvestorStatus.hasIndividualIncome,
      isAccreditedProfessional:
        data[i].eligibleStatus.accreditedInvestorStatus.isProfessional,
      isQualified: data[i].eligibleStatus.isQualified,
      isQualifiedIndividualNet:
        data[i].eligibleStatus.qualifiedPurchaserStatus
          .hasInvestmentsInBlackstoneFunds,
      isQualifiedCompany:
        data[i].eligibleStatus.qualifiedPurchaserStatus.isCompany,
      isQualifiedTrust: data[i].eligibleStatus.qualifiedPurchaserStatus.isTrust,
      isQualifiedIndividual:
        data[i].eligibleStatus.qualifiedPurchaserStatus.isIndividualInvestment,
      notQualifiedAccredited: data[i].eligibleStatus.isNotEligible,
      canReallocate: data[i].canReallocate,
      strategyName: "Total Election",
      offeredAmount: data[i].electionResultTotals.offeredAmount ?? 0,
      electedAmount: data[i].electionResultTotals.electedAmount,
      additionalRequested:
        data[i].electionResultTotals.additionalRequestedAmount,
      isSubmitted: data[i].electionSubmission.user != undefined,
      submitDate: getDateAndTime(data[i].electionSubmission.submitDateTimeUTC),
      userEmail: data[i].electionSubmission.user?.email ?? "",
      userName: data[i].electionSubmission.user?.fullName ?? "",
      userUPN: data[i].electionSubmission.user?.upn ?? "",
    });

    for (const electionByStrategy of data[i].electionsByStrategy) {
      electionResultRows.push({
        ivId: data[i].mdmInvestmentVehicleId,
        ivName: data[i].investmentVehicleName,
        isNYResident: data[i].eligibleStatus.isNYResident,
        isUSResident: data[i].eligibleStatus.isUSResident,
        isAccredited: data[i].eligibleStatus.isAccredited,
        isAccreditedIndividualNet:
          data[i].eligibleStatus.accreditedInvestorStatus.hasIndividualNetWorth,
        isAccreditedIndividual:
          data[i].eligibleStatus.accreditedInvestorStatus.hasIndividualIncome,
        isAccreditedProfessional:
          data[i].eligibleStatus.accreditedInvestorStatus.isProfessional,
        isQualified: data[i].eligibleStatus.isQualified,
        isQualifiedIndividualNet:
          data[i].eligibleStatus.qualifiedPurchaserStatus
            .hasInvestmentsInBlackstoneFunds,
        isQualifiedCompany:
          data[i].eligibleStatus.qualifiedPurchaserStatus.isCompany,
        isQualifiedTrust:
          data[i].eligibleStatus.qualifiedPurchaserStatus.isTrust,
        isQualifiedIndividual:
          data[i].eligibleStatus.qualifiedPurchaserStatus
            .isIndividualInvestment,
        notQualifiedAccredited: data[i].eligibleStatus.isNotEligible,
        canReallocate: data[i].canReallocate,
        strategyName: electionByStrategy.strategyName,
        strategyMax: electionByStrategy.strategyMax,
        electedAmount: electionByStrategy.electedAmount,
        overMax: electionByStrategy.overMax,
        isSubmitted: data[i].electionSubmission.user != undefined,
        submitDate: getDateAndTime(
          data[i].electionSubmission.submitDateTimeUTC
        ),
        userEmail: data[i].electionSubmission.user?.email ?? "",
        userName: data[i].electionSubmission.user?.fullName ?? "",
        userUPN: data[i].electionSubmission.user?.upn ?? "",
      });
    }
  }

  return electionResultRows;
};

export const buildElectionResultWorksheet = (
  params: WorksheetGeneratorParams
) => {
  const { settings, workbook } = params;
  const worksheet = workbook.addWorksheet("Elections", {
    views: [
      {
        state: "frozen",
        ySplit: 1,
      },
    ],
  });
  worksheet.columns = electionResultColumns;
  worksheet.addRows(getRows(settings.resultResponse));
  const headerRow = worksheet.getRow(1);
  headerRow.font = { ...headerRow.font, bold: true };
  headerRow.alignment = { ...headerRow.alignment, horizontal: "left" };
  return workbook;
};
