import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ElectionAdminLabels } from "common";
import React, { useState } from "react";

import ElectionResultsDownloadButton from "../ElectionResultsDownloadButton";
import styles from "./ElectionResultSubHeader.module.scss";

export const ElectionResultSubHeader = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isdisabled, setIsDisabled] = useState<boolean>(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = event.target.value;
    setInputValue(data);
    setIsDisabled(data.length === 0);
  };

  return (
    <div className={styles.subnavbar}>
      <Box className={styles.toolbar} alignItems="center">
        <Typography variant="h5" className={styles.searchText}>
          {ElectionAdminLabels.ENTER_ROUND_ID}
        </Typography>
        <Box className={styles.searchBar}>
          <TextField
            placeholder={ElectionAdminLabels.ENTER_ROUND_ID}
            onChange={handleInputChange}
            value={inputValue}
          />
        </Box>
        <ElectionResultsDownloadButton
          id={inputValue}
          label={ElectionAdminLabels.DOWNLOAD_RESULTS}
          disabled={isdisabled}
          showIcon={true}
          isOutlined={true}
          className={styles.resultDownload}
        />
      </Box>
    </div>
  );
};
