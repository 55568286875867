import { ThemeOptions } from "@mui/material";

const outlinedInput: ThemeOptions = {
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: "1px",
        },
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
          },
        },
      },
    },
  },
};

export const CustomOutlinedInputTheme =
  outlinedInput.components?.MuiOutlinedInput;
