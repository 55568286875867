import { Text, View } from "@react-pdf/renderer";
import { ElectionsLabels } from "common";
import React from "react";

import { sharedStyles } from "../../SharedStyleSheet";
import { styles } from "./ElectionAmountTableHeaderStyleSheet";

export const ElectionAmountTableHeader = () => {
  return (
    <View style={[styles.row, sharedStyles.blackBorderBottom]}>
      <Text style={[sharedStyles.textColumn, styles.strategy]}>
        {ElectionsLabels.STRATEGY}
      </Text>
      <Text style={[sharedStyles.textColumn, styles.remainingCommitment]}>
        {ElectionsLabels.ANTICIPATED_PRIOR_PERIODS_CAPITAL_CALLS}
      </Text>
      <Text style={styles.signColumn}>{ElectionsLabels.PLUS_SIGN}</Text>
      <Text style={[sharedStyles.textColumn, styles.mandatoryCommitment]}>
        {ElectionsLabels.MANDATORY_COMMITMENT}
      </Text>
      <Text style={styles.signColumn}>{ElectionsLabels.PLUS_SIGN}</Text>
      <Text style={[sharedStyles.textColumn, styles.election]}>
        {ElectionsLabels.OPTIONAL_ELECTION}
      </Text>
      <Text style={styles.signColumn}>{ElectionsLabels.EQUAL_SIGN}</Text>
      <Text style={[sharedStyles.textColumn, styles.totalCommitment]}>
        {ElectionsLabels.TOTAL_COMMITMENT}
      </Text>
      <Text style={[sharedStyles.textColumn, styles.forecastedInvestment]}>
        {ElectionsLabels.TOTAL_FORECASTED_INVESTMENT}
      </Text>
    </View>
  );
};
