import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  AdminClientLabels,
  DropdownSearchBar,
  IInteralInvestmentClient,
  IInvestmentVehicleByClientByPeriod,
  isEntityLoaded,
  isSomething,
  PeriodHash,
  reqInternalInvestmentData,
  reqSelectedClient,
  setInternalInvestmentDataFilters,
} from "common";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AdminDownloadButton } from "../../common/components/AdminDownload/AdminDownloadButton";
import { HasEntitlementContainer } from "../../common/components/HasEntitlementContainer/HasEntitlementContainer";
import { RelativePath } from "../../constants/Paths";
import { AdminUIStore } from "../../redux/store";
import {
  findInternalInvestmentClientForClientId,
  getClientOptionLabel,
  searchAndSortClients,
} from "../../utils/adminClientUtils";
import { NavigationTabs } from "./NavigationTabs/NavigationTabs";
import styles from "./SubHeader.module.scss";

export const SubHeader = () => {
  const { internalInvestmentClients } = useSelector(
    (store: AdminUIStore) => store.clients
  );
  const { filteredBy, entities } = useSelector(
    (store: AdminUIStore) => store.internalInvestmentData
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchableClients: IInteralInvestmentClient[] = isSomething(
    internalInvestmentClients
  )
    ? internalInvestmentClients.value
    : [];

  // Extract the clientId from the pathname
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const clientFromPath = findInternalInvestmentClientForClientId(
    clientId,
    internalInvestmentClients
  );

  /*
   * On user searching for a client,
   * Request all data for client and navigate to dashboard.
   */
  const onSearch = (selectedClient: IInteralInvestmentClient | null) => {
    if (selectedClient) {
      const isLoaded = isEntityLoaded(entities, selectedClient.id);
      const client: IInvestmentVehicleByClientByPeriod = {
        clientId: selectedClient.id,
        investmentVehicleId: undefined,
        periodId: PeriodHash.LATEST,
      };

      if (isLoaded) {
        dispatch(setInternalInvestmentDataFilters(client));
      } else {
        dispatch(reqInternalInvestmentData(client));
      }

      dispatch(reqSelectedClient(selectedClient));
      navigate(
        RelativePath.CLIENT_DEFAULT.replace(
          ":clientId",
          selectedClient.mdmId.toString()
        )
      );
    }
  };

  return (
    <>
      <div className={styles.subnavbar}>
        <HasEntitlementContainer
          entitlementLoadStatusName="internalInvestmentAdminEntitlementLoadStatus"
          entitlementName="hasInternalInvestmentAdminEntitlement"
          noDataChildren={<></>}
        >
          <Box className={styles.toolbar} alignItems="center">
            <Typography variant="h5" className={styles.searchText}>
              {AdminClientLabels.SEARCH_FOR}
            </Typography>
            <DropdownSearchBar<IInteralInvestmentClient>
              placeholder={AdminClientLabels.SEARCH_PLACEHOLDER}
              dropdownOptions={searchableClients}
              onChange={onSearch}
              getOptionLabel={getClientOptionLabel}
              filterAndSortDropdownOptions={searchAndSortClients}
              maxDropdownOptions={7} // this is the max before a scrollbar shows, per product reqs
              getNoOptionsMessage={AdminClientLabels.NO_RESULTS}
              className={`${styles.searchBar} ${styles.fullWidth}`}
              initialValue={clientFromPath}
            />
            {isSomething(filteredBy) && <AdminDownloadButton />}
          </Box>
        </HasEntitlementContainer>
      </div>
      <NavigationTabs />
    </>
  );
};
