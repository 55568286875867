import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ElectionsLabels,
  getCurrencyFormattedValueWithZeroDefault,
  MonospaceNumber,
  PriorElection,
} from "common";
import React, { useCallback } from "react";

import styles from "./PriorElectionsTable.module.scss";

export interface IPriorElectionsTableProps {
  priorElections: PriorElection[];
}

export const PriorElectionsTable = (props: IPriorElectionsTableProps) => {
  const { priorElections } = props;
  const yrSet = new Set(
    priorElections.flatMap((k) => k.electedAmountsByYear.map((k) => k.year))
  );

  const currentYear: number = new Date().getFullYear();
  //if the endYear is one of the years in the priorElections data that user has, only go back 3 years else go back 4 years.
  const startYear = yrSet.has(currentYear) ? currentYear - 3 : currentYear - 4;

  const orderedYears: number[] = Array.from(yrSet)
    .filter((year) => year >= startYear && year <= currentYear)
    .sort((a, b) => a - b);

  // Only render rows for strategies with data from a year we plan to render.
  const strategies = priorElections
    .filter((election) =>
      election.electedAmountsByYear.some((amount) =>
        orderedYears.includes(amount.year)
      )
    )
    .map((election) => election.strategyId);

  const getTotalCell = useCallback(
    (year: number) => {
      const totalValue = priorElections.reduce((total, election) => {
        const amount = election.electedAmountsByYear.find(
          (amount) => amount.year === year
        );
        total += amount?.electedAmount ?? 0;
        return total;
      }, 0);

      return (
        <TableCell key={`totals-${year}`} align="right">
          <MonospaceNumber
            value={totalValue}
            valueFormatter={getCurrencyFormattedValueWithZeroDefault}
          />
        </TableCell>
      );
    },
    [priorElections]
  );

  const getStrategyYearCell = useCallback(
    (election: PriorElection, year: number) => {
      const electedAmountForYear = election.electedAmountsByYear.find(
        (amount) => amount.year === year
      );
      const valueToRender = electedAmountForYear?.electedAmount ?? 0;
      return (
        <TableCell key={`${election.strategyId}-${year}`} align="right">
          <MonospaceNumber
            value={valueToRender}
            valueFormatter={getCurrencyFormattedValueWithZeroDefault}
          />
        </TableCell>
      );
    },
    []
  );

  const getStrategyRow = useCallback(
    (strategyId: number) => {
      const strategy = priorElections.find(
        (election) => election.strategyId === strategyId
      );

      return strategy !== undefined ? (
        <TableRow key={strategyId}>
          <TableCell>{strategy.strategyName}</TableCell>
          {orderedYears.map((year) => getStrategyYearCell(strategy, year))}
        </TableRow>
      ) : null;
    },
    [getStrategyYearCell, orderedYears, priorElections]
  );

  return (
    <TableContainer id={styles.priorElectionsTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{ElectionsLabels.STRATEGY}</TableCell>
            {orderedYears.map((yr) => (
              <TableCell key={yr} align="right">
                {yr} Elected
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {strategies.map(getStrategyRow)}
          <TableRow>
            <TableCell>{ElectionsLabels.TOTAL}</TableCell>
            {orderedYears.map(getTotalCell)}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
