import {
  EquityLabel,
  EquityTooltip,
} from "../constants/LabelAndTooltipConstants";
import {
  IEquityDatum,
  IRealizedCalloutData,
  IUnrealizedCalloutData,
} from "../types/dataTypes";
import { isSomething } from "../types/typeGuards";
import { Optional } from "../types/typeUtils";

export type NoSubValuesProps = {
  valueLabel: string;
  value: number;
  unitLabel: string;
  hasSubValue: boolean;
  valuetooltips: string;
};

//Gets Data for Single Value Equity Cards.
export const getSingleCardData = (
  hasViewRestrictedEquity: boolean | undefined,
  data: Optional<IEquityDatum>
): NoSubValuesProps => {
  if (!isSomething(data)) {
    return {
      valueLabel: "",
      value: 0,
      unitLabel: "",
      hasSubValue: false,
      valuetooltips: "",
    };
  }

  const totalUnvestedUnits = data.value.totalUnvestedUnits;
  const vestedRestrictedUnits = isSomething(data.value.vestedRestrictedUnits)
    ? data.value.vestedRestrictedUnits.value
    : 0;
  const vestedExchangeableUnits = isSomething(
    data.value.vestedExchangeableUnits
  )
    ? data.value.vestedExchangeableUnits.value
    : 0;

  if (!hasViewRestrictedEquity) {
    return {
      valueLabel: EquityLabel.TOTAL_UNVESTED_VALUE,
      value: totalUnvestedUnits,
      unitLabel: EquityLabel.NUMBER_OF_UNVESTED_UNITS,
      hasSubValue: false,
      valuetooltips: EquityTooltip.TOTAL_UNVESTED_VALUE,
    };
  }

  if (
    totalUnvestedUnits === 0 &&
    vestedExchangeableUnits === 0 &&
    vestedRestrictedUnits !== 0
  ) {
    return {
      valueLabel: EquityLabel.TOTAL_VESTED_RESTRICTED_VALUE,
      value: vestedRestrictedUnits,
      unitLabel: EquityLabel.NUMBER_OF_VESTED_RESTRICTED_UNITS,
      hasSubValue: false,
      valuetooltips: EquityTooltip.TOTAL_TOOLTIP_VESTED_RESTRICTED,
    };
  }

  if (
    vestedRestrictedUnits === 0 &&
    vestedExchangeableUnits === 0 &&
    totalUnvestedUnits !== 0
  ) {
    return {
      valueLabel: EquityLabel.TOTAL_UNVESTED_VALUE,
      value: totalUnvestedUnits,
      unitLabel: EquityLabel.NUMBER_OF_UNVESTED_UNITS,
      hasSubValue: false,
      valuetooltips: EquityTooltip.TOTAL_UNVESTED_VALUE,
    };
  }

  return {
    valueLabel: "",
    value: 0,
    unitLabel: "",
    hasSubValue: true,
    valuetooltips: "",
  };
};

//Checks if vested exchangeable units is 0.
export const showVestedExchangeable = (
  data: Optional<IEquityDatum>
): boolean => {
  if (!isSomething(data)) {
    return false;
  }

  const vestedExchangeableUnits = data.value.vestedExchangeableUnits;
  return (
    isSomething(vestedExchangeableUnits) && vestedExchangeableUnits.value !== 0
  );
};

export const hasAnyRealizedData = (
  realizedData: Optional<IRealizedCalloutData>
) => {
  return (
    isSomething(realizedData) &&
    (realizedData.value.realizedCarry.value !== 0 ||
      realizedData.value.realizedGainLoss.value !== 0 ||
      realizedData.value.realizedOptionalAndMandatoryInvestments.value !== 0 ||
      realizedData.value.returnOfCapital.value !== 0 ||
      realizedData.value.totalRealizedProceeds.value !== 0)
  );
};

export const hasAnyUnrealizedData = (
  unrealizedData: Optional<IUnrealizedCalloutData>
) => {
  return (
    isSomething(unrealizedData) &&
    (unrealizedData.value.netUnrealizedValue.value !== 0 ||
      unrealizedData.value.totalUnrealizedValue.value !== 0 ||
      unrealizedData.value.unrealizedCapitalInvested.value !== 0 ||
      unrealizedData.value.unrealizedGainLoss.value !== 0 ||
      unrealizedData.value.unrealizedOptionalAndMandatoryInvestments.value !==
        0 ||
      unrealizedData.value.unrealizedCarry.value !== 0)
  );
};
