import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Badge, Popover } from "@mui/material";
import { DocumentsLabel, IconButtonWithTooltip } from "common";
import React, { useState } from "react";

import { FilterPanel } from "../FilterPanel/FilterPanel";
import styles from "./FilterButton.module.scss";

export interface IFilterProps {
  disabled: boolean;
  countActiveFilters: number;
  handleApplyFilters: () => void;
  handleResetFilters: () => void;
  hasErrors: boolean;
  hasWarnings: boolean;
  children: React.ReactNode;
}

export const FilterButton = ({
  disabled,
  countActiveFilters,
  ...filteProps
}: IFilterProps) => {
  const [filterPanelAnchor, setFilterPanelAnchor] =
    useState<HTMLButtonElement | null>(null);

  const handleFilterPanelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterPanelAnchor(e.currentTarget);
  };

  const handleFilterPanelClose = () => {
    setFilterPanelAnchor(null);
  };

  const handleApplyFilters = () => {
    filteProps.handleApplyFilters();
    handleFilterPanelClose();
  };

  const handleResetFilters = () => {
    filteProps.handleResetFilters();
    handleFilterPanelClose();
  };

  return (
    <Badge
      overlap={"circular"}
      badgeContent={countActiveFilters}
      max={999}
      color="info"
    >
      <IconButtonWithTooltip
        onClickFunction={handleFilterPanelClick}
        buttonIcon={FilterAltOutlinedIcon}
        hoverText={DocumentsLabel.FILTERS}
        disabled={disabled}
        className={styles.filterIconButton}
      />
      <Popover
        className={styles.popover}
        open={filterPanelAnchor !== null}
        anchorEl={filterPanelAnchor}
        onClose={handleFilterPanelClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FilterPanel
          {...filteProps}
          handleApplyFilters={handleApplyFilters}
          handleClose={handleFilterPanelClose}
          handleResetFilters={handleResetFilters}
        />
      </Popover>
    </Badge>
  );
};
