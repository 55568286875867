import React from "react";

import styles from "./AccountIdTypeSampleImage.module.scss";

const AccountIdTypeSampleImageSrc = "/assets/images/account-sample-image.png";

export const AccountIdTypeSampleImage = () => {
  return (
    <img
      className={styles.AccountIdTypeSampleImage}
      src={AccountIdTypeSampleImageSrc}
    />
  );
};
