import clsx from "clsx";
import React from "react";

import { ILabeledDataPoint } from "../../../../types/labelTypes";
import { getCurrencyFormattedValue } from "../../../../utils/formatters";
import { InfoTooltip } from "../../../InfoTooltip/InfoTooltip";
import { MonospaceNumber } from "../../../MonospaceNumber/MonospaceNumber";
import styles from "./OverviewDataPoint.module.scss";

/**
 * Generic component that encapsulates a data point on a split callout card
 *
 * @param props
 * @returns a single row of data on a split callout card
 */
export const OverviewDataPoint = (props: ILabeledDataPoint) => {
  const {
    label,
    tooltipParagraphs,
    calloutDatum,
    getFormattedValue = getCurrencyFormattedValue,
    shouldHide,
    isSubLabel = false,
    isTotalLabel = false,
    subRows = [],
    className = "",
  } = props;

  return shouldHide ? (
    <></>
  ) : (
    <div className={`${styles.callout} ${className}`}>
      <div
        className={clsx(
          styles.calloutLabel,
          isSubLabel && styles.subLabel,
          isTotalLabel && styles.totalValue,
          subRows.length === 0 && styles.indent
        )}
      >
        <span>{label}</span>
        {tooltipParagraphs.length > 0 && (
          <InfoTooltip
            className={styles.tooltipIcon}
            tooltipContent={tooltipParagraphs}
          />
        )}
      </div>
      <MonospaceNumber
        value={calloutDatum?.value}
        className={clsx(styles.calloutValue, isTotalLabel && styles.totalValue)}
        valueFormatter={getFormattedValue}
      />
    </div>
  );
};
