import { Text, View } from "@react-pdf/renderer";
import {
  ElectionsLabels,
  IElectCardData,
  isSomething,
  mapYesOrNo,
  Optional,
} from "common";
import React from "react";

import { styles } from "../ElectionAgreementDocumentStyleSheet";
import { ElectionAmountTable } from "../ElectionAmountTable/ElectionAmountTable";
import { QuestionResponse } from "../QuestionResponses/QuestionResponse/QuestionResponse";

interface IElectionPageProps {
  electionAmount: IElectCardData;
  canUseFinancing: boolean;
  useReallocation: Optional<boolean>;
}
export const ElectionPage = (props: IElectionPageProps) => {
  const { electionAmount, canUseFinancing, useReallocation } = props;

  return (
    <>
      <View break>
        <Text style={styles.h2}>{ElectionsLabels.ELECTIONS}</Text>
        <Text style={styles.h3}>{ElectionsLabels.ELECTED_AMOUNTS}</Text>
      </View>
      <ElectionAmountTable
        electionAmount={electionAmount}
        canUseFinancing={canUseFinancing}
      />
      <View>
        <Text style={styles.h3}>{ElectionsLabels.REALLOCATION}</Text>
      </View>
      {isSomething(useReallocation) && (
        <QuestionResponse
          question={ElectionsLabels.REALLOCATION_QUESTION}
          response={mapYesOrNo(useReallocation.value)}
        />
      )}
    </>
  );
};
