import { IAggFuncParams } from "ag-grid-community";

import { DataLoadStatus } from "../constants/enums";
import { CommitmentsLabels } from "../constants/LabelAndTooltipConstants";
import {
  ICommitment,
  ICommitmentAnnual,
  ICommitmentLOF,
  IForecastedCapCall,
  IForecastedCapCallByStrategy,
} from "../types/dataTypes";
import { IInternalInvestmentDataStore } from "../types/storeTypes";
import { isSomething } from "../types/typeGuards";
import { getPercentageAsDecimalFormattedValue } from "./formatters";

/*
 * Groups forecasted capital calls data (which is organized by fund) by strategy instead.
 * Strategies are groupings of funds.
 * Amount values across funds belonging to the same strategy should be summed.
 */
export const groupForecastedCapitalCallsByStrategy = (
  forecastedCapitalCalls: IForecastedCapCall[]
): IForecastedCapCallByStrategy[] => {
  const groupedByStrategy = forecastedCapitalCalls.reduce((acc, curr) => {
    const strategyId = curr.fund.strategyId;
    if (!acc[strategyId]) {
      acc[strategyId] = {
        strategyName: curr.fund.strategyName,
        strategyId: strategyId,
        amount: curr.amount,
      };
    } else {
      acc[strategyId].amount += curr.amount;
    }
    return acc;
  }, {} as Record<string, IForecastedCapCallByStrategy>);

  return Object.values(groupedByStrategy);
};

/*
 * Sorts strategies alphabetically per Product Design requirements.
 * Add totals row here to avoid fiddling with AG Grid grouping logic.
 */
export const sortForecastedCapitalCallsAndAddTotalsRow = (
  forecastedCapCallsByStrategy: IForecastedCapCallByStrategy[]
) => {
  // Sort the array by strategyName in ascending order (A-Z)
  const sortedByStrategyName = forecastedCapCallsByStrategy.sort((a, b) => {
    return a.strategyName.localeCompare(b.strategyName);
  });

  // Calculate the total amount value across all strategies (just a sum of all amount values across all strategies)
  const totalAmount = forecastedCapCallsByStrategy.reduce(
    (sum, record) => sum + record.amount,
    0
  );

  // Add total row at the end
  return [
    ...sortedByStrategyName,
    {
      strategyName: CommitmentsLabels.TOTAL_FUTURE_CAP_CALLS,
      amount: totalAmount,
    },
  ];
};

/*
 * Converts generic commitment type to LOF commitment data type.
 * Calculates Capital Called parcentage.
 */
export const convertLOFCommitmentDataToGridFormat = (
  lofCommitments: ICommitment[]
): ICommitmentLOF[] => {
  const commitments: ICommitmentLOF[] = [];
  lofCommitments.forEach((commitment) => {
    if (commitment.fund) {
      const percent = getPercentageAsDecimalFormattedValue(
        commitment.capitalCalled,
        commitment.electedAmount
      );

      const lof: ICommitmentLOF = {
        fundName: commitment.fund.name,
        fundId: commitment.fund.id,
        electedAmount: commitment.electedAmount,
        capitalCalled: commitment.capitalCalled,
        capitalCalledPercent: percent == Infinity ? null : percent,
      };
      commitments.push(lof);
    }
  });

  // sort commitments alphabetically
  commitments.sort((a, b) => {
    return a.fundName.localeCompare(b.fundName);
  });

  return commitments;
};

/*
 * Converts generic commitment type to Annual commitment data type.
 * Calculates Capital Called parcentage.
 */
export const convertAnnualCommitmentToGridFormat = (
  commitments: ICommitment[]
): ICommitmentAnnual[] => {
  const annual: ICommitmentAnnual[] = [];

  commitments.forEach((commitment) => {
    if (commitment.fund) {
      const percent = getPercentageAsDecimalFormattedValue(
        commitment.capitalCalled,
        commitment.electedAmount
      );

      const commitmentWithPercentage: ICommitmentAnnual = {
        fundName: commitment.fund.name,
        fundId: commitment.fund.id,
        electedAmount: commitment.electedAmount,
        capitalCalled: commitment.capitalCalled,
        capitalCalledPercent: percent == Infinity ? null : percent,
        electionYear: commitment.electionYear,
      };

      annual.push(commitmentWithPercentage);
    }
  });
  return annual;
};

/*
 *  Aggregates Annual Commitments value by election year.
 *  Sorts by year. Used for excel exports.
 */
export const sortAndAggregateAnnualCommitments = (
  commitments: ICommitmentAnnual[]
): ICommitmentAnnual[] => {
  const aggregateCommitments: ICommitmentAnnual[] = [];

  // grab all years user has data for
  const years = new Set(commitments.map((commit) => commit.electionYear));

  // For each year aggregate data
  for (const year of years) {
    const capCalls = commitments.flatMap((commitment) =>
      commitment.electionYear == year ? commitment.capitalCalled : []
    );

    const electedAmnts = commitments.flatMap((commitment) =>
      commitment.electionYear == year ? commitment.electedAmount : []
    );

    const total: ICommitmentAnnual = {
      fundName: year.toString(),
      fundId: -999,
      electionYear: year,
      electedAmount: calculateTotalElectedAmnt(electedAmnts),
      capitalCalled: calculateTotalCapCall(capCalls),
      capitalCalledPercent: null,
    };

    total.capitalCalledPercent = getPercentageAsDecimalFormattedValue(
      total.capitalCalled,
      total.electedAmount
    );

    aggregateCommitments.push(total);
  }
  aggregateCommitments.sort(
    (a, b) =>
      b.electionYear - a.electionYear || a.fundName.localeCompare(b.fundName)
  );

  return aggregateCommitments;
};

// Calculates total capital call value for annual aggregation
export const calculateTotalCapCall = (capCalls: number[]) => {
  let total = 0;
  for (const capCall of capCalls) {
    total += capCall;
  }
  return total;
};

// Calculates total elected amount value for annual aggregation
export const calculateTotalElectedAmnt = (electedAmounts: number[]) => {
  let total = 0;
  for (const amnt of electedAmounts) {
    total += amnt;
  }
  return total;
};

// aggregates capital called percentage for annual commitments grid
export const aggregateCapCallPercentageForGrid = (props: IAggFuncParams) => {
  const rowNodes = props.rowNode.allLeafChildren ?? [];
  const totalCapCall = calculateTotalCapCall(
    rowNodes.map((row) => row.data.capitalCalled)
  );
  const totalElectedAmnt = calculateTotalElectedAmnt(
    rowNodes.map((row) => row.data.electedAmount)
  );

  return getPercentageAsDecimalFormattedValue(totalCapCall, totalElectedAmnt);
};

// aggregation func for capital called in annual commitments grid
export const aggregateCapCallForGrid = (props: IAggFuncParams) => {
  const rowNodes = props.rowNode.allLeafChildren ?? [];
  return calculateTotalCapCall(rowNodes.map((row) => row.data.capitalCalled));
};

// aggreagation func for elected amount in annual commitments grid
export const aggregateElectedAmntForGrip = (props: IAggFuncParams) => {
  const rowNodes = props.rowNode.allLeafChildren ?? [];
  return calculateTotalElectedAmnt(
    rowNodes.map((row) => row.data.electedAmount)
  );
};

export const hasOrIsLoadingCommitmentData = (
  internalInvestmentData: IInternalInvestmentDataStore
): boolean => {
  const internalInvestmentDataLoadStatus =
    internalInvestmentData.internalInvestmentDataLoadStatus;

  return (
    internalInvestmentDataLoadStatus === DataLoadStatus.LOADING ||
    internalInvestmentDataLoadStatus === DataLoadStatus.NOT_REQUESTED ||
    internalInvestmentData.entities.some(
      (entity) =>
        entity.commitmentData !== null && isSomething(entity.commitmentData)
    )
  );
};
