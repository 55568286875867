import { EnvironmentResolver, VestingEvents } from "common";
import React from "react";
export const VestingEventsWrapper = () => {
  return (
    <VestingEvents
      // set to true for admin
      hasViewRestrictedEquity={
        EnvironmentResolver.ENV.RESTRICTED_EQUITY_ADMIN_FEATURE_FLAG
      }
    />
  );
};
