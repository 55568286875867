import { Commitments, Page, SectionHeader } from "common";
import React from "react";

const CommitmentsWrapper = () => {
  return (
    <Page pageHeader={SectionHeader.COMMITMENTS} content={<Commitments />} />
  );
};

export default CommitmentsWrapper;
