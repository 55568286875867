import { Tab, Tabs, Typography } from "@mui/material";
import React, { isValidElement, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { SubTabPageName } from "../../constants/enums";
import { SubTabPages } from "../../types/SubTabPages";
import { a11yProps } from "../../utils/tabsUtils";
import { TabPanel } from "../TabPanel/TabPanel";
import styles from "./PageWithSubTabs.module.scss";

export interface IPageWithSubTabsProps {
  pageHeader: string | JSX.Element;
  pageMenu?: JSX.Element;
  pages: SubTabPages;
  tabDisplayOrder: SubTabPageName[];
  defaultTab: string;
  className?: string;
}

export const PageWithSubTabs = (props: IPageWithSubTabsProps) => {
  const {
    pageHeader,
    pageMenu = null,
    pages,
    tabDisplayOrder,
    defaultTab,
    className,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  /*
  helper function which uses current url path and tab display order 
    to determine tab index number. needed for showing correct tab as selected
  */
  const determineTabIndex = (path: string): number => {
    let curIndex = 0;
    tabDisplayOrder.forEach((tabName: SubTabPageName, index: number) => {
      if (path.includes(pages[tabName]?.path ?? "")) {
        curIndex = index;
      }
    });
    return curIndex;
  };

  const [currentTabIndex, setCurrentTabIndex] = useState(
    determineTabIndex(location.pathname)
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
    navigate(`${pages[tabDisplayOrder[newValue]]?.path}`);
  };

  return (
    <div className={className ? `${className} ${styles.page}` : styles.page}>
      <div className={pageMenu ? styles.pageHeaderWithMenu : styles.pageHeader}>
        {pageMenu}
        {isValidElement(pageHeader) ? (
          pageHeader
        ) : (
          <Typography variant="h1">{pageHeader}</Typography>
        )}
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          className={styles.pageTab}
        >
          {tabDisplayOrder.map((tab, index) => (
            <Tab
              label={pages[tab]?.name}
              key={pages[tab]?.name}
              value={index}
              {...a11yProps(index)}
            ></Tab>
          ))}
        </Tabs>
      </div>
      <Routes>
        {Object.values(pages).map((page) => {
          return (
            <Route
              key={page.path}
              path={page.path}
              element={
                <div className={styles.pageContent}>
                  {tabDisplayOrder.map((tab, index) => {
                    const curPage = pages[tab];
                    return (
                      <TabPanel
                        selectedTabValue={currentTabIndex}
                        index={index}
                        key={pages[tab]?.name}
                      >
                        {curPage && <curPage.element />}
                      </TabPanel>
                    );
                  })}
                </div>
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to={`${defaultTab}`} replace />} />
      </Routes>
    </div>
  );
};
