import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { AccountType } from "common";
import React from "react";
import { FieldError } from "react-hook-form";

import FieldLabelWithTooltip from "../../../../../components/FieldLabelWithTooltip/FieldLabelWithTooltip";
import styles from "./AccountTypeRadioButtonInput.module.scss";

export interface IAccountTypeRadioButtonInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  disabled: boolean;
  label: string;
  options: Array<AccountType>;
  tooltip?: string | React.ReactNode;
  error?: FieldError;
}

export const AccountTypeRadioButtonInput = (
  props: IAccountTypeRadioButtonInputProps
) => {
  const { value, onChange, disabled, options } = props;

  return (
    <Stack>
      <FieldLabelWithTooltip {...props} />
      <RadioGroup row onChange={onChange} value={value}>
        {options.map((option) => {
          return (
            <FormControlLabel
              key={"AddAccountRadio-".concat(option)}
              className={styles.radioButton}
              value={option}
              control={<Radio disabled={disabled} />}
              label={option}
            />
          );
        })}
      </RadioGroup>
    </Stack>
  );
};
