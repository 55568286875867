import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingBottom: 8,
    fontFamily: "Guardian Sans",
  },
  leftColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 94,
  },
  rightColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 6,
    fontWeight: 500,
  },
  question: {
    fontSize: 12,
  },
  response: {
    textAlign: "right",
    fontSize: 12,
  },
});
