import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            height: 18,
            width: 18,
          },
          "&.Mui-checked:not(.Mui-disabled)": {
            color: colors.black,
          },
          "&.Mui-disabled": {
            color: colors.disabled_field_grey,
          },
        },
      },
      defaultProps: {
        disableTouchRipple: true,
      },
    },
  },
};

export const CustomRadioTheme = partialTheme.components?.MuiRadio;
