import { FileDownloadOutlined } from "@mui/icons-material";
import { CardHeader, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { CardFooter } from "../../../../components/CardFooter/CardFooter";
import { DataCard } from "../../../../components/DataCard/DataCard";
import { IconButtonWithTooltip } from "../../../../components/IconButtonWithTooltip/IconButtonWithTooltip";
import {
  CommitmentsLabels,
  getAsOfLine,
  SectionHeader,
} from "../../../../constants/LabelAndTooltipConstants";
import { StringConstants } from "../../../../constants/StringConstants";
import { useFetchDatasetIfIdDefined } from "../../../../hooks/dataFetchHooks";
import { useExcelDownload } from "../../../../hooks/useExcelDownload";
import { reqInternalInvestmentData } from "../../../../redux/actions/internalInvestmentActions";
import {
  selectFilteredBy,
  selectInternalInvestmentDataLoadStatus,
  selectLifeOfFundCommitments,
} from "../../../../redux/selectors";
import { isSomething } from "../../../../types/typeGuards";
import { isInProgress, isUnsuccessful } from "../../../../utils/dataLoadUtils";
import { worksheetGenerators } from "../../../../utils/excelUtils/excelGenerationUtils";
import { LifeOfFundsGrid } from "./LifeOfFundCommitmentsGrid/LifeOfFundCommitmentsGrid";

export const LifeOfFundCommitments = () => {
  const filteredBy = useSelector(selectFilteredBy);
  const internalInvestmentDataLoadStatus = useSelector(
    selectInternalInvestmentDataLoadStatus
  );
  const lifeOfFundCommitments = useSelector(selectLifeOfFundCommitments);

  const retryFunction = useFetchDatasetIfIdDefined(
    reqInternalInvestmentData,
    filteredBy,
    internalInvestmentDataLoadStatus
  );

  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    const fileName = `${SectionHeader.LIFE_OF_FUNDS}`;
    await handleExcelDownload(
      worksheetGenerators.LifeOfFundCommitments,
      fileName
    );
  };

  return (
    <DataCard
      failed={isUnsuccessful(internalInvestmentDataLoadStatus)}
      loading={isInProgress(internalInvestmentDataLoadStatus)}
      noData={
        !isSomething(lifeOfFundCommitments) ||
        lifeOfFundCommitments.value.commitments.length === 0
      }
      hideIfNoData={true}
      onReload={retryFunction}
    >
      {isSomething(lifeOfFundCommitments) &&
        lifeOfFundCommitments.value.commitments.length > 0 && (
          <>
            <CardHeader
              title={
                <Typography variant="h3">
                  {SectionHeader.LIFE_OF_FUNDS}
                </Typography>
              }
              subheader={
                <Typography variant="subtitle1">
                  {getAsOfLine(lifeOfFundCommitments.value.asOfDate)}
                </Typography>
              }
              action={
                <IconButtonWithTooltip
                  onClickFunction={handleDownloadClick}
                  buttonIcon={FileDownloadOutlined}
                  hoverText={StringConstants.DOWNLOAD}
                />
              }
            />
            <Grid container flex="column">
              <LifeOfFundsGrid
                lifeOfFundCommitments={lifeOfFundCommitments.value.commitments}
              />
            </Grid>
            <CardFooter
              footerLines={[CommitmentsLabels.COMMITMENTS_FOOTNOTE]}
            />
          </>
        )}
    </DataCard>
  );
};
