import { createAction } from "@reduxjs/toolkit";

import { DataLoadStatus } from "../../constants/enums";
import { IUserAgreement, IWriteUserAgreement } from "../../types/dataTypes";

export const reqUserAgreements = createAction<void>("REQ_USER_AGREEMENTS");

export const recvUserAgreements = createAction<IUserAgreement[]>(
  "RECV_USER_AGREEMENTS"
);

export const errUserAgreements = createAction<DataLoadStatus>(
  "ERR_USER_AGREEMENTS"
);

export const putUserAgreement =
  createAction<IWriteUserAgreement>("PUT_USER_AGREEMENT");
