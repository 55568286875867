import { ClientLevelPageName, PageName } from "../constants/Pages/Pages";

export const isPageVisible = (
  pageName: PageName,
  canViewEquityPage: boolean
) => {
  switch (pageName) {
    case PageName.EQUITY_LANDING_PAGE:
      return canViewEquityPage;
    default:
      return true;
  }
};

export const isClientPageVisible = (
  pageName: ClientLevelPageName,
  canViewDashboardPage: boolean,
  canViewInvestmentsAndCarryPage: boolean,
  canViewCommitmentsPage: boolean
) => {
  switch (pageName) {
    case ClientLevelPageName.DASHBOARD:
      return canViewDashboardPage;
    case ClientLevelPageName.INVESTMENTS_AND_CARRY:
      return canViewInvestmentsAndCarryPage;
    case ClientLevelPageName.COMMITMENTS:
      return canViewCommitmentsPage;
    default:
      return true;
  }
};
