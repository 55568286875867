import { Chip } from "@mui/material";
import {
  colors,
  ElectionWorkflowLabels,
  ElectionWorkflowStageId,
  ElectionWorkflowStageIdToLabel,
} from "common";
import React from "react";

import styles from "./CurrentStageChip.module.scss";

interface ICurrentStageChipProps {
  activeStageId: number;
}

const getChipCellColorByStatus = (status: ElectionWorkflowStageId): string => {
  switch (status) {
    case ElectionWorkflowStageId.COMPLETED:
      return colors.avocado;
    case ElectionWorkflowStageId.OVERVIEW:
    case ElectionWorkflowStageId.ELIGIBILITY:
    case ElectionWorkflowStageId.STRATEGIES:
    case ElectionWorkflowStageId.ELECT:
    case ElectionWorkflowStageId.BANK_ACCOUNT:
    case ElectionWorkflowStageId.REVIEW_AND_SIGN:
      return colors.cobalt;
    default:
      return colors.silver;
  }
};

const CurrentStageChip: React.FC<ICurrentStageChipProps> = ({
  activeStageId,
}) => {
  const electionWorkflowStage = activeStageId as ElectionWorkflowStageId;
  const chipColor = getChipCellColorByStatus(electionWorkflowStage);
  const label =
    ElectionWorkflowStageIdToLabel[electionWorkflowStage] ??
    ElectionWorkflowLabels.UNKNOWN;

  return (
    <Chip
      className={styles.chip}
      size="small"
      label={label}
      style={{ backgroundColor: chipColor }}
    />
  );
};

export default CurrentStageChip;
