import { Grid } from "@mui/material";
import {
  colors,
  getChartCellColorByIndex,
  getCurrencyFormattedValue,
  getCurrencyFormattedValueWithZeroDefault,
  MonospaceNumber,
  PieConstants,
  ResponsiveRechartsWrapper,
  UnrealizedValue,
  UnrealizedValueLabels,
} from "common";
import React from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";

import styles from "./UnrealizedValueChart.module.scss";

/**
 * Builds the unrealized value tooltip
 * @param unrealizedValue - The unrealized value object
 * @param unrealizedValueIndex - The unrealized value index
 * @returns  JSX.Element
 */
const UnrealizedValueTooltip = (
  {
    strategyName,
    remainingCapitalInvested,
    gainLoss,
    carriedInterest,
    total,
  }: UnrealizedValue,
  unrealizedValueIndex: number
) => {
  return (
    <div key={`unrealizedValue_${unrealizedValueIndex}`}>
      <table>
        <tbody>
          <tr>
            <td className={styles.label}>{strategyName}</td>
          </tr>
          {remainingCapitalInvested > 0 && (
            <tr>
              <td className={styles.label}>
                {UnrealizedValueLabels.REMAINING_CAPITAL_INVESTED}
              </td>
              <td className={styles.value}>
                <MonospaceNumber
                  value={remainingCapitalInvested}
                  valueFormatter={getCurrencyFormattedValue}
                />
              </td>
            </tr>
          )}
          {gainLoss > 0 && (
            <tr>
              <td className={styles.label}>
                {UnrealizedValueLabels.GAIN_LOSS}
              </td>
              <td className={styles.value}>
                <MonospaceNumber
                  value={gainLoss}
                  valueFormatter={getCurrencyFormattedValue}
                />
              </td>
            </tr>
          )}
          {carriedInterest > 0 && (
            <tr>
              <td className={styles.label}>{UnrealizedValueLabels.CARRY}</td>
              <td className={styles.value}>
                <MonospaceNumber
                  value={carriedInterest}
                  valueFormatter={getCurrencyFormattedValue}
                />
              </td>
            </tr>
          )}
          {remainingCapitalInvested !== total &&
            gainLoss !== total &&
            carriedInterest !== total && (
              <tr className={styles.totalRow}>
                <td className={styles.label}>
                  {UnrealizedValueLabels.TOTAL_UNREALIZED_VALUE}
                </td>
                <td className={styles.value}>
                  <MonospaceNumber
                    value={total}
                    valueFormatter={getCurrencyFormattedValue}
                  />
                </td>
              </tr>
            )}
        </tbody>
      </table>
    </div>
  );
};

export interface IUnrealizedValueChartProps {
  total: number;
  unrealizedValues: UnrealizedValue[];
}

export const UnrealizedValueChart = (props: IUnrealizedValueChartProps) => {
  const { total, unrealizedValues } = props;
  //Position for the X Axis from pie chart
  const xAxis = "50%";
  //Position for the Y Axis from pie chart
  const yAxis = 94;

  const pieStyle = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  return (
    <>
      <Grid item xs={12} md={12} className={styles.unrealizedValueGrid}>
        <ResponsiveRechartsWrapper>
          <PieChart className={styles.pieChart} margin={pieStyle}>
            {unrealizedValues && (
              <Tooltip
                cursor={{
                  /* Recharts adds a gray hover when there's no data by default, 
                this sets the hover color to white, so we don't have a weird hover when there's no data selected*/
                  stroke:
                    unrealizedValues.length === 0
                      ? colors.white
                      : colors.lightest_grey,
                }}
                content={({ payload }) => {
                  return (
                    <div className={styles.tooltip}>
                      {payload &&
                        payload
                          .flatMap((item) => item.payload)
                          .map(UnrealizedValueTooltip)}
                    </div>
                  );
                }}
              />
            )}
            <text
              dx={xAxis}
              y={yAxis - 15}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieUnrealizedValueText}
            >
              {UnrealizedValueLabels.UNREALIZED_VALUE}
            </text>
            <text
              dx={xAxis}
              y={yAxis + 15}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieUnrealizedValue}
            >
              {getCurrencyFormattedValueWithZeroDefault(total)}
            </text>
            <Pie
              data={unrealizedValues}
              cx={xAxis}
              cy={yAxis}
              innerRadius={PieConstants.INNER_RADIUS}
              outerRadius={PieConstants.OUTER_RADIUS}
              blendStroke={false}
              minAngle={0}
              dataKey="total"
              nameKey="strategyName"
              startAngle={90} // pie chart should begin at 12 o'clock position
              endAngle={-270} // pie chart should go clockwise, which is negative direction
            >
              {unrealizedValues &&
                unrealizedValues.map((unrealizedValue, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={total !== 0 ? getChartCellColorByIndex(index) : ""}
                  />
                ))}
              {[].map((index) => (
                <Cell key={`cell-${index}`} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveRechartsWrapper>
      </Grid>
    </>
  );
};
