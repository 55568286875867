import { Circle } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Dot,
  ReferenceLine,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { InvestmentBreakdownKey, PeriodType } from "../../constants/enums";
import { InvestmentHistoryLabel } from "../../constants/LabelAndTooltipConstants";
import {
  chartMargins,
  RechartsConstants,
  xAxisTick,
  yAxisTick,
} from "../../constants/RechartsSVGStyles";
import { StringConstants } from "../../constants/StringConstants";
import colors from "../../styles/_colors.scss";
import { ComposedChartType } from "../../types/charting";
import { IHistoricalSummaryDatum } from "../../types/dataTypes";
import { InvestmentHistoryValueItem } from "../../types/investmentHistoryTypes";
import { getTicksAndLabels } from "../../utils/charting";
import {
  getCurrencyFormattedValue,
  getLabelFromCamelCase,
} from "../../utils/formatters";
import {
  calculateDateDomain,
  calculateDateTicks,
  formatYAxisTicks,
  getActiveData,
  getDataAccesor,
  getQuarterlyLabel,
  getQuarterlyLabelWithQTD,
  getYearLabelByQuarter,
  getYearLabelByQuarterWithSuffix,
  getYearlySuffix,
} from "../../utils/historicalSummaryUtils";
import { InvestmentBreakDownValueSelectors } from "../../utils/investmentBreakdownUtils";
import { MonospaceNumber } from "../MonospaceNumber/MonospaceNumber";
import { ResponsiveRechartsWrapper } from "../ResponsiveRechartsWrapper/ResponsiveRechartsWrapper";
import styles from "./CashFlowChart.module.scss";

interface CashFlowChartProps {
  historicalSummaryData: IHistoricalSummaryDatum[];
  firstQuarterDate: Date;
  dataTypes: InvestmentHistoryValueItem[];
  highlightedBar: InvestmentBreakdownKey | null;
  graphInitialized: boolean;
  periodType: PeriodType;
}

export const CashFlowChart = (props: CashFlowChartProps) => {
  const {
    historicalSummaryData,
    firstQuarterDate,
    dataTypes,
    graphInitialized,
    periodType,
  } = props;

  const [graphData, setGraphData] = useState<IHistoricalSummaryDatum[]>([]);
  const [dateTicks, setDateTicks] = useState<number[]>([]);
  const [yAxisTicks, setYAxisTicks] = useState<number[]>([]);
  const [yAxisTickLabels, setYAxisTickLabels] = useState<string[]>([]);
  const [strokeWidth, setStrokeWidth] = useState<number>(0);
  const [dateRange, setDateRange] = useState<number[]>([0, 0]);
  // controls where vertical reference lines (between years) appear on graph
  const [referenceLinePlacements, setReferenceLinePlacements] = useState<
    number[]
  >([]);

  useEffect(() => {
    const activeQuarters = getActiveData(dataTypes, historicalSummaryData).map(
      (datum) => datum.displayQuarterAsOfDate
    );

    const calculatedDateTicks = calculateDateTicks(activeQuarters);
    setDateTicks(calculatedDateTicks);
    const calculatedRange = calculateDateDomain(activeQuarters, periodType);
    setDateRange(calculatedRange);

    // Multiply capital invested by -1, as we want it to render as a loss on this chart
    const cleanedData = historicalSummaryData.map((datum) => {
      return {
        ...datum,
        cumulative: {
          ...datum.cumulative,
          capitalInvested: InvestmentBreakDownValueSelectors.capitalInvested(
            datum.cumulative
          ),
        },
        nonCumulative: {
          ...datum.nonCumulative,
          capitalInvested: InvestmentBreakDownValueSelectors.capitalInvested(
            datum.nonCumulative
          ),
        },
      };
    });

    setGraphData(cleanedData);

    // determine if reference line should appear after a data point
    //  a whole number year denotes Q4 data, so only display reference line
    //  after those, excluding the last value, since no line is needed
    //  at the end of the graph. Quarters are zero-indexed.
    const refLines = cleanedData
      .slice(0, cleanedData.length - 1)
      .map((datum) => datum.displayQuarterAsOfDate.valueOf())
      .filter((quarter) => quarter % 4 === 3);

    setReferenceLinePlacements(refLines);

    const activeDataTypes = dataTypes.filter((line) => line.isSelected);
    const values: number[] = [];
    cleanedData.forEach((datum) =>
      activeDataTypes.forEach((item) => {
        values.push(
          InvestmentBreakDownValueSelectors[item.key](datum.nonCumulative) ?? 0
        );
      })
    );

    const tickInfo = getTicksAndLabels(values);

    setYAxisTicks(tickInfo.tickValues);
    setYAxisTickLabels(tickInfo.tickLabels);
  }, [historicalSummaryData, dataTypes, periodType]);

  // Hack to replicate BarChart hover hightlight on a ComposedChart. By default it only shows a vertical line.
  const initChart = (ref: ComposedChartType) => {
    const tooltipCursorWidth =
      (ref?.props?.width ?? 0) / (historicalSummaryData.length + 2);
    setStrokeWidth(tooltipCursorWidth);
  };

  return (
    <div className={styles.bar}>
      {graphInitialized && historicalSummaryData.length === 0 ? (
        <div className={styles.noDataMessage}>
          <p>
            <InfoOutlinedIcon className={styles.infoOutlinedIcon} />
            {StringConstants.NO_CASH_FLOW_DATA_MESSAGE}
          </p>
        </div>
      ) : (
        <ResponsiveRechartsWrapper>
          <ComposedChart
            ref={initChart}
            data={getActiveData(dataTypes, graphData)}
            barCategoryGap="25%"
            maxBarSize={50}
            margin={chartMargins}
            stackOffset={"sign"}
          >
            <Tooltip<number, string>
              cursor={{
                /* Recharts adds a gray hover when there's no data by default, 
                  this sets the hover color to white, so we don't have a weird hover when there's no data selected*/
                stroke:
                  dateTicks.length === 0 ? colors.white : colors.lightest_grey,
                strokeWidth: strokeWidth,
              }}
              content={({ payload, label }) => {
                let yearlyLabelSuffix: string | undefined = undefined;
                if (payload && payload.length) {
                  if (payload[0].payload) {
                    yearlyLabelSuffix = getYearlySuffix(
                      payload[0].payload.asOfDate,
                      firstQuarterDate,
                      true
                    );
                  }
                }
                return (
                  <div className={styles.tooltip}>
                    <div>
                      <table className={styles.itemList}>
                        <thead>
                          <tr className={styles.item}>
                            <td className={styles.itemLabel}>
                              {periodType === PeriodType.QUARTERLY
                                ? getQuarterlyLabelWithQTD(label)
                                : getYearLabelByQuarterWithSuffix(
                                    label,
                                    yearlyLabelSuffix
                                  )}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {payload &&
                            payload.map((item, index) => {
                              const isNetValue = item.color == undefined;
                              return (
                                <tr
                                  key={`${item.dataKey}${index}`}
                                  className={`${styles.item} ${
                                    isNetValue && styles.netValue
                                  }`}
                                >
                                  <td className={styles.itemLabel}>
                                    <span>
                                      <Circle
                                        className={
                                          isNetValue
                                            ? styles.netValCircle
                                            : styles.circle
                                        }
                                        style={{
                                          color: item.color,
                                        }}
                                      />{" "}
                                      {item.name}
                                      {":"}
                                    </span>
                                  </td>
                                  <td className={styles.itemValue}>
                                    <MonospaceNumber
                                      value={item.value}
                                      className={styles.itemValue}
                                      valueFormatter={getCurrencyFormattedValue}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              }}
            />
            <CartesianGrid
              vertical={false}
              strokeDasharray="2 2"
              stroke={colors.slate}
            />
            <XAxis
              type={"number"}
              axisLine={false}
              dataKey={(data) => data.displayQuarterAsOfDate}
              domain={[dateRange[0], dateRange[1]]}
              tick={xAxisTick}
              tickLine={false}
              ticks={dateTicks.length === 0 ? [""] : dateTicks}
              tickFormatter={
                periodType === PeriodType.QUARTERLY &&
                graphData.length <=
                  RechartsConstants.MAX_QUARTERS_TO_LABEL_INDIVIDUALLY
                  ? getQuarterlyLabel
                  : getYearLabelByQuarter
              }
            />
            <YAxis
              axisLine={true}
              domain={["dataMin", "dataMax"]}
              tick={yAxisTick}
              tickFormatter={(value, idx) =>
                formatYAxisTicks(value, idx, yAxisTickLabels)
              }
              tickLine={false}
              ticks={
                /* When no data is selected, it will render $0 in the middle of the chart by default, 
                this will force the chart to render $0 at the bottom */
                yAxisTicks.length === 1 && yAxisTicks[0] === 0
                  ? [0, 1]
                  : yAxisTicks
              }
              type="number"
            />
            <ReferenceLine y={0} stroke={colors.dark_grey} />
            {referenceLinePlacements.map((item, idx) => {
              return (
                <ReferenceLine
                  key={idx}
                  x={item + (periodType === PeriodType.YEARLY ? 2.0 : 0.5)} // need to move line over so it is between two points
                  stroke={colors.slate}
                  strokeDasharray="2 2"
                />
              );
            })}
            {dataTypes
              .filter(
                (type) =>
                  type.isSelected &&
                  historicalSummaryData.some(
                    (datum) =>
                      InvestmentBreakDownValueSelectors[type.key](
                        datum.nonCumulative
                      ) !== 0
                  )
              )
              .map((item) => (
                <Bar
                  key={item.key}
                  dataKey={getDataAccesor(item.key, false)}
                  fill={item.color}
                  name={item.labelOverride ?? getLabelFromCamelCase(item.key)}
                  stackId="a"
                  opacity={
                    props.highlightedBar === null ||
                    props.highlightedBar === item.key
                      ? 1
                      : 0.2
                  }
                />
              ))}
            <Scatter
              dataKey={getDataAccesor(
                InvestmentBreakdownKey.NET_CASH_FLOW,
                false
              )}
              shape={
                <Dot
                  className={styles.netValueDot}
                  r={3.5}
                  stroke={colors.black}
                  strokeWidth={"0.75px"}
                  fill={colors.white}
                />
              }
              opacity={
                props.highlightedBar === null ||
                props.highlightedBar === InvestmentBreakdownKey.NET_CASH_FLOW
                  ? 1
                  : 0.2
              }
              name={InvestmentHistoryLabel.NET_CASH_FLOW}
            />
          </ComposedChart>
        </ResponsiveRechartsWrapper>
      )}
    </div>
  );
};
