import { Grid } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Cell, Pie, PieChart, Sector, Tooltip } from "recharts";

import { ResponsiveRechartsWrapper } from "../../../../../components/ResponsiveRechartsWrapper/ResponsiveRechartsWrapper";
import { ALL_FUNDS_PLACEHOLDER } from "../../../../../constants/InvestmentBreakdownConstants";
import { CarryLabel } from "../../../../../constants/LabelAndTooltipConstants";
import { PieConstants } from "../../../../../constants/PieConstants";
import { setPointerType } from "../../../../../redux/actions/viewActions";
import { selectLastPointerType } from "../../../../../redux/selectors";
import colors from "../../../../../styles/_colors.scss";
import { ICarryChartData } from "../../../../../types/dataTypes";
import { Optional, some } from "../../../../../types/typeUtils";
import { getChartCellColorByIndex } from "../../../../../utils/charting";
import { getCurrencyFormattedValueWithZeroDefault } from "../../../../../utils/formatters";
import styles from "./CarryChart.module.scss";
import { CarryChartTooltip } from "./CarryChartTooltip";

interface CarryChartProps {
  carryChartData: ICarryChartData[];
  unrealizedCarry: number | undefined;
  activeIndex: number | undefined;
  setActiveIndex: (val: number | undefined) => void;
  setSelectedBusinessUnit: (
    value: React.SetStateAction<Optional<ICarryChartData>>
  ) => void;
}

const NoValueCarryChart: ICarryChartData[] = [
  {
    businessUnitName: "",
    fundShortName: "",
    mdmFundId: 0,
    totalUnrealized: 1,
    totalRealized: 0,
  },
];

export const CarryChart = (props: CarryChartProps) => {
  const {
    carryChartData,
    unrealizedCarry,
    activeIndex,
    setActiveIndex,
    setSelectedBusinessUnit,
  } = props;

  const lastPointerType = useSelector(selectLastPointerType);
  const dispatch = useDispatch();

  const isClickable = useMemo(() => {
    return (
      carryChartData.length > 0 &&
      carryChartData[0].mdmFundId === ALL_FUNDS_PLACEHOLDER
    );
  }, [carryChartData]);

  //Validates if the list of holdback per fund has 1 or more values
  const carryChartHasValues = carryChartData.length > 0;
  //Position for the X Axis from pie chart
  const xAxis = "50%";
  //Position for the Y Axis from pie chart
  const yAxis = 115;
  const pieStyle = {
    top: 10,
    right: 0,
    bottom: 0,
    left: 0,
  };

  const renderActiveShape = ({
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  }: {
    cx: number;
    cy: number;
    innerRadius: number;
    outerRadius: number;
    startAngle: number;
    endAngle: number;
    fill: string;
  }) => {
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 10}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          cursor={isClickable ? "pointer" : "default"}
        />
      </g>
    );
  };

  const onMouseEnter = (data: ICarryChartData, idx: number) => {
    setActiveIndex(idx);
  };

  const onMouseLeave = () => {
    setActiveIndex(undefined);
  };

  const handleSliceClick = useCallback(
    (
      carryChartData: ICarryChartData,
      index: number,
      event: React.PointerEvent<SVGElement>
    ) => {
      event.stopPropagation();
      dispatch(setPointerType(event.pointerType));
      if (event.pointerType === "mouse") {
        if (carryChartData.mdmFundId === ALL_FUNDS_PLACEHOLDER) {
          setSelectedBusinessUnit(some(carryChartData));
          setActiveIndex(undefined);
        }
      } else {
        setActiveIndex(index);
      }
    },
    [dispatch, setActiveIndex, setSelectedBusinessUnit]
  );

  const onLinkClick = () => {
    if (activeIndex !== undefined) {
      setSelectedBusinessUnit(some(carryChartData[activeIndex]));
      setActiveIndex(undefined);
    }
  };

  return (
    <>
      <Grid item xs={12} md={12} className={styles.pie}>
        <ResponsiveRechartsWrapper
          onTouchStart={() => setActiveIndex(undefined)}
        >
          <PieChart
            onMouseLeave={onMouseLeave}
            className={styles.pie}
            margin={pieStyle}
          >
            {carryChartHasValues && (
              <Tooltip<number, string>
                cursor={{
                  /* Recharts adds a gray hover when there's no data by default, 
                this sets the hover color to white, so we don't have a weird hover when there's no data selected*/
                  stroke:
                    carryChartData.length === 0
                      ? colors.white
                      : colors.lightest_grey,
                }}
                active={activeIndex !== undefined}
                content={() =>
                  activeIndex !== undefined &&
                  carryChartData[activeIndex] && (
                    <CarryChartTooltip
                      activeData={carryChartData[activeIndex]}
                      onLinkClick={onLinkClick}
                      color={getChartCellColorByIndex(activeIndex)}
                    />
                  )
                }
                wrapperStyle={{
                  pointerEvents: lastPointerType === "mouse" ? "none" : "all",
                  visibility: activeIndex === undefined ? "hidden" : "visible",
                }}
              />
            )}
            <text
              dx={xAxis}
              y={yAxis - 5}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieCenterText}
              fill={colors.bx_primary_grey_text}
            >
              {CarryLabel.UNREALIZED_CARRY}
            </text>
            <text
              dx={xAxis}
              y={yAxis + 25}
              textAnchor="middle"
              dominantBaseline="middle"
              className={styles.pieCenterValue}
            >
              {getCurrencyFormattedValueWithZeroDefault(unrealizedCarry)}
            </text>
            <Pie
              data={carryChartHasValues ? carryChartData : NoValueCarryChart}
              cx={xAxis}
              cy={yAxis}
              innerRadius={PieConstants.INNER_RADIUS}
              outerRadius={PieConstants.OUTER_RADIUS}
              fill={colors.white}
              blendStroke={carryChartData.length <= 1}
              minAngle={carryChartData.length > 1 ? 10 : 0}
              dataKey="totalUnrealized"
              nameKey="businessUnitName"
              startAngle={90} // pie chart should begin at 12 o'clock position
              endAngle={-270} // pie chart should go clockwise, which is negative direction
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onPointerDown={handleSliceClick}
            >
              {carryChartHasValues &&
                carryChartData.map((carryData, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      carryData.totalUnrealized !== 0
                        ? getChartCellColorByIndex(index)
                        : colors.bx_slate
                    }
                    cursor={isClickable ? "pointer" : "default"}
                  />
                ))}
              {NoValueCarryChart.map((index) => (
                <Cell key={`cell-${index}`} fill={colors.medium_grey} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveRechartsWrapper>
      </Grid>
    </>
  );
};
