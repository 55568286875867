import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: Font.Family.GUARDIAN_SANS,
          fontSize: Font.Size.STANDARD,
          fontWeight: Font.Weight.MEDIUM,
          padding: "8px 16px",
        },
        sizeSmall: {
          padding: "2px 8px",
          fontWeight: Font.Weight.STANDARD,
          "&.MuiTableCell-head": {
            fontWeight: 500,
          },
        },
        head: {
          position: "relative",
          top: "-3px",
          lineHeight: "20px",
          borderBottomColor: colors.black,
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          fontWeight: 500,
          paddingTop: "4px",
          paddingBottom: "8px",
        },
      },
    },
  },
};

export const CustomTableCellTheme = partialTheme.components?.MuiTableCell;
