import { ElectionsLabels, FormCardHeader } from "common";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";

import { selectResidentialInformationTooltip } from "../../../../../redux/selectors";
import { IBaseStore } from "../../../../../redux/store";
import { ElectionCard } from "../../Shared/ElectionCard";
import { ResidentialInformationState } from "../Eligibility";
import { ResidentialInformationForm } from "./ResidentialInformationForm";

interface IResidentialInformationCardProps {
  form: UseFormReturn<ResidentialInformationState>;
  hasErrors: boolean;
}

export const ResidentialInformationCard: React.FC<
  IResidentialInformationCardProps
> = ({ form, hasErrors }) => {
  const tooltip = useSelector((state: IBaseStore) =>
    selectResidentialInformationTooltip(state)
  );

  return (
    <ElectionCard error={hasErrors}>
      <FormCardHeader
        title={ElectionsLabels.RESIDENTIAL_INFORMATION}
        tooltip={tooltip}
        required={true}
        hasError={hasErrors}
      />
      <ResidentialInformationForm form={form} />
    </ElectionCard>
  );
};
