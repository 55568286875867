export enum RelativePath {
  HOME = "/",
  LOGIN = "/login",
  CLIENT_DATA = "/client-data",
  ELECTIONS_ADMIN = "/internal-elections-admin",
  EQUITY_LANDING_PAGE = "/employee-data",
  CLIENT_DEFAULT = "/client-data/:clientId/",
  DASHBOARD = "/client-data/:clientId/dashboard",
  INVESTMENTS_AND_CARRY = "/client-data/:clientId/investments-and-carry",
  COMMITMENTS = "/client-data/:clientId/commitments",
  EQUITY = "/employee-data/:employeeId/equity",
  ELECTION_ADMIN = "/sbs-elections/:electionRoundId",
  ELECTION_EDITOR = "/sbs-elections/election-editor",
  ELECTION = "/sbs-elections/:electionRoundId/elector-view/:mdmInvestmentVehicleId",
  ELECTION_REVIEW = "review",
}

export enum SearchParam {
  FROM_LOGOUT = "fromLogout",
  ERROR = "error",
}
