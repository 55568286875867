import { InfoOutlined } from "@mui/icons-material";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  ElectionsLabels,
  IElectStage,
  isSomething,
  nothing,
  Optional,
  returnOptionalBooleanFromFormValue,
  setIsSBSElectionSaveEnabled,
  updateReallocationState,
} from "common";
import React, { useCallback, useEffect } from "react";
import { Control, Controller, ControllerRenderProps } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { selectReallocationState } from "../../../../../redux/selectors";
import { IBaseStore } from "../../../../../redux/store";
import styles from "./ReallocationForm.module.scss";

interface IReallocationQuestionProps {
  title: string;
  control: Control<IElectStage>;
  onUpdate: (useReallocation: Optional<boolean>) => void;
}

const ReallocationQuestion = (props: IReallocationQuestionProps) => {
  const { title, control, onUpdate } = props;

  /**
   * Sets the value of the legal attestation checkbox as well as updating the store
   * @param event A change event
   */
  const onChange = (
    field: ControllerRenderProps<IElectStage, `useReallocation`>
  ) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const optVal = returnOptionalBooleanFromFormValue(event.target.value);
      const useReallocation = event.target.checked ? optVal : nothing;
      onUpdate(useReallocation);
      field.onChange(useReallocation);
    };
  };

  return (
    <>
      <span>{title}</span>
      <Controller
        control={control}
        name={"useReallocation"}
        render={({ field }) => (
          <RadioGroup
            {...field}
            defaultValue={field.value}
            onChange={onChange(field)}
            row
            aria-labelledby={name + "-label"}
          >
            <FormControlLabel
              value={true}
              control={
                <Radio
                  checked={isSomething(field.value) ? field.value.value : false}
                />
              }
              label={ElectionsLabels.YES}
            />
            <FormControlLabel
              value={false}
              control={
                <Radio
                  checked={
                    isSomething(field.value) ? !field.value.value : false
                  }
                />
              }
              label={ElectionsLabels.NO}
            />
          </RadioGroup>
        )}
        rules={{
          validate: (reallocaiton: Optional<boolean>) => {
            if (!isSomething(reallocaiton)) {
              return ElectionsLabels.PLEASE_COMPLETE_ELECT_REALLOCATION;
            }
          },
        }}
      />
    </>
  );
};

interface IReallocationFormProps {
  control: Control<IElectStage>;
  setHighlight: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReallocationForm = (props: IReallocationFormProps) => {
  const dispatch = useDispatch();

  const reallocationState = useSelector((state: IBaseStore) =>
    selectReallocationState(state)
  );

  const { control, setHighlight } = props;

  const dispatchUpdateReallocationState = useCallback(
    (useReallocation: Optional<boolean>) => {
      dispatch(setIsSBSElectionSaveEnabled(true));
      dispatch(updateReallocationState(useReallocation));
    },
    [dispatch]
  );

  useEffect(
    () =>
      setHighlight(
        isSomething(reallocationState) ? !reallocationState.value : false
      ),
    [reallocationState, setHighlight]
  );

  return (
    <Stack
      id="reallocationForm"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
    >
      <ReallocationQuestion
        title={ElectionsLabels.REALLOCATION_QUESTION}
        control={control}
        onUpdate={dispatchUpdateReallocationState}
      />
      {isSomething(reallocationState) && !reallocationState.value && (
        <>
          <Typography className={styles.extraMessage}>
            <InfoOutlined className={styles.infoIcon} />
            {ElectionsLabels.REALLOCATION_CONFIRMATION}
          </Typography>
        </>
      )}
    </Stack>
  );
};
