import "@mdxeditor/editor/style.css";

import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";
import React, { useEffect, useState } from "react";

import { SanitizeMarkdownToMarkdown } from "../../utils/sanitizeUtils";

export interface EditorProps {
  className?: string;
  markdown: string;
  onChange: (markdown: string) => void;
}

export const TextEditor = ({ className, markdown, onChange }: EditorProps) => {
  const [sanitizedText, setSanitizedText] = useState<string | null>(null);

  useEffect(() => {
    const updateSanitizeText = async () => {
      const sanitizedMarkdown = await SanitizeMarkdownToMarkdown(markdown);
      setSanitizedText(sanitizedMarkdown);
    };

    updateSanitizeText();
  }, [markdown, onChange]);

  const toolbar = toolbarPlugin({
    toolbarContents: () => (
      <>
        <UndoRedo />
        <BlockTypeSelect />
        <BoldItalicUnderlineToggles options={["Bold", "Italic"]} />
        <CreateLink />
        <ListsToggle options={["bullet", "number"]} />
      </>
    ),
  });

  const OnChangeEvent = (markdown: string) => {
    setSanitizedText(markdown);
    onChange(markdown);
  };

  if (sanitizedText === null) {
    return <></>;
  }

  return (
    <MDXEditor
      className={className}
      markdown={sanitizedText}
      plugins={[
        headingsPlugin({}),
        listsPlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        markdownShortcutPlugin(),
        toolbar,
      ]}
      onChange={OnChangeEvent}
      suppressHtmlProcessing={false}
    />
  );
};
