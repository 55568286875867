import { BookmarkBorder, Warning } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import clsx from "clsx";
import {
  BreakpointConstants,
  buildIVByElectionRoundTitle,
  convertElectionWorkflowStateToUpdateSource,
  ElectionsLabels,
  ElectionStepperButton,
  ElectionWorkflowPagesType,
  ElectionWorkflowStageId,
  ElectionWorkflowStageOrder,
  FailedToLoadError,
  getBannerAccordionIdFromWorkflowStageId,
  getElectionStepperButtonClass,
  getElectionStepperButtonsStates,
  getElectionWorkflowStateToResetSource,
  IBaseStore,
  IElectionWorkflowPage,
  IElectionWorkflowState,
  isElectionStepperButtonDisabled,
  isInProgress,
  isSomething,
  IStageConfiguration,
  isUnsuccessful,
  LoadingIndicator,
  NoDataAvailableError,
  nothing,
  openAlert,
  Optional,
  reqElectionIVConfiguration,
  reqElectionRoundConfiguration,
  reqElectionsInvestmentPortfolio,
  reqElectionWorkflowState,
  reqPutElectionWorkflowState,
  reqResetElectionWorkflowState,
  requestStageValidation,
  setIsSBSElectionSaveEnabled,
  some,
  undoBankAccountChanges,
  updateWorkflowStageOrder,
  useFetchDatasetIfIdDefined,
} from "common";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  selectActiveElectionClient,
  selectCurrentStageConfiguration,
  selectCurrentStageId,
  selectElectionRoundDocuments,
} from "../../../redux/selectors";
import { BackToElectionsButton } from "../Shared/BackToElectionsButton/BackToElectionsButton";
import { DownloadDocumentButton } from "../Shared/DownloadDocumentButton/DownloadDocumentButton";
import styles from "./ElectionWorkflowPage.module.scss";
import { StageBanner } from "./Shared/StageBanner/StageBanner";
import { StageFootnote } from "./Shared/StageFootnote/StageFootnote";
import { StepIconComponent } from "./Shared/StepIconComponent/StepIconComponent";
import { UnsavedChangesModal } from "./Shared/UnsavedChangesModal/UnsavedChangesModal";

type ElectionStepperButtonsProps = {
  activeStageId: ElectionWorkflowStageId;
  isSBSElectionSaveEnabled: boolean;
  permittedElectionWorkflowStagesOrder: ElectionWorkflowStageId[];
  handlePreviousStageNavigation: (previousStageId: number) => void;
  activeStage?: IElectionWorkflowPage;
  activeStageIndex: number;
  isAdmin: boolean;
};
const ElectionStepperButtons = ({
  activeStageId,
  isSBSElectionSaveEnabled,
  permittedElectionWorkflowStagesOrder,
  handlePreviousStageNavigation,
  activeStage,
  activeStageIndex,
  isAdmin,
}: ElectionStepperButtonsProps) => {
  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.EXTRA_SMALL_MAX_WIDTH}px)`
  );
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const {
    electionWorkflowStateLocal,
    electionRoundConfiguration,
    electionIVConfiguration,
  } = useSelector((state: IBaseStore) => state.selectedElection);

  if (typeof activeStageId === "undefined") return null;
  const buttonStates = getElectionStepperButtonsStates(activeStageId);

  const handleClickSave = () => {
    if (
      isSomething(electionWorkflowStateLocal) &&
      isSomething(electionRoundConfiguration) &&
      isSomething(electionIVConfiguration)
    ) {
      dispatch(
        reqPutElectionWorkflowState({
          investmentVehicleId:
            electionWorkflowStateLocal.value.investmentVehicleId,
          electionRoundId: electionWorkflowStateLocal.value.electionRoundId,
          targetState: convertElectionWorkflowStateToUpdateSource({
            ...electionWorkflowStateLocal.value,
            electionRoundConfigurationVersion:
              electionRoundConfiguration.value.version,
            ivConfigurationVersion: electionIVConfiguration.value.version,
          }),
          isAdmin: isAdmin,
        })
      );
    }
    dispatch(setIsSBSElectionSaveEnabled(false));
  };

  const handleClickBack = (activeStageIndex: number) => {
    const previousStageId =
      permittedElectionWorkflowStagesOrder[activeStageIndex - 1];
    handlePreviousStageNavigation(previousStageId);
    if (backButtonRef.current !== null) {
      backButtonRef.current.blur();
    }
  };

  const handleClickNext = () => {
    if (nextButtonRef.current !== null) {
      nextButtonRef.current.blur();
    }
    dispatch(requestStageValidation());
  };

  return (
    <Stack direction={"row"} spacing={isMobile ? "6px" : "16px"}>
      <Button
        color="secondary"
        variant="outlined"
        disabled={
          !isSBSElectionSaveEnabled ||
          isElectionStepperButtonDisabled(
            buttonStates[ElectionStepperButton.SAVE]
          )
        }
        onClick={handleClickSave}
        className={clsx(
          getElectionStepperButtonClass(
            styles,
            buttonStates[ElectionStepperButton.SAVE]
          ),
          isMobile && styles.tinyButton
        )}
      >
        {isMobile ? <BookmarkBorder /> : ElectionsLabels.SAVE}
      </Button>
      {!isMobile && (
        <Button
          id={styles.backButton}
          color="secondary"
          variant="outlined"
          disabled={isElectionStepperButtonDisabled(
            buttonStates[ElectionStepperButton.BACK]
          )}
          onClick={() => handleClickBack(activeStageIndex)}
          ref={backButtonRef}
          className={getElectionStepperButtonClass(
            styles,
            buttonStates[ElectionStepperButton.BACK]
          )}
        >
          {ElectionsLabels.BACK}
        </Button>
      )}
      <Button
        ref={nextButtonRef}
        onClick={handleClickNext}
        disabled={isElectionStepperButtonDisabled(
          buttonStates[ElectionStepperButton.NEXT]
        )}
        endIcon={!isMobile && <ArrowForwardIcon />}
        className={clsx(
          getElectionStepperButtonClass(
            styles,
            buttonStates[ElectionStepperButton.NEXT]
          ),
          isMobile && styles.tinyButton
        )}
      >
        {isMobile ? (
          <ArrowForwardIcon />
        ) : (
          activeStage?.nextButtonTextOverride ?? ElectionsLabels.NEXT
        )}
      </Button>
    </Stack>
  );
};

interface IElectionWorkflowPageProps {
  canReadBankAccounts: boolean;
  ElectionWorkflowPages: ElectionWorkflowPagesType;
  electionListPath: string;
  electionDetailPath: string;
  isAdmin: boolean;
}

export const ElectionWorkflowPage = (props: IElectionWorkflowPageProps) => {
  const {
    canReadBankAccounts,
    ElectionWorkflowPages,
    electionListPath,
    electionDetailPath,
    isAdmin,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    permittedElectionWorkflowStagesOrder,
    setPermittedElectionWorkflowStagesOrder,
  ] = useState<ElectionWorkflowStageId[]>(ElectionWorkflowStageOrder);

  const activeElectionClient = useSelector(selectActiveElectionClient);

  const {
    electionRoundConfigurationLoadStatus,
    electionRoundConfiguration,
    electionWorkflowStateLoadStatus,
    electionWorkflowStateUpdateStatus,
    electionIVConfigurationLoadStatus,
    electionInvestmentPortfolioLoadStatus,
    activeElection,
    electionIVConfiguration,
    electionWorkflowStateLocal,
    electionWorkflowStateApi,
    submissionRequested,
  } = useSelector((state: IBaseStore) => state.selectedElection);

  const getWorkflowParameters = useMemo(() => {
    if (!isSomething(activeElection)) {
      return activeElection;
    }
    return some({ ...activeElection.value, isAdmin });
  }, [activeElection, isAdmin]);

  const { unsubmittedChanges } = useSelector(
    (store: IBaseStore) => store.bankAccounts
  );

  const hasUnsubmittedChangesBankAccounts = isSomething(electionIVConfiguration)
    ? Object.values(
        unsubmittedChanges[
          electionIVConfiguration.value.investmentVehicle.investmentVehicleId
        ] ?? {}
      ).some(Boolean)
    : false;

  // keeps track of the user's current stage
  const currentStageId = useSelector((state: IBaseStore) =>
    selectCurrentStageId(state)
  );

  const activeStageId = useMemo(() => {
    if (
      currentStageId === ElectionWorkflowStageId.COMPLETED &&
      submissionRequested
    ) {
      return ElectionWorkflowStageId.REVIEW_AND_SIGN;
    }
    return currentStageId;
  }, [currentStageId, submissionRequested]);

  // keeps trak of the current stage configuration
  const currentStageConfiguration: Optional<IStageConfiguration> = useSelector(
    (state: IBaseStore) => selectCurrentStageConfiguration(state, activeStageId)
  );

  const documents = useSelector((state: IBaseStore) =>
    selectElectionRoundDocuments(state)
  );

  const { isSBSElectionSaveEnabled } = useSelector(
    (state: IBaseStore) => state.viewData
  );

  const stepperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Undo changes when navigating through navbar links
    return () => {
      if (isSomething(electionIVConfiguration)) {
        dispatch(
          undoBankAccountChanges({
            investmentVehicleId:
              electionIVConfiguration.value.investmentVehicle
                .investmentVehicleId,
          })
        );
      }
    };
  }, [dispatch, electionIVConfiguration]);

  useEffect(() => {
    if (isUnsuccessful(electionWorkflowStateUpdateStatus)) {
      dispatch(
        openAlert({
          severity: "error",
          message: ElectionsLabels.UNABLE_TO_SAVE_LATEST,
          hideDuration: 5000,
        })
      );
    }
  }, [dispatch, electionWorkflowStateUpdateStatus]);

  useEffect(() => {
    const permittedWorkflowOrder: ElectionWorkflowStageId[] = [];
    ElectionWorkflowStageOrder.forEach((workflowStageId) => {
      if (
        workflowStageId === ElectionWorkflowStageId.BANK_ACCOUNT &&
        !canReadBankAccounts
      ) {
        return;
      }
      permittedWorkflowOrder.push(workflowStageId);
    });
    setPermittedElectionWorkflowStagesOrder(permittedWorkflowOrder);
    dispatch(updateWorkflowStageOrder(permittedWorkflowOrder));
  }, [canReadBankAccounts, dispatch]);

  // if the stage is completed and not just came from clicking submit, redirect to review page
  useEffect(() => {
    if (
      activeStageId === ElectionWorkflowStageId.COMPLETED &&
      !submissionRequested
    ) {
      navigate(`${electionDetailPath}/review`);
    }
  }, [activeStageId, submissionRequested, navigate, electionDetailPath]);

  const { activeStageIndex, activeStage } = useMemo(() => {
    return {
      activeStageIndex:
        permittedElectionWorkflowStagesOrder.indexOf(activeStageId),
      activeStage: ElectionWorkflowPages[activeStageId],
    };
  }, [
    ElectionWorkflowPages,
    activeStageId,
    permittedElectionWorkflowStagesOrder,
  ]);

  useEffect(() => {
    if (stepperRef.current) {
      const activeStepElement: HTMLDivElement | null =
        stepperRef.current.querySelector(".MuiStep-root .Mui-active");
      if (activeStepElement) {
        // Scroll horizontally to the active step
        stepperRef.current.scrollLeft =
          activeStepElement.offsetLeft - stepperRef.current.offsetLeft;
      }
    }
  }, [activeStageId]);

  const StageElement = activeStage?.element;

  const ivConfigRetry = useFetchDatasetIfIdDefined(
    reqElectionIVConfiguration,
    activeElection,
    electionIVConfigurationLoadStatus
  );
  const roundConfigRetry = useFetchDatasetIfIdDefined(
    reqElectionRoundConfiguration,
    activeElection,
    electionRoundConfigurationLoadStatus
  );

  const workflowStateRetry = useFetchDatasetIfIdDefined(
    reqElectionWorkflowState,
    getWorkflowParameters,
    electionWorkflowStateLoadStatus
  );
  const portfolioRetry = useFetchDatasetIfIdDefined(
    reqElectionsInvestmentPortfolio,
    isSomething(activeElectionClient)
      ? some(activeElectionClient.value.clientId)
      : nothing,
    electionInvestmentPortfolioLoadStatus
  );

  const combinedRetry = useCallback(() => {
    ivConfigRetry();
    roundConfigRetry();
    workflowStateRetry();
    portfolioRetry();
  }, [ivConfigRetry, portfolioRetry, roundConfigRetry, workflowStateRetry]);

  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState<boolean>(false);
  const [isAdminResetModalOpen, setIsAdminResetModalOpen] =
    useState<boolean>(false);
  const [navBackTargetStage, setNavBackTargetStage] = useState<number>(0);

  const navigateToStage = useCallback(
    (stageId: number, electionWorkflowState: IElectionWorkflowState) => {
      dispatch(setIsSBSElectionSaveEnabled(false));
      if (
        isSomething(electionRoundConfiguration) &&
        isSomething(electionIVConfiguration)
      ) {
        dispatch(
          reqPutElectionWorkflowState({
            investmentVehicleId: electionWorkflowState.investmentVehicleId,
            electionRoundId: electionWorkflowState.electionRoundId,
            targetState: convertElectionWorkflowStateToUpdateSource({
              ...electionWorkflowState,
              electionRoundConfigurationVersion:
                electionRoundConfiguration.value.version,
              ivConfigurationVersion: electionIVConfiguration.value.version,
              currentStage: stageId,
            }),
            isAdmin: isAdmin,
          })
        );
        window.scrollTo(0, 0);
      }
    },
    [dispatch, electionIVConfiguration, electionRoundConfiguration, isAdmin]
  );

  const resetAdminWorkflow = useCallback(() => {
    dispatch(setIsSBSElectionSaveEnabled(false));
    if (
      isSomething(electionWorkflowStateApi) &&
      isSomething(electionRoundConfiguration) &&
      isSomething(electionIVConfiguration)
    ) {
      dispatch(
        reqResetElectionWorkflowState({
          investmentVehicleId:
            electionWorkflowStateApi.value.investmentVehicleId,
          electionRoundId: electionWorkflowStateApi.value.electionRoundId,
          targetState: getElectionWorkflowStateToResetSource({
            ...electionWorkflowStateApi.value,
            electionRoundConfigurationVersion:
              electionRoundConfiguration.value.version,
            ivConfigurationVersion: electionIVConfiguration.value.version,
          }),
          isAdmin: isAdmin,
        })
      );
      window.scrollTo(0, 0);
    }
  }, [
    dispatch,
    electionIVConfiguration,
    electionRoundConfiguration,
    electionWorkflowStateApi,
    isAdmin,
  ]);

  const handlePreviousStageNavigation = (stageId: number) => {
    if (isSBSElectionSaveEnabled || hasUnsubmittedChangesBankAccounts) {
      setNavBackTargetStage(stageId);
      setIsUnsavedChangesModalOpen(true);
    } else {
      if (isSomething(electionWorkflowStateApi)) {
        navigateToStage(stageId, electionWorkflowStateApi.value);
      }
    }
  };

  const handleClickStageTab = (stageId: number) => {
    // if it goes to next step a form validation is needed otherwise it can go to a previous step
    if (stageId > activeStageId) {
      dispatch(requestStageValidation());
    } else {
      handlePreviousStageNavigation(stageId);
    }
  };

  useEffect(() => {
    if (
      isSomething(electionWorkflowStateApi) &&
      activeStageId === ElectionWorkflowStageId.BANK_ACCOUNT &&
      !canReadBankAccounts
    ) {
      navigateToStage(
        ElectionWorkflowStageId.ELECT,
        electionWorkflowStateApi.value
      );
    }
  }, [
    activeStageId,
    canReadBankAccounts,
    electionWorkflowStateApi,
    navigateToStage,
  ]);

  const displayStage: boolean = useMemo(() => {
    const anyInProgress: boolean = isInProgress(
      electionIVConfigurationLoadStatus,
      electionRoundConfigurationLoadStatus,
      electionWorkflowStateLoadStatus,
      electionInvestmentPortfolioLoadStatus
    );
    return !anyInProgress;
  }, [
    electionIVConfigurationLoadStatus,
    electionRoundConfigurationLoadStatus,
    electionWorkflowStateLoadStatus,
    electionInvestmentPortfolioLoadStatus,
  ]);

  if (
    isInProgress(
      electionIVConfigurationLoadStatus,
      electionRoundConfigurationLoadStatus,
      electionWorkflowStateLoadStatus
    )
  ) {
    return (
      <div className={styles.status}>
        <LoadingIndicator />
      </div>
    );
  }
  if (
    isUnsuccessful(
      electionIVConfigurationLoadStatus,
      electionRoundConfigurationLoadStatus,
      electionWorkflowStateLoadStatus
    )
  ) {
    return (
      <div className={styles.status}>
        <FailedToLoadError retryRequest={combinedRetry} />
      </div>
    );
  }
  if (
    !isSomething(electionIVConfiguration) ||
    !isSomething(electionRoundConfiguration) ||
    !isSomething(electionWorkflowStateApi)
  ) {
    return (
      <div className={styles.status}>
        <NoDataAvailableError />
      </div>
    );
  }

  const handleAcceptUnsavedChangesModal = () => {
    if (!isSomething(electionWorkflowStateLocal)) return;

    switch (currentStageId) {
      case ElectionWorkflowStageId.BANK_ACCOUNT:
        dispatch(
          undoBankAccountChanges({
            investmentVehicleId:
              electionIVConfiguration.value.investmentVehicle
                .investmentVehicleId,
          })
        );
        navigateToStage(currentStageId - 1, electionWorkflowStateApi.value);
        break;
      default:
        navigateToStage(navBackTargetStage, electionWorkflowStateLocal.value);
        break;
    }
  };

  const handleDeclineUnsavedChangesModal = () => {
    if (!isSomething(electionWorkflowStateApi)) return;

    switch (currentStageId) {
      case ElectionWorkflowStageId.BANK_ACCOUNT:
        return;
      default:
        navigateToStage(navBackTargetStage, electionWorkflowStateApi.value);
        break;
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.pageHeader}>
        {isAdmin && (
          <Typography className={styles.extraMessage}>
            <Warning className={styles.infoIcon} />
            {ElectionsLabels.ADMIN_DISCLAIMER}
          </Typography>
        )}
        <BackToElectionsButton
          checkForUnsavedChanges={true}
          label={
            isAdmin
              ? ElectionsLabels.BACK_TO_ELECTOR_VIEW
              : ElectionsLabels.BACK_TO_ELECTIONS
          }
          backUrl={electionListPath}
        />
        {isAdmin && (
          <div className={styles.resetAdminWorkflow}>
            <Button
              className={styles.resetAdminWorkflowButton}
              onClick={() => setIsAdminResetModalOpen(true)}
            >
              {ElectionsLabels.RESET_ADMIN_WORKFLOW}
            </Button>
            <UnsavedChangesModal
              activeStageId={activeStageId}
              isAdminResetModal={true}
              open={isAdminResetModalOpen}
              setOpen={setIsAdminResetModalOpen}
              handleGoWithoutSave={() => {
                return;
              }}
              handleGoWithSave={resetAdminWorkflow}
            />
          </div>
        )}
        <Stack direction={"row"} spacing={"6px"} className={styles.titleRow}>
          <Stack direction={"row"} spacing={"6px"} alignItems={"center"}>
            <Typography variant="h1" className={styles.title}>
              {buildIVByElectionRoundTitle(
                electionIVConfiguration,
                electionRoundConfiguration
              )}
            </Typography>

            {isSomething(activeElection) && (
              <DownloadDocumentButton
                outline={true}
                documents={documents}
                electionRoundId={activeElection.value.electionRoundId}
              />
            )}
          </Stack>
          <ElectionStepperButtons
            handlePreviousStageNavigation={handlePreviousStageNavigation}
            activeStageIndex={activeStageIndex}
            permittedElectionWorkflowStagesOrder={
              permittedElectionWorkflowStagesOrder
            }
            activeStageId={activeStageId}
            activeStage={activeStage}
            isSBSElectionSaveEnabled={isSBSElectionSaveEnabled}
            isAdmin={isAdmin}
          />
        </Stack>
        <Stepper
          activeStep={activeStageId}
          className={styles.stepper}
          connector={<></>}
          ref={stepperRef}
        >
          {permittedElectionWorkflowStagesOrder.map(
            (workflowStageId, index) => {
              const workflowPage = ElectionWorkflowPages[workflowStageId];

              const disabled =
                workflowStageId !== activeStageId &&
                !activeStage?.validStepperNavigationStages.includes(
                  workflowStageId
                );

              return (
                <Step
                  key={workflowStageId}
                  onClick={
                    disabled
                      ? undefined
                      : () => handleClickStageTab(workflowStageId)
                  }
                  active={activeStageId === workflowStageId}
                  disabled={disabled}
                  completed={index < activeStageIndex}
                >
                  <StepLabel
                    StepIconComponent={() => {
                      return (
                        // TODO: update logic for isComplete, isActive, and isDisabled once we have more data contracts
                        <StepIconComponent
                          value={index + 1} // add 1 so numbers start at 1 instead of 0
                          isComplete={index < activeStageIndex}
                          isActive={index === activeStageIndex}
                          isDisabled={disabled}
                        />
                      );
                    }}
                  >
                    {workflowPage?.name.toUpperCase()}
                  </StepLabel>
                </Step>
              );
            }
          )}
        </Stepper>
      </div>
      {isAdmin && <div className={styles.adminExtraSpacing} />}
      {isSomething(currentStageConfiguration) && displayStage && StageElement && (
        <div className={styles.pageContent}>
          <StageBanner
            accordionId={getBannerAccordionIdFromWorkflowStageId(activeStageId)}
            stageConfiguration={currentStageConfiguration.value}
          />
          <Stack>
            <StageElement isAdmin={isAdmin} />
            {!activeStage?.suppressFooter && (
              <StageFootnote
                stageConfiguration={currentStageConfiguration.value}
              />
            )}
          </Stack>
        </div>
      )}
      {isSomething(electionWorkflowStateApi) &&
        isSomething(electionWorkflowStateLocal) && (
          <UnsavedChangesModal
            activeStageId={activeStageId}
            open={isUnsavedChangesModalOpen}
            setOpen={setIsUnsavedChangesModalOpen}
            handleGoWithoutSave={handleDeclineUnsavedChangesModal}
            handleGoWithSave={handleAcceptUnsavedChangesModal}
          />
        )}
    </div>
  );
};
