import { EquityDistributionsNoDataLabel, EquityLabel } from "common";
import React from "react";

import styles from "./Footer.module.scss";

export const Footer = () => {
  const FooterMessage = (
    <>
      <p>
        {EquityLabel.FOOTER_FIRST_LINE}
        <a
          href={EquityLabel.MERRILL_LYNCH_URL}
          target="_blank"
          rel="noreferrer"
        >
          {EquityLabel.MERRILL_LYNCH_URL}
        </a>
        .
      </p>
      <p>
        {EquityDistributionsNoDataLabel.CONTACT_TEXT}
        <a href={`mailto:${EquityDistributionsNoDataLabel.EMAIL_ID}`}>
          {EquityDistributionsNoDataLabel.EMAIL_ID}
        </a>
        .
      </p>
      <br />
    </>
  );

  return <div className={styles.footer}>{FooterMessage}</div>;
};
