import { WarningRounded } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useMemo } from "react";
import { ControllerRenderProps } from "react-hook-form";
import { useSelector } from "react-redux";

import FieldLabelWithTooltip from "../../../../../components/FieldLabelWithTooltip/FieldLabelWithTooltip";
import { BankAccountConstants } from "../../../../../constants/BankAccountConstants";
import { IBaseStore } from "../../../../../redux/store";
import {
  AddBankAccountState,
  BankAccountAssignment,
  SelectableBankAccount,
} from "../../../../../types/bankAccountDataTypes";
import { isSomething } from "../../../../../types/typeGuards";
import { Optional } from "../../../../../types/typeUtils";
import styles from "./AutoAssignField.module.scss";

export interface IAutoAssignFieldProps {
  field: ControllerRenderProps<AddBankAccountState, "autoAssign">;
  label: string;
  investmentVehicleId: number;
  assignmentReasonForIneligibility: { [x in BankAccountAssignment]: string[] };
}

export const AutoAssignField = (props: IAutoAssignFieldProps) => {
  const { field, assignmentReasonForIneligibility, investmentVehicleId } =
    props;

  const handleChange = (
    checked: boolean,
    assignment: BankAccountAssignment
  ) => {
    const newVal = {
      ...field.value,
      [assignment]: {
        ...field.value[assignment],
        isChecked: checked,
      },
    };
    field.onChange(newVal);
  };

  const { selectedBankAccounts } = useSelector(
    (state: IBaseStore) => state.bankAccounts
  );

  const isSelectedContributionApproved = useMemo(() => {
    if (selectedBankAccounts[investmentVehicleId]) {
      const con: Optional<SelectableBankAccount> =
        selectedBankAccounts[investmentVehicleId].contributionBankAccount;
      if (isSomething(con)) {
        return con.value.isApproved;
      }
    }
    return false;
  }, [investmentVehicleId, selectedBankAccounts]);

  const isSelectedDistributionApproved = useMemo(() => {
    if (selectedBankAccounts[investmentVehicleId]) {
      const dist: Optional<SelectableBankAccount> =
        selectedBankAccounts[investmentVehicleId].distributionBankAccount;
      if (isSomething(dist)) {
        return dist.value.isApproved;
      }
    }
    return false;
  }, [investmentVehicleId, selectedBankAccounts]);

  const renderTooltipContent = (tooltipContent: string[]) => {
    if (tooltipContent.length > 0)
      return tooltipContent
        .sort()
        .map((e, i) => <span key={i}>{e}</span>)
        .reduce((agg, curr) => (
          <>
            {agg}
            <br />
            {curr}
          </>
        ));
    // otherwise, if we got an actual react element, just use that
    return <></>;
  };

  return (
    <Stack>
      <FieldLabelWithTooltip {...props} required={false} />
      <FormGroup className={styles.form}>
        <Stack className={styles.field}>
          <Stack direction={"row"}>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -15],
                    },
                  },
                ],
              }}
              placement={"bottom-start"}
              title={renderTooltipContent(
                assignmentReasonForIneligibility[
                  BankAccountAssignment.CONTRIBUTION
                ]
              )}
              disableHoverListener={
                assignmentReasonForIneligibility[
                  BankAccountAssignment.CONTRIBUTION
                ].length === 0
              }
              disableTouchListener={
                assignmentReasonForIneligibility[
                  BankAccountAssignment.CONTRIBUTION
                ].length === 0
              }
            >
              <FormControlLabel
                checked={
                  field.value.Contribution.isChecked &&
                  field.value.Contribution.isEligible
                }
                disabled={
                  assignmentReasonForIneligibility[
                    BankAccountAssignment.CONTRIBUTION
                  ].length > 0
                }
                control={<Checkbox />}
                label={"Contribution"}
                onChange={(_, checked: boolean) =>
                  handleChange(checked, BankAccountAssignment.CONTRIBUTION)
                }
              />
            </Tooltip>
            <Tooltip
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -15],
                    },
                  },
                ],
              }}
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              placement={"bottom-start"}
              title={renderTooltipContent(
                assignmentReasonForIneligibility[
                  BankAccountAssignment.DISTRIBUTION
                ]
              )}
              disableHoverListener={
                assignmentReasonForIneligibility[
                  BankAccountAssignment.DISTRIBUTION
                ].length === 0
              }
              disableTouchListener={
                assignmentReasonForIneligibility[
                  BankAccountAssignment.DISTRIBUTION
                ].length === 0
              }
            >
              <FormControlLabel
                checked={
                  field.value.Distribution.isChecked &&
                  field.value.Distribution.isEligible
                }
                disabled={
                  assignmentReasonForIneligibility[
                    BankAccountAssignment.DISTRIBUTION
                  ].length > 0
                }
                control={<Checkbox />}
                label={"Distribution"}
                onChange={(_, checked: boolean) =>
                  handleChange(checked, BankAccountAssignment.DISTRIBUTION)
                }
              />
            </Tooltip>
          </Stack>
          {((isSelectedContributionApproved && field.value.Contribution) ||
            (isSelectedDistributionApproved && field.value.Distribution)) && (
            <Alert
              className={styles.confirmAlert}
              variant="standard"
              severity="warning"
              icon={<WarningRounded />}
            >
              <AlertTitle className={styles.title}>
                {BankAccountConstants.ACCOUNT_ASSIGNED_WARNING}
              </AlertTitle>
            </Alert>
          )}
        </Stack>
      </FormGroup>
    </Stack>
  );
};
