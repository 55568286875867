import { TableCell, TableRow } from "@mui/material";
import {
  FinancingRowKeys,
  FinancingRowKeysToTitles,
  FinancingRowsOrder,
  getCurrencyFormattedValueWithZeroDefault,
  IElectCardFinancing,
  isSomething,
  MonospaceNumber,
  nothing,
  Optional,
  some,
} from "common";
import React from "react";

import styles from "./FinancingRows.module.scss";

interface IFinancingRowProps {
  title: string;
  nEmptyCols: number;
  commitment: number;
  totalForecastedInvestment: Optional<number>;
  idName?: string;
}

const FinancingRow = (props: IFinancingRowProps) => {
  const {
    title,
    nEmptyCols,
    commitment,
    totalForecastedInvestment,
    idName = "",
  } = props;

  return (
    <TableRow>
      <>
        {/* display empty columns */}
        {[...Array(nEmptyCols)].map((_, j: number) => {
          return <TableCell key={j} align="right"></TableCell>;
        })}
        <TableCell id={idName} align="right">
          {title}
        </TableCell>
        <TableCell id={idName} align="right">
          <MonospaceNumber
            value={commitment}
            valueFormatter={getCurrencyFormattedValueWithZeroDefault}
          />
        </TableCell>
        {isSomething(totalForecastedInvestment) && (
          <TableCell id={idName} align="right">
            <MonospaceNumber
              value={totalForecastedInvestment.value}
              valueFormatter={getCurrencyFormattedValueWithZeroDefault}
            />
          </TableCell>
        )}
      </>
    </TableRow>
  );
};

interface IFinancingRowsProps {
  financing: IElectCardFinancing;
  hasForecastedInvestment: boolean;
  nColsInTable: number; // number of columns in main elect table (can vary based on which columns there are data for)
}

/*
Display Financing Rows in Elect Table according to the following specs:
  - displays 5 rows at the bottom of the elect table:
    - cash
    - financing
    - existing loan balance
    - projected loan balance
    - loan limit
  - the labels for the above column always show in the `optional Election` column of the table
  - optionally shows values for Total Forecasted Investment column, based on presence of data
  - data always appears in the right most columns of the table, so depending
        on how many columns appear in the table overall, we need to display
        some blank columns in these rows
*/
export const FinancingRows = (props: IFinancingRowsProps) => {
  const { financing, hasForecastedInvestment, nColsInTable } = props;

  // number of columsn that should appear empty for overall table alignment
  const nEmptyCols = hasForecastedInvestment
    ? nColsInTable - 3
    : nColsInTable - 2;

  return (
    <>
      {FinancingRowsOrder.map((rowKey: FinancingRowKeys, idx: number) => {
        return rowKey === FinancingRowKeys.LOAN_LIMIT &&
          financing.totalCommitment.loanLimit <= 0 &&
          financing.totalForecastedInvestment.loanLimit <= 0 ? (
          <></>
        ) : (
          <FinancingRow
            key={idx}
            title={FinancingRowKeysToTitles[rowKey]}
            nEmptyCols={nEmptyCols}
            commitment={financing.totalCommitment[rowKey]}
            totalForecastedInvestment={
              hasForecastedInvestment
                ? some(financing.totalForecastedInvestment[rowKey])
                : nothing
            }
            idName={
              rowKey === FinancingRowKeys.FINANCING ? styles.financing : ""
            }
          />
        );
      })}
    </>
  );
};
