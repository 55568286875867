import { WebStorageStateStore } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";

import { RelativePath } from "../constants/Paths";
import { OIDCProviderDefinition } from "../services/environmentResolver";
import { onSigninCallback } from "../utils/authUtils";

export const OIDCConfig = (
  oidcSettings: OIDCProviderDefinition
): AuthProviderProps => ({
  authority: oidcSettings.AUTHORITY_URL_BASE,
  client_id: oidcSettings.CLIENT_ID,
  onSigninCallback: onSigninCallback,
  redirect_uri: `${window.location.protocol}//${window.location.host}${RelativePath.LOGIN}`,
  scope: oidcSettings.SCOPE,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});
