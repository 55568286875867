import { createSlice } from "@reduxjs/toolkit";

export interface IAuthStore {
  forceAttemptSSO: boolean;
}

const initialState: IAuthStore = {
  forceAttemptSSO: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    refreshFailure: (state) => {
      // when a refresh fails we want to see if we quietly start a new login for
      // internal user to make the blip less noticeable.
      state.forceAttemptSSO = true;
    },
    resetAuthState: () => initialState,
  },
});

export const { refreshFailure, resetAuthState } = authSlice.actions;
export const authReducer = authSlice.reducer;
