import { SelectChangeEvent } from "@mui/material";
import {
  ClientSelectLabels,
  EntitySelectLabels,
  getEntityId,
  IBaseStore,
  IInvestmentVehicleByClientByPeriod,
  IPermittedEntities,
  isEntityLoaded,
  isSomething,
  Optional,
  PeriodHash,
  reqInternalInvestmentData,
  selectInternalInvestmentData,
  setInternalInvestmentDataFilters,
} from "common";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { selectActiveInvestmentEntityDropdownItemId } from "../../redux/selectors/investmentEntitySelectors";
import { IInvestmentEntityDropdownItem } from "../../types/investmentEntityDropdownTypes";
import { getInvestmentEntityDropdownItems } from "../../utils/investmentEntityDropdownUtils";
import {
  BaseEntityDropdown,
  IDropdownItemData,
} from "../BaseEntityDropdown/BaseEntityDropdown";
import styles from "./InvestmentEntityDropdown.module.scss";

interface InvestmentEntityDropdownProps {
  viewInternalInvestmentDataPermittedEntities: Optional<IPermittedEntities>;
  clientsOnly?: boolean;
}
export const InvestmentEntityDropdown = (
  props: InvestmentEntityDropdownProps
) => {
  const { viewInternalInvestmentDataPermittedEntities, clientsOnly = false } =
    props;
  const { entities, filteredBy } = useSelector((state: IBaseStore) =>
    selectInternalInvestmentData(state)
  );

  const activeInvestmentEntityDropdowmItem = useSelector((state: IBaseStore) =>
    selectActiveInvestmentEntityDropdownItemId(state)
  );

  const [selectedInvestmentEntity, setSelectedInvestmentEntity] =
    React.useState(activeInvestmentEntityDropdowmItem);

  const dispatch = useDispatch();

  /*
   * If we're on a page that should have a client-only dropdown
   * and user has an IV selected, update the user to have the
   * client of that IV selected instead!
   */
  useEffect(() => {
    // If we should only show clients but IV is selected
    if (
      clientsOnly &&
      isSomething(filteredBy) &&
      filteredBy.value.investmentVehicleId !== undefined
    ) {
      // set the client that's parent to that IV as selected instead
      setSelectedInvestmentEntity(filteredBy.value.clientId);

      const newFilter = {
        clientId: filteredBy.value.clientId,
        periodId: PeriodHash.LATEST,
      } as IInvestmentVehicleByClientByPeriod;
      dispatch(setInternalInvestmentDataFilters(newFilter));
    }
  }, [clientsOnly, dispatch, filteredBy]);

  // Calculate dropdown items using useMemo for performance optimization
  const investmentEntityDropdownItems = useMemo(() => {
    if (!isSomething(viewInternalInvestmentDataPermittedEntities)) {
      return [];
    }

    if (
      viewInternalInvestmentDataPermittedEntities.value.clients.length === 0
    ) {
      return [];
    }

    // For now, we are only taking the first client
    const firstClient =
      viewInternalInvestmentDataPermittedEntities.value.clients[0];
    const clients = [firstClient];
    return getInvestmentEntityDropdownItems(clients, clientsOnly);
  }, [clientsOnly, viewInternalInvestmentDataPermittedEntities]);

  /**
   * Sets the seleted investment entity menu item.
   * @param event A select change event
   */
  const onInvestmentEntityChange = (event: SelectChangeEvent) => {
    const [clientId, investmentVehicleId] = event.target.value.split(":");
    setSelectedInvestmentEntity(event.target.value as string);
    const payload = {
      clientId,
      investmentVehicleId,
      periodId: PeriodHash.LATEST,
    } as IInvestmentVehicleByClientByPeriod;

    const selectedId = getEntityId(clientId, investmentVehicleId);
    const isLoaded = isEntityLoaded(entities, selectedId);

    if (isLoaded) {
      dispatch(setInternalInvestmentDataFilters(payload));
    } else {
      dispatch(reqInternalInvestmentData(payload));
    }
  };

  const getMenuItemTextClass = (
    investmentEntityDropdownItem: IInvestmentEntityDropdownItem
  ): string => {
    if (investmentEntityDropdownItem.isClient) {
      return investmentEntityDropdownItem.id === selectedInvestmentEntity
        ? styles.selectedClientText
        : styles.clientText;
    } else {
      return investmentEntityDropdownItem.id === selectedInvestmentEntity
        ? styles.selectedInvestmentVehicleText
        : styles.investmentVehicleText;
    }
  };

  const getMenuItemData = (
    item: IInvestmentEntityDropdownItem
  ): IDropdownItemData => {
    const label = item.displayName;
    const classes = getMenuItemTextClass(item);
    return { label, classes };
  };

  const getActiveItemLabel = (id: string): string => {
    const foundDropdownItem = investmentEntityDropdownItems.find(
      (dropdownItem) => dropdownItem.id == id
    );
    const label = foundDropdownItem?.activeDisplayName ?? "-";
    return label;
  };

  return (
    <BaseEntityDropdown
      items={investmentEntityDropdownItems}
      getActiveItemLabel={getActiveItemLabel}
      getMenuItemData={getMenuItemData}
      onSelectionChange={onInvestmentEntityChange}
      selectedValue={selectedInvestmentEntity}
      subheaderLabel={
        clientsOnly
          ? ClientSelectLabels.DEFAULT_TEXT
          : EntitySelectLabels.DEFAULT_TEXT
      }
    />
  );
};
