import { OverviewStage } from "common";
import React from "react";
import { useSelector } from "react-redux";

import { AdminUIStore } from "../../../../redux/store";

export const OverviewStageWrapper = () => {
  const { isBankAccountsAdmin } = useSelector(
    (state: AdminUIStore) => state.entitlements
  );
  return <OverviewStage canReadBankAccounts={isBankAccountsAdmin} />;
};
