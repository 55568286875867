import { ThemeOptions } from "@mui/material";

import colors from "../../_colors.scss";
import { Font } from "../sharedStyleConstants";

// Shared constants we may want to move in unison
const TextField = {
  Font: {
    FAMILY: Font.Family.GUARDIAN_SANS,
    WEIGHT: Font.Weight.STANDARD,
  },
};

// Style definition
const partialTheme: ThemeOptions = {
  components: {
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          marginBottom: "20px",
          "& label.Mui-focused": {
            fontFamily: TextField.Font.FAMILY,
            fontWeight: TextField.Font.WEIGHT,
            color: "rgba(0, 0, 0, 0.6)",
          },
          "& .MuiOutlinedInput-root": {
            fontFamily: TextField.Font.FAMILY,
            fontWeight: TextField.Font.WEIGHT,

            "&.Mui-error fieldset": {
              borderColor: colors.crimson_50,
            },

            "&.Mui-focused fieldset": {
              borderColor: colors.black,
              borderWidth: "1px",
            },

            "&.Mui-error.Mui-focused fieldset": {
              borderColor: colors.crimson_50,
            },
          },
        },
      },
    },
  },
};

export const CustomTextFieldTheme = partialTheme.components?.MuiTextField;
