import {
  convertToRoundedValidNumber,
  getFormattedDateMMMYYYY,
  IDailyVestingEvent,
  IEquityDatum,
  IMonthlyVestingEvent,
  INextVestingEvent,
  isSomething,
  IStockDataValue,
  Optional,
} from "common";
/**
 * Gets the start date today
 * @returns A date
 */
const getStartDateToday = (): Date => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

/**
 * Compares a next vesting event to a daily vesting event by year, month and date
 * if they are equal returns true otherwise false.
 * @param nextVestingEvent - The list of next vesting events
 * @returns A boolean value
 */
const isEqualByDate = (nextVestingEvent: INextVestingEvent) => {
  return (dailyVestingEvents: IDailyVestingEvent): boolean => {
    const existingYear: number = dailyVestingEvents.date.getFullYear();
    const existingMonth: number = dailyVestingEvents.date.getMonth();
    const existingDay: number = dailyVestingEvents.date.getDate();
    const possibleYear: number = nextVestingEvent.date.getFullYear();
    const possibleMonth: number = nextVestingEvent.date.getMonth();
    const possibleDay: number = nextVestingEvent.date.getDate();
    return (
      existingYear === possibleYear &&
      existingMonth === possibleMonth &&
      existingDay === possibleDay
    );
  };
};

/**
 * Compares a daily vesting event to a monthly vesting event by year and month
 * if they are equal returns true otherwise false.
 * @param dailyVestingEvents - The list of daily vesting events
 * @returns A boolean value
 */
const isEqualByMonth = (dailyVestingEvent: IDailyVestingEvent) => {
  return (monthlyVestingEvents: IMonthlyVestingEvent): boolean => {
    const existingYear: number = monthlyVestingEvents.date.getFullYear();
    const existingMonth: number = monthlyVestingEvents.date.getMonth();
    const possibleYear: number = dailyVestingEvent.date.getFullYear();
    const possibleMonth: number = dailyVestingEvent.date.getMonth();
    return existingYear === possibleYear && existingMonth === possibleMonth;
  };
};

/**
 * Groups a list of next vesting events into a list of daily vesting events.
 * @param dailyVestingEvents - The list of daily vesting events
 * @param nextVestingEvent - The next vesting event
 * @returns A list of the daily vesting events
 */
const groupNextVestingEventsByDate = (
  dailyVestingEvents: IDailyVestingEvent[],
  nextVestingEvent: INextVestingEvent
): IDailyVestingEvent[] => {
  const index = dailyVestingEvents.findIndex(isEqualByDate(nextVestingEvent));
  if (index === -1) {
    dailyVestingEvents.push({
      date: nextVestingEvent.date,
      value: nextVestingEvent.value,
      nextVestingEvents: [nextVestingEvent],
    });
  } else {
    dailyVestingEvents[index].value += nextVestingEvent.value;
    dailyVestingEvents[index].nextVestingEvents.push(nextVestingEvent);
  }
  return dailyVestingEvents;
};

/**
 * Groups a list of daily vesting events into a list of monthly vesting events.
 * @param monthlyVestingEvents - The list of monthly vesting events
 * @param dailyVestingEvent - The daily vesting event
 * @returns A list of the monthly vesting events
 */
const groupDailyVestingEventsByMonth = (
  monthlyVestingEvents: IMonthlyVestingEvent[],
  dailyVestingEvent: IDailyVestingEvent
) => {
  const index = monthlyVestingEvents.findIndex(
    isEqualByMonth(dailyVestingEvent)
  );
  if (index === -1) {
    monthlyVestingEvents.push({
      date: dailyVestingEvent.date,
      label: getFormattedDateMMMYYYY(dailyVestingEvent.date),
      key: monthlyVestingEvents.length,
      value: dailyVestingEvent.value,
      dailyVestingEvents: [dailyVestingEvent],
    });
  } else {
    monthlyVestingEvents[index].value += dailyVestingEvent.value;
    monthlyVestingEvents[index].dailyVestingEvents.push(dailyVestingEvent);
  }
  return monthlyVestingEvents;
};

/**
 * Compares two monthly vesting events by year and month
 * to see which is firstchronologically
 * @param a - A daily vesting event
 * @param b - A daily veting event
 * @returns A number
 */
const sortByMonthAsc = (a: IMonthlyVestingEvent, b: IMonthlyVestingEvent) => {
  if (a.date.getFullYear() !== b.date.getFullYear()) {
    return a.date.getFullYear() - b.date.getFullYear();
  } else {
    return a.date.getMonth() - b.date.getMonth();
  }
};

/**
 * Compares two daily vesting events by year, month and date
 * to see which is firstchronologically
 * @param a - A daily vesting event
 * @param b - A daily veting event
 * @returns A number
 */
const sortByDayAsc = (a: IDailyVestingEvent, b: IDailyVestingEvent): number => {
  if (a.date.getFullYear() !== b.date.getFullYear()) {
    return a.date.getFullYear() - b.date.getFullYear();
  } else if (a.date.getMonth() !== b.date.getMonth()) {
    return a.date.getMonth() - b.date.getMonth();
  } else {
    return a.date.getDate() - b.date.getDate();
  }
};

/**
 * Compares two vesting names to see which is alphabetically first
 * @param a - A next vesting event
 * @param b - A next veting event
 * @returns A number
 */
const sortByNameAsc = (a: INextVestingEvent, b: INextVestingEvent): number => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

/**
 * Returns the monthly vesting event data.
 * @param equities - The rquity data for single stock symbol
 * @param stockData - The list of stock prices for a single stock symbol
 * @returns A list of monthly vesting events
 */
export const getVestingEventsData = (
  equityData: IEquityDatum,
  stockData: IStockDataValue[]
) => {
  const latestStockValue = stockData[0].value;
  const nextVestingEvents = getNextVestingEvents(
    equityData,
    latestStockValue
  ).sort(sortByNameAsc);
  const dailyVestingEvents = nextVestingEvents
    .reduce(groupNextVestingEventsByDate, [])
    .sort(sortByDayAsc);
  const monthlyVestingEvents = dailyVestingEvents
    .reduce(groupDailyVestingEventsByMonth, [])
    .sort(sortByMonthAsc);

  return monthlyVestingEvents;
};

export const getNextVestingEvents = (
  equityData: IEquityDatum,
  stockValue: number
) => {
  const startOfToday = getStartDateToday();

  let futureVestingEvents: INextVestingEvent[] = [];
  equityData.awards.forEach((award) => {
    futureVestingEvents = futureVestingEvents.concat(
      award.vestingData
        .filter((event) => event.date >= startOfToday)
        .map((event) => ({
          date: event.date,
          name: award.name,
          value: convertToRoundedValidNumber(event.quantity * stockValue),
        }))
    );
  });

  return futureVestingEvents;
};

export const getAsOfDate = (data: Optional<IEquityDatum>): Date | undefined => {
  if (!isSomething(data)) {
    return undefined;
  }
  return data.value.asOfDate;
};
