import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import {
  a11yProps,
  IBaseStore,
  selectCanViewCommitmentsPage,
  selectCanViewDashboard,
  selectCanViewInvestmentsAndCarry,
} from "common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { HasEntitlementContainer } from "../../../common/components/HasEntitlementContainer/HasEntitlementContainer";
import {
  ClientLevelPageName,
  ClientLevelPages,
  ClientTabDisplayOrder,
} from "../../../constants/Pages/Pages";
import { AdminUIStore } from "../../../redux/store";
import { findInternalInvestmentClientForClientId } from "../../../utils/adminClientUtils";
import { isClientPageVisible } from "../../../utils/routingUtils";
import styles from "./NavigationTabs.module.scss";

export const NavigationTabs = () => {
  const { internalInvestmentClients } = useSelector(
    (store: AdminUIStore) => store.clients
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  // Extract the clientId from the pathname
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const clientFromPath = findInternalInvestmentClientForClientId(
    clientId,
    internalInvestmentClients
  );

  const canViewDashboard = useSelector((store: IBaseStore) =>
    selectCanViewDashboard(store, true)
  );

  const canViewInvestmentsAndCarryPage = useSelector((store: IBaseStore) =>
    selectCanViewInvestmentsAndCarry(store, true)
  );

  const canViewCommitmentsPage = useSelector(selectCanViewCommitmentsPage);

  const visiblePages: ClientLevelPageName[] = useMemo(() => {
    return ClientTabDisplayOrder.filter((tab: ClientLevelPageName) => {
      return isClientPageVisible(
        tab,
        canViewDashboard,
        canViewInvestmentsAndCarryPage,
        canViewCommitmentsPage
      );
    });
  }, [
    canViewDashboard,
    canViewInvestmentsAndCarryPage,
    canViewCommitmentsPage,
  ]);

  /*
    helper function which uses current url path and tab display order 
    to determine tab index number. needed for showing correct tab as selected
  */
  const determineTabIndex = useCallback(
    (path: string): number => {
      let curIndex = 0;
      visiblePages.forEach((tabName: ClientLevelPageName, index: number) => {
        if (path.includes(ClientLevelPages[tabName]?.getPath(clientId) ?? "")) {
          curIndex = index;
        }
      });
      return curIndex;
    },
    [clientId, visiblePages]
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`${ClientLevelPages[visiblePages[newValue]]?.getPath(clientId)}`);
  };

  const [currentTabIndex, setCurrentTabIndex] = useState(
    determineTabIndex(pathname)
  );

  useEffect(() => {
    setCurrentTabIndex(determineTabIndex(pathname));
  }, [determineTabIndex, pathname]);

  return clientFromPath !== undefined ? (
    <HasEntitlementContainer
      entitlementLoadStatusName="internalInvestmentAdminEntitlementLoadStatus"
      entitlementName="hasInternalInvestmentAdminEntitlement"
      noDataChildren={<></>}
    >
      <Box className={styles.navTabBar}>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          className={styles.navTabs}
          TabIndicatorProps={{
            className: styles.indicator,
          }}
        >
          {visiblePages.map((tab, index) => (
            <Tab
              label={ClientLevelPages[tab]?.name}
              key={ClientLevelPages[tab]?.name}
              value={index}
              className={styles.navTab}
              {...a11yProps(index)}
            ></Tab>
          ))}
        </Tabs>
      </Box>
    </HasEntitlementContainer>
  ) : (
    <></>
  );
};
