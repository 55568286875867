import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const useElectionQueryParams = () => {
  const {
    mdmInvestmentVehicleId: mdmInvestmentVehicleIdParam,
    electionRoundId,
  } = useParams();

  const mdmInvestmentVehicleId = useMemo(() => {
    if (
      mdmInvestmentVehicleIdParam &&
      !isNaN(parseInt(mdmInvestmentVehicleIdParam))
    ) {
      return parseInt(mdmInvestmentVehicleIdParam);
    }
  }, [mdmInvestmentVehicleIdParam]);

  return { electionRoundId, mdmInvestmentVehicleId };
};
