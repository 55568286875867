import { Stack, Typography } from "@mui/material";
import { ICellRendererParams } from "ag-grid-enterprise";
import {
  getCurrencyFormattedValueWithZeroDefault,
  IElectCardFinancingValues,
  MonospaceNumber,
} from "common";
import React from "react";

import styles from "./FinancingColumnsCellRenderer.module.scss";

interface IFinancingColumnsCellRendererProps extends ICellRendererParams {
  financingValues: IElectCardFinancingValues;
}

export const FinancingColumnsCellRenderer = (
  props: IFinancingColumnsCellRendererProps
) => {
  const { financingValues } = props;

  return props.node.rowPinned ? (
    <Stack id={styles.financingRow}>
      <div className={styles.amount}>
        <MonospaceNumber
          value={props.value}
          valueFormatter={getCurrencyFormattedValueWithZeroDefault}
        />
      </div>
      <Typography id={styles.label}>
        <MonospaceNumber
          value={financingValues.cash}
          valueFormatter={getCurrencyFormattedValueWithZeroDefault}
        />
      </Typography>
      <Typography id={styles.label}>
        <MonospaceNumber
          value={financingValues.financing}
          valueFormatter={getCurrencyFormattedValueWithZeroDefault}
        />
      </Typography>
      <hr className={styles.line} />
      <Typography id={styles.label}>
        <MonospaceNumber
          value={financingValues.existingLoanBalance}
          valueFormatter={getCurrencyFormattedValueWithZeroDefault}
        />
      </Typography>
      <Typography id={styles.label}>
        <MonospaceNumber
          value={financingValues.projectedLoanBalance}
          valueFormatter={getCurrencyFormattedValueWithZeroDefault}
        />
      </Typography>
      {financingValues.loanLimit > 0 && (
        <Typography id={styles.label}>
          <MonospaceNumber
            value={financingValues.loanLimit}
            valueFormatter={getCurrencyFormattedValueWithZeroDefault}
          />
        </Typography>
      )}
    </Stack>
  ) : (
    <div className={styles.amount}>
      <MonospaceNumber
        value={props.value}
        valueFormatter={getCurrencyFormattedValueWithZeroDefault}
      />
    </div>
  );
};
