import {
  DataLoadStatus,
  IGetElectionDetailsRequestPayload,
  isSomething,
  reqElectionClientMappings,
  selectActiveElection,
  selectActiveElectionClient,
  setActiveElection,
  setActiveElectionClient,
  some,
} from "common";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AdminUIStore } from "../../../redux/store";
import { useElectionQueryParams } from "./useElectionQueryParamsHook";

export const useActiveElection = () => {
  const dispatch = useDispatch();

  const { electionRoundId, mdmInvestmentVehicleId } = useElectionQueryParams();

  const { electionsClientMappings, electionsClientMappingsLoadStatus } =
    useSelector((state: AdminUIStore) => state.clients);

  const clientForCurrentElection = useMemo(() => {
    const clientMappings = isSomething(electionsClientMappings)
      ? electionsClientMappings.value
      : [];
    return clientMappings.find(
      (clientMapping) =>
        clientMapping.mdmInvestmentVehicleId === mdmInvestmentVehicleId
    );
  }, [electionsClientMappings, mdmInvestmentVehicleId]);

  const activeElection = useSelector(selectActiveElection);

  const activeElectionClient = useSelector(selectActiveElectionClient);

  useEffect(() => {
    if (electionsClientMappingsLoadStatus === DataLoadStatus.NOT_REQUESTED) {
      dispatch(reqElectionClientMappings());
    }
  }, [dispatch, electionsClientMappingsLoadStatus]);

  // This catches cases where we've refreshed the page or otherwise navigated
  // to the workflow without passing through the Elections List. We want to set
  // the active election client to whatever client corresponds to the current
  // workflow.
  useEffect(() => {
    if (
      clientForCurrentElection &&
      (!isSomething(activeElectionClient) ||
        clientForCurrentElection.axiomClientId !==
          activeElectionClient.value.clientId)
    ) {
      dispatch(
        setActiveElectionClient(
          some({
            clientId: clientForCurrentElection.axiomClientId,
            clientName: clientForCurrentElection.clientName,
            investmentVehicles: [
              {
                investmentVehicleId:
                  clientForCurrentElection.axiomInvestmentVehicleId,
                name: clientForCurrentElection.investmentVehicleName,
              },
            ],
          })
        )
      );
    }
  }, [
    activeElectionClient,
    dispatch,
    clientForCurrentElection,
    mdmInvestmentVehicleId,
  ]);

  useEffect(() => {
    if (!electionRoundId || !clientForCurrentElection) {
      return;
    }

    const investmentVehicleId =
      clientForCurrentElection.axiomInvestmentVehicleId;

    const currentElection: IGetElectionDetailsRequestPayload = {
      electionRoundId,
      investmentVehicleId,
      isAdmin: true,
    };

    if (
      !isSomething(activeElection) ||
      activeElection.value.electionRoundId !== electionRoundId ||
      activeElection.value.investmentVehicleId !== investmentVehicleId
    ) {
      dispatch(setActiveElection(currentElection));
    }
  }, [dispatch, electionRoundId, clientForCurrentElection, activeElection]);
};
