import { Breadcrumbs, Grid, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { IDashboardCardContentProps } from "../../../../components/DashboardCard/DashboardCard";
import { HoverableChartLegends } from "../../../../components/HoverableChartLegends/HoverableChartLegends";
import { TreeNumber } from "../../../../constants/enums";
import { ALL_FUNDS_PLACEHOLDER } from "../../../../constants/InvestmentBreakdownConstants";
import { UnrealizedValueLabels } from "../../../../constants/LabelAndTooltipConstants";
import { setPointerType } from "../../../../redux/actions/viewActions";
import {
  ICarryCardData,
  ICarryChartData,
  IInvestmentBreakdownDatum,
} from "../../../../types/dataTypes";
import { isSomething } from "../../../../types/typeGuards";
import { nothing, Optional, some } from "../../../../types/typeUtils";
import {
  createCarryChartList,
  filterToPositiveCarryByBusinessUnit,
  filterToPositiveCarryByFund,
  sortAndReduceCarryChartData,
} from "../../../../utils/carryCardUtils";
import { mapCarryDataToChartLegends } from "../../../../utils/charting";
import styles from "./Carry.module.scss";
import { CarryChart } from "./CarryChart/CarryChart";

export const Carry = (props: IDashboardCardContentProps<ICarryCardData>) => {
  const dispatch = useDispatch();

  const { investmentBreakdownGrouped, calloutData } = props.data;
  const [selectedBusinessUnit, setSelectedBusinessUnit] =
    useState<Optional<ICarryChartData>>(nothing);
  //Set unrealized carry value
  const unrealizedCarry = isSomething(calloutData.unrealized)
    ? calloutData.unrealized.value.unrealizedCarry.value
    : undefined;

  const carryCardChartData: ICarryChartData[] | undefined = useMemo(() => {
    if (isSomething(investmentBreakdownGrouped)) {
      let filtererdInvestments: IInvestmentBreakdownDatum[] = [];

      if (isSomething(selectedBusinessUnit)) {
        /*
          When there's a business unit selected, we should show
          investments grouped by funds for that selected 
          business unit.
        */
        filtererdInvestments = filterToPositiveCarryByFund(
          investmentBreakdownGrouped.value[TreeNumber.BUSINESS_UNIT],
          false,
          selectedBusinessUnit.value.businessUnitName
        );
      } else {
        /*
          When there's not business unit selected, we should show
          investments grouped by business unit level.
        */
        filtererdInvestments = filterToPositiveCarryByBusinessUnit(
          investmentBreakdownGrouped.value[TreeNumber.BUSINESS_UNIT]
        );
      }
      // Convert IInvestmentBreakdownDatum list into ICarryChartData list
      const carryChartList = createCarryChartList(filtererdInvestments);
      /* 
        Sort and reduce the data to show the top 5 and all the remaining
        as 'Other' category
      */
      return sortAndReduceCarryChartData(
        carryChartList,
        isSomething(selectedBusinessUnit)
      );
    }
  }, [investmentBreakdownGrouped, selectedBusinessUnit]);

  /*
    handleLegendClick function handles the click on left side legends
    On business unit level, we should show data grouped by funds.
    On funds level, the legends shouldn't change anything.
  */
  const handleLegendClick = useCallback(
    (legendItemName: string, event: React.PointerEvent) => {
      dispatch(setPointerType(event.pointerType));
      if (event.pointerType === "mouse") {
        setActiveIndex(undefined);
        const carryChartData = carryCardChartData?.find(
          (data) => data.businessUnitName === legendItemName
        );
        if (
          carryChartData &&
          carryChartData.mdmFundId === ALL_FUNDS_PLACEHOLDER
        ) {
          setSelectedBusinessUnit(some(carryChartData));
        }
      } else {
        const selectedIndex = carryCardChartData?.findIndex(
          (data) =>
            data.businessUnitName === legendItemName ||
            data.fundShortName === legendItemName
        );
        setActiveIndex(selectedIndex);
      }
    },
    [carryCardChartData, dispatch]
  );

  /*
    handleAllBusinessClick function handles the click on
    "All Business Unit" navigation crumb.
  */
  const handleAllBusinessClick = () => {
    if (isSomething(selectedBusinessUnit)) {
      setSelectedBusinessUnit(nothing);
    }
  };

  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} md={4} className={styles.legends}>
        <Breadcrumbs className={styles.breadcrumbs}>
          <span onClick={handleAllBusinessClick}>
            <Typography
              variant="subtitle1"
              style={{
                cursor: isSomething(selectedBusinessUnit)
                  ? "pointer"
                  : "default",
              }}
            >
              {UnrealizedValueLabels.BUSINESS_UNITS}
            </Typography>
          </span>
          {isSomething(selectedBusinessUnit) && (
            <Typography variant="subtitle1" className={styles.funds}>
              {selectedBusinessUnit.value.businessUnitName}
            </Typography>
          )}
        </Breadcrumbs>
        {carryCardChartData && carryCardChartData.length > 0 && (
          <HoverableChartLegends
            legends={mapCarryDataToChartLegends(
              carryCardChartData,
              handleLegendClick,
              isSomething(selectedBusinessUnit)
            )}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
      </Grid>
      <Grid item xs={12} md={8} className={styles.chartContainer}>
        <CarryChart
          carryChartData={carryCardChartData ?? []}
          unrealizedCarry={
            isSomething(selectedBusinessUnit)
              ? selectedBusinessUnit.value.totalUnrealized
              : unrealizedCarry
          }
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          setSelectedBusinessUnit={setSelectedBusinessUnit}
        />
      </Grid>
    </Grid>
  );
};
