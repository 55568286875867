import dayjs from "dayjs";

import { EntityType } from "../constants/enums";
import { BankAccountsResponseDto } from "../services/bankAccountsService";
import {
  AccountNumberType,
  AccountType,
  BankAccountsResponse,
  BankIdType,
  GPBankAccount,
  GPInvestmentVehicle,
  GPPendingBankAccount,
  IBankAccount,
  WSSBankAccount,
  WSSBankAccountDetail,
} from "../types/bankAccountDataTypes";
import { QuarterDate } from "../types/dataTypes";
import { isSomething } from "../types/typeGuards";
import { Json, nothing, Optional, some } from "../types/typeUtils";

// Rounds a number to nearest integer
// If the value is not a number or it is zero or near-zero it will return zero.
// Otherwise, it will round the number to the nearest integer.
export const convertToRoundedValidNumber = (
  value: number | string | undefined
): number => {
  const numberValue = Number(value);
  if (isNaN(numberValue) || Math.abs(numberValue) < 0.001) return 0;
  return Math.round(parseFloat(numberValue.toFixed(2)));
};

// Rounds a number to nearest 2-digit deicmal
export const convertToTwoDigitRoundedDecimal = (
  value: number | string | undefined
): string => {
  const numberValue = Number(value);
  if (isNaN(numberValue)) return "0.00";
  return numberValue.toFixed(2);
};

// Converts a date string to a Date object
export const convertToDateObject = (dateString: string): Date => {
  return dayjs(dateString).toDate();
};

// Strips the date of its timezone information and converts to new date
export const convertToShortDateObject = (dateString: string): Date => {
  const dateSplit = dateString.split("+")[0].split(".")[0];
  return new Date(dateSplit.replaceAll("-", "/").replace("T", " "));
};

export const convertToQuarterDateObject = (dateString: string): QuarterDate => {
  return new QuarterDate(dateString.replaceAll("-", "/"));
};

// Converts a CST to a New york time
export const convertToNYTimeZoneDateObject = (dateString: string): Date => {
  const nyOffset = new Date()
    .toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
      timeZoneName: "longOffset",
    })
    .split(" ")[2];
  const formattedDate = dateString.replaceAll("-", "/").replace("T", " ");
  const nyFormattedDate = `${formattedDate} ${nyOffset}`;
  return new Date(nyFormattedDate);
};

export const convertToOptional = <T>(
  value: T | undefined | null
): Optional<T> => {
  if (value === undefined || value === null) {
    return nothing;
  } else {
    return some(value);
  }
};

export const convertFromOptional = <T>(value: Optional<T>): T | null => {
  return isSomething(value) ? value.value : null;
};

export const convertToOptionalDateObject = (
  sourceValue: string | null | undefined
): Optional<Date> => {
  if (sourceValue) {
    return some(convertToDateObject(sourceValue));
  }
  return nothing;
};

export const convertFromOptionalDateObject = (
  sourceValue: Optional<Date>
): string | null => {
  return isSomething(sourceValue) ? sourceValue.value.toDateString() : null;
};

export function obfuscateAccountNumber(accountNumber: string): string {
  if (accountNumber.length > 4) {
    const obfuscatedDigits = "X".repeat(Math.max(0, accountNumber.length - 4));
    const lastFourDigits = accountNumber.slice(-4);
    return obfuscatedDigits + lastFourDigits;
  }
  return accountNumber;
}

const convertWSSBankAccountDetailsDto = (
  dto: Json<WSSBankAccountDetail>
): WSSBankAccountDetail => {
  return {
    ...dto,
    accountType: dto.accountType as AccountType,
    accountNumberType: dto.accountNumberType as AccountNumberType,
    bankIdType: dto.bankIdType as BankIdType,
    lastModified: convertToDateObject(dto.lastModified),
  };
};

const convertWssBankAccountDto = (
  dto: Json<WSSBankAccount>
): WSSBankAccount => {
  return {
    main: convertWSSBankAccountDetailsDto(dto.main),
    intermediary: dto.intermediary
      ? some(convertWSSBankAccountDetailsDto(dto.intermediary))
      : nothing,
    assignments: dto.assignments,
  };
};

const convertGpBankAccountDto = (dto: Json<GPBankAccount>): GPBankAccount => {
  return {
    investmentVehicle: dto.investmentVehicle,
    bankAccount: convertWssBankAccountDto(dto.bankAccount),
  };
};

const convertBankAccountDto = (dto: Json<IBankAccount>): IBankAccount => {
  return {
    ...dto,
    accountType: dto.accountType as AccountType,
    accountNumberType: dto.accountNumberType as AccountNumberType,
    bankIdType: dto.bankIdType as BankIdType,
  };
};

const convertGpPendingBankAccountDto = (
  dto: Json<GPPendingBankAccount>
): GPPendingBankAccount => {
  return {
    ...dto,
    main: convertBankAccountDto(dto.main),
    intermediary: dto.intermediary
      ? some(convertBankAccountDto(dto.intermediary))
      : nothing,
    effectiveDate: convertToOptional(
      convertToDateObject(dto.effectiveDate ?? "")
    ),
    submittedDate: convertToOptional(
      convertToDateObject(dto.submittedDate ?? "")
    ),
    requestId: convertToOptional(dto.requestId),
  };
};

const convertGpInvestmentVehicleDto = (
  dto: Json<GPInvestmentVehicle>
): GPInvestmentVehicle => {
  return {
    ...dto,
    entityType: dto.entityType as EntityType,
  };
};

export const convertBankAccountsResponseDto = (
  dto: BankAccountsResponseDto
): BankAccountsResponse => {
  return {
    bankAccounts: dto.bankAccounts.map(convertGpBankAccountDto),
    pendingBankAccounts: dto.pendingBankAccounts.map(
      convertGpPendingBankAccountDto
    ),
    investmentVehicles: dto.investmentVehicles.map(
      convertGpInvestmentVehicleDto
    ),
  };
};
