import { Stack, Typography } from "@mui/material";
import { ICellRendererParams } from "ag-grid-enterprise";
import { emptyIfNothing, IElectCardOperatorValues } from "common";
import React from "react";

import styles from "./OperatorColumnsCellRenderer.module.scss";

interface IOperatorCellRendererProps extends ICellRendererParams {
  operatorValues: IElectCardOperatorValues;
}

export const OperatorColumnsCellRenderer = (
  props: IOperatorCellRendererProps
) => {
  const { operatorValues } = props;

  return props.node.rowPinned ? (
    <Stack id={styles.operatorRow}>
      <div id={styles.total}>{emptyIfNothing(props.value)}</div>
      <Typography id={styles.label}>
        {emptyIfNothing(operatorValues.cash)}
      </Typography>
      <Typography id={styles.label}>
        {emptyIfNothing(operatorValues.financing)}
      </Typography>
      <hr className={styles.line} />
    </Stack>
  ) : (
    <>{emptyIfNothing(props.value)}</>
  );
};
